<template>
  <div>
    <v-overlay v-if="loading" :opacity="0.3">
      <v-progress-circular indeterminate size="50">
      </v-progress-circular>
    </v-overlay>

    <base-box title="Compte">
        <div class="d-flex">
          <div>
            Nom : <span class="enhance">{{user.firstname}} {{user.lastname}}</span>
            <br/>
            Réseau : <span class="enhance">{{user.rname}}</span>
            <br/>
            Email : <span class="enhance">{{user.email}}</span>
            <br/>
            Créé le : <span class="enhance">{{stringDate(user.created_at)}}</span>
          </div>
          <v-spacer></v-spacer>
          <v-img contain class="my-2" :src="franchiseImageUrl(user.id_franchise)" max-width="120"></v-img>
        </div>
    </base-box>
<!--
SUCCESS {{hasParamSuccess ? "YES" : ""}}
FAIL {{hasParamFail ? "YES" : ""}}
<br/>
Basic {{isBasic}} Essentiel {{isEssentiel}} Pro {{isPro}} Enterprise {{isEnterprise}}
<br/>
USER stripe_payment {{user.stripe_payment}} status {{user.status}} currentPlan {{user.current_plan}}
<br/>
SUB {{subscription}}
<br/>
PLAN {{user.plan}}
-->
    <base-box title="Abonnement" v-if="displayAbo" class="mt-4">
      <div class="mt-2 ml-2 mb-2">
        Plan en cours : <span class="enhance">{{currentPlanName}}</span>
        <br/>
        Créé le : <span class="enhance">{{formatD(currentPlanStart)}}</span>
        
        <div v-if="subscription" class="mt-2">
          <p v-if="isPlanCanceled">
            Annulé le : <span class="enhance">{{formatD(user.plan.canceled_at)}}</span>
            <br/>
            Fin effective le : <span class="enhance">{{formatD(user.plan.end)}}</span>
          </p>
          <p v-else>
            Montant : <span class="enhance">{{euros(subscription.price.toFixed(2))}} par {{periode(subscription.interval)}}</span>
            <br/>
            Prochaine facturation : <span class="enhance">{{formatD(subscription.next_payment)}}</span>              
          </p>
        </div>

        <!-- no subscription = trial --> 
        <div v-else class="mt-2">
          <p v-if="isTrialEnded">
            La période d'essai de votre abonnement <span class="enhance">ESSENTIEL</span> est terminée depuis le <span>{{formatD(trialEndDate)}}</span> <v-icon color="red">mdi-close-circle</v-icon>
          </p>
          <p v-else>
            La période d'essai de votre abonnement se termine le <span class="enhance">{{formatD(trialEndDate)}}</span> <v-icon color="green">mdi-check-circle</v-icon>
          </p>
        </div>

        <p v-if="isBasic" class="mt-2">
          Note : Certaines fonctionnalités ont été réduites ou supprimées dans le plan <span class="enhance">BASIC</span>.
        </p>

        <v-btn class="mt-2" depressed @click="goPortal" v-if="user.plan">
          Gérer
          <v-img class="ml-2" src="@/assets/images/arrow-ab.png" max-width="12"></v-img>
        </v-btn>
      </div>
    </base-box>

    <base-box title="Plans" v-if="displayPlan" class="mt-4">
      <div>
        <div v-if="goodCoupon">
          <div class="d-flex justify-center align-center mb-4">
            Vous bénéficiez d'un coupon de <v-img class="mx-2" src="@/assets/images/coupon.png" max-width="75"/> à valoir sur votre prochain abonnement
        </div>
          <div class="d-flex justify-center smaller mb-4">
            (valable jusqu'au {{stringDate(user.coupon_end)}})
          </div>
        </div>

        <div class="d-flex justify-center">
          <div class="d-flex align-center">
            <h3 class="mr-4" :class="facturationColorA">Annuel</h3>
            <v-switch
              v-model="facturation"
              inset
              :ripple="false"
              class="facturation-color"
              false-value="A"
              true-value="M"
            ></v-switch>
            <h3 :class="facturationColorM">Mensuel</h3>
          </div>
        </div>

        <div class="d-flex justify-center flex-wrap" style="gap:20px;">
          <div class="plan-box">
            <img width="350" height="638" :src="require(`@/assets/images/plan-basic-${facturation}.png`)"/>
            <v-btn
              :disabled="true"
              rounded
              large
              depressed
              color="#8ACBB6"
              class="plan-button"
              @click="go('basic')"
            >
              Choisir ce plan
            </v-btn>

          </div>
          <div class="plan-box">
            <img width="350" height="638" :src="require(`@/assets/images/plan-essentiel-${facturation}.png`)" class="plan-box"/>
            <v-btn
              :disabled="disablePlan('essentiel')"
              rounded
              large
              depressed
              color="#EE9B0E"
              class="plan-button"
              @click="go('essentiel')"
            >
              Choisir ce plan
            </v-btn>
          </div>
          <div class="plan-box">
            <img width="350" height="638" :src="require(`@/assets/images/plan-pro-${facturation}.png`)" class="plan-box"/>
            <v-btn
              :disabled="disablePlan('pro')"
              rounded
              large
              depressed
              color="#EF7C00"
              class="plan-button"
              @click="go('pro')"
            >
            Choisir ce plan
            </v-btn>
          </div>
        </div>

        <div class="d-flex justify-center">
          <span class="smaller">Prix Hors Taxe</span>
        </div>
      </div>
    </base-box>

    <BaseSnackbar />

  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  const moment = require('moment');

  export default {
    name: 'Account',

    data: () => ({
      loading: false,
      subscription: null,
      plans: null,
      facturation: 'A',
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),

      hasParamSuccess() {
        return Object.keys(this.$route.query).includes('success');
      },

      hasParamFail() {
        return Object.keys(this.$route.query).includes('fail');
      },

/* */
      isUnpaid() {
        return !this.user.plan ? false : this.user.plan.status;
      },

      isPaymentAttached() {
        return this.user.stripe_payment == 'attached' || this.user.stripe_payment == 'succeded';
      },

      isSubTrial() {
        return !this.subscription ? false : (this.subscription.status == 'trialing');
      },

      isSubActive() {
        return !this.subscription ? false : (this.subscription.status == 'active');
      },
/* */

      isPlanCanceled() {
        return !this.user ? false : (this.user.plan.status == 'canceled');
      },

      currentPlanName() {
        return this.user.current_plan.toUpperCase();
      },

      currentPlanInterval() {
        if (!this.user.plan) return '';
        return this.user.plan.payment;
      },

      currentPlanStart() {
        return !this.subscription ? this.user.created_at : this.subscription.start;
      },

      trialEndDate() {
        return this.user ? this.user.trial_end_date : 0;
      },

      facturationColorM() {
        return this.facturation == 'M' ? 'plan-selected' : 'plan-not-selected';
      },

      facturationColorA() {
        return this.facturation == 'A' ? 'plan-selected' : 'plan-not-selected';
      },

      facturationColor() {
        return this.facturation == 'M' ? 'plan-mensuel' : 'plan-annuel';
      },

      goodCoupon() {
        let ok = this.user && this.user.coupon && this.user.coupon != 'used' ? true : false;
        if (!ok) return false;
        const diff = moment().diff(moment(this.user.coupon_end));
        ok = ok && diff <= 0;
        return ok;
      },

      displayAbo() {
        if (this.isMandataire && this.user.stripe_customer_id) return !this.loading;
        else if (this.isNetwork) return true;
        else return !this.loading;
      },

      displayPlan() {
        if (this.isMandataire && this.user.stripe_customer_id) return !this.loading;
        else if (this.isNetwork) return false;
        else return !this.loading;
      }

    },

    methods: {
      ...mapActions('User', [ 'stripeCreatePortal' ]),
      ...mapActions('Stripe', [ 'stripeFetchSubscription', 'stripeFetchPlans', 'stripeCreateLink', 'stripeCheckout' ]),

      stringDate(date) {
        return this.formatDate(date, 'D MMMM YYYY').replace(/^1 /, '1er ');
      },

      formatD(date) {
        return this.stringDate(date) + ' à ' + this.formatDate(date, 'HH:mm');
      },

      periode(p) {
        return p == 'month' ? 'mois' : 'an';
      },

      go(plan, interval) {
        // interval or facturation
        interval = interval ? interval : (this.facturation == 'M' ? 'month' : 'year');

        console.log("go", plan, this.currentPlanInterval, interval);
        if (0 && this.user.plan) {
          this.showYesNo({
            icon: 'alert',
            yes : "Contacter le support",
            no: "Fermer",
            message: `Le changement de plan n'est pas encore implémentée.
              <br/><br/>
              veuillez confirmer le changement de plan auprès de notre support qui le réalisera dans les meilleurs délais.
              <br/><br/>
              Merci de votre compréhension.`,
            onClick: this.contactSupport,
            dataClick: { plan: plan, interval: interval }
          });
        } else {
          this.checkout(plan, interval, 0);
        }
      },

      contactSupport(what, data) {
        if (what == 'no') return;
        console.log("contactSupport", what, JSON.stringify(data));

        const currentEngagement = this.currentPlanInterval == 'year' ? 'avec engagement annuel' : 'sans engagement';
        const newEngagement = data.interval == 'year' ? 'avec engagement annuel' : 'mensuel sans engagement';
        const subject = "Demande de changement de plan";
        const body = `Bonjour,
          %0D%0A %0D%0A
          Je souhaite changer mon plan actuel ${this.currentPlanName} ${currentEngagement}
          %0D%0A %0D%0A
          vers le plan ${data.plan.toUpperCase()} ${newEngagement}
          %0D%0A %0D%0A
          Merci.
          ${this.mailtoUser()}
        `;
        window.location.href = this.mailto('support') + '?subject=' + subject + '&body=' + body;
      },

      goPortal() {
        if (!this.isLocalhost) {
          // Sendinblue tracking
          window.sendinblue.track('portal_' + this.currentPlanName, { plan: this.currentPlanName });
        }
        // window.open(this.user.stripe_portal_url, '_blank');
        window.location.href = this.user.stripe_portal_url;
      },

      async paymentLink(plan, interval, trial) {
        console.log("paymentLink", plan, interval, trial);
        const link = await this.stripeCreateLink({plan:plan, interval:interval, trial:trial});

        if (!this.isLocalhost) {
          // Sendinblue tracking
          window.sendinblue.track('payment_' + plan, { plan: plan });
        }

        window.open(link, '_blank');
      },

      async checkout(plan, interval, trial) {
        const session = await this.stripeCheckout({customerId: this.user.stripe_customer_id, plan:plan, interval:interval, trial:trial});
        console.log("checkout SESSION", session);
        if (!session) {
          this.showSnackbarServerError();
        } else {
          window.location.href = session.url; // payment url
        }
      },

      disablePlan(plan) {
        if (this.user && this.user.plan && this.user.plan.type && plan == this.user.plan.type) {
          const fact = this.user.plan.payment == 'month' ? 'M' : 'A';
          return fact == 'A' || fact == this.facturation;
        } 
        return false;
      },
    },

    async mounted() {
      if (this.user.stripe_customer_id) {
        this.loading = true;

        const result = await this.stripeCreatePortal({uuid:this.userUUID, url:window.location.href});
        console.log("mounted result", result);
        if (result) {
          if (this.user.plan && this.user.plan.stripe_subscription_id) {
            this.subscription = await this.stripeFetchSubscription(this.user.plan.stripe_subscription_id);
            console.log("SUBSCRIPTION", JSON.stringify(this.subscription));
          } else {
            console.log("NO SUBSCRIPTION");
          }

          // this.plans = await this.stripeFetchPlans(this.user.type);
          // console.log(this.plans);
        }

        this.loading = false;

        if (this.hasParamFail || !result) {
          this.showSnackbarServerError();
        }
      } else {
        // NO STRIPE ID
      }
    }
  }
</script>

<style lang="scss">
.my-sheet {
  color: white;
  text-align: center;
}

.plan-title {
  font-size: 22px;
  font-weight: 600;
  margin-top: 10px;
}

.plan-price-month {
  font-size: 20px;
  font-weight: 600;
  margin-top: 30px;
}

.plan-selected {
  color: #005466;
}

.plan-not-selected {
  color: #CCC;
}

.facturation-color .v-input--selection-controls__input div {
  color: #089F8F !important;
}

.plan-box {
  position: relative;
  transition: all .2s linear;
}

.plan-box:hover {
  filter: drop-shadow(4px 4px 4px rgba(33,33,33,.2));
  /*transform: translateY(-3px);*/
}

.plan-box .plan-button {
  position: absolute;
  left: 50%;
  bottom: 28px;
  transform: translate(-50%, -50%);
  color: white;
}

.enhance {
  font-weight: 600;
}


</style>
