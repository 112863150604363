<template>
  <div>
  <vue-apex-charts type="line" :height="height" :width="width" :options="options" :series="series"></vue-apex-charts>
  </div>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'SparkLine',

    components: {
      VueApexCharts
    },

    props: {
      data: {
        type: Array,
      },
      color: {
        type: String,
        default: '#005F73'
      },
      width: {
        type: Number,
        default: 100
      },
      height: {
        type: Number,
        default: 35
      },
      theme: {
        type: String,
        default: 'light'
      }
    },

    data() {
      return {
        options: {
          series: [{
            data: []
          }],
          chart: {
            type: 'line',
            width: this.width,
            height: 35,
            sparkline: {
              enabled: true
            },
          },
          stroke: {
            width: 2,
          },
          tooltip: {
            x: {
              show: false,
            },
            y: {
              formatter: function (v) {
                  return v.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ") + " ";
              },
              title: {
                formatter: function(serieName) {
                  return '';
                }
              }
            },
            marker: {
              show: false
            },
            theme: this.theme
          }
        }
      }
    },

    computed: {
      series() {
        return [{ data: this.data }];
      },

      // fontColor() {
      //   return "color:" + (this.negative && this.value < 0 ? "#C4280D" : this.color);
      // }
    },

    methods: {
    },

    mounted() {
    }
  }
</script>

<style scoped>
</style>