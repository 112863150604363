export default {
  computed: {
    dashboardSteps() {
      return [
        {
          target: '#step-dashboard-1',
          header: {
            title: 'Mon réseau',
          },
          content: `Retrouvez ici les mandataires que vous avez invités ou qui vous ont invité.
          <br/>Invitez des mandataires à partir de leur fiche et recevez des notification par emails de leurs activités.`,
          offset: -100
        },
        {
          target: '#step-dashboard-2',
          header: {
            title: 'Mandataires favoris',
          },
          content: `Retrouvez ici les mandataires que vous avez mis en favoris à partir de leur fiche.`,
          offset: -70,
          before: async () => {
            return '#step-dashboard-3';
            // if (0 && (this.amIMandataire || this.isNetworkAgences) && this.userFavorisMandataires.length) return '#step-dashboard-2'; else return '#step-dashboard-3';
          },
        },
        {
          target: '#step-dashboard-3',
          header: {
            title: 'Zones favorites',
          },
          content: `Retrouvez ici les communes que vous avez mis en favoris à partir de la carte.
          <br/>La liste est par défaut remplie avec votre localité principale.`,
          offset: -70,
          params: {
            placement: 'bottom' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-dashboard-4',
          header: {
            title: 'Nouveau Mandataires sur vos zones',
          },
          content: `Retrouvez ici les mandataires nouvellement créés sur l'ensemble de vos communes.
          <br/>Vous pouvez filtrer en cliquant sur les zones.`,
          offset: -70,
          params: {
            placement: 'bottom' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-dashboard-5',
          header: {
            title: 'Dernières annonces sur vos zones',
          },
          content: `Retrouvez ici l'activité sur vos zones.
          <br/>Vous pouvez filtrer le tableau tableau et trier les lignes en cliquant sur les entêtes de colonnes.`,
          offset: -300,          
          params: {
            placement: 'top' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        }
      ]
    },

    mandataireSteps() {
      return [
        {
          target: '#step-mandataire-1',
          header: {
            title: 'Profil',
          },
          content: `Retrouvez ici les informations du mandataire.
          <br/>Vous pouvez ajouter le mandataire dans vos favoris, l'inviter, centrer la carte sur sa ville et voir sa fiche sur son réseau.`,
          offset: -70
        },
        {
          target: '#step-mandataire-2',
          header: {
            title: 'Indicateurs',
          },
          content: 'Retrouvez ici indicateurs clé du mandataire.',
          offset: -70
        },
        {
          target: '#step-mandataire-3',
          header: {
            title: 'Activité',
          },
          content: `Retrouvez ici toute l'activité du mandataire.
            <br/>Pour certains réseaux qui ne donnent pas l'information, la partie vente est vide.`,
          offset: -240,
          // before: async () => {
            // return '#step-mandataire-4';
            // if (0 && (this.amIMandataire || this.isNetworkAgences) && this.userFavorisMandataires.length) return '#step-mandataire-2'; else return '#step-mandataire-3';
          // },
        },
        {
          target: '#step-mandataire-4',
          header: {
            title: 'Mandats',
          },
          content: 'Passez le curseur sur le graphique pour afficher dynamiquement les annonces en cours, vendues ou supprimées.',
          offset: -70,
          params: {
            placement: 'right' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-mandataire-5',
          header: {
            title: 'Ventes cumulées',
          },
          content: 'Si elles sont présentes, passez le curseur sur le graphique pour afficher dynamiquement les ventes.',
          offset: -70,
          params: {
            placement: 'left' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-mandataire-6',
          header: {
            title: 'Zones',
          },
          content: 'Cette partie vous permet de filter les Mandats et Ventes par commune.',
          offset: -70,
          params: {
            placement: 'top' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-mandataire-7',
          header: {
            title: 'Mandats et Ventes',
          },
          content: 'Retrouvez ici tous les mandats en cours du mandataire ainsi que toutes ses ventes passées.',
          offset: -300,
          params: {
            placement: 'top' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        }
      ]
    },

    simulSteps() {
      return [
        {
          target: '#step-simul-1',
          header: {
            title: 'Formulaire',
          },
          content: `Paramétrer ici votre chiffre d'affaires pour connaître instantanément la simulation de vos revenus dans le tableau. 
          <br/><br/>Si votre réseau vous permet de créer votre réseau de filleuls, vous pouvez le préciser dans la simulation.`,
          offset: -200
        },
      ]
    },

    mapboxSteps() {
      return [
        {
          target: '#step-map-1',
          header: {
            title: 'Carte',
          },
          content: `La carte est centrée sur votre commune et est découpée/colorée selon la variable dans l'entête (par défaut 'Nombre de mandataires').
          <div style="text-align: left; margin-top: 6px">
          <ul>
            <li>Clic sur une commune : affiche les données relatives à la commune</li>
            <li>Clic sur 'Réseaux' : affiche les réseaux d'une commune au passage de la souris</li>
            <li>Clic déplacer sur la carte : déplace le centre de la carte</li>
            <li>Clic sur +/- permet de zoomer/dézoomer la carte</li>
          </ul>
          </div>`,
          params: {
            placement: 'top' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-map-2',
          header: {
            title: `Recherche`,
          },
          content: `Recherchez une commune pour centrer la carte dessus.`,
          params: {
            placement: 'bottom' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-map-3',
          header: {
            title: `Variable d'affichage`,
          },
          content: `Sélectionnez la variable de coloriage de la carte.`,
          params: {
            placement: 'bottom' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
        {
          target: '#step-map-4',
          header: {
            title: 'Echelle',
          },
          content: `L'échelle indique 4 couleurs. La verte représente le meilleur cas selon la variable choisie dans l'entête.`,
          params: {
            placement: 'right-end' // https://popper.js.org/popper-documentation.html#Popper.placements
          }
        },
      ]
    },

    reseauxSteps() {
      return [
        {
          target: '#step-reseaux-1',
          header: {
            title: 'Liste des réseaux',
          },
          content: `Retrouver ici tous les réseaux avec pour chacun les tendances du nombre de mandataires, d'annonces et de ventes.`,
          offset: -150,
          params: {
            placement: 'bottom-start'
          }
        },
      ]
    },

    recruitSteps() {
      return [
        {
          target: '#step-recruit-1',
          header: {
            title: 'Liste des mandataires',
          },
          content: `Retrouver ici tous les mandataires pour lesquels vous avez entâmer un processus de recrutement à partir de leur fiche.`,
          offset: -150,
          params: {
            placement: 'bottom-start'
          }
        },
        {
          target: '#step-recruit-2',
          header: {
            title: 'Dates des événements',
          },
          content: `Suivez les étapes de recrutement du mandataire en ajoutant les dates des actions que vous menez.`
        },
        {
          target: '#step-recruit-3',
          header: {
            title: 'informations du mandataire',
          },
          content: `Notre robot peut collecter les informations (téléphone, adresse email) du mandataire sur le site de leur réseau.
          <br/><br/>Une fois récupérées, passez le curseur sur les informations pour les copier dans le presse-papier.`,
          params: {
            placement: 'left',
          }
        },
      ]
    },
  },

  methods: {
    forceDisplayTour(tour) {
      try {
        this.tourStart(this.$tours[this.firstUpper(tour)]);
      } catch(err) {
        console.log('no tour available');
      }
    },

    displayTour(tour) {
      const variable = 'first' + this.firstUpper(tour);
      if (process.env.NODE_ENV == 'production' && !localStorage.getItem(variable)) {
        this.tourStart(this.$tours[tour]);
        localStorage.setItem(variable, '1');
      }
    },

    tourStart(tour) {
      tour.options.labels.buttonPrevious = 'Précédent';
      tour.options.labels.buttonNext = 'Suivant';
      tour.options.labels.buttonSkip = 'Passer';
      tour.options.labels.buttonStop = 'Fin';

      tour.start();
    }
  }
}