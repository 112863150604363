import Vue from "vue";
import Vuex from "vuex";
import API from './modules/api.module';
import Mandataire from './modules/mandataire.module';
import Reseau from './modules/reseau.module';
import Map from './modules/map.module';
import Commune from './modules/commune.module';
import ReseauA from './modules/reseauA.module';
import User from './modules/user.module';
import Stripe from './modules/stripe.module';
// import Tasks from './modules/tasks.module';
// import Searches from './modules/searches.module';
// import Deals from './modules/deals.module';
// import Offers from './modules/offers.module';
// import Campaigns from './modules/campaigns.module';
// import Users from './modules/users.module';
// import Franchises from './modules/franchises.module';
// import Emails from './modules/emails.module';
// import Templates from './modules/templates.module';
// import Conversations from './modules/conversations.module';
// import Partners from './modules/partners.module';
// import Sharings from './modules/sharings.module';
// import Stripe from './modules/stripe.module';
// import SelPersons from './modules/selpersons.module';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    Variable: null, // in MapBox ?

    // NOT USED ?
    Sidebar_drawer: null,
    Customizer_drawer: false,
    SidebarColor: "white", //Change Sidebar Color || 'white', | "#2b2b2b" | "rgb(44, 59, 164)" | "rgb(96, 44, 164)" | "rgb(151, 210, 219)" | "rgb(77, 86, 100)"
    SidebarBg: "",
    navbarColor: "primary",
    setHorizontalLayout: false, // Horizontal layout

    zonesUpdated: true, // default to force loading without cache
  },
  mutations: {
    SET_SIDEBAR_DRAWER(state, payload) {
      state.Sidebar_drawer = payload;
    },
    SET_CUSTOMIZER_DRAWER(state, payload) {
      state.Customizer_drawer = payload;
    },
    SET_SIDEBAR_COLOR(state, payload) {
      state.SidebarColor = payload;
    },
    SET_NAVBAR_COLOR(state, payload) {
      state.navbarColor = payload;
    },
    SET_LAYOUT(state, payload) {
      state.setHorizontalLayout = payload;
    },
    SET_VARIABLE(state, payload) {
      state.Variable = payload;
    },
    SET_ZONES_UPDATED(state, payload) {
      state.zonesUpdated = payload;
    }
  },
  getters: {},
  actions: {},
  modules: {
      API,
      Mandataire,
      Reseau,
      Map,
      Commune,
      ReseauA,
      User,
      Stripe,
      // Tasks,
      // Searches,
      // Deals,
      // Offers,
      // Campaigns,
      // Users,
      // Franchises,
      // Emails,
      // Templates,
      // Conversations,
      // Partners,
      // Sharings,
      // Stripe,
      // SelPersons,
   },
});
