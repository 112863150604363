import Router from 'vue-router';

import Map from './views/Map.vue';
import Simul from './views/Simul.vue';
import Mapbox from './views/Mapbox.vue';
import Reseau from './views/Reseau.vue';
import ReseauInfo from './views/ReseauInfo.vue';
import Welcome from './views/Welcome.vue';
import Mandataire from './views/Mandataire.vue';
import Dashboard from './views/Dashboard.vue';
import Ragences from './views/Ragences.vue';
import Signup from './views/Signup.vue';
import Signin from './views/Signin.vue';
import Account from './views/Account.vue';
import Marketplace from './views/Marketplace.vue';
import NotFound from './views/NotFound.vue';
import DesktopOnly from './views/DesktopOnly.vue';
import Maintenance from './views/Maintenance.vue';
import Reseaux from './views/Reseaux.vue';
import Recruit from './views/Recruit.vue';
import Commune from './views/Commune.vue';

// import D3 from './views/D3.vue';

import store from "./store/store";

const router = new Router({
  mode: 'history',
  routes: [
    {
      path: '*',
      name: 'notfound',
      component: NotFound
    },
    {
      path: '*',
      name: 'desktop-only',
      component: DesktopOnly
    },
    {
      path: '*',
      name: 'maintenance',
      component: Maintenance
    },

    {
      path: "/auth",
      component: () => import("@/layouts/blank-layout/Blanklayout"),
      children: [
        {
          name: "signup",
          path: "signup/:uid?",
          component: Signup
        },
        {
          name: "signin",
          path: "signin",
          component: Signin
        },
      ]
    },

    {    
      name: 'root',
      path: '/',
      redirect: '/dashboard',
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "map",
          path: "map",
          component: Map,
          meta: {
            auth: ['m','rm','ra','a'],
            help: true,
          },
        },
        {
          name: "welcome",
          path: "/welcome",
          component: Welcome,
          meta: {
            auth: ['m','rm','ra','a'],
          }
        },
        {
          name: "dashboard",
          path: "dashboard",
          component: Dashboard,
          meta: {
            auth: ['m','rm','ra','a'],
            help: true,
          },
        },
        {
          name: "simul",
          path: "/simul",
          component: Simul,
          meta: {
            auth: ['rm'],
            help: true
          },
        },
        {
          name: "mapbox",
          path: "/mapbox/:id?",
          component: Mapbox,
          meta: {
            auth: ['m','rm','ra','a'],
            help: true
          },
        },
        {
          name: "reseau",
          path: "/reseau/:id",
          component: Reseau,
          meta: {
            auth: ['rm','ra'], // TODO: rm & ra only !
          },
        },
        {
          name: "reseauInfo",
          path: "/reseau/:id/info",
          component: ReseauInfo,
          meta: {
            auth: ['rm','ra'], // TODO: rm & ra only !
          },
        },
        {
          name: "mandataire",
          path: "/mandataire/:id",
          component: Mandataire,
          meta: {
            auth: ['m','rm','ra','a'],
            help: true
          },
        },
        {
          name: "reseaux",
          path: "/reseaux",
          component: Reseaux,
          meta: {
            auth: ['rm','ra'],
            help: true
          },
        },
        {
          name: "recruit",
          path: "/recrutement",
          component: Recruit,
          meta: {
            auth: ['rm','ra'],
            help: true,
          },
        },
        {
          name: "ragences",
          path: "/ragences/:id",
          component: Ragences,
          meta: {
            auth: ['ra','m'],
          },
        },
        {
          name: "commune",
          path: "/commune/:id",
          component: Commune,
          meta: {
            auth: ['m','rm','ra','a'],
          },
        },
        {
          name: "account",
          path: "/account/:uid?",
          component: Account,
          meta: {
            auth: ['m','rm','ra','a'],
          },
        },
        {
          name: "marketplace",
          path: "/offres",
          component: Marketplace,
          meta: {
            auth: ['m'],
          },
        },
        // {
        //   name: "d3",
        //   path: "/d3",
        //   component: D3
        // }
      ]
    },
  ]
});

const deviceType = () => {
  const ua = navigator.userAgent;
  if (/(tablet|ipad|playbook|silk)|(android(?!.*mobi))/i.test(ua)) {
    return 'tablet';
  } else if (/Mobile|Android|iP(hone|od)|IEMobile|BlackBerry|Kindle|Silk-Accelerated|(hpw|web)OS|Opera M(obi|ini)/.test(ua)) {
    return 'mobile';
  }
  return 'desktop';
};

const paramsCoupon = (s) => {
  // s == uuid or will-uuid / end-uuid
  let a = s.match(/will-(.*)/);
  if (a) {
    return [ 'will', a[1] ];
  } else {
    a = s.match(/end-(.*)/);
    if (a) {
      return [ 'end', a[1] ];
    }
  }
  return [ null, s ];
};

router.beforeEach(async (to, from, next) => {
  // check settings maintenance
  if (to.name != 'maintenance') {
    const settings = await store.dispatch('API/fetchSettings');
    if (settings.maintenance) return next({name:'maintenance'});
  }

  // prevent right click
  if (!window.location.href.match(/localhost/)) window.document.addEventListener('contextmenu', event => event.preventDefault());

  // check mobile
  if (to.name != 'desktop-only' && deviceType() == 'mobile') {
    return next({name:'desktop-only'});
  }

  if (1 || !window.location.href.match(/localhost/)) {
    // Sendinblue tracking
    window.sendinblue.page(to.name, {ma_title: to.name});

    // window.gtag('set', 'page_path', to.name);
    // window.gtag('event', 'page_view');
  }

  // to erase all when blocked 
  // await store.dispatch('User/raz');

  // get user from uuid
  const uuid = store.getters['User/userUUID'];
  if (uuid && uuid != 'undefined' && uuid != null) {
    await store.dispatch('User/fetchUserInfo', uuid);
    const user = store.getters['User/user'];

    // console.log("beforeEach", JSON.stringify(to.meta.auth), uuid, user.type);

    // check rights to go
    if (to.meta.auth && !to.meta.auth.includes(user.type)) {
      next({name:'notfound'});
    } else if (to.name == 'account' && to.params.uid) {
      // url from will-end/end email = /account/will-uuid or /account/end-uuid
      const [coupon, couponUuid] = paramsCoupon(to.params.uid);
      if (coupon && couponUuid) {
        await store.dispatch('User/updateUserCoupon', {userUuid: couponUuid, coupon: coupon});
        next({name: 'account'}); // not param
      }
    } else {
      next();
    }
  } else {
    if (to.name == 'account') {
      if (to.params.uid) {
        // url from will-end/end email = /account/will-uuid or /account/end-uuid
        const [coupon, couponUuid] = paramsCoupon(to.params.uid);
        if (coupon && couponUuid) {
          await store.dispatch('User/updateUserCoupon', {userUuid: couponUuid, coupon: coupon});
        }
      }
      next({name: 'signin'});
    }
    // else if (to.name == 'signup') {
    //   if (window.location.href.match(/agentbis/)) {
    //     next({name: 'signin'});
    //   } else {
    //     next();
    //   }
    // } 
    else if (to.name != 'signin') {
      next({name: 'signin'});
    } else {
      next();
    }
  }
})

// router.beforeResolve((to, from, next) => {
  // // If this isn't an initial page load.
  // if (to.name) {
  //   // Start the route progress bar.
  //   NProgress.start(800);
  // }
  // next();
// });

// router.afterEach(() => {
  // Complete the animation of the route progress bar.
  // NProgress.done();
// });

export default router;
