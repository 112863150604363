import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: '#005F73',
				secondary: '#EE9B00',
				tertiary: '#0A9396',
				quaternary: '#CA6702',
				quinary: '#94D2BD',
				senary: '#E9D8A6',
				septenary: '#FFFFFF',
				octonary: '#FFFFFF',
				nonary: '#FFFFFF',
				denary: '#001219',
				accent: '#9B2226',
				error: '#9B2226',
				anchor: '#0A9396',
			},
			dark: {
				primary: '#005F73',
				secondary: '#EE9B00',
				accent: '#E9D8A6',
				error: '#9B2226',
				anchor: 'red',
			}
		},
	},
});
