import { render, staticRenderFns } from "./Marketplace.vue?vue&type=template&id=760cabd0&scoped=true&"
import script from "./Marketplace.vue?vue&type=script&lang=js&"
export * from "./Marketplace.vue?vue&type=script&lang=js&"
import style0 from "./Marketplace.vue?vue&type=style&index=0&id=760cabd0&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "760cabd0",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VImg } from 'vuetify/lib/components/VImg';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VImg,VOverlay,VProgressCircular,VSpacer})
