<template>
  <base-box :title="titleBox">
    <div class="mt-4" v-if="lastCA.length == 0">
      Aucune donnée pour ce réseau.
    </div>
    <div v-else>
      <div class="d-flex mt-4">
        <div class="mr-4">
            <div v-if="anneesCA.length > 0">
              <span><b>Chiffre d'affaires</b></span>
              <vue-apex-charts
                ref="evolCA"
                class="mx-auto"
                :width="plotWidth"
                :height="plotHeight"
                type="line"
                :options="evolCA"
                :series="chartCA"
              ></vue-apex-charts>
            </div>

            <div v-if="anneesRes.length > 0">
              <span><b>Résultat net</b></span>
              <vue-apex-charts
                ref="evolRes"
                class="mx-auto"
                :width="plotWidth"
                :height="plotHeight"
                type="line"
                :options="evolRes"
                :series="chartRes"
              ></vue-apex-charts>
            </div>

            <div v-if="anneesEff.length > 0">
              <span><b>Effectif</b></span>
              <vue-apex-charts 
                ref="evolEff"
                class="mx-auto"
                :width="plotWidth"
                :height="plotHeight"
                type="line"
                :options="evolEff"
                :series="chartEff"
              ></vue-apex-charts>
            </div>
        </div>

        <v-simple-table dense>
          <template v-slot:default>
            <thead>
              <tr>
                <td></td>
                <td v-for="data in lastCA" :key="data.annee">
                  <b>{{data.annee}}</b>
                </td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td colspan="6"><b>Performance</b></td>
              </tr>
              <tr>
                <td>Chiffre d'affaires (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.chiffre_affaires,1)}}
                </td>
              </tr>
              <tr>
                <td>Variation (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.variation_ca,1)}}
                </td>
              </tr>
              <tr>
                <td>CA moyen / mandataire (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.avg_ca_mandataires,1)}}
                </td>
              </tr>
              <tr>
                <td>Marge brute (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.marge_brute,1)}}
                </td>
              </tr>
              <tr>
                <td>EBITDA - EBE (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(-(data.taux_marge_EBITDA-data.excedent_brut_exploitation),2)}}
                </td>
              </tr>
              <tr>
                <td>Résultat d'exploitation (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.resultat_exploitation,2)}}
                </td>
              </tr>
              <tr>
                <td>Résultat net (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.resultat,2)}}
                </td>
              </tr>

              <tr>
                <td colspan="6"><b>Croissance</b></td>
              </tr>
              <tr>
                <td>Taux de croissance du CA (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.taux_croissance_chiffre_affaires,1)}}
                </td>
              </tr>
              <tr>
                <td>Taux de marge d'EBITDA (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.taux_marge_EBITDA,1)}}
                </td>
              </tr>
              <tr>
                <td>Taux de marge opérationnelle (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.taux_marge_operationnelle,1)}}
                </td>
              </tr>
              <tr>
                <td colspan="6"><b>Gestion BFR</b></td>
              </tr>
              <tr>
                <td>BFR (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.BFR,2)}}
                </td>
              </tr>
              <tr>
                <td>BFR exploitation (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.BFR_exploitation,2)}}
                </td>
              </tr>

              <tr>
                <td colspan="6"><b>Autonomie financière</b></td>
              </tr>
              <tr>
                <td>Capacité d'autofinancement (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.capacite_autofinancement,2)}}
                </td>
              </tr>
              <tr>
                <td>Capacité d'autofinancement / CA (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.capacite_autofinancement_CA,1)}}
                </td>
              </tr>
              <tr>
                <td>Fonds de roulement net global (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.fonds_roulement_net_global,2)}}
                </td>
              </tr>
              <tr>
                <td>Trésorerie (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.tresorerie,2)}}
                </td>
              </tr>
              <tr>
                <td>Dettes financières (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.dettes_financieres,0)}}
                </td>
              </tr>
              <tr>
                <td>Capacité de remboursement</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.capacite_remboursement,1)}}
                </td>
              </tr>
              <tr>
                <td>Autonomie financière (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.autonomie_financiere,1)}}
                </td>
              </tr>

              <tr>
                <td colspan="6"><b>Solvabilité</b></td>
              </tr>
              <tr>
                <td>État des dettes à 1 an au plus (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.etat_dettes_1_an_au_plus,2)}}
                </td>
              </tr>
              <tr>
                <td>Liquidité générale</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.liquidite_generale,1)}}
                </td>
              </tr>
              <tr>
                <td>Fonds propres (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.fonds_propres,2)}}
                </td>
              </tr>

              <tr>
                <td colspan="6"><b>Rentabilité</b></td>
              </tr>
              <tr>
                <td>Marge nette (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.marge_nette,1)}}
                </td>
              </tr>
              <tr>
                <td>Rentabilité sur fonds propres (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.rentabilite_fonds_propres,1)}}
                </td>
              </tr>
              <tr>
                <td>Rentabilité économique (%)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.rentabilite_economique,1)}}
                </td>
              </tr>
              <tr>
                <td>Valeur ajoutée (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.valeur_ajoutee,2)}}
                </td>
              </tr>
              
              <tr>
                <td colspan="6"><b>Structure d'activité</b></td>
              </tr>
              <tr>
                <td>Salaires et charges sociales (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.salaires_charges_sociales,2)}}
                </td>
              </tr>
              <tr>
                <td>Impôts et taxes (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.impots_taxes,0)}}
                </td>
              </tr>
              <tr>
                <td>Chiffre d'affaires à l'export (€)</td>
                <td v-for="data in lastCA" :key="data.annee">
                  {{displayNumber(data.chiffre_affaires_export,1)}}
                </td>
              </tr>

            </tbody>
          </template>
        </v-simple-table>
      </div>
    </div>

    <!-- {{infos.pappers}} -->
  </base-box>
</template>

<script>
  import axios from 'axios';
  // import { EventBus } from '@/event-bus.js';
  import VueApexCharts from 'vue-apexcharts';
  import VectorMap from '@/components/VectorMap';
  import Scale from '@/components/Scale';
  import Sheet from "@/components/Sheet";
  // import GraphLine from "@/components/GraphLine";
  import GraphBar from "@/components/GraphBar";
  import BaseBox from "@/components/commonComponents/BaseBox";
  import { mapGetters, mapActions } from "vuex";
  import mixinApex from "@/mixins/mixin-apex";

  const ss = require('simple-statistics');
  const moment = require('moment');

  export default {
    name: 'ReseauInfo',
    mixins: [mixinApex],

    components: {
      VueApexCharts,
      VectorMap,
      Scale,
      Sheet,
      // GraphLine,
      GraphBar,
      BaseBox,
    },

    data: () => ({
      infos: {},
      lastCA: [],
      plotWidth: 600,
      plotHeight: 300,
      chartCA: [],
      chartRes: [],
      evolCA: {
        noData: {
          text: 'Chargement ...'
        },
        chart: {
          type: 'line',
          stacked: false,
          toolbar: {
            show: false
          },
          events: {
          }
        },
        colors: ['#008FFB'],
        fill: {
          opacity: [1],
        },
        plotOptions: {
        },
        dataLabels: {
          enabled: false,
          // enabledOnSeries: [0],
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        title: {
          text: "",
          align: 'center'
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          }
        },
        yaxis: [],
        tooltip: {
          enabled: true,
          shared: false,
          intersect: true,
        },
        xaxis: {
          categories: [],
          title: {
            text: ''
          },
        },
        markers: {
          size: 4
        },
      },
      evolRes: {},
      evolEff: {},
    }),

    computed: {
      ...mapGetters('User', [ 'user' ]),
      // ...mapGetters('ReseauA', [ 'currentReseauA' ]),

      titleBox() {
        // return this.infos.pappers ? `Données financières ${this.infos.pappers.nom_entreprise}` : '';
        return this.infos.name ? `Données financières ${this.infos.name}` : '';
      },

      anneesCA() {
        return this.infos.pappers.finances.filter(f => f.chiffre_affaires).map(f => f.annee);
      },

      cagrCA() {
        const values = this.infos.pappers.finances.map(f => f.chiffre_affaires);
        return ((Math.pow(values[values.length-1] / values[values.length-5], (1 / 5)) - 1) * 100).toFixed(1);
      },

      cagrRes() {
        const values = this.infos.pappers.finances.map(f => f.resultat);
        return ((Math.pow(values[values.length-1] / values[values.length-5], (1 / 5)) - 1) * 100).toFixed(1);
      },

      anneesRes() {
        return this.infos.pappers.finances.filter(f => f.resultat).map(f => f.annee);
      },

      anneesEff() {
        return this.infos.pappers.finances.filter(f => f.effectif).map(f => f.annee);
      },

      currentDate() {
        return moment().format('D MMMM YYYY').replace(/^1 /, '1er ');
      },
    },

    methods: {
      ...mapActions('Reseau', [ 'fetchReseauPappers' ]),

      async fetch() {
        this.infos = await this.fetchReseauPappers(this.id);

        // console.log(this.infos);

        // merge years with DATAFARM
        if (this.infos.datafarm) {
          for (let data of this.infos.datafarm.data.ca) {
            if (!this.infos.pappers.finances.find(ca => ca.annee == data.year)) this.infos.pappers.finances.push({annee: parseInt(data.year), chiffre_affaires: parseInt(data.count)});
          }
        }

        // compute avg mandataire per year
        for (let i=0; i<this.infos.pappers.finances.length; i++) {
          const mands = this.infos.datafarm.data.mandataires.filter(m => m.year == this.infos.pappers.finances[i].annee).map(m => parseInt(m.count));
          if (mands.length) {
            const avg = ss.mean(mands);
            this.infos.pappers.finances[i].avg_ca_mandataires = this.infos.pappers.finances[i].chiffre_affaires / avg;
          } else {
            this.infos.pappers.finances[i].avg_ca_mandataires = null;
          }
        }

        // update options and series
        const self = this;
        setTimeout(() => {
          self.infos.pappers.finances = self.infos.pappers.finances.sort((a,b) => a.annee-b.annee);

          self.apexSetOptions('evolCA', {
            xaxis: {
              categories: self.infos.pappers.finances.map(f => f.annee),
            },
            tooltip: {
              x: {
                show: false,
                formatter: function (val) {
                  return self.infos.pappers.finances[val-1].annee;
                }
              },
              y: {
                formatter: function (val) {
                  return self.bigNumber(Math.abs(val))
                }
              },
            },
          });

          self.chartCA = [
            {
              name: 'CA',
              data: self.infos.pappers.finances.map(f => f.chiffre_affaires),
              type: 'line',
            }
          ];


          self.apexSetOptions('evolRes', {
            xaxis: {
              categories: self.anneesRes,
            },
            tooltip: {
              x: {
                show: false,
                formatter: function (val) {
                  return self.anneesRes[val-1];
                }
              },
              y: {
                formatter: function (val) {
                  return self.bigNumber(Math.abs(val))
                }
              },
            },
          });

          self.chartRes = [
            {
              name: 'Résultat',
              data: self.infos.pappers.finances.filter(f => f.resultat).map(f => f.resultat),
              type: 'line',
            }
          ];

          self.apexSetOptions('evolEff', {
            xaxis: {
              categories: self.anneesEff,
            },
            tooltip: {
              x: {
                show: false,
                formatter: function (val) {
                  return self.anneesEff[val-1];
                }
              },
              y: {
                formatter: function (val) {
                  return self.bigNumber(Math.abs(val))
                }
              },
            },
          });

          self.chartEff = [
            {
              name: 'Résultat',
              data: self.infos.pappers.finances.filter(f => f.effectif).map(f => f.effectif),
              type: 'line',
            }
          ];
        });
      
        // last CA
        this.lastCA = this.infos.pappers.finances.sort((a,b) => b.annee-a.annee).slice(0,6);

        // compute CA variation
        for (let i=0; i<this.lastCA.length-1; i++) {
          this.lastCA[i].variation_ca = (this.lastCA[i].chiffre_affaires / this.lastCA[i+1].chiffre_affaires - 1) * 100;
        }
        console.log(this.lastCA);

        this.lastCA = this.lastCA.slice(0,5);
      },

      displayNumber(n, decimals) {
        if (!n) return '-';
        if ((Math.abs(n) / 1000000) > 1) {
          return (n/1000000).toFixed(decimals) + 'M';
        } else if ((Math.abs(n) / 100000) > 1) {
          return (Math.round(n/1000)).toFixed(decimals) + 'k';
        } else if ((Math.abs(n) / 10000) > 1) {
          return (Math.round(n/1000)).toFixed(decimals) + 'k';
        }
        return n.toFixed(decimals);
      }
    },

    async mounted() {
      if (!this.isEnterprise) {
        this.showPremiumDialog('enterprise');
      }

      this.id = this.$route.params.id;
      this.evolRes = {...this.evolCA};
      this.evolEff = {...this.evolCA};

      await this.fetch();
    }
  }
</script>

<style scoped>
  
</style>