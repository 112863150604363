<template>
  <div v-if="isEnterprise || isNetwork">
    <v-overlay v-if="loading" :opacity="0.3">
      <v-progress-circular indeterminate size="50">
      </v-progress-circular>
    </v-overlay>

    <base-box v-if="stats">
      <div class="d-flex">
        <div>
          <b>{{stats.info.name}}</b>
          <br/>
          Siège social : {{stats.info.city}}, {{stats.info.nom_departement}} ({{stats.info.departement}}) - Créé en <span :class="blurEssentiel">{{stats.info.start_year}}</span>
          <br/>
          Carte pro : {{stats.info.procard}}
          <br/>
          <span :class="blurEssentiel"><strong>{{bigNumber(stats.mandataires.nb)}}</strong></span> mandataires, <span :class="blurEssentiel"><strong>{{bigNumber(stats.annonces.nb)}}</strong></span> annonces
          <span v-if="stats.info.has_sold">
            et <span :class="blurEssentiel"><strong>{{bigNumber(stats.ventes.nb)}}</strong></span>  ventes au {{currentDate}}
          </span>
          <span v-else>
            - aucune donnée de vente
          </span>
          <div class="mt-2">
            <v-btn
              outlined
              x-small
              color="primary"
              @click="gotoReseauInfo"
            >
              Données financières
              <v-icon right small color="primary">
                mdi-finance
              </v-icon>
            </v-btn>
          </div>
        </div>
        <v-spacer></v-spacer>
        <v-img max-width="160" max-height="120" contain :src="franchiseImageUrl(stats.info.id)" class="pointer" @click="gotoReseau(stats.info.url)"></v-img>
      </div>
    </base-box>

    <base-box class="mt-4" title="Indicateurs" v-if="!loading && stats">
      <v-row justify="space-around" class="mt-3">
        <v-col align="center">
          <sheet
            :value="Math.round(stats.top_mandataires_zerosale.length / stats.mandataires.nb * 100)+'%'"
            title="% mandataires inactifs<br/>(sans mandat)"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center">
          <sheet
            :value="Math.round(stats.top_mandataires_zerosale3.length / stats.mandataires.nb * 100)+'%'"
            title="% mandataires<br/>(sans mandat depuis 3 mois)"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center">
          <sheet
            :value="Math.round(stats.last_distrib_tosale[1].nb / stats.mandataires.nb * 100)+'%'"
            title="% mandataires<br/>(avec 1 mandat)"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center" v-if="stats.info.has_sold">
          <sheet
            :value="Math.round(stats.top_mandataires_zerosold3.length / stats.mandataires.nb * 100)+'%'"
            title="% mandataires<br/>(sans vente depuis 3 mois)"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center" v-if="stats.info.has_sold">
          <sheet
            :value="Math.round(stats.top_mandataires_zerosold6.length / stats.mandataires.nb * 100)+'%'"
            title="% mandataires<br/>(sans vente depuis 6 mois)"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <!-- <v-col align="center">
          <sheet
            :value="stats.indics.avgMandNew"
            title="Moyenne nouveaux<br/>mandataires"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center">
          <sheet
            :value="stats.indics.avgMandRemoved"
            title="Moyenne mandataires<br/>partis"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col> -->
        <!-- <v-col align="center">
          <sheet
            :value="bigNumber(stats.indics.newAnnonces)"
            title="Nouveaux mandats"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col>
        <v-col align="center" v-if="stats.info.has_sold">
          <sheet
            :value="bigNumber(stats.indics.newSold)"
            title="Nouvelles ventes"
            :width="80"
            :blurry="blurEssentiel"
          ></sheet>
        </v-col> -->
      </v-row>
    </base-box>

    <base-box class="mt-4" title="Maillage territorial" v-if="!loading">
      <div v-if="isEnterprise">
        <div style="width:300px" class="mx-auto">
          <v-select
            class="mt-4"
            label="Variable"
            :items="variables"
            v-model="variable"
          ></v-select>
        </div>

        <div class="div-donut">
          <vue-apex-charts
            class="donut"
            ref="donutOptions"
            height="230"
            type="donut"
            :options="donutOptions"
            :series="donutSeries"
          ></vue-apex-charts>  
        </div>

        <div class="d-flex flex-row">
          <div class="map-parent mx-auto">
            <scale
              class="map-scale"
              :gcolors="scaleColors"
              :labels="scaleLabelsD"
              :size="60"
            ></scale>
            <vector-map v-if="!loading"
              name="departements"
              type="departements"
              :gradients="gradients[1]"
              :gcolors="gcolors"
              @click="deptClick"
              @hover="deptHover"
              @out="deptOut"
              @tip="deptTip"
            >
            <!-- :selected="[44]" -->
            <!-- selcolor="yellow" -->
            <!-- :colors="dcolors" -->
            </vector-map>
            
            <!-- <vector-map v-if="!loading"
              name="bassins"
              type="bassins"
              :gradients="gradients[1]"
              :gcolors="gcolors"
            >
            </vector-map> -->

            <!-- <vector-map v-if="!loading"
              name="toto"
              type="toto"
              :gradients="gradients[1]"
              :gcolors="gcolors"
            >
            </vector-map> -->
           

            <vue-apex-charts v-if="!loading"
              class="mt-6"
              ref="treemapDept"
              type="treemap"
              height="500"
              :options="treeMapOptions"
              :series="treeMapSeriesDept">
            </vue-apex-charts>

          </div>
          <div class="map-parent mx-auto">
            <scale
              class="map-scale"
              :gcolors="scaleColors"
              :labels="scaleLabelsR"
              :size="60"
            ></scale>
            <vector-map v-if="!loading"
              name="regions"
              type="regions"
              :zoomable="false"
              :gradients="gradients[0]"
              :gcolors="gcolors"
              @click="regionClick"
              @hover="regionHover"
              @out="regionOut"
              @tip="regionTip"
            >
            </vector-map>

            <vue-apex-charts v-if="!loading"
              class="mt-6"
              ref="treemapRegion"
              type="treemap"
              height="500"
              :options="treeMapOptions"
              :series="treeMapSeriesRegion">
            </vue-apex-charts>
          </div>
        </div>
      </div>
      <div v-else>
        <v-img
          width="1200"
          src="@/assets/images/specimen-reseau-1.png"
        ></v-img>
    </div>
    </base-box>
    
    <base-box class="mt-4" title="Statistique Annonces / Mandataire" v-if="!loading && stats">
      <div class="d-flex justify-center">
        <graph-bar
          name="barAnnoncesMand"
          title="Annonces / Mandataire" 
          :data="barAnnoncesMand" 
          :stacked="false"
          :colors="['#7449D7', '#F68F15']"
          :width="800" 
          :height="350"
          :showDataLabel="false"
          :separatorWidth="2"
          labelX="# annonces"
          labelY="# mandataires"
          legendPosition="bottom"
          :delayToDisplay="400"
          :tooltip="tooltipAM"
          >
        </graph-bar>
      </div>
    </base-box>

    <base-box class="mt-4" title="Evolution nombre de mandataires & annonces" v-if="!loading">
      <div class="d-flex">
        <div>  
          <vue-apex-charts v-if="isEnterprise"
            ref="EvolNbMandataires"
            class="mx-auto"
            width="1000"
            height="500"
            type="bar"
            :options="evolNbMandatairesChartOptions"
            :series="chartSeries"
          ></vue-apex-charts>
          <v-row justify="space-around" class="mt-3" v-else>
            <v-col align="center">  
              <v-img
                width="900"
                src="@/assets/images/specimen-reseau-mands.png"
              ></v-img>
            </v-col>
          </v-row>
        </div>
        <div class="ml-6 mt-8">
            <span :style="`color:${evolNbMandatairesChartOptions.colors[0]}`">Mandataires</span>
            <sheet
              :value="croissanceMands.nb"
              title=""
              :width="80"
              class="mt-4"
              negative
            ></sheet>
            <sheet
              :value="croissanceMands.perc"
              suffixe="%"
              title=""
              :width="80"
              negative
            ></sheet>
        </div>
        <div class="ml-6 mt-8">
            <span :style="`color:${evolNbMandatairesChartOptions.colors[1]}`">Annonces</span>
            <sheet
              :value="croissanceMands.nbAnnonces"
              title=""
              :width="80"
              class="mt-4"
              negative
            ></sheet>
            <sheet
              :value="croissanceMands.percAnnonces"
              suffixe="%"
              title=""
              :width="80"
              negative
            ></sheet>
        </div>
        <div class="ml-6 mt-8" v-if="stats.info.has_sold">
            <span :style="`color:${evolNbMandatairesChartOptions.colors[2]}`">&nbsp;&nbsp;Ventes</span>
            <sheet
              :value="croissanceMands.nbVentes"
              title=""
              :width="80"
              class="mt-4"
              negative
            ></sheet>
            <sheet
              :value="croissanceMands.percVentes"
              suffixe="%"
              title=""
              :width="80"
              negative
            ></sheet>
        </div>
      </div>

      <div v-if="0" class="d-flex">
        <graph-bar v-if="!loading"
          class="mx-auto mt-6"
          name="agesCount" title="Répartition selon l'ancienneté des mandataires dans le réseau par tranches de temps" 
          :data="agesCount" 
          :width="700" :height="350" :stacked="false"
          legendPosition="bottom"
          valueSuffix="%"
        >
        </graph-bar>
      </div>
      <!-- <v-row justify="space-around" class="mt-4">
        <v-col align="center" >
          <graph-line
            name="evolAnnonces" 
            title="Nombre d'annonces créés"
            :data="evolAnnonces" 
            :width="580" :height="350">
          </graph-line>
        </v-col>
        <v-col align="center" >
          <graph-line v-if="stats.info.has_sold"
            name="evolAnnoncesSold" 
            title="Nombre biens vendus"
            :data="evolAnnoncesSold" 
            :width="580" :height="350">
          </graph-line>
        </v-col>
      </v-row> -->
      <div v-if="1" class="d-flex">
        <vue-apex-charts
          ref="evolAnnoncesMix"
          class="mt-6 mx-auto"
          height="500"
          width="800"
          type="bar"
          :options="chartEvolAnnoncesOptions"
          :series="seriesEvolAnnonces"
        ></vue-apex-charts>
      </div>

      <div class="d-flex" v-if="0 && dateEvolNbMandats>=0">
        <div class="mx-auto">
          <vue-apex-charts
            ref="evolNbMandats"
            class="mt-4"
            height="500"
            width="800"
            type="bar"
            :options="chartEvolNbMandatsOptions"
            :series="seriesEvolNbMandats"
          ></vue-apex-charts>

          {{ticksEvolNbMandats[dateEvolNbMandats]}}
          <v-slider
            v-model="dateEvolNbMandats"
            step="1"
            min="0"
            :max="maxEvolNbMandats"
            ticks="always"
            tick-size="4"
          ></v-slider>
        </div>
      </div>

    </base-box>

    <base-box class="mt-4" title="Communes actives" v-if="!loading">
      Centrage : <span :class="blur">{{bigNumber(ckmeanPop[0])}}</span> à <span :class="blur">{{bigNumber(ckmeanPop[ckmeanPop.length-1])}}</span> habitants
      <br/>
      <v-row justify="space-around" class="mt-3">
        <v-col align="center">  
          <vue-apex-charts v-if="isEnterprise"
            ref="maillage"
            height="500"
            width="700"
            type="bar"
            :options="chartMaillageOptions"
            :series="seriesMaillage"
          ></vue-apex-charts>
          <v-img
            v-else
            width="720"
            src="@/assets/images/specimen-reseau-centrage.png"
          ></v-img>
        </v-col>
      </v-row>
    </base-box>

<!--
    <base-box class="mt-4" :title="`Classement - ${variableLabel}`" v-if="!loading">
      <p class="mt-2"><b>Départements</b></p>
      <v-simple-table height="400">
        <template v-slot:default>
          <tbody>
            <tr v-for="top in topDepts" :key="top.code">
              <td style="padding-right:10"> {{top.name}} ({{top.code}})</td>
              <td style="padding-right:10" v-for="reseau of top.top.slice(0,3)" :key="reseau.id">
                <router-link style="text-decoration:none" :to="{ name: 'reseau', params: {id: reseau.id } }">{{reseau.name}}</router-link>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
      
      <p class="mt-4"><b>Régions</b></p>
      <v-simple-table height="400">
        <template v-slot:default>
          <tbody>
            <tr v-for="top in topRegions" :key="top.code">
              <td style="padding-right:10">{{top.name}} ({{top.code}})</td>
              <td style="padding-right:10" v-for="reseau of top.top.slice(0,3)" :key="reseau.id">
                <router-link style="text-decoration:none" :to="{ name: 'reseau', params: {id: reseau.id } }">{{reseau.name}}</router-link>
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </base-box>
-->

    <base-box class="mt-4" title="Top / Down Mandataires" v-if="!loading">
      <div class="d-flex">
        <v-chip-group active-class="primary--text" column mandatory v-model="currentTopMand">
          <v-chip small v-for="top in topMand" 
            :key="top.key"
            :value="top.key"
            active-class="chip-active"
            outlined
          >
            {{top.label}}
          </v-chip>
        </v-chip-group>
        <v-text-field
          dense
          class="ml-4 searchtextinput"
          placeholder="rechercher"
          v-model="searchMand"
          @input="inputSearch"
        ></v-text-field>
      </div>

      <v-data-table
        fixed-header
        style="overflow: auto"
        height="600"
        dense
        :headers="headersTopMand"
        :items="currentTopMandList"
        item-key="id"
        disable-pagination
        hide-default-footer
        class="table-top-mand"
        :search="searchMand"
        :custom-filter="customFilter"
        :custom-sort="customSort"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="mand in items" :key="mand.id"
               :class="mand.url ? 'pointer' : ''" @click="gotoMandataire(mand)"
            >
              <td class="text-center" v-if="!currentTopMand.match(/zero/)">
                {{mand.rank}}
              </td>
              <td class="text-center">
                <div class="d-flex">
                  <div class="ml-4 mt-2">
                    <photo-mand
                      :obj="mand"
                      :size="40"
                    ></photo-mand>
                  </div>
                  <div class="ml-4 mt-4">
                    <span :class="blur"><b>{{mand.firstname}} {{mand.lastname}}</b></span>
                  </div>
                </div>
              </td>
              <td class="text-center">
                <span v-if="mand.city" :class="blur">{{mand.city}} ({{mand.zip}})</span>
              </td>
              <td class="text-center">
                <span :class="blur">{{formatDate(mand.d, 'D MMM YYYY')}}</span>
              </td>
              <td class="text-center" v-if="!currentTopMand.match(/zero/)">
                <span :class="blur">{{mand.nb}}</span>
              </td>
              <td class="text-center" v-if="!currentTopMand.match(/zero/)">
                <span :class="blur">{{mand.value2}}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </base-box>

    <base-box class="mt-4" title="Top Villes" v-if="!loading">
      <div class="d-flex">
        <v-chip-group active-class="primary--text" column mandatory v-model="currentTopCities">
          <v-chip small v-for="top in topCities" 
            :key="top.key"
            :value="top.key"
            active-class="chip-active"
            outlined
          >
            {{top.label}}
          </v-chip>
        </v-chip-group>
        <v-text-field
          dense
          class="ml-4 searchtextinput"
          placeholder="rechercher"
          v-model="searchCities"
          @input="inputSearch"
        ></v-text-field>
      </div>

      <v-data-table
        fixed-header
        style="overflow: auto"
        height="600"
        dense
        :headers="headersTopCities"
        :items="currentTopCitiesList"
        item-key="id"
        disable-pagination
        hide-default-footer
        class="table-top-mand"
        :search="searchCities"
        :custom-filter="customFilter"
        :custom-sort="customSort2"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="city in items" :key="city.id" 
              class="pointer" @click="gotoCity(city.id)"
            >
              <td class="text-center">
                {{city.rank}}
              </td>
              <td class="text-center">
                <span :class="blur">{{city.nom_commune_complet}} ({{city.code_postal}})</span>
              </td>
              <td class="text-center">
                <span :class="blur">{{city.dept}} ({{city.code_departement}})</span>
              </td>
              <td class="text-center">
                <span :class="blur">{{city.region}} ({{city.code_region}})</span>
              </td>
              <td class="text-center">
                <span :class="blur">{{city.pop ? bigNumber(city.pop) : '-'}}</span>
              </td>
              <td class="text-center">
                <span :class="blur">{{city.nb}}</span>
              </td>
            </tr>
          </tbody>
        </template>
      </v-data-table>
    </base-box>
    
    <base-box class="mt-4" title="Maillage territorial" v-show="0 && !loading && isEnterprise">
      <div class="d-flex">
        <div class="mx-auto">
          <vector-map
            name="time"
            type="departements"
          >
          </vector-map>
          <div>
            <v-btn
              class="mr-2"
                color="blue"
                dark
                depressed
                fab
                small
                @click="evolStartStop"
              >
              <v-icon>
                {{ evolPlaying ? 'mdi-pause' : 'mdi-play' }}
              </v-icon>
            </v-btn>
            {{ticksEvol[dateEvol]}}
            <v-slider
              v-model="dateEvol"
              step="1"
              min="0"
              :max="maxEvol"
              ticks="always"
              tick-size="4"
            ></v-slider>
          </div>
        </div>
      </div>
    </base-box>
  
  </div>
</template>

<script>
  import axios from 'axios';
  // import { EventBus } from '@/event-bus.js';
  import VueApexCharts from 'vue-apexcharts';
  import VectorMap from '@/components/VectorMap';
  import Scale from '@/components/Scale';
  import Sheet from "@/components/Sheet";
  // import GraphLine from "@/components/GraphLine";
  import GraphBar from "@/components/GraphBar";
  import BaseBox from "@/components/commonComponents/BaseBox";
  import PhotoMand from "@/components/PhotoMand";
  import { mapGetters, mapActions } from "vuex";
  import mixinApex from "@/mixins/mixin-apex";

  window.jQuery = require('jquery'); // npm -i jquery
  var $ = window.jQuery;

  const ss = require('simple-statistics');
  const moment = require('moment');

  export default {
    name: 'Reseau',
    mixins: [mixinApex],

    components: {
      VueApexCharts,
      VectorMap,
      Scale,
      Sheet,
      // GraphLine,
      GraphBar,
      BaseBox,
      PhotoMand,
    },

    data: () => ({
      scaleLabels: {},
      scaleColors: [ '#2362ca', '#2385c7', '#23a7c3', '#23cac0' ],
      dateEvol: 0,
      ticksEvol: [],
      datesEvol: [],
      evolPlaying: false,
      evolInter: null,
      dateEvolNbMandats: -1,
      datesEvolNbMandats: [],
      ticksEvolNbMandats: [],
      evolAnnonces: [],
      evolAnnoncesSold: [],
      donutOptions: {
        chart: {
          width: 100,
          type: 'donut',
          animations: {
            enabled: false,
          }
        },
        labels: [],
        colors: [],
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            colors: [ "#000" ],
          },
          formatter : function(value, data) {
            return data.w.config.labels[data.seriesIndex] + ":" + data.w.config.series[data.seriesIndex]
          },
          background: {
            enabled: true,
            foreColor: '#fff',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 0,
            borderColor: '#000',
          },
          dropShadow: {
            enabled: false,
          }
        },
        legend: {
          show: false,
          position: 'left',
          offsetY: 0,
          height: 230,
        }
      },
      donutSeries: null,
      barAnnoncesMand: {
        series: [],
        labels: [],
      },
      tooltipAM: {
        enabled: true,
        y: {
          title: {
            formatter: (s) => '',
          }
        },
        x: {
          show: false
        }
      },
      loading: false,
      id: null,
      stats: null,
      series: null,
      dept: '-',
      region: '-',
      topDepts: [],
      topRegions: [],
      gradients: [ {}, {} ],
      //   '64': 1.0,
      //   '65': 2.0,
      //   '66': 3.0,
      //   '67': 4.0,
      // },
      // gcolors: ['#d896ab', '#b0013a'], 
      gcolors: ['#23CAC0', '#2362CA'],      
      dcolors: {
        '24': 'blue',
        '76': 'green'
      },
      variable: 'mand', // default
      variables: [
        {
          text: 'Nombre mandataires',
          value: 'mand'
        },
        {
          text: 'Nombre mandats',
          value: 'annonces'
        },
        {
          text: 'Nombre ventes',
          value: 'ventes'
        },
        {
          text: "Chiffre d'affaires (M€)",
          value: 'ca'
        },
        {
          text: 'Communes actives',
          value: 'activity'
        },
      ],

      chartSeries: [],

      evolNbMandatairesChartOptions: {
        noData: {
          text: 'Chargement ...'
        },
        chart: {
          type: 'bar',
          height: 500,
          stacked: false,
          toolbar: {
            show: false
          },
          events: {
            mouseLeave: function(event, chartContext, config) {
              chartContext.clearAnnotations();
            },
            mouseMove: function(event, chartContext, config) {
              // console.log("dataPointIndex", config.dataPointIndex, "seriesIndex", config.seriesIndex, "len", config.globals.seriesXvalues.length);
              // console.log("config", config);
              // console.log(config.globals.clientX, config.globals.clientY);
              for (let idx=0; idx<config.globals.seriesXvalues[0].length; idx++) {
                if (config.globals.clientX-140 >= config.globals.seriesXvalues[0][idx]-3 && config.globals.clientX-140 <= config.globals.seriesXvalues[0][idx]+3) {

                  chartContext.clearAnnotations();

                  let text = `${config.globals.series[0][idx]} Mandataires - ${config.globals.series[1][idx]} Annonces`;

                  chartContext.addXaxisAnnotation({
                    x: config.config.xaxis.categories[idx],
                    label: {
                      text: ''
                    },
                  });

                  chartContext.addPointAnnotation({
                    x: config.config.xaxis.categories[idx],
                    y: config.globals.series[0][idx],
                    yAxisIndex: 0,
                    label: {
                      text: config.globals.series[0][idx],
                      borderColor: '#008FFB',
                    },
                  });
                  chartContext.addPointAnnotation({
                    x: config.config.xaxis.categories[idx],
                    y: config.globals.series[1][idx],
                    yAxisIndex: 1,
                    label: {
                      text: config.globals.series[1][idx],
                      borderColor: '#FF4560',
                    },
                  });
                  if (config.globals.series.length > 2) {
                    chartContext.addPointAnnotation({
                      x: config.config.xaxis.categories[idx],
                      y: config.globals.series[2][idx],
                      yAxisIndex: 2,
                      label: {
                        text: config.globals.series[2][idx],
                        borderColor: '#5C970A',
                      },
                    });
                  }
                  
                  break;
                }
              }
            }
          }
        },
        colors: ['#008FFB', '#FF4560', '#5C970A'],
        fill: {
          opacity: [0.2, 1, 1],
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            barHeight: '100%',
            dataLabels: {
              position: 'top',
            }
          },

        },
        dataLabels: {
          enabled: false,
          // enabledOnSeries: [0],
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        title: {
          text: "",
          align: 'center'
        },
        stroke: {
          width: 3,
          curve: 'smooth'
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          }
        },
        yaxis: [],
        tooltip: {
          enabled: true,
          shared: false,
          intersect: true,
          x: {
            show: true,
            formatter: function (val) {
              return val
            }
          },
          y: {
            formatter: function (val) {
              return Math.abs(val)
            }
          },
        },
        xaxis: {
          categories: [],
          title: {
            text: ''
          },
        },
        markers: {
          size: 2
        },
      },
      franchiseColors: [],

      seriesMaillage: [],
      ckmeanPop: [],
      chartMaillageOptions: {
        noData: {
          text: 'Chargement ...'
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        colors: ['#008FFB', '#FF4560'],
        plotOptions: {
          bar: {
            horizontal: true,
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          offsetX: -15,
          formatter: function(val) {
            return val+'%';
          },
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          }
        },
        title: {
          text: 'Activités sur les communes selon leur population',
          align: 'center'
        },
        xaxis: {
          categories: [],
          title: {
            text: ''
          },
        },
      },

      seriesEvolAnnonces: [],
      chartEvolAnnoncesOptions: {
        noData: {
          text: 'Chargement ...'
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        colors: ['#FF4560', '#5C970A'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          enabled: false,
          offsetY: -25,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          }
        },
        title: {
          text: 'Entrées Mandats et Ventes',
          align: 'center'
        },
        xaxis: {
          categories: [],
          title: {
            text: ''
          },
        },
      },

      seriesEvolNbMandats: [],
      chartEvolNbMandatsOptions: {
        noData: {
          text: 'Chargement ...'
        },
        chart: {
          type: 'bar',
          toolbar: {
            show: false
          },
        },
        colors: ['#008FFB'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '80%',
            dataLabels: {
              position: 'top',
            },
          },
        },
        dataLabels: {
          offsetY: -25,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          }
        },
        title: {
          text: 'Distribution nombre de mandataires selon nombre de mandats',
          align: 'center'
        },
        xaxis: {
          categories: [],
          title: {
            text: ''
          },
        },
      },

      treeMapOptions: {
        chart: {
          type: "treemap",
          toolbar: {
            show: false
          },
        },
      },

      treeMapSeriesDept: [],
      treeMapSeriesRegion: [],

      agesCount: {},
      headers: [
        { text: "#", value: "rank", align: 'center', show: true },
        { text: "Mandataire", value: "", align: 'center', show: true, sortable: false },
        { text: "Ville", value: "city", align: 'center', show: true },
        { text: "Entrée", value: "d", align: 'center', show: true },
        { text: "", value: "nb", align: 'center', show: true },
        { text: "", value: "value2", align: 'center', show: true },
      ],

      currentTopMand: 'annonces',
      topMand: [
        { key: 'annonces', 'label': "Nombre d'annonces moyen" },
        { key: 'ventes', 'label': "Nombre de ventes moyen" },
        { key: 'exclusivity', 'label': "% d'exclusivité" },
        { key: 'zerosale', 'label': "Sans mandat" },
        { key: 'zerosale3', 'label': "Sans mandat depuis 3 mois" },
        { key: 'zerosold3', 'label': "Sans vente depuis 3 mois" },
        { key: 'zerosold6', 'label': "Sans vente depuis 6 mois" },
      ],

      headersCities: [
        { text: "#", value: "rank", align: 'center', show: true },
        { text: "Ville", value: "city", align: 'center', show: true },
        { text: "Département", value: "dept", align: 'center', show: true },
        { text: "Région", value: "region", align: 'center', show: true },
        { text: "Population", value: "pop", align: 'center', show: true },
        { text: "", value: "nb", align: 'center', show: true },
      ],

      currentTopCities: 'annonces',
      topCities: [
        { key: 'annonces', 'label': "Nombre d'annonces moyen" },
        { key: 'ventes', 'label': "Nombre de ventes moyen" },
        // { key: 'exclusivity', 'label': "% d'exclusivité" },
      ],

      croissanceMands: { nb:0, perc:0 },
      searchMand: '',
      searchCities: '',
    }),

    computed: {
      ...mapGetters('User', [ 'user' ]),
      ...mapGetters('Map', [ 'mapCenter' ]),
      ...mapGetters('ReseauA', [ 'currentReseauA' ]),

      currentDate() {
        return moment().format('D MMMM YYYY').replace(/^1 /, '1er ');
      },

      maxEvol() {
        return this.ticksEvol.length-1;
      },

      maxEvolNbMandats() {
        return this.ticksEvolNbMandats.length-1;
      },

      scaleLabelsD() {
        return this.scaleLabels["nb_departements_"+this.variable];
      },

      scaleLabelsR() {
        return this.scaleLabels["nb_regions_"+this.variable];
      },

      variableLabel() {
        return this.variables.find(v => v.value == this.variable).text;
      },

      currentTopMandList() {
        if (!this.isEnterprise) return [];
        const v = 'top_mandataires_' + this.currentTopMand;
        return this.stats[v];
      },

      headersTopMand() {
        return this.headers.filter(h => h.show);
      },

      headersTopCities() {
        return this.headersCities.filter(h => h.show);
      },

      currentTopCitiesList() {
        if (!this.isEnterprise) return [];
        const v = 'top_cities_' + this.currentTopCities;
        return this.stats[v];
      },

    },

    watch: {
      variable: {
        handler(newVal, oldVal) {
          // old value
          this.razGradients('regions', 0);
          this.razGradients('departements', 1);          

          // new value
          this._computeGradients(newVal);
          this._computeTop();

          this.changeGradients('regions', 0);
          this.changeGradients('departements', 1);

          this.setTreemap(newVal);
        },
      },

      dateEvol() {
        this.setMarkers(true);
      },

      dateEvolNbMandats: {
        handler(newVal) {
          this.setEvolNbMandats(newVal);
        },
      },

      // to update column label in top mand list
      currentTopMand: {
        async handler(value) {
          if (value.match(/zero/)) {
            this.headers[0].show = false;
            this.headers[4].show = false;
            this.headers[5].show = false;
          } else {
            this.headers[0].show = true;
            this.headers[4].show = true;
            this.headers[5].show = true;
            this.headers[4].text = this.topMand.find(top => top.key == value).label;
            this.headers[5].text = value == 'exclusivity' ? "Nombre d'annonces moyen" : 'Nombre de mois';
          }
        },
        immediate: true
      },

      // to update column label in top cities list
      currentTopCities: {
        handler(value) {
          this.headersCities[5].text = this.topCities.find(top => top.key == value).label;
        },
        immediate: true
      }
    },

    methods: {
      ...mapActions('Reseau', [ 'fetchReseauColors', 'fetchReseauStats' ]),
      ...mapActions('ReseauA', [ 'fetchReseauAInfo', 'fetchReseauAAgences' ]),
      ...mapActions('Map', [ 'setMapCenter', 'setMapZoom', 'fetchDeptCenter' ]),

      changeGradients(mapName, num) {
        const map = $('#' + mapName).vectorMap('get', 'mapObject');
        map.series.regions[0].setValues(this.gradients[num]);
      },

      razGradients(mapName, num) {
        const map = $('#' + mapName).vectorMap('get', 'mapObject');
        map.reset();
      },

      setMarkerReseau() {
        console.log("setMarkerReseau", this.stats.info);
        let map = $('#departements').vectorMap('get', 'mapObject');
        const data = {latLng: [this.stats.info.lat, this.stats.info.lng], name: `${this.stats.info.name} ● ${this.stats.info.city} (${this.stats.info.zip})`};
        map.addMarkers([data], []);
        map = $('#regions').vectorMap('get', 'mapObject');
        map.addMarkers([data], []);
      },

      gradientColor(start_color, end_color, percent) {
         // strip the leading # if it's there
         start_color = start_color.replace(/^\s*#|\s*$/g, '');
         end_color = end_color.replace(/^\s*#|\s*$/g, '');

         // convert 3 char codes --> 6, e.g. `E0F` --> `EE00FF`
         if (start_color.length == 3){
           start_color = start_color.replace(/(.)/g, '$1$1');
         }

         if (end_color.length == 3){
           end_color = end_color.replace(/(.)/g, '$1$1');
         }

         // get colors
         var start_red = parseInt(start_color.substr(0, 2), 16),
             start_green = parseInt(start_color.substr(2, 2), 16),
             start_blue = parseInt(start_color.substr(4, 2), 16);

         var end_red = parseInt(end_color.substr(0, 2), 16),
             end_green = parseInt(end_color.substr(2, 2), 16),
             end_blue = parseInt(end_color.substr(4, 2), 16);

         // calculate new color
         var diff_red = end_red - start_red;
         var diff_green = end_green - start_green;
         var diff_blue = end_blue - start_blue;

         diff_red = ( (diff_red * percent) + start_red ).toString(16).split('.')[0];
         diff_green = ( (diff_green * percent) + start_green ).toString(16).split('.')[0];
         diff_blue = ( (diff_blue * percent) + start_blue ).toString(16).split('.')[0];

         // ensure 2 digits by color
         if( diff_red.length == 1 ) diff_red = '0' + diff_red
         if( diff_green.length == 1 ) diff_green = '0' + diff_green
         if( diff_blue.length == 1 ) diff_blue = '0' + diff_blue

         return '#' + diff_red + diff_green + diff_blue;
      },

      // not used (pas de evolution_latlng)
      setMarkers(boolInit) {
        if (!boolInit) {
          this.ticksEvol = [...new Set(this.stats.evolution_latlng.map(e => e.created_at))];
          this.dateEvol = 0;

          // une couleur par date
          const len = this.ticksEvol.length;
          this.datesEvol = this.ticksEvol.map((t,i) => { 
            const randomColor = this.gradientColor('#FF0000', '#00FF00', i/len);
        
            return {
              date: t,
              color: randomColor
            }
          });
          this.setMarkers(true);

        } else if (this.isEnterprise) {
          let map = $('#time').vectorMap('get', 'mapObject');

          // filter
          const theDate = this.datesEvol.find(d => d.date == this.ticksEvol[this.dateEvol]);
          let data = [];
          // markers array
          for (let de of this.datesEvol) {
            if (moment(de.date) > moment(theDate.date)) continue;
            data = data.concat(this.stats.evolution_latlng.filter(e => e.created_at == de.date).map(e => {
                return {
                  latLng: [e.lat, e.lng],
                  name: e.created_at,
                  style: { r: 3, fill: de.color }
                }
              })
            )
          }

          map.removeAllMarkers();
          map.addMarkers(data, []);
        }
      },

      evolStartStop() {
        if (!this.evolPlaying) {
          this.evolPlaying = true;
          const self = this;
          this.evolInter = setInterval(() => {
            self.dateEvol++;
            if (self.dateEvol >= self.ticksEvol.length) self.dateEvol = 0;
          }, 500);
        } else {
          this.evolPlaying = false;
          clearInterval(this.evolInter);
        }
      },

      async deptClick(code) {
        console.log("deptClick", code);

        let map = $('#departements').vectorMap('get', 'mapObject');
        map.tip.hide();
        map = $('#regions').vectorMap('get', 'mapObject');
        map.tip.hide();

        const res = await this.fetchDeptCenter(code);
        // console.log(res);
        const route = this.$router.resolve({ name: 'mapbox' , query: { lng: res.lng, lat: res.lat }});
        window.open(route.href, '_blank');
        // EventBus.$emit('searchCommune', { latitude: res.lat, longitude: res.lng });
      },

      deptHover(code) {
        const vdepts = 'departements_' + this.variable;
        const info = this.stats[vdepts].find(rd => rd.code_departement == code);
        if (1 || info) { // pour tous !
          // console.log("deptHover", code, info.nb, info.percentile_nb);
          const top = this.topDepts.find(top => top.code == code);
          top.top.length = 5; // only 5 first
          // console.log("TOP", JSON.stringify(top));

          this.donutSeries = top.top.map(t => Math.round(t.nb));
          // console.log("donutSeries", this.donutSeries, top.top.map(t => t.name));

          this.apexSetOptions('donutOptions', {
            labels: top.top.map(t => t.name),
            colors: top.top.map(t => t.color).map(c => c || '#EDEDED'),
          });
        }
      },

      deptOut() {
        this.donutSeries = [];
      },

      deptTip({el, code}) {
        const vdepts = 'departements_' + this.variable;
        const info = this.stats[vdepts].find(rd => rd.code_departement == code);
        if (info) {
          el.html(el.html()+' : '+info.nb);
        }
      },

      regionClick(code) {
        console.log("regionClick", code);

        let map = $('#departements').vectorMap('get', 'mapObject');
        map.tip.hide();
        map = $('#regions').vectorMap('get', 'mapObject');
        map.tip.hide();
      },

      regionHover(code) {
        const vregions = 'regions_' + this.variable;
        const info = this.stats[vregions].find(rm => rm.code_region == code);
        if (1 || info) { // pour toutes
          // console.log("regionHover", code, info.nb, info.percentile_nb);
          const top = this.topRegions.find(top => top.code == code);
          top.top.length = 5; // only 5 first
          // console.log("TOP", JSON.stringify(top));

          this.donutSeries = top.top.map(t => t.nb);
          this.apexSetOptions('donutOptions', {
            labels: top.top.map(t => t.name),
            colors: top.top.map(t => t.color).map(c => c || '#EDEDED'),
          });
        }
      },

      regionOut() {
        this.donutSeries = [];
      },

      regionTip({el, code}) {
        const vregions = 'regions_' + this.variable;
        const info = this.stats[vregions].find(rd => rd.code_region == code);
        if (info) {
          el.html(el.html()+' : '+info.nb);
        }
      },

      // what = (D)épartement / (R)égion
      _computePercentile(result, variable, what) {
        const clusters = 4;

        const values = result.map(r => r[variable]);
        
        // clustering
        let ckmeans = null;
        try {
          ckmeans = ss.ckmeans(values, clusters);
        } catch(err) {
          // console.log("CKMEANS NULL");
        }

        const percName = 'percentile_' + variable;

        console.log("ckmeans", variable, ckmeans);
        
        if (ckmeans == null) {
          return null;
        }

        // percentiles = 0, 1 ...
        for (let i=0; i<result.length; i++)  {
          for (let j=0; j<ckmeans.length; j++) {
            const val = result[i][variable];
            if (ckmeans[j].includes(val)) {
              result[i][percName] = j;
            }
          }
        }

        // scaleLabels : min/max
        const scaleWhat = variable + "_" + what;
        this.scaleLabels[scaleWhat] = [];
        for (let mean of ckmeans) {
          this.scaleLabels[scaleWhat].push({min:mean[0], max:mean[mean.length-1]});
        }
        this.scaleLabels[scaleWhat] = this.scaleLabels[scaleWhat].reverse();

        console.log("scale", what, scaleWhat, this.scaleLabels[scaleWhat]);

        return result;
      },

      _computeGradients(val) {
        const vregions = 'regions_' + val;
        const vdepts = 'departements_' + val;

        console.log("_computeGradients REG", this.stats[vregions]);
        console.log("_computeGradients DEP", this.stats[vdepts]);

        this.gradients[0] = {};
        for (let rm of this.stats[vregions]) {
          this.gradients[0][rm.code_region] = rm.percentile_nb;
        }
        this.gradients[1] = {};
        for (let rd of this.stats[vdepts]) {
          this.gradients[1][rd.code_departement] = rd.percentile_nb;
        }
        // console.log("regions gradients", JSON.stringify(this.gradients[0]));
        // console.log("depts gradients", JSON.stringify(this.gradients[1]));
      },

      _computeTop() {    
        console.log("computeTop", this.variable);
        let vtop = "departements_" + this.variable + "_top";
        let depts = this.stats[vtop].filter(s => s.code_departement);
        depts = [...new Map(depts.map(s => [s.code_departement, s])).values()];
        this.topDepts = [];
        for (let dept of depts) {
          this.topDepts.push({
            name: dept.nom_departement,
            code: dept.code_departement,
            top: this.stats[vtop]
                    .filter(s => s.code_departement == dept.code_departement)
                    .map(s => ({id: s.id_franchise, name: s.name, nb: s.nb, color: this.franchiseColors[s.id_franchise]}))
          });
        }

        vtop = "regions_" + this.variable + "_top";
        let regions = this.stats[vtop].filter(s => s.code_region);
        regions = [...new Map(regions.map(s => [s.code_region, s])).values()];

        this.topRegions = [];
        for (let region of regions) {
          this.topRegions.push({
            name: region.nom_region,
            code: region.code_region,
            top: this.stats[vtop]
                    .filter(s => s.code_region == region.code_region)
                    .map(s => ({id: s.id_franchise, name: s.name, nb: s.nb, color: this.franchiseColors[s.id_franchise]}))
          });
        }
        console.log("TOP DEPTS", this.topDepts);
        console.log("TOP REGIONS", this.topRegions);
      },

      setEvolNbMandats(newVal) {
        const nbMandats = this.stats.evolution_distrib_annonces[this.ticksEvolNbMandats[newVal]];
        console.log("NB MANDATS", nbMandats);
        this.seriesEvolNbMandats = [
          {
            name: 'mandats',
            data: nbMandats.map(m => m.nb_m)
          }
        ];

        this.apexSetCategories('evolNbMandats', nbMandats.map(m => {
          return (m.nba_min == m.nba_max) ? m.nba_min : m.nba_min+'-'+m.nba_max;
        }));
      },

      setTreemap(v) {
        console.log("SET TREEMAP", v);
        const vdepts = 'departements_' + v;
        this.treeMapSeriesDept = [
          {
            data: this.stats[vdepts].filter(d => d.nom_departement).map(d => ({ x: d.nom_departement, y: d.nb }))
          }
        ];

        let scaleWhat = 'nb_departements_' + v;
        const self = this;
        this.apexSetOptions('treemapDept', {
          plotOptions: {
            treemap: {
              shadeIntensity: 0,
              colorScale: {
                ranges: this.scaleLabels[scaleWhat].map((s,i) => ({
                  from: s.min,
                  to: s.max,
                  color: self.scaleColors[i]
                }))
              }
            }
          }
        });
        scaleWhat = 'nb_regions_' + v;
        this.apexSetOptions('treemapRegion', {
          plotOptions: {
            treemap: {
              shadeIntensity: 0,
              colorScale: {
                ranges: this.scaleLabels[scaleWhat].map((s,i) => ({
                  from: s.min,
                  to: s.max,
                  color: self.scaleColors[i]
                }))
              }
            }
          }
        });

        // this.apexSetOptions('treemapDept', {
        //   plotOptions: {
        //     treemap: {
        //       shadeIntensity: 0.5,
        //       colorScale: {
        //         ranges: [
        //           {
        //             from: 1,
        //             to: 7,
        //             color: '#23CAC0'
        //           },
        //           {
        //             from: 8,
        //             to: 19,
        //             color: '#23A7C3'
        //           },
        //           {
        //             from: 27,
        //             to: 47,
        //             color: '#2285C7'
        //           },
        //           {
        //             from: 8,
        //             to: 19,
        //             color: '#2362CA'
        //           }
        //         ]
        //       }
        //     }
        //   }
        // });

        const vregions = 'regions_' + this.variable;
        this.treeMapSeriesRegion = [
          {
            data: this.stats[vregions].filter(d => d.nom_region).map(d => ({ x: d.nom_region, y: d.nb }))
          }
        ];
        console.log("treeMapSeriesRegion", this.treeMapSeriesRegion);
      },

      
      _smoothOut (vector, variance) {
        const _avg = function (v) {
          return v.reduce((a,b) => a+b, 0)/v.length;
        };
        var t_avg = _avg(vector)*variance;
        var ret = Array(vector.length);
        for (var i = 0; i < vector.length; i++) {
          (function () {
            var prev = i>0 ? ret[i-1] : vector[i];
            var next = i<vector.length ? vector[i] : vector[i-1];
            ret[i] = _avg([t_avg, _avg([prev, vector[i], next])]);
          })();
        }
        return ret;
      },

      _addValue(arr, i, value, attr) {
        // console.log("_addValue", i, value ? value[attr] : 'null');
        if (value) {
          if (value[attr] > 0) {
            if (i > 0) {
              if (0 && value[attr] > (arr[i-1]*1.3) && arr[i-1] > 0) arr.push(arr[i-1]);
              else arr.push(value[attr]);
            } else {
              arr.push(value[attr]);
            }
          } else {
            if (i > 0) arr.push(arr[arr.length-1]);
            else arr.push(0);
          }
        }
        return arr;
      },

      _filterOutliers(someArray) {  
          // Copy the values, rather than operating on references to existing values
          var values = someArray.concat();

          // Then sort
          values.sort( function(a, b) {
                  return a - b;
               });

          /* Then find a generous IQR. This is generous because if (values.length / 4) 
           * is not an int, then really you should average the two elements on either 
           * side to find q1.
           */     
          var q1 = values[Math.floor((values.length / 4))];
          // Likewise for q3. 
          var q3 = values[Math.ceil((values.length * (3 / 4)))];
          var iqr = q3 - q1;

          // Then find min and max values
          var maxValue = q3 + iqr*1.5;
          var minValue = q1 - iqr*1.5;

          // Then filter anything beyond or beneath these values.
          var filteredValues = values.filter(function(x) {
              return (x <= maxValue) && (x >= minValue);
          });

          return filteredValues;
      },

      distribAnnonces(data) {
        console.log(data);
        // const ldtDistrib = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, -15, -20, -30, -40, -50, 999999];
        const ldtDistrib = [0, -3, -5, -10, -15, -20, -30, -40, -50, 999999];
        const minMax = [];
        for (let i=0; i<ldtDistrib.length-1; i++) {
            const min = ldtDistrib[i] >= 0 ? ldtDistrib[i] : Math.abs(ldtDistrib[i-1])+1;
            const max = ldtDistrib[i] >= 0 ? ldtDistrib[i] : Math.abs(ldtDistrib[i]);
            minMax.push({min, max});
        }

        minMax.push({min: Math.abs(ldtDistrib[ldtDistrib.length-2]), max: ldtDistrib[ldtDistrib.length-1]});
        console.log(minMax);
        let series2 = {
            data: [],
            labels: []
        }
        for (let i=0; i<minMax.length; i++) {
            let arr = data.filter(d => d.c >= minMax[i].min && d.c <= minMax[i].max);
            const sum = ss.sum(arr.map(v => v.nb));
            // console.log(minMax[i].min, minMax[i].max, sum);
            series2.data.push(sum);
            let label = `${minMax[i].min}`;
            label += (i == minMax.length-1) ? '-' : (minMax[i].min == minMax[i].max ? '' : `-${minMax[i].max}`);
            series2.labels.push(label)
        }
        console.log(series2);

        return series2;
      },

      async fetch() {
        this.loading = true;

        // colors for donut
        this.franchiseColors = await this.fetchReseauColors();

        const stats = await this.fetchReseauStats(this.id);
        this.stats = stats;
        console.log("STATS2", this.stats);

        // compute rank of top_mandataires_...
        for (let top of this.topMand) {
          const v = 'top_mandataires_' + top.key;
          for (let i=0; i<this.stats[v].length; i++) this.stats[v][i].rank = i+1;
          // console.log(v, this.stats[v]);
        }

        // compute rank of top_cities_...
        for (let top of this.topCities) {
          const v = 'top_cities_' + top.key;
          for (let i=0; i<this.stats[v].length; i++) this.stats[v][i].rank = i+1;
          console.log(v, this.stats[v]);
        }

        // remove stats #ventes top mand if not supported
        // remove stats #ventes top cities if not supported
        // remove ca from variables if no sold
        // on supprime les ventes si pas de données
        if  (!this.stats.info.has_sold) {
          this.topMand = this.topMand.filter(top => top.key != 'ventes');
          this.topCities = this.topCities.filter(top => top.key != 'ventes');
          this.variables = this.variables.filter(v => v.value != 'ca');
          this.variables = this.variables.filter(v => v.value != 'ventes');
          this.chartEvolAnnoncesOptions.title.text = "Entrées Mandats";
        }

        this.topMand[3].label += ` (${this.stats.top_mandataires_zerosale.length})`;
        this.topMand[4].label += ` (${this.stats.top_mandataires_zerosale3.length})`;
        this.topMand[5].label += ` (${this.stats.top_mandataires_zerosold3.length})`;
        this.topMand[6].label += ` (${this.stats.top_mandataires_zerosold6.length})`;

        this.stats.indics = {};

        // calcul déciles ages
        const totalCountAges = this.stats.ages_count.reduce((acc, a) => acc + a.count, 0);
        this.agesCount = {
          series: [
            {
              name: 'Ancienneté',
              data: this.stats.ages_count.map(ac => Math.round(ac.count / totalCountAges * 100))
            }
          ],
          labels: this.stats.ages_count.map((ac,i) => {
            return i > 1 ? ac.label_years : ac.label_months
          }),
        }

        // calcul des percentiles pour toutes les variables
        for (let v of this.variables) {
          const vregions = 'regions_' + v.value;
          const vdepts = 'departements_' + v.value;

          this.stats[vregions] = this._computePercentile(this.stats[vregions], 'nb', vregions);
          this.stats[vdepts] = this._computePercentile(this.stats[vdepts], 'nb', vdepts);
        }

        this._computeGradients(this.variable)

        // données pour le graph
        let mands = {
          dates: [],
          total: [],
          annonces_tosale: [],
          annonces_sold: [],
        };

        for (let i=0; i<this.stats.evolution_mand.length; i++) {
          const d = this.stats.evolution_mand[i].created_at;
          
          mands.dates.push(d);
          
          const tot = this.stats.evolution_mand.find(em => em.created_at == d);
          mands.total = this._addValue(mands.total, i, tot, 'nb_mandataires');

          // if (tot) {
          //   if (tot.nb_mandataires > 0) mands.total.push(tot.nb_mandataires);
          //   else {
          //     console.log("MAND 0", mands.total[mands.total.length-1]);
          //     if (i > 0) mands.total.push(mands.total[mands.total.length-1]); 
          //   }
          // }

          const tosale = this.stats.evolution_annonces_tosale.find(em => em.created_at == d);
          mands.annonces_tosale = this._addValue(mands.annonces_tosale, i, tosale, 'nb');
          // mands.annonces_tosale = this._smoothOut(mands.annonces_tosale, 0.90);

          // if (tosale) {
          //   if (tosale.nb > 0) mands.annonces_tosale.push(tosale.nb);
          //   else {
          //     console.log("TOSALE 0", mands.annonces_tosale[mands.annonces_tosale.length-1]);
          //     if (i > 0) mands.annonces_tosale.push(mands.annonces_tosale[mands.annonces_tosale.length-1]); 
          //   }
          // }

          if (this.stats.info.has_sold) {
            const sold = this.stats.evolution_annonces_sold.find(em => em.created_at == d);
            mands.annonces_sold = this._addValue(mands.annonces_sold, i, sold, 'nb');
            // if (sold) {
            //   if (sold.nb > 0) mands.annonces_sold.push(sold.nb);
            //   else {
            //     console.log("SOLD 0", mands.annonces_sold[mands.annonces_sold.length-1]);
            //     if (i > 0) mands.annonces_sold.push(mands.annonces_sold[mands.annonces_sold.length-1]);
            //   }
            // }
          }
        }
        this.croissanceMands.nb = mands.total[mands.total.length-1] - mands.total[0];
        this.croissanceMands.perc = (this.croissanceMands.nb / mands.total[mands.total.length-1] * 100).toFixed(2);

        this.croissanceMands.nbAnnonces = mands.annonces_tosale[mands.annonces_tosale.length-1] - mands.annonces_tosale.find(v => v);
        this.croissanceMands.percAnnonces = (this.croissanceMands.nbAnnonces / mands.annonces_tosale[mands.annonces_tosale.length-1] * 100).toFixed(2);

        this.croissanceMands.nbVentes = mands.annonces_sold[mands.annonces_sold.length-1] - mands.annonces_sold.find(v => v);
        this.croissanceMands.percVentes = (this.croissanceMands.nbVentes / mands.annonces_sold[mands.annonces_sold.length-1] * 100).toFixed(2);

        console.log("MANDS", mands);

        this.chartSeries = [
          {
            name: 'Mandataires',
            data: mands.total,
            type: 'area',
          },
          {
            name: 'Annonces',
            data: mands.annonces_tosale,
            type: 'line'
          },        
        ];

        if (this.stats.info.has_sold) {
          this.chartSeries.push(
            {
              name: 'Ventes',
              data: mands.annonces_sold,
              type: 'line'
            },
          );
        }

        // this.stats.indics.avgMandNew = mands.new.length > 1 ? Math.round(ss.mean(mands.new.filter(n => n != null))) : 0;
        // this.stats.indics.avgMandRemoved = mands.removed.length > 1 ? Math.round(-ss.mean(mands.removed.filter(n => n != null))) : 0;

        // console.log("MANDS", JSON.stringify(mands), "max new", Math.max(...mands.new), Math.max(...mands.removed), Math.max(...mands.total));

        this.seriesPop = [
          {
            name: 'Population',
            data: this.stats.communesZonesPop.map(czp => Math.round(czp.count_communes / this.stats.communesPop * 100.0))
          },
        ];

        this.seriesMaillage = [
          {
            name: 'Communes',
            data: this.stats.communesZonesPop.map(czp => Math.round(czp.count_communes / this.stats.communesPop * 100.0))
          },
          {
            name: 'Communes actives',
            data: this.stats.communesZonesPop.map(czp => Math.round(czp.count_zones / czp.count_communes * 100.0))
          },
        ];

        // dernier quartile = centrage population
        const ckmeanMaillage = ss.ckmeans(this.seriesMaillage[1].data, 4);
        this.ckmeanPop = ckmeanMaillage[3].map(ckm => {
          const idx = this.seriesMaillage[1].data.findIndex(sm => sm == ckm);
          return parseInt(this.stats.communesZonesPop[idx].pop);
        }).sort((a,b) => a-b);

        // annonces
        // this.evolAnnonces = {
        //   series: [
        //     {
        //       name: 'annonces',
        //       data: this.stats.evolution_new_annonces.map((a,i) => {
        //         if (i == 0) return 0;
        //         return a.nb
        //       }),
        //     },
        //   ],
        //   labels: this.stats.evolution_new_annonces.map(a => a.created_at),
        // };
        // // annonces
        // this.evolAnnoncesSold = {
        //   series: [
        //     {
        //       name: 'vendu',
        //       data: this.stats.evolution_sold_annonces.map((a,i) => {
        //         if (i == 0) return 0;
        //         return a.nb
        //       }),
        //     },
        //   ],
        //   labels: this.stats.evolution_sold_annonces.map(a => a.sold_at),
        // };

        // annonces et vendus si possible
        this.seriesEvolAnnonces = [
          {
            name: 'mandats',
            data: this._filterOutliers(this.stats.evolution_new_annonces.map((a,i) => {
                if (i == 0) return 0;
                return a.nb
              })),
          }
        ];

        // console.log('seriesEvolAnnonces mandats', this.seriesEvolAnnonces);

        this.stats.indics.newAnnonces = this.seriesEvolAnnonces[0].data[this.seriesEvolAnnonces[0].data.length-1];
        // moyenne : this.seriesEvolAnnonces[0].data.length ? Math.round(ss.mean(this.seriesEvolAnnonces[0].data)) : 0;

        if (this.stats.info.has_sold) {
          this.seriesEvolAnnonces.push({
            name: 'ventes',
            data: this._filterOutliers(this.stats.evolution_sold_annonces.map((a,i) => {
                if (i == 0) return 0;
                return a.nb
              })),
          });
          this.stats.indics.newSold = this.seriesEvolAnnonces[1].data[this.seriesEvolAnnonces[1].data.length-1];
          // moyenne : this.seriesEvolAnnonces[1].data.length ? Math.round(ss.mean(this.seriesEvolAnnonces[1].data)) : 0;
        }

        // console.log('seriesEvolAnnonces mandats&ventes', this.seriesEvolAnnonces);

        // top
        this._computeTop();

        // evol nb Mandats
        // this.ticksEvolNbMandats = Object.keys(this.stats.evolution_distrib_annonces);

        this.loading = false;

        // plus tard pour laisser le temps aux composants apex
        const self = this;
        setTimeout(async () => {
          self.setMarkerReseau();

          // Annonces / Mandataire
          console.log("LDT", self.stats.last_distrib_tosale, "total count", ss.sum(self.stats.last_distrib_tosale.map(ldt => ldt.c)), "total mand", ss.sum(self.stats.last_distrib_tosale.map(ldt => ldt.nb)));
          const seriesDA = this.distribAnnonces(this.stats.last_distrib_tosale);  
          console.log("seriesDA", seriesDA);
          this.barAnnoncesMand.labels = seriesDA.labels;
          this.barAnnoncesMand.series = [
            { 
              name: 'Mandataires',
              data: seriesDA.data,
            }
          ];
          this.tooltipAM.y.formatter = (val) => { return this.bigNumber(val) + " (" + Math.round(val / this.stats.mandataires.nb * 100) + "%)"; }

          // pas de evolution_latlng
          // self.setMarkers(false);

          const maxAnnonces = Math.max(...mands.annonces_tosale);
          const minAnnonces = Math.min(...mands.annonces_tosale);

          const maxSold = Math.max(...mands.annonces_sold);
          const minSold = Math.min(...mands.annonces_sold);

          const maxMand = Math.max(...mands.total);
          const minMand = Math.min(...mands.total);

          // console.log("MINMAX", minMand, maxMand);
          // console.log("MINMAX tosale", minAnnonces, maxAnnonces);
          // console.log("MINMAX sold", minSold, maxSold);

          let ySeries = [
            {
              seriesName: 'Mandataires',
              max: maxMand + 20,
              min: minMand - 20,
              labels: {
                style: {
                  colors: this.evolNbMandatairesChartOptions.colors[0]
                }
              },
            },
            {
              seriesName: 'Annonces',
              max: maxAnnonces+10,
              min: minAnnonces-10,
              opposite: true,
              labels: {
                style: {
                  colors: this.evolNbMandatairesChartOptions.colors[1]
                }
              }
            }
          ];

          if (this.stats.info.has_sold) {
            ySeries.push(
              {
                seriesName: 'Ventes',
                max: maxSold+10,
                min: minSold-10,
                opposite: true,
                labels: {
                  style: {
                    colors: this.evolNbMandatairesChartOptions.colors[2]
                  }
                }
              },
            )
          }

          this.apexSetOptions('EvolNbMandataires', {
            xaxis: {
              categories: mands.dates
            },
            yaxis: ySeries,
          });

          this.apexSetOptions('maillage', {
            xaxis: {
              categories: this.stats.communesZonesPop.map(czp => czp.pop)
            },
            yaxis: {
              max: 100,
              labels: {
                formatter: function(val) {
                  return self.bigNumber(val); 
                }
              },
            },
          });

          this.apexSetOptions('evolAnnoncesMix', {
            xaxis: {
              categories: this.stats.evolution_new_annonces.map(a => a.created_at),
            }
          });

          // this.dateEvolNbMandats = 0;
          // this.setEvolNbMandats(this.dateEvolNbMandats);

          this.setTreemap(this.variable);

          // réseau agences → affichage des points des agences 
          // console.log("RESEAU AGENCES", JSON.stringify(self.currentReseauA));
          
          if (self.currentReseauA) {            
            const agences = await self.fetchReseauAAgences(self.currentReseauA.id);

            const mapD = $('#departements').vectorMap('get', 'mapObject');
            const mapR = $('#regions').vectorMap('get', 'mapObject');

            const data = agences.map(a => {
              return {
                latLng: [a.lat, a.lng],
                name: a.name,
                style: { r: 3, fill: this.currentReseauA.color }
              }
            });

            mapD.addMarkers(data, []);
            mapR.addMarkers(data, []);
          }

        }, 400);
        
      },

      gotoMandataire(mand) {
        const routeData = this.$router.resolve({ name: 'mandataire', params: { id: mand.id } });
        window.open(routeData.href, '_blank');
      },

      gotoReseau(url) {
        window.open(url, '_blank');
      },

      gotoCity(id) {
        const route = this.$router.resolve({ name: 'mapbox', params: { id: id } });
        window.open(route.href, '_blank');
      },

      customFilter(value, search, item) {
        return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()));
      },

      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          switch (index[0]) {
            case 'd':
              return !isDesc[0] ? (moment(a.d) - moment(b.d)) : (moment(b.d) - moment(a.d));
            case 'nb':
              return !isDesc[0] ? (a.nb - b.nb) : (b.nb - a.nb);
            case 'rank':
              return !isDesc[0] ? (a.rank - b.rank) : (b.rank - a.rank);
            case 'value2':
              return !isDesc[0] ? (a.value2 - b.value2) : (b.value2 - a.value2);
            case 'city':
              return !isDesc[0] ? a.city.localeCompare(b.city) : b.city.localeCompare(a.city);
          }
        });
        return items;
      },

      customSort2(items, index, isDesc) {
          items.sort((a, b) => {
          switch (index[0]) {
            case 'd':
              return !isDesc[0] ? (moment(a.d) - moment(b.d)) : (moment(b.d) - moment(a.d));
            case 'nb':
              return !isDesc[0] ? (a.nb - b.nb) : (b.nb - a.nb);
            case 'rank':
              return !isDesc[0] ? (a.rank - b.rank) : (b.rank - a.rank);
            case 'pop':
              return !isDesc[0] ? (a.pop - b.pop) : (b.pop - a.pop);
            case 'city':
              return !isDesc[0] ? a.nom_commune_complet.localeCompare(b.nom_commune_complet) : b.nom_commune_complet.localeCompare(a.nom_commune_complet);
            case 'dept':
              return !isDesc[0] ? a.dept.localeCompare(b.dept) : b.dept.localeCompare(a.dept);
            case 'region':
              return !isDesc[0] ? a.region.localeCompare(b.region) : b.region.localeCompare(a.region);
          }
        });
        return items;
      },

      inputSearch() {
        // if (!this.isPro && !this.isEnterprise) {
        //   this.showPremiumDialog('pro');
        //   return false;
        // }
        return true;
      },

      gotoReseauInfo() {
        const routeData = this.$router.resolve({ name: 'reseauInfo', params: { id: this.id } });
        window.open(routeData.href, '_blank');
      }
    },

    async mounted() {
      if (!this.isEnterprise) {
        this.showPremiumDialog('enterprise');
      }

      this.loading = true;

      if (this.isNetworkAgences) {
        await this.fetchReseauAInfo(this.user.reseauAId);
      }

      this.id = this.$route.params.id;
      await this.fetch();
    }
  }
</script>

<style scoped>
  @import '../components/jquery-jvectormap-2.0.5.css';

  .v-slider__tick-label {
    font-size: 10px;
  }

  .map-parent {
    position: relative;
  }

  .map-scale {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    background-color: white;
  }
  .theme--light.v-data-table tbody tr:nth-of-type(even) {
    background-color: rgba(0, 0, 0, .03);
  }
  .theme--dark.v-data-table tbody tr:nth-of-type(even) {
      background-color: rgba(0, 0, 0, .5);
  }

  .v-data-table.table-top-mand td
  {
    height: 50px !important;
  }
  .photo {
    border-radius: 50%;
    width: 40px;
    height: 40px;
  }
  .chip-active {
    color: #005F73;
  }
  .smaller {
    font-size: 12px;
  }
  .div-donut {
    position: relative;
    z-index: 99;
  }
  .donut {
    position: absolute;
    top: 200px;
    left: 50%;
    margin-left:-150px;
  }
  .searchtextinput {
    max-width: 250px;
  }
  ::v-deep .v-data-table__wrapper > table > thead > tr > th {
    font-weight: 600;
  }
</style>