<template>
  <div class="d-flex" style="height:100%">
    <v-overlay v-if="loading" :opacity="0.3">
      <v-progress-circular indeterminate size="50">
      </v-progress-circular>
    </v-overlay>

    <v-row justify="center" class="my-auto mx-auto">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="6" class="primary d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex justify-center pa-10" style="flex-direction:column">
                    <!-- <div class="d-flex"> -->
                      <img :src="require(`@/assets/images/${logoABMM_trans}`)" class="mx-auto"/>
                    <!-- </div> -->
                    <v-img src="@/assets/images/login-image.png" width="420" max-width="450" class="my-10 mx-auto" />
                    <p class="white--text mx-auto bigger text-center">Bénéficiez du plan <a class="white--text" href="https://www.meilleursmandataires.fr/tarifs" target="_blank"><b>ESSENTIEL</b></a><br/>gratuit pendant 15 jours</p>
                    <p class="grey--text text--lighten-2 mx-auto smaller text-center mt-4">aucune carte de paiement demandée</p>

                </div>
              </div>
            </v-col>
            <v-col lg="6">
              <div class="pa-7 pa-sm-12" style="position: relative">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Inscription</h2>
                <div v-if="showToolTip" style="position: absolute" class="pa-4 tooltip">
                  Le formulaire a été rempli avec les données que nous avons trouvées avec votre email professionnel, veuillez les corriger si besoin.
                </div>
                <h6 class="subtitle-1">
                  Vous avez un compte ?
                  <router-link :to="{ name: 'signin'}">Connexion</router-link>
                </h6>

                <v-form ref="form" v-model="formValid" @keyup.enter.native="submit">
                  <v-row>
                    <v-col>  
                      <v-text-field
                        v-model="firstname"
                        :rules="rules.firstname"
                        label="Prénom"
                        placeholder=" "
                        persistent-placeholder
                        class="mt-4"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                    <v-col>
                      <v-text-field
                        v-model="lastname"
                        :rules="rules.lastname"
                        label="Nom"
                        placeholder=" "
                        persistent-placeholder
                        class="mt-4"
                        required
                        outlined
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    label="E-mail (fourni par votre réseau)"
                    placeholder=" "
                    persistent-placeholder
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    ref="password"
                    v-model="password"
                    counter
                    :rules="rules.password"
                    label="Mot de passe"
                    placeholder=" "
                    persistent-placeholder
                    required
                    outlined
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword=!showPassword"
                  ></v-text-field>

                  <v-autocomplete 
                    v-model="reseauId"
                    :items="reseaux"
                    :rules="rules.reseau"
                    required
                    label="Réseau"
                    outlined
                  >
                    <template v-slot:item="{ item }">
                      <v-img :src="franchiseImageUrl(item.value)" max-width="40"></v-img><span class="ml-4">{{ item.text }}</span>
                    </template>
                  </v-autocomplete>

                  <div class="d-block d-sm-flex mb-4 mb-sm-4" style="flex-direction:column">
                    <div class="d-flex align-center check-line">
                      <v-checkbox
                        v-model="checkboxCGU"
                        :rules="rules.checkboxCGU"
                        required
                        hide-details
                      >
                        <template slot="label">
                          <span class="smaller">Je suis d'accord avec les&nbsp;<a @click="checkboxCGU=!checkboxCGU; showCGUDialog('cgu')">conditions générales</a></span>
                        </template>
                      </v-checkbox>
                    </div>
                    <!-- <div class="d-flex align-center check-line">
                      <v-checkbox
                        v-model="checkboxPC"
                        :rules="rules.checkboxPC"
                        required
                        hide-details
                      >
                        <template slot="label">
                          <span class="smaller">Je suis d'accord avec la&nbsp;<a @click="checkboxCGU=!checkboxCGU;; showCGUDialog('pc')">politique de confidentialité</a></span>
                        </template>
                      </v-checkbox>
                    </div> -->
                  </div>

                  <v-btn
                    :disabled="!formValid || userNotFound"
                    color="primary"
                    block
                    class="mt-2 mr-4"
                    submit
                    @click="submit"
                  >S'inscrire</v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="confirm-box">
        <v-card-title>
          Inscription
        </v-card-title>
        <v-card-text class="px-8 py-6">
          <div align="center">
            <v-img :src="welcomeImage" width="200" class="mb-4"></v-img>
            {{signupMessage}}
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn depressed @click="gotoLogin">
            Se connecter
            <v-img class="ml-2" src="@/assets/images/arrow-ab.png" max-width="12"></v-img>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BaseSnackbar />

    <CGUDialog />

  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import CGUDialog from "@/components/CGUDialog";

export default {
  name: "BoxedRegister",

  components: {
    CGUDialog
  },

  data: () => ({
    loading: false,
    showToolTip: false,
    firstname: "",
    lastname: "",
    email: "",
    password: "",
    checkboxCGU: false,
    checkboxPC: false,
    showPassword: false,
    formValid: false,
    userNotFound: false,
    showDialog: false,
    rules: {
      password: [
        v => !!v || "Mot de passe requis",
        v => (v && v.length >= 6) || "Le mot de passe doit comporter au moins 6 caractères"
      ],
      email: [
        v => !!v || "E-mail requis",
        v => /.+@.+\..+/.test(v) || "E-mail non valide"
      ],
      firstname: [
        v => !!v || "Prénom requis",
      ],
      lastname: [
        v => !!v || "Nom requis",
      ],
      checkboxCGU: [
        v => !!v || "Vous devez confirmer les conditions générales",
      ],
      checkboxPC: [
        v => !!v || "Vous devez confirmer la politique de confidentialité",
      ],
      reseau: [
        v => !!v || "Réseau requis",
      ]
    },
    reseaux: [],
    reseauId: "",
    signupMessageEmail: "Vous allez recevoir un e-mail de confirmation de votre inscription avec un lien de validation, veuillez cliquer dessus",
    signupMessageOk: "Votre inscription est réussie et votre compte est activé",
    paramUid: null,
    coupon: null,
  }),

  computed: {
    ...mapGetters('User', [ 'user', 'userUUID' ]),

    signupMessage() {
      return this.paramUid ? this.signupMessageOk : this.signupMessageEmail;
    },


    welcomeImage() {
      return require(this.paramUid ? '@/assets/images/account-confirmed.png' : '@/assets/images/email-confirm.png');
    }
  },

  async mounted() {    
    this.visibilitySIBWidget(false);

    const reseaux = await this.fetchAllReseaux();
    this.reseaux = reseaux.map(r => ({ value: r.id, text: r.name}));

    this.paramUid = this.$route.params.uid || this.$route.query.UID; // sendinblue landing page included

    if (this.paramUid) {
      await this.fetchUserInfo(this.paramUid);
      // console.log("USER", this.user);
      if (Object.keys(this.user).length) {
        this.firstname = this.user.firstname;
        this.lastname = this.user.lastname;
        this.email = this.user.email;
        this.reseauId = this.user.id_franchise;

        this.$refs.password.focus();
        this.showToolTip = true;
      } else {
        this.showError('notfound');
        this.userNotFound = true;
      }
    }
  },

  methods: {
    ...mapActions('User', [ 'fetchUserInfo', 'signup' ]),
    ...mapActions('Reseau', [ 'fetchAllReseaux' ]),

    async submit() {
      this.loading = true;

      const result = await this.signup({
        uid: this.paramUid,      // filled if uid param not empty 
        type: 'm',
        firstname: this.firstname,
        lastname: this.lastname,
        email: this.email,
        password: this.password,
        franchiseId: this.reseauId,
      });

      this.loading = false;

      console.log("result", result);
      
      if (result.error) {
        this.showError(result.error);
      } else {
        this.$refs.form.reset();
        this.showDialog = true;

        console.log("updateUserCoupon", this.paramUid, this.coupon);
        this.updateUserCoupon({userUuid: this.paramUid, coupon: this.coupon});
      }
    },

    showError(error) {
      let message;
      switch(error) {
        case 'notfound':
          message = "Utilisateur non trouvé, veuillez remplir le formulaire";
          break;
        case 'alreadydone':
          message = "Utilisateur déjà inscrit";
          break;
        case 'alreadyexists':
          message = "Utilisateur avec e-mail " + this.email + " déjà existant";
          break;
        default:
          message = "Erreur système, veuillez réessayer et contacter le support si cela persiste";
          break;
      }        
      this.showSnackbar(message, 'error');
    },

    gotoLogin() {
      this.showDialog = false;
      this.gotoRoute('signin');
    },

    paramsCoupon(s) {
      // s == uuid or will-uuid / end-uuid
      let a = s.match(/will-(.*)/);
      if (a) {
        return [ 'will', a[1] ];
      } else {
        a = s.match(/end-(.*)/);
        if (a) {
          return [ 'end', a[1] ];
        }
      }
      return [ null, s ];
    },
  }
};
</script>
<style scoped>
.bigger {
  font-size: 24px;
}
.smaller {
  font-size: 14px;
}

.tooltip {
  top: 0px;
  right: 10px;
  background-color: #EE9B0E;
  border-radius: 8px;
  font-size: 14px;
  color: black;
  text-align: right;
}

@media (min-width: 1264px) {
  .tooltip {
    width: 250px;
  }
}

@media (min-width: 400px) {
  .tooltip {
    width: 340px;
  }
}

.check-line {
  height: 28px;
}
</style>