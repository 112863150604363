<template>
  <v-dialog v-model="showDialog" max-width="450">
    <v-card>
      <v-card-text class="px-8 py-6 text-center">
        <v-img
          class="ma-auto"
          src="@/assets/images/locked.png"
          max-width="100">
        </v-img>
        <p class="mt-6 msg">
          <span v-if="message">
            {{message}}
          </span>
          <span v-else>
            Fonctionnalité ou donnée
          </span>
          réservée
          <br/>au plan <b>{{planLabel}}</b>
        </p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" class="mr-2" @click.stop="showDialog=!showDialog">OK</v-btn>
        <v-btn v-if="!isENTERPRISE" text color="primary" @click.stop="goAccount()">
          Changer de Plan
          <v-img class="ml-2" src="@/assets/images/arrow-ab.png" max-width="8"></v-img>
        </v-btn>
        <v-btn v-else text color="primary" @click.stop="goContactUs()">
          Nous contacter
          <v-img class="ml-2" src="@/assets/images/arrow-ab.png" max-width="8"></v-img>
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { EventBus } from '@/event-bus.js';

  export default {
    name: 'BasePremiumDialog',

    data: function () {
      return {
        plan: null,
        message: null,
        showDialog: false
      }
    },

    computed: {
      planLabel() {
        return this.plan == 'enterprise' ? 'ENTREPRISE' : (this.plan ? this.plan.toUpperCase() : 'ESSENTIEL ou PRO');
      },

      isENTERPRISE() {
        return this.planLabel == 'ENTREPRISE';
      },
    },
    
    created() {
      EventBus.$on('showPremiumDialog', data => {
        if (typeof data == 'object') {
          this.plan = data.plan;
          this.message = data.message;
        } else {
          this.plan = data;
          this.message = null;
        }
        this.showDialog = true;
      })
    },

    methods:{
      goAccount() {
        this.showDialog = false;
        this.gotoRoute('account');
      }
    }
  }
</script>

<style lang="scss" scoped>
.msg {
  font-size: 18px;
  font-weight: 400;
}
</style>