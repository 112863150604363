// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { find, getOne, getManyFromName, getHistoProperties, getProperties, getZones, getStats, 
         addLink, removeLink, linkStatus, newInZones } from "@/api/mandataire"

const state = {
    mand: {},
}

const getters = {
    currentMand: (state) => state.mand,
}

const actions = {
    setMandataire({commit}, mand) {
        commit('SET_MAND', mand);        
    },

    async findMandataire({ commit }, { franchiseId, email }) {
        try {
            const response = await find(franchiseId, email);
            if (response.data.result) commit('SET_MAND', response.data.result);
            return response;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchMandataire({ commit }, mandId) {
        try {
            const response = await getOne(mandId);
            console.log("vuex fetchMandataire isMember", response.data.result.isMember);
            commit('SET_MAND', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchMandataireNames({ commit }, str) {
        try {
            const response = await getManyFromName(str);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },
    
    async fetchHistoProperties({ commit }, mandId) {
        try {
            const response = await getHistoProperties(mandId);
            commit('SET_MAND_HISTO_PROPS', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchProperties({ commit }, mandId) {
        try {
            const response = await getProperties(mandId);
            commit('SET_MAND_PROPS', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchZones({ commit }, {mandId, year}) {
        try {
            console.log("fetchZones", mandId, year);
            const response = await getZones(mandId, year);
            commit('SET_MAND_ZONES', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchStats({ commit }, mandId) {
        try {
            const response = await getStats(mandId);
            commit('SET_MAND_STATS', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async addLink({ commit }, {mandId1, mandId2, type}) {
        try {
            const response = await addLink(mandId1, mandId2, type);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async removeLink({ commit }, {mandId1, mandId2, type}) {
        try {
            const response = await removeLink(mandId1, mandId2, type);
            console.log("vuex removeLink", JSON.stringify(response));
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async setLinkStatus({ commit }, {linkId, token, status}) {
        try {
            const response = await linkStatus(linkId, token, status);
            console.log("vuex setLinkStatus", JSON.stringify(response));
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async newInZones({ commit }, {userId, mandId, period, cache} ) {
        try {
            const response = await newInZones(userId, mandId, period, cache);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },    
}

const mutations = {
    SET_MAND(state, data) {
        state.mand = data;
    },
    SET_MAND_PROPS(state, data) {
        state.mand.properties = data;
    },
    SET_MAND_HISTO_PROPS(state, data) {
        state.mand.histoProperties = data;
    },
    SET_MAND_ZONES(state, data) {
        state.mand.zones = data;
    },
    SET_MAND_STATS(state, data) {
        state.mand.allStats = data;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}