<template>
  <div>
    <!-- manual overlay to avoid click outside of temporary mode -->
    <!-- <v-overlay :value="show"></v-overlay> -->

    <v-navigation-drawer
      ref="navdrawer"
      v-model="show"
      fixed
      right
      :width="width"
      class="draw-panel"
      color="primary"
      :dark="dark"
      :permanent="show"
    >
      <!-- @transitionend="transitionend" -->
      <!-- :temporary="temporary" -->

      <v-toolbar outlined class="elevation-0 fixed-bar">
        <v-toolbar-title>
          <slot name="title"></slot>        
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <slot name="actions"></slot>
        
        <v-btn icon color="tertiary" @click="close()">
          <v-icon>mdi-window-close</v-icon>
        </v-btn>
      </v-toolbar>

      <div :class="compPadding" ref="divcontent">
        <slot name="content"></slot>
      </div>
    </v-navigation-drawer>

    <div v-esc="escape" v-if="noesc"></div>
</div>
 
</template>

<script>

// ESSAYER https://github.com/Dafrok/v-hotkey

export default {
  name: "RightSideDrawer",
  props: {
    dark: {
      type: Boolean,
      default: false
    },
    show: {
      type: Boolean,
      default: false
    },
    padding: {
      type: String,
      default: "px-6 pb-0"
    },  
    width: {
      type: String,
      default: "550px"
    },
    noesc: {
      type: Boolean,
      default: true
    }
    // temporary: {
    //   type: Boolean,
    //   default: false
    // }
  },
  
  data() {
    return {
    }
  },

  // watch: {
  //   show(val) {
  //     if (val) window.addEventListener('keydown', this.keyDownHandler);
  //     else window.removeEventListener('keydown', this.keyDownHandler);
  //   },
  // },

  computed: {
    compPadding() {
      return this.padding + ' pb-4';
    }
  },

  methods: {
    transitionend() {
      if (!this.show) this.close(); // warning :( to force clean closing when click outside 
      else {
        // scroll to top
        // const form = this.$refs.divcontent.children[0]; // expect a form
        // if (form) form.scrollIntoView(true);
        // NE FONCTIONNE PAS, JUSTE EN DESSOUS :()
      }
    },

    close() {
      console.log("CLOSE");
      this.$emit('update:show', false)
    },

    // keyDownHandler (event) {
    //   if (event.code === 'Escape') {
    //     this.close();
    //   }
    // },
    
    // from vue-esc
    escape() {
      this.close();
    }

  }
}
</script>

<style scoped>

.draw-panel {
  top:50px;
  overflow: auto;
}

.fixed-bar {
  position: sticky;
  position: -webkit-sticky; /* for Safari */
  top: 0;
  z-index: 2;
}
</style>