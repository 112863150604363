<template>
  <vue-apex-charts
    :ref="name"
    :height="height"
    :width="width"
    type="line"
    :options="chartOptions"
    :series="series"
    @click="onClick"
    @move="onMove"
    @leave="onLeave"
  ></vue-apex-charts>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'GraphLine',

    components: {
      VueApexCharts,
    },

    props: {
      name: {
        type: String,
      },
      title: {
        type: String
      },
      labelX: {
        type: String
      },
      labelY: {
        type: String
      },
      rotateLabels: {
        type: Boolean,
        default: false,
      },
      data: {
        type: Object,
      },
      width: {
        type: Number,
      },
      height: {
        type: Number,
      },
      colors: {
        type: Array,
        default: () => ['#77B6EA']
      },
      onClick: {
        type: Function,
        default: () => {}
      },
      onMove: {
        type: Function,
        default: () => {}
      },
      onLeave: {
        type: Function,
        default: () => {}
      },
      noData: {
        type: String,
      },
      funcVal: {
        type: Function
      },
    },

    data: () => ({
      chartOptions: {
        chart: {
          type: 'line',
          dropShadow: {
            enabled: false,
            color: '#000',
            top: 18,
            left: 7,
            blur: 10,
            opacity: 0.2
          },
          toolbar: {
            show: false
          },
          // background: '#fff',
        },
        tooltip: {
          enabled: true,
          x: {
            show: false,
          },
          y: {
            show: false,
            formatter: function(value) {
              return value;
            }
          },
          marker: {
            show: false,
          }
        },
        colors: [],
        dataLabels: {
          enabled: true,
        },
        stroke: {
          curve: 'smooth'
        },
        title: {
          text: '',
          align: 'center'
        },
        grid: {
          borderColor: '#e7e7e7',
          row: {
            colors: ['#f3f3f3', 'transparent'],
            opacity: 0.5
          },
        },
        markers: {
          size: 1
        },
        xaxis: {
          categories: [],
          tickPlacement: 'between',
        },
      },
    }),

    watch: {
      data() {
        const self = this;
        setTimeout(() => {
          self.update();
        }, 150);
      }
    },

    computed: {
      series() { return this.data.series },
      categories() { return this.data.labels },
    },

    methods: {
      update() {
        const chart = this.$refs[this.name].chart;
        let dataLabels = {
          enabled: true,
        };
        let tooltip = null;
        if (this.funcVal) {
          dataLabels.formatter = this.funcVal;
          tooltip = {
            y: {
              show: false,
              formatter: this.funcVal
            }
          }
        }
        const self = this;
        chart.updateOptions({
          noData: {
            text: this.noData,
          },
          chart: {
            events: {
              click: (event, chartContext, config) => {
                self.$emit('click', config.dataPointIndex);
              },
              mouseMove: function(event, chartContext, config) {
                self.$emit('move', config.dataPointIndex);
              },
              mouseLeave: () => {
                self.$emit('leave');
              }
            },
          },
          title: {
            text: this.title,
          },
          dataLabels: dataLabels,
          yaxis: {
            title: {
              text: this.labelY,
            },
          },
          colors: this.colors,
          xaxis: {
            categories: this.categories,
            title: {
              text: this.labelX,
            },
            labels: {
              rotate: -45,
              rotateAlways: this.rotateLabels,
            }
          },
        });

        if (tooltip) {
          chart.updateOptions({tooltip});
        } 
      }
    },

    mounted() {
      this.update();
    }
  }
</script>

<style>
</style>