<template>
  <fieldset :style="dynStyle1(width, height)">
    <legend v-if="title">{{title}}</legend>
    <div :class="dynStyle2()">
      <slot/>
    </div>
  </fieldset>
</template>

<script>
export default {
  name: "BaseBox",

  props: {
    title: {
      type: String,
    },
    width: {
      type: String
    },
    height: {
      type: String
    },
    bgcolor: {
      type: String,
      default: '#fff'
    }
  },

  methods: {
    dynStyle1(width, height) {
      let style = "background-color:"+this.bgcolor+"; max-width:"+width+"px; padding:6px 6px; 0px; 6px";
      if (height) style += ";min-height:"+height+"px";
      return style;
    },
    dynStyle2() {
      return this.title ? "px-2 pb-2" : "px-2 py-2";
    }
  }
};
</script>

<style scoped>
fieldset {
  border:1px solid #E0E0E0;
  -moz-border-radius:4px;
  -webkit-border-radius:4px;  
  border-radius:4px;  
}
legend {
  margin-left: 3px;
  padding-left: 6px;
  padding-right: 6px;
  font-weight: 600;
  font-size: 14px;
  color: #616161;
}
</style>