// https://www.digitalocean.com/community/tutorials/how-to-set-up-vue-js-authentication-and-route-handling-using-vue-router

import { signup, signin, signout, lostPassword, setPassword, getInfo, 
         createStripePortal,
         getPlans, update, addFavori, removeFavori, replaceFavoris, addSearch, updateCoupon,
         recruitAdd, recruitUpdate, recruitRemove,
         googleAuth } from "@/api/user"

const LOCAL = {
  TOKEN: 'user-token',
  UUID: 'user-uuid',
  // GOOGLE_TOKEN: 'google-token',
}

const state = {
  user: {},
  token: '',
  uuid: '',
  // gtoken: localStorage.getItem(LOCAL.GOOGLE_TOKEN) || '',
}

const getters = {
  user: state => state.user,
  userUUID: state => state.uuid,
}

const actions = {
  local({ commit }) {
    commit('SET_UUID');
  },

  raz({ commit }) {
    commit('CLEAR');
  },

  async signup({ commit }, data) {
    commit('SET_USER', {});
    const response = await signup(data);
    return response.data.result;
  },

  async signin({ commit }, data) {
    try {
        const response = await signin(data);
        console.log("vuex signin", JSON.stringify(response.data.result));
        if (response.data.result.id) {
          console.log("vuex signin", JSON.stringify(response.data.result));
          commit('SET_USER', response.data.result);
          commit('AUTH_SUCCESS', response.data.result.token);
          return response.data.result;
        } else {
          commit('AUTH_ERROR');
        }
    } catch(error) {
      //
    }
  },
  async signout({ commit }) {
    try {
      if (state.uuid) {
        console.log("signout", state.uuid);
        await signout(state.uuid);
      }
      commit('AUTH_LOGOUT');
    } catch(error) {
      //
    }
  },
  async lostPassword({ commit }, email) {
    try {
      const response = await lostPassword(email);
      console.log("vuex lostPassword", email, response);
      commit('NONE');
      return response.data.result;
    } catch(error) {
      //
    }
  },
  async setPassword({ commit }, data) {
    try {
      const response = await setPassword(data);
      // console.log("vuex setPassword", email, response);
      commit('NONE');
      return response.data.result;
    } catch(error) {
      //
    }
  },
  async fetchUserInfo({ commit }, uuuid) {
    try {
      if (uuuid) {
        console.log("USER.fetchUserInfo", uuuid);
        const response = await getInfo(uuuid);
        commit('SET_USER_INFO', response.data.result);
      }
    } catch(error) {
      //
    }
  },
  async getPlans({ commit }, uuuid) {
    try {
      console.log("vuex getPlans");
      const response = await getPlans(uuuid);
      commit('NONE');
      return response.data.result;
    } catch(error) {
      //
    }
  },
  async update({ commit }, data) {
    try {
      console.log("USER UPDATE", JSON.stringify(data));
      await update(data.uuuid, data.info);
      commit('NONE');
    } catch(error) {
      //
    }
  },

  async addFavori({ commit }, {userUuid, id, type}) {
      try {
          const response = await addFavori(userUuid, id, type);
          commit('NONE');
          return response.data.result;
      } catch(error) {
          // handle the error here
      }
  },

  async removeFavori({ commit }, {userUuid, id, type}) {
      try {
          const response = await removeFavori(userUuid, id, type);
          commit('NONE');
          return response.data.result;
      } catch(error) {
          // handle the error here
      }
  },

  async replaceFavoris({ commit }, {userUuid, favoris}) {
      try {
          const response = await replaceFavoris(userUuid, favoris);
          commit('NONE');
          return response.data.result;
      } catch(error) {
          // handle the error here
      }
  },

  async addSearch({ commit }, {userUuid, search, id, type}) {
      try {
          await addSearch(userUuid, search, id, type);
          commit('NONE');
      } catch(error) {
          // handle the error here
      }
  },

  async updateUserCoupon({ commit }, {userUuid, coupon}) {
      try {
          const response = await updateCoupon(userUuid, coupon);
          if (response.data.result) commit('SET_COUPON', coupon);
      } catch(error) {
          // handle the error here
      }
  },

  async addRecruit({ commit }, {userUuid, mandId}) {
      try {
          await recruitAdd(userUuid, mandId);
          commit('NONE');
      } catch(error) {
          // handle the error here
      }
  },

  async updateRecruit({ commit }, {userUuid, mandId, body}) {
      try {
          await recruitUpdate(userUuid, mandId, body);
          commit('NONE');
      } catch(error) {
          // handle the error here
      }
  },

  async removeRecruit({ commit }, {userUuid, mandId}) {
      try {
          await recruitRemove(userUuid, mandId);
          commit('NONE');
      } catch(error) {
          // handle the error here
      }
  },

  // STRIPE
  async stripeCreatePortal({ commit }, {uuid, url}) {
    if (!state.user.stripe_customer_id) return null;
    const response = await createStripePortal(uuid, url);
    if (response.data.result) {
      commit('SET_STRIPE_INFO', response.data.result);
      return true;
    }
    return false;
  },

  // GOOGLE
  async googleAuth ({ commit }, authCode) {
    try {
      console.log("googleAuth authCode", JSON.stringify(authCode));
      await googleAuth(state.uuid, authCode);
      commit('NONE');
    } catch(err) {
      console.log(err);
    }
  },

  async googleSignin ({ commit }, data) {
    try {
      console.log("googleSignin", JSON.stringify(data));

/*
{
  "Ca": "117790743893762408779",
  "uc": {
    "token_type": "Bearer",
    "access_token": "ya29.A0AfH6SMBOz3J1arakQ5QX4hJIMcc_2TfCtzMR5fVzWWEap7cEPV71468R7iNdVMtmLlA8vXxVsyOruj3GnRa146QFOz8Hx2UxaTDsbEHieOioo6ZOLjAiW18tmd1lwR2F7fg8ouqyBCjFfAnjBthYng_smaFU",
    "scope": "email profile openid https://www.googleapis.com/auth/gmail.readonly https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile",
    "login_hint": "AJDLj6JUa8yxXrhHdWRHIV0S13cAbzf_36LFBgBY3AZr91ZPO5foshpnAkJrrnfdYqqNaCskl92h2QUCdYo3c_rCbdWazUnn2g",
    "expires_in": 3599,
    "id_token": "eyJhbGciOiJSUzI1NiIsImtpZCI6ImZlZDgwZmVjNTZkYjk5MjMzZDRiNGY2MGZiYWZkYmFlYjkxODZjNzMiLCJ0eXAiOiJKV1QifQ.eyJpc3MiOiJhY2NvdW50cy5nb29nbGUuY29tIiwiYXpwIjoiODc1Mjg0NzIyNjI3LThmdjltaW81MW1zYjBrcmVwa3BxZzZxN25rMDg4aTlwLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwiYXVkIjoiODc1Mjg0NzIyNjI3LThmdjltaW81MW1zYjBrcmVwa3BxZzZxN25rMDg4aTlwLmFwcHMuZ29vZ2xldXNlcmNvbnRlbnQuY29tIiwic3ViIjoiMTE3NzkwNzQzODkzNzYyNDA4Nzc5IiwiZW1haWwiOiJwLmNoYXNzYW55QGdtYWlsLmNvbSIsImVtYWlsX3ZlcmlmaWVkIjp0cnVlLCJhdF9oYXNoIjoiWTgtODFUNDh5SmJ0X01QN0pqOWNNUSIsIm5hbWUiOiJQaGlsaXBwZSBDaGFzc2FueSIsInBpY3R1cmUiOiJodHRwczovL2xoMy5nb29nbGV1c2VyY29udGVudC5jb20vYS0vQU9oMTRHZ1Q1dTZOLV9waVlJdzE2X3l3NHRLSnFibnB0czhNYnF3UHNremQ5QT1zOTYtYyIsImdpdmVuX25hbWUiOiJQaGlsaXBwZSIsImZhbWlseV9uYW1lIjoiQ2hhc3NhbnkiLCJsb2NhbGUiOiJlbiIsImlhdCI6MTYxNDIxODMzMiwiZXhwIjoxNjE0MjIxOTMyLCJqdGkiOiJlNzE2OGYzNzY5Y2I5NWY4ZWMwNjY4YjIyMjZjNzhhMDljYmNlYjM5In0.PczNjT-XZ4wI6kjCi5I-UFhaQllClVRbKOicXCW3zrcBEaPqC8HoQ6G7CUNf7plyLrvpaE_Dj19MKnKEFXHE6gK_uymaawCcaQ73Pzh7KeMv8HxxjoLqzR6BwLOZUrsDxB8gAfsGTlZrCe64zi_rpSwYJPYlrdspCLLrgm7EnXOKhlSQUSxj5RYXxfsalOXegQD8h0CORjuKwOwnWZ1cuK8dB7qP12jsvjLbl59emEaAyVNhJIMCeIcpwjC5oG1CQLeb-B1pXBYll1Wi4c698GsFycQuBlQwHFqQRCjZxeHR1Kn46r-O8zm-UQmLfBoR1RxhhbZtjMaS_DbE_K_aEQ",
    "session_state": {
      "extraQueryParams": {
        "authuser": "0"
      }
    },
    "first_issued_at": 1614218332786,
    "expires_at": 1614221931786,
    "idpId": "google"
  },
  "Es": {
    "JR": "117790743893762408779",
    "sd": "Philippe Chassany",
    "nT": "Philippe",
    "pR": "Chassany",
   -> photo "vI": "https://lh3.googleusercontent.com/a-/AOh14GgT5u6N-_piYIw16_yw4tKJqbnpts8MbqwPskzd9A=s96-c",
    "kt": "p.chassany@gmail.com"
  }
}
*/



      //commit('AUTH_SUCCESS', token);
      commit('NONE');

      // googleUser.getId() : Get the user's unique ID string.
      // googleUser.getBasicProfile() : Get the user's basic profile information.
      // googleUser.getAuthResponse() : Get the response object from the user's auth session. access_token and so on
      //this.isSignIn = this.$gAuth.isAuthorized
    } catch(error) {
      console.log("GOOGLE AUTH ERROR", error);
    }
  },
}

const mutations = {
  CLEAR() {
    state.user = {};
    state.token = '';
    state.uuid = '';
    localStorage.clear();
  },
  SET_UUID(state) {
    state.uuid = localStorage.getItem(LOCAL.UUID);
  },
  SET_USER(state, data) {
    state.user = data;
    state.uuid = data.uuid,
    localStorage.setItem(LOCAL.UUID, data.uuid);
  },
  SET_USER_INFO(state, data) {
    if (Object.keys(data).length == 0) state.user = {};
    else state.user = {...state.user, ...data};
  },
  SET_STRIPE_INFO(state, data) {
    state.user = {...state.user, ...data};
  },
  SET_COUPON(state, data) {
    state.user.coupon = data;
  },
  AUTH_SUCCESS(state, token) {
    state.token = token;
    localStorage.setItem(LOCAL.TOKEN, token);
  },
  AUTH_ERROR(state) {
    state.user = {};
    state.uuid = '';
    state.token = '';
    localStorage.removeItem(LOCAL.TOKEN);
    localStorage.removeItem(LOCAL.UUID);
    // localStorage.removeItem(LOCAL.GOOGLE_TOKEN);
  },
  AUTH_LOGOUT(state) {
    // delete(state.user);
    state.user = {};
    state.token = '';
    state.uuid = '';
    localStorage.removeItem(LOCAL.TOKEN);
    localStorage.removeItem(LOCAL.UUID);
    // localStorage.removeItem(LOCAL.GOOGLE_TOKEN);
    console.log("END AUTH_LOGOUT user", JSON.stringify(state));
  },
  // GAUTH_SUCCES(state, token) {
  //   state.gtoken = token;
  //   state.status = "succes"
  // },
  NONE() {
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}