// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { getAll, getAllWithMA, getColors, getStats, getManyFromName, getPappers } from "@/api/reseau"

const state = {
    reseau: {},
}

const getters = {
    currentreseau: (state) => state.reseau,
}

const actions = {
    setReseau({commit}, reseau) {
        commit('SET_RESEAU', reseau);        
    },

    async fetchAllReseaux({ commit }) {
        try {
            const response = await getAll();
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchAllReseauxWithMA({ commit }) {
        try {
            const response = await getAllWithMA();
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchReseauColors({ commit }) {
        try {
            const response = await getColors();
            commit('NONE');
            let result = [];
            for (let item of response.data.result) result[item.id] = item.color; 
            return result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchReseauStats({ commit }, id) {
        try {
            const response = await getStats(id);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchReseauNames({ commit }, str) {
        try {
            const response = await getManyFromName(str);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchReseauPappers({ commit }, id) {
        try {
            const response = await getPappers(id);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

}

const mutations = {
    SET_RESEAU(state, data) {
        state.reseau = data;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}