import { render, staticRenderFns } from "./Scale.vue?vue&type=template&id=95f3ba58&scoped=true&"
import script from "./Scale.vue?vue&type=script&lang=js&"
export * from "./Scale.vue?vue&type=script&lang=js&"
import style0 from "./Scale.vue?vue&type=style&index=0&id=95f3ba58&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "95f3ba58",
  null
  
)

export default component.exports