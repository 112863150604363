import http from './httpClient';

const END_POINT = '/commune';

const getAllMandataires = (code, period) => http.get(http.path([END_POINT,code,'mandataires'])+'?period='+period);
const getCommuneStats = (code) => http.get(http.path([END_POINT,code,'stats']));
const getCommuneINSEE = (code) => http.get(http.path([END_POINT,code,'insee']));
const getCommuneINSEE2 = (code) => http.get(http.path([END_POINT,code,'insee2']));
const getCommuneINSEE3 = (code) => http.get(http.path([END_POINT,code,'insee3']));
const nearestCommunes = (data) => http.get(http.path([END_POINT,'nearest'])+http.args2(data));
const getCommune = (id) => http.get(http.path([END_POINT,id]));
const fetchCommunes = (search) => http.get(http.path([END_POINT,'search',search]));

export {
    getAllMandataires,
    getCommuneStats,
    getCommuneINSEE,
    getCommuneINSEE2,
    getCommuneINSEE3,
    nearestCommunes,
    getCommune,
    fetchCommunes,
}