<template>
  <div class="map">
    <div v-if="areaOver" class="map-city-overlay pt-2">
      {{areaOver}}
    </div>

    <div id="step-map-1" class="map-pixel map-pixel-1"></div>

    <div id="step-map-2" class="map-pixel map-pixel-2"></div>

    <div id="step-map-3" class="map-pixel map-pixel-3"></div>

    <scale v-if="displayScale && scaleColors && scaleLabels && scaleLabels[0].min != MAX_SCALE_VALUE && communes.length"
      class="map-scale"
      :gcolors="scaleColors"
      :labels="scaleLabels"
      :size="120"
      id="step-map-4"
    ></scale>

    <MglMap
      ref="map"
      :center.sync="center"
      :zoom.sync="zoom"
      :minZoom="minZoom"
      :maxZoom="maxZoom"
      :boxZoom="false"
      :accessToken="accessToken"
      :mapStyle="mapStyleLight"
      :scrollZoom="false"
      :attributionControl="false"
      @load="onMapLoaded"
      @error="onMapError"
      @dragstart="onMapDragStart"
      @dragend="onMapDragEnd"
    >
      <MglNavigationControl position="bottom-left" v-if="displayControls"/>

      <div class="map-cdr-overlay" v-if="0">
        <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
          <button @click="selectFocus" small :class="mapCDRbuttonStyle[0]" type="button" title="Communes" aria-label="Communes">C</button>
          <button @click="selectFocus" :class="mapCDRbuttonStyle[1]" type="button" title="Départements" aria-label="Départements">D</button>
          <button @click="selectFocus" :class="mapCDRbuttonStyle[2]" type="button" title="Régions" aria-label="Régions">R</button>
        </div>
      </div>

      <div ref="overlayReseaux" v-if="currentAreaCode" class="map-overlay-reseaux" :class="mapOverlayReseaux" @click="clickToOpenReseaux">
          <div v-if="!overlayReseauxOpen">
            <img class="mt-1 ml-2 mr-2" src="@/assets/images/arrow-ab.png" style="height:12px"/>
            Réseaux
          </div>
          <div v-else class="grid-reseaux">
            <div v-for="reseau in reseauxInCommune" :key="reseau.id" :class="mapItemReseau">
              <v-badge color="#b6b6b6" offset-x="20" offset-y="15">
                <template v-slot:badge>
                  <div class="badge-reseau">{{reseau.n}}</div>
                </template>
                <v-img class="img-reseau" :width="imgReseauSize" :height="imgReseauSize" contain :src="franchiseImageUrl(reseau.id)"></v-img>
              </v-badge>
            </div>
          </div>
      </div>

      <!-- <MglGeolocateControl position="top-right" /> -->
      <!-- <MglFullscreenControl position="top-right" /> -->
      <!-- <MglScaleControl position="top-left" /> -->
      <!-- <MglAttributionControl position="bottom-right" /> -->

      <!-- <MglMarker
        :coordinates="markerCoordinates"
        color='green'
      /> -->
      
      <!-- Communes Fill -->
      <MglGeojsonLayer v-for="geo in allGeoCommunes" :key="geo.data.id"
        :ref="geo.data.id"
        :sourceId="geo.data.id"
        :source="geo"
        :layer="geo.data.color"
        :layerId="geo.data.id"
        @click="mouseclick"
        @mousemove="mouseover"
        @mouseout="mouseout"
      />

      <!-- Communes Polygon -->
      <MglGeojsonLayer v-for="geo in allGeoCommunes" :key="geo.data.line"
        :ref="geo.data.line"
        :sourceId="geo.data.line"
        :source="geo"
        :layer="geo.data.layer"
        :layerId="geo.data.line"
      />

      <!-- Departements Fill -->
      <!-- PLUS TARD
      <MglGeojsonLayer v-for="geo in allGeoDepts" :key="geo.data.id"
        :ref="geo.data.id"
        :sourceId="geo.data.id"
        :source="geo"
        :layer="geo.data.color"
        :layerId="geo.data.id"
        @click="mouseclickDept"
      />
      -->
      <!-- Departements Polygon -->
      <!-- PLUS TARD
      <MglGeojsonLayer v-for="geo in allGeoDepts" :key="geo.data.line"
        :ref="geo.data.line"
        :sourceId="geo.data.line"
        :source="geo"
        :layer="geo.data.layer"
        :layerId="geo.data.line"
      />
      -->
    </MglMap>

    <right-side-drawer
      id="step-map-3"
      :show.sync="drawer"
      width="370"
      :dark="true"
    >
      <template #title>
        <div class="right_side" v-if="ville">
          <span class="right_side_title">{{ville.nom}} {{(ville.cp)}}
            <br/>
            {{ville.dept}}
            <v-btn
              v-if="!isMaVille"
              :disabled="showProgressFav"
              outlined
              x-small
              color="white"
              class="ml-2"
              @click="favoriteZone"
            >
              {{isFavorite ? 'Supprimer' : 'Ajouter'}}
              <v-icon v-if="!showProgressFav" right small :color="isFavorite ? 'red' : 'light-blue'">
                {{isFavorite ? 'mdi-heart-remove' : 'mdi-heart'}}
              </v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="14" width="1" class="ml-2"
              ></v-progress-circular>
            </v-btn>
            <v-btn
              v-if="!isMaVille"
              outlined
              x-small
              color="white"
              class="ml-2"
              @click="gotoCommune"
            >
              <v-icon small>mdi-finance</v-icon>
            </v-btn>
          </span>
        </div>
      </template>

      <template #content>
        <div class="right_side side-container">
          <div class="plus-side-container" v-if="ville">
            <v-icon small @click="toggleInfo('infoCity')">{{infoCity ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
          </div>
          <div class="d-flex my-2" v-if="ville && infoCity">
            <div>
              Superficie : {{bigNumber(stats.SUPERF)||'-'}} km&sup2;
              <br/>
              Population : {{bigNumber(stats.P19_POP)}}
              <br/>
              Ménages : {{bigNumber(stats.P19_MEN)}}
              <br/>
              <!-- Ménages fiscaux: {{bigNumber(stats.NBMENFISC18)}}
              <br/> -->
              Logements : {{bigNumber(stats.P19_LOG)}}
            </div>
            <v-spacer></v-spacer>
            <div>
              Résidences : {{bigNumber(stats.P19_RP)}}
              <br/>
              Propriétaires : {{bigNumber(stats.P19_RP_PROP)}} ({{decimal(stats.P19_RP_PROP/stats.P19_RP*100)}}%)
              <br/>
              Niveau de vie : {{bigNumber(stats.MED20, true)}}€
              <br/>
              Taux de pauvreté : {{decimal(stats.TP6020)}}%
            </div>
          </div>
          <div @click="toggleInfo('infoCity')" class="d-flex align-center my-2 toggle-info" v-else-if="ville">
            <v-divider/>&nbsp;Informations ville&nbsp;<v-divider/>
          </div>

          <div class="side-container">
            <div class="plus-side-container" v-if="ville">
              <v-icon small @click="toggleInfo('indicCity')">{{indicCity ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
            </div>
            <div v-if="mandatairesComputed.length && indicCity">
              <v-row justify="space-around" class="mt-3">
                <v-col align="center" >
                  <sheet
                    :value="(values.val1000*(uniteHabitants/1000)).toFixed(1)"
                    :title="`Mandataire / ${bigNumber(uniteHabitants)} habitants`"
                  ></sheet>
                </v-col>
                <v-col align="center">
                  <sheet
                    :value="Math.round(values.valToSale)"
                    title="annonces / mandataire"
                  ></sheet>
                </v-col>
                <v-col align="center">
                  <sheet
                    :value="Math.round(values.valSold)"
                    title="ventes / mandataire"
                  ></sheet>
                </v-col>
                <v-col align="center" v-if="values.delayToSale > 0">
                  <sheet
                    :value="values.delayToSale"
                    title="Délai de vente (jours)"
                  ></sheet>
                </v-col>
              </v-row>
              <!-- {{values.stats}} -->
            </div>
            <div @click="toggleInfo('indicCity')" class="d-flex align-center my-2 toggle-info" v-else-if="ville">
              <v-divider/>&nbsp;Indicateurs ville&nbsp;<v-divider/>
            </div>
          </div>

          <div class="side-container">
            <div class="plus-side-container" v-if="ville">
              <v-icon small @click="toggleInfo('infoPrix')">{{infoPrix ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
            </div>
            <base-box bgcolor="#383B3B" v-if="displayBoxAvgPrices(values.stats) && ville && infoPrix">
              Prix moyen au m²
              <v-row justify="space-around" class="mt-1">
                <v-col align="center" v-if="values && values.stats && values.stats.tosale.maisons_pricem2_avg.avg">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" large color="#008FFB" class="mx-auto mb-2">{{ iconType('maison') }}</v-icon>
                    </template>
                    Maison
                  </v-tooltip>
                  <div class="d-flex justify-center">
                    <v-icon x-small class="mr-2">mdi-arrow-collapse-up</v-icon>
                    <span>{{euros(values.stats.tosale.maisons_pricem2_avg.max)}}</span>
                  </div>
                  <span class="font-bigger">{{euros(values.stats.tosale.maisons_pricem2_avg.avg)}}</span>
                  <div class="d-flex justify-center">
                    <v-icon x-small class="mr-2">mdi-arrow-collapse-down</v-icon>
                    <span>{{euros(values.stats.tosale.maisons_pricem2_avg.min)}}</span>
                  </div>
                  <spark-line class="inner-stats mt-2" ref="statsPricesMaison" :data="statsPricesMaison" :width="80" theme="dark"></spark-line>
                </v-col>
                <v-col align="center" v-if="values && values.stats && values.stats.tosale.appartements_pricem2_avg.avg">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" large color="#008FFB" class="mx-auto mb-2">{{ iconType('appartement') }}</v-icon>
                    </template>
                    Appartement
                  </v-tooltip>
                  <div class="d-flex justify-center">
                    <v-icon x-small class="mr-2">mdi-arrow-collapse-up</v-icon>
                    <span>{{euros(values.stats.tosale.appartements_pricem2_avg.max)}}</span>
                  </div>
                  <span class="font-bigger">{{euros(values.stats.tosale.appartements_pricem2_avg.avg)}}</span>
                  <br/>
                  <div class="d-flex justify-center">
                    <v-icon small>mdi-arrow-collapse-down</v-icon>
                    <span>{{euros(values.stats.tosale.appartements_pricem2_avg.min)}}</span>
                  </div>
                  <spark-line class="inner-stats mt-2" ref="statsPricesAppartement" :data="statsPricesAppartement" :width="80" theme="dark"></spark-line>
                </v-col>
                <v-col align="center" v-if="values && values.stats && values.stats.tosale.terrains_pricem2_avg.avg">
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-on="on" v-bind="attrs" large color="#008FFB" class="mx-auto mb-2">{{ iconType('terrain') }}</v-icon>
                    </template>
                    Terrain
                  </v-tooltip>
                  <div class="d-flex justify-center">
                    <v-icon x-small class="mr-2">mdi-arrow-collapse-up</v-icon>
                    <span>{{euros(values.stats.tosale.terrains_pricem2_avg.max)}}</span>
                  </div>
                  <span class="font-bigger">{{euros(values.stats.tosale.terrains_pricem2_avg.avg)}}</span>
                  <br/>
                  <div class="d-flex justify-center">
                    <v-icon x-small class="mr-2">mdi-arrow-collapse-down</v-icon>
                    <span>{{euros(values.stats.tosale.terrains_pricem2_avg.min)}}</span>
                  </div>
                  <spark-line class="inner-stats mt-2" ref="statsPricesTerrain" :data="statsPricesTerrain" :width="80" theme="dark"></spark-line>
                </v-col>
              </v-row>
            </base-box>
            <div @click="toggleInfo('infoPrix')" class="d-flex align-center toggle-info" v-else-if="displayBoxAvgPrices(values.stats) && ville">
              <v-divider/>&nbsp;Prix au m2&nbsp;<v-divider/>
            </div>
          </div>

          <div class="side-container">
            <div class="plus-side-container" v-if="ville">
              <v-icon small @click="toggleInfo('infoStats')">{{infoStats ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
            </div>
            <base-box class="mt-4" bgcolor="#383B3B" v-if="ville && infoStats">
              <div class="d-flex justify-space-between">
                <div class="evol-stats">  
                  Mandataires
                  <spark-line class="inner-stats mt-2" ref="statsMands" :data="statsMands" theme="dark"></spark-line>
                </div>
                <div class="evol-stats">
                  Réseaux
                  <spark-line class="inner-stats mt-2" ref="statsReseaux" :data="statsReseaux" theme="dark"></spark-line>
                </div>
              </div>              
              <div class="d-flex justify-space-between mt-2">
                <div class="evol-stats">
                  Annonces
                  <spark-line class="inner-stats mt-2" ref="statsAnnonces" :data="statsAnnonces" theme="dark"></spark-line>
                </div>
                <div class="evol-stats">
                  Ventes
                  <spark-line class="inner-stats mt-2" ref="statsSold" :data="statsSold" theme="dark"></spark-line>
                </div>
              </div>
            </base-box>
            <div @click="toggleInfo('infoStats')" class="d-flex align-center my-2 toggle-info"  v-else-if="ville">
              <v-divider/>&nbsp;Evolutions&nbsp;<v-divider/>
            </div>
          </div>

          <!-- <div v-if="oneMand">
            zones {{oneMand.zg_stats[currentPeriod].z}}
            <br/>
            global {{oneMand.zg_stats[currentPeriod].g}}
          </div> -->

          <div class="side-container">
            <div class="plus-side-container" v-if="ville">
              <v-icon small @click="toggleInfo('infoFiltres')">{{infoFiltres ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
            </div>
            <base-box class="mt-4" bgcolor="#383B3B" v-if="ville && infoFiltres">
                Mandataires ({{mandatairesComputed.length}})
                <v-select dense class="mt-3 py-0 selectFilter" :items="periodItems" v-model="period" @change="changePeriod">
                  <template #prepend-inner>
                    <v-icon small class="pt-1">mdi-clock-time-nine-outline</v-icon>
                  </template>
                </v-select> 
                <v-select dense class="mt-0 py-0 selectFilter" :items="mandSortItems" v-model="mandSort" @change="changeMandSort">
                  <template #prepend-inner>
                    <v-icon small class="pt-1">mdi-sort-variant</v-icon>
                  </template>
                </v-select>  
                <v-select dense class="my-0 py-0 selectFilter" :items="reseauSelectItems" v-model="reseauSelected" @change="changeReseauSelect">
                  <template #prepend-inner>
                    <v-icon small class="pt-1">mdi-filter-outline</v-icon>
                  </template>
                </v-select>
            </base-box>
            <div @click="toggleInfo('infoFiltres')" class="d-flex align-center my-2 toggle-info" v-else-if="ville">
              <v-divider/>&nbsp;Filtres&nbsp;<v-divider/>
            </div>

            <div v-if="loading2">
              <v-overlay v-if="loading2" :opacity="0.3">
                <v-progress-circular color="grey lighten-5" indeterminate size="50">
                </v-progress-circular>
              </v-overlay>
            </div>

            <div v-else>
              <v-text-field
                class="mt-4 mb-0"
                v-model="searchMand"
                placeholder="rechercher mandataire"
                dense
              ></v-text-field>
              <base-box bgcolor="#383B3B" v-for="(mand, i) in mandatairesComputedSliced" :key="i" class="mb-4">
                <div class="d-flex box-mand">
                  <div>
                    <!-- <img class="photo" :src="mand.photo||''" @error="defaultAvatarEvent" @mouseover="onPhoto" @mouseleave="leavePhoto"> -->
                    <div class="d-flex">
                      <photo-mand
                        :obj="mand"
                        :size="40"
                        @onClick="clickMandataire"
                        :clickData="mand"
                      ></photo-mand>
                      
                      <p class="for_photo ml-2">
                        <a class="link-dark" @click="clickMandataire(mand)" :class="blur">{{mand.firstname}} {{mand.lastname}}</a>
                        <br/>
                        <span class="city">{{mand.city}}</span> - <a @click="clickReseau(mand.rid)">{{mand.rname}}</a>
                      </p>
                    </div>
                  </div>
                  
                  <v-spacer></v-spacer>
                  <img v-if="isPro || isEnterprise" :src="require(`@/assets/images/${medalPicto(i)}`)" width="30" height="30" />

                  <div v-if="(isPro || isEnterprise) && i>2" class="num-mand">{{i+1}}</div>
                </div>
                <!-- <span class="ml-10">Avis : <span :class="blur">{{mand.avis_count}}</span> - Moyenne : <span :class="blur">({{mand.avis_average.toFixed(1)}})</span></span> -->
                <table>
                  <tr>
                    <td></td>
                    <td class="center-text title-zone">sur la ville</td>
                    <td class="center-text title-zone">global</td>
                  </tr>
                  <tr>
                    <td class="center-text">Annonces</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.tosale}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.tosale}}</td>
                  </tr>
                  <tr>
                    <td class="center-text">Exclusivités</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.exclu}}%</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.exclu}}%</td>
                  </tr>
                  <tr v-if="mand.zg_stats[currentPeriod].z.maison || mand.zg_stats[currentPeriod].g.maison">
                    <td class="center-text">Maisons</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.maison}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.maison}}</td>
                  </tr>
                  <tr v-if="mand.zg_stats[currentPeriod].z.appartement || mand.zg_stats[currentPeriod].g.appartement">
                    <td class="center-text">Appartements</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.appartement}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.appartement}}</td>
                  </tr>
                  <tr v-if="mand.zg_stats[currentPeriod].z.terrain || mand.zg_stats[currentPeriod].g.terrain">
                    <td class="center-text">Terrains</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.terrain}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.terrain}}</td>
                  </tr>
                  <tr v-if="mand.zg_stats[currentPeriod].z.autre || mand.zg_stats[currentPeriod].g.autre">
                    <td class="center-text">Autres</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.autre}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.autre}}</td>
                  </tr>
                  <tr>
                    <td class="center-text">Ventes</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].z.sold}}</td>
                    <td class="center-text">{{mand.zg_stats[currentPeriod].g.sold}}</td>
                  </tr>
                </table>
              </base-box>
            </div>
          </div>
        </div>
      </template>
      
    ></right-side-drawer>
    
    <v-overlay v-if="loading" :opacity="0.3">
      <v-progress-circular indeterminate size="50">
      </v-progress-circular>
    </v-overlay>

    <!-- <BasePageLoader v-if="loading" /> -->

    <v-tour name="Mapbox" :steps="mapboxSteps"></v-tour>

  </div>
</template>

<script>
  import Vue from 'vue';
  import axios from 'axios';
  import { EventBus } from '@/event-bus.js';
  import 'mapbox-gl/dist/mapbox-gl.css';
  import RightSideDrawer from "@/components/RightSideDrawer";
  import BaseBox from "@/components/commonComponents/BaseBox";
  // import BasePageLoader from "@/components/commonComponents/BasePageLoader";
  import Scale from "@/components/Scale";
  import Sheet from "@/components/Sheet";
  import PhotoMand from "@/components/PhotoMand";
  import SparkLine from "@/components/SparkLine";
  import { mapActions, mapGetters } from "vuex";
  // import userDefault from '@/assets/images/user-default.jpg';
  import mixinTour from "@/mixins/mixin-tour-steps";
  import mixinApex from "@/mixins/mixin-apex";

  import {
    MglMap,
    // MglAttributionControl,
    MglNavigationControl,
    // MglGeolocateControl,
    // MglFullscreenControl,
    // MglScaleControl,
    // MglMarker,
    MglGeojsonLayer,
  } from 'vue-mapbox';

  export default {
    name: 'Mapbox',

    mixins: [mixinTour, mixinApex],

    components: {
      MglMap,
      // MglAttributionControl,
      MglNavigationControl,
      // MglGeolocateControl,
      // MglFullscreenControl,
      // MglScaleControl,
      MglGeojsonLayer,
      // MglMarker,
      RightSideDrawer,
      BaseBox,
      // BasePageLoader,
      Scale,
      Sheet,
      PhotoMand,
      SparkLine,
    },

    watch: {
      // update variable in verticalHeader
      mapVariable2() {
        this.currentPercValue = this.mapVariable;
        this.changeValue(this.mapVariable2);
      },

      mapVariable() {
        this.mapVariable2 = this.mapVariable;
      },

      location() {
        console.log("location changed", JSON.stringify(this.location));
        this.center = { lng: this.location.lng, lat: this.location.lat }
      },

      async center(newVal, oldVal) {
        console.log("watch center", JSON.stringify(this.center), "lastCenter", JSON.stringify(this.lastCenter), "zoom", this.zoom, "newVal", JSON.stringify(newVal), "oldVal", JSON.stringify(oldVal));
        
        if (!oldVal || (newVal.lng === this.lastCenter.lng && newVal.lat === this.lastCenter.lat)) {
          console.log("do nothing");
          return;
        }

        this.lastCenter = JSON.parse(JSON.stringify(this.center));

        if (this.zoom == this.maxZoom || this.zoom <= this.minZoom) return;

        this.setMapCenter(this.center);

        this.ville = '';
        this.mandataires = [];

        if (this.moveFromSearch) {
          // force jump to update getBounds !
          this.$refs.map.map.jumpTo({center: {lon: this.center.lng, lat: this.center.lat}});
          this.currentAreaCode = null;
          this.removeAllLayers();
          this.moveFromSearch = false;
        }

        await this.fetch();

        this.changeValue(this.mapVariable);
        this.mandSortItemsDisplayed();
      },

      zoom(newVal, oldVal) {
        console.log("zoom changed - current", this.zoom, "newVal", newVal, "oldVal", oldVal);
  
        this.setMapZoom(this.zoom);
        
        if (this.zoom == this.maxZoom || this.zoom <= this.minZoom) return;

        this.removeAllLayers();
        this.drawer = false;

        // if (this.zoom >= 10) this.CDRButton = 0;
        // else if (this.zoom >= 7) this.CDRButton = 1;
        // else if (this.zoom >= 5) this.CDRButton = 2;

        if (this.$route.query.lat) {
          this.CDRButton = 0; // forcé à Commune tant que pas de département région
        }      
      },

      drawer() {
        this.setMapDrawer(this.drawer);
      }
    },

    data: () => ({
      MAX_SCALE_VALUE: 999999999,
      showProgressFav: false,
      mini: true,
      location: null,
      drawer: false,
      loading: false,
      loading2: false,
      infoCity: true,
      indicCity: true,
      infoPrix: true,
      infoFiltres: true,
      infoStats: true,
      accessToken: 'pk.eyJ1IjoiYWdlbnRiaXMiLCJhIjoiY2t4NnJld3N0MWVocTJ1bnh6cmJyZXhkNCJ9.sIQSrxNPVAah_OQigxQtvg',
      mapStyle: 'mapbox://styles/mapbox/streets-v11',
      mapStyleLight: "mapbox://styles/mapbox/light-v10",
      mapBounds: null,
      map: null,
      zoom: 12,
      lastCenter : { lng: 2.1791122380758425, lat: 48.88614255261046 }, 
      center: null, // on mounted
      markerCoordinates: [],
      displayScale: true,
      displayControls: true,
      communes: [],
      rueil: {},
      departements: [],
      regions: [],
      stats: "",
      mandataires: "",
      values: "",
      ville: "",
      allGeoCommunes: [],
      allGeoDepts: [],
      currentAreaCode: null,
      mandCommune: null,
      mapVariable2: null,
      mandSortItems: [
        {
          header: "Ville"
        },
        {
          text: "Nombre d'annonces",
          value: 'z.tosale'
        },
        {
          text: "% d'exclusivités",
          value: 'z.exclu'
        },
        {
          text: "Nombre de ventes",
          value: 'z.sold'
        },
        {
          header: "Global"
        },
        {
          text: "Nombre d'annonces",
          value: 'g.tosale'
        },
        {
          text: "% d'exclusivités",
          value: 'g.exclu'
        },
        {
          text: "Nombre de ventes",
          value: 'g.sold'
        },
        // {
        //   text: '# Avis',
        //   value: 'mand.avis_count'
        // },
      ],
      mandSort: 'z.tosale',
      sortZone: 'z',
      sortWhat: 'tosale',
      periodItems: [
        {
          text: "Toutes les données",
          value: -1
        },
        {
          text: "6 derniers mois",
          value: 6
        },
        {
          text: "3 derniers mois",
          value: 3
        },
        {
          text: "dernier mois",
          value: 1
        },
      ],
      period: -1,
      currentProps: null,
      reseauSelectItems: [],
      reseauSelected: 'all',
      moveFromSearch: false,
      areaOver: null,
      reseauxInCommune: [],
      layerFill: {
        type: "fill",
        paint: {
          "fill-color": "#0080ff",
          "fill-opacity": 0.3,
          "fill-outline-color": '#000',
        }
      },

      // green, yellow, orange, red, default
      colors: [ "#90CD3D", "#E7EA0F", "#f1a204", "#F14104", "#B7B0AD" ],
      colorsReverse: [ "#F14104", "#f1a204", "#E7EA0F", "#90CD3D", "#B7B0AD" ],
      scaleLabels: null,

      defaultZoom: 12,
      CDRButton: 0, // 0: communes, 1: départements, 2: régions
      expandOnHover: true,
      hasStats: true,
      searchCommuneINSEE: null,
      saveObj: null,
      overlayReseauxOpen: false,
      imgReseauSize: 0,
      searchMand: null,
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),
      ...mapGetters('Map', [ 'mapCenter', 'mapZoom', 'mapDrawer', 'mapVille', 'mapVariable', 'mapLocalVariable' ]),
      ...mapGetters('Commune', [ 'currentMands', 'currentStats' ]),
      ...mapGetters('Mandataire', [ 'currentMand' ]),

      mapCDRbuttonStyle() {
        let res = [1, 2, 3].fill('map-cdr-button');
        if (this.CDRButton != null) res[this.CDRButton] = 'map-cdr-button-sel';
        return res;
      },

      minZoom() {
        // console.log("minZoom CDR=", this.CDRButton, "zoom", this.zoom);
        let val;
        switch(this.CDRButton) {
          case 0: val = this.$route.query.lat ? 9 : 9; break;
          case 1: val = 5; break;
          case 2: val = 4; break;
        }
        return val;
      },

      maxZoom() {
        // console.log("maxZoom CDR=", this.CDRButton, "zoom", this.zoom);
        let val;
        switch(this.CDRButton) {
          case 0: val = 13; break;
          case 1: val = 9; break;
          case 2: val = 6; break; 
        }
        return val;
      },

      percentileValue() {
        return 'percentile_' + this.mapVariable;
      },

      theColors() {
        const smallToBig = ['valMand','val1000','delayToSale'].includes(this.mapVariable);
        return smallToBig ? 'colors' : 'colorsReverse';
      },

      scaleReverse() {
        const smallToBig = ['valMand','val1000','delayToSale'].includes(this.mapVariable);
        return smallToBig ? false : true;
      },

      worstColor() {
        return this[this.theColors][4];
      },

      scaleColors() {
        return this[this.theColors].slice(0, -1);
      },

      mandatairesComputed() {
        if (this.reseauSelected == 'all') {
          if (this.isPro || this.isEnterprise) return this.mandataires;
          // 2022-10-13: array shuffled for non pro
          return [...this.mandataires].sort((a, b) => 0.5 - Math.random()); 
        } else {
          if (this.isPro || this.isEnterprise) return this.mandataires.filter(m => m.rid == this.reseauSelected);
          return [...this.mandataires].sort((a, b) => 0.5 - Math.random());
        }
      },

      mandatairesComputedSliced() {
        if (this.isBasic) return this.mandatairesComputed.slice(0, 50);
        return this.mandatairesComputed.filter(mand => {
          if (!this.searchMand) return true;
          const regx = new RegExp(this.searchMand, 'i');
          return mand.firstname.match(regx) || mand.lastname.match(regx);
        });
      },

      uniteHabitants() {
        return 10000;
        // if (this.stats.P19_POP > 10000) return 10000;
        // return 1000;
      },

      isFavorite() {
        return this.user.favoris.find(f => f.id_favori == this.ville.id_commune && f.type == 'zone');
      },

      isMaVille() {
        if (this.isNetworkAgences) {
          return this.user.agenceCommuneId == this.ville.id_commune;
        } else if (this.user) {
          return false;
        }
        return false;
      },

      oneMand() {
        return false;
        // return this.currentMands.find(m => m.id == 2864218);
      },

      currentPeriod() {
        return "p" + this.period;
      },

      mapOverlayReseaux() {
        return this.overlayReseauxOpen ? "map-overlay-reseaux-open" : "map-overlay-reseaux-closed"; 
      },

      mapItemReseau() {
        return this.reseauxInCommune.length >= 20 ? 'item-reseau-small' : 'item-reseau'; 
      },

      statsMands() {
        return this.currentStats ? this.currentStats[0].map(v => v.nb) : [];
      },

      labelStatsMands() {
        return this.currentStats ? this.currentStats[0].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsReseaux() {
        return this.currentStats ? this.currentStats[1].map(v => v.nb) : [];
      },

      labelStatsReseaux() {
        return this.currentStats ? this.currentStats[1].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsAnnonces() {
        return this.currentStats ? this.currentStats[2].map(v => v.nb) : [];
      },

      labelStatsAnnonces() {
        return this.currentStats ? this.currentStats[2].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsSold() {
        return this.currentStats ? this.currentStats[3].map(v => v.nb) : [];
      },

      labelStatsSold() {
        return this.currentStats ? this.currentStats[3].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsPricesMaison() {
        return this.currentStats ? this.currentStats[4].map(v => v.nb) : [];
      },

      labelStatsPricesMaison() {
        return this.currentStats ? this.currentStats[4].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsPricesAppartement() {
        return this.currentStats ? this.currentStats[5].map(v => v.nb) : [];
      },

      labelStatsPricesAppartement() {
        return this.currentStats ? this.currentStats[5].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

      statsPricesTerrain() {
        return this.currentStats ? this.currentStats[6].map(v => v.nb) : [];
      },

      labelStatsPricesTerrain() {
        return this.currentStats ? this.currentStats[6].map(v => v.year + '-' + v.month.toString().padStart(2, '0')) : [];
      },

    },

    methods: {
      ...mapActions('Mandataire', [ 'setMandataire', 'fetchMandataire' ]),
      ...mapActions('Map', [ 'setMapCenter', 'setMapZoom', 'setMapDrawer', 'setMapVille', 'setMapLocalVariable', 'setMapVariable' ]),
      ...mapActions('User', [ 'fetchUserInfo', 'addFavori', 'removeFavori' ]),
      ...mapActions('Commune', [ 'fetchCommune', 'fetchNearestCommunes', 'fetchAllMandataires', 'fetchCommuneStats' ]),

      mandSortItemsDisplayed() {
        if (!this.isPro && !this.isEnterprise) {
          this.mandSort = 'no-sort';
          this.mandSortItems = [ 
          {
            text: "aucun tri",
            value: 'no-sort'
          }, ...this.mandSortItems ];
        }
      },

      medalPicto(i) {
        switch(i) {
          case 0: return '1st.png';
          case 1: return '2nd.png';
          case 2: return '3rd.png';
          default: return '1x1.png';
        }
      },

      clickMandataire(mand) {
        console.log("clickMandataire", mand, typeof(mand));
        this.setMandataire(mand);
        const route = this.$router.resolve({ name: 'mandataire', params: { id: mand.id } });
        window.open(route.href, '_blank');
      },

      clickReseau(reseauId) {
        if (!this.isEnterprise) {
          this.showPremiumDialog('enterprise');
          return;
        }
        this.$router.push({ name: 'reseau', params: { id: reseauId } });
      },

      async selectFocus(e) {
        const which = e.srcElement.innerText;
        console.log("selectFocus", which);
        this.CDRButton = which == 'C' ? 0 : (which == 'D' ? 1 : 2);

        this.removeAllLayers();
        this.scaleLabels = null;

        this.zoom = this.maxZoom;

        await this.fetch();
        this.changeValue(this.mapVariable);
        this.mandSortItemsDisplayed();
      },

      // what = 'commune' || dept' || 'region' → NOT USED
      removeAllPolygons(what) {
        try {
          let allLayers = Object.values(this.$refs)
                              .filter(ref => Array.isArray(ref) && ref[0] && ref[0]._props.source.data.properties.tag == what)
                              .map(ref => Array.isArray(ref) && ref[0] && ref[0]._props.layerId);
          console.log("allLayers", what, allLayers);
          const map = this.$refs.map.map;
          allLayers.map(id => {
            if (id && map.getLayer(id)) {
              map.removeLayer(id);
            }
          });
        } catch(err) {
          console.log("removeAllPolygons", err.message);
        }
      },

      removeAllLayers() {
        console.log("removeAllLayers");

        const map = this.$refs.map.map;

        // raise onMapError
        map.getStyle().layers.filter(l => l.type == "line" || l.type == "fill").map(l => {
            if (map.getLayer(l.id)) map.removeLayer(l.id)
          });

        this.allGeoCommunes = [];

        // this.removeAllPolygons('commune');

        // this.removeAllPolygons('dept');
        // this.removeAllPolygons('region');
        // const layers = this.map.getStyle().layers;
        // const allLayers = layers.filter(l => l.source && !isNaN(parseInt(l.source)));
        // const allPolygons = layers.filter(l => l.source && l.source.startsWith('l'));
        // // console.log("ALL LAYERS", allLayers.length);
        // // console.log("ALL POLYGONS", allPolygons.length);
        // allLayers.map(l => this.map.removeLayer(l.id));
        // allPolygons.map(l => this.map.removeLayer(l.id));
      },

      randomColor() {
        return this.colors[Math.round(Math.random()*3)];
      },

      leavePhoto() {
        if (this.mandCommune) this.displayPolygon(this.mandCommune, 0);
        this.areaOver = null;
      },

      onPhoto(e) {
        const mandId = e.srcElement.alt;
        console.log("mandId", mandId);
        this.mandCommune = false;
        let mandCommuneName = "";
        for (let geojson of this.allGeoCommunes) {
          const f =  geojson.data.properties.mandataires && geojson.data.properties.mandataires.find(m => m.id == mandId && m.sa_ville == 1);
          if (f) {
            this.mandCommune = geojson.data.id;
            mandCommuneName = geojson.data.properties.nom_commune_complet;
            break;
          }
        }
        // console.log(this.mandCommune);
        if (this.mandCommune) {
          this.displayPolygon(this.mandCommune, 1);
          this.areaOver = mandCommuneName;
        }
      },

      displayPolygon(code, bool) {
        // console.log("displayPolygon", code, bool);
        const v = this.$refs['l'+code];
        // Vue.set(v[0]._props.layer.paint, 'line-opacity', bool);
        v[0]._props.layer.paint['line-opacity'] = bool;

        // console.log("displayPolygon layer0", this.allLayers[0]);
        // const layer = this.allLayers.find(l => l.line == 'l'+code);
        // console.log("displayPolygon layer", layer);
        // layer.paint['line-opacity'] = bool;
      },

      changeValue(value) {
        // console.log("#### changeValue", value, "perc", this.percentileValue, "allGeoCommunes", this.allGeoCommunes.length, "communes", this.communes.length)

        this.currentPercValue = value;

        this.scaleLabels = null;
        let minMax = [
          { min: this.MAX_SCALE_VALUE, max: 0},
          { min: this.MAX_SCALE_VALUE, max: 0},
          { min: this.MAX_SCALE_VALUE, max: 0},
          { min: this.MAX_SCALE_VALUE, max: 0}
        ];

        for (let i=0; i<this.allGeoCommunes.length; i++) {
          const props = this.allGeoCommunes[i].data.properties;
          const percIndex = props.values[this.percentileValue];

          // console.log("####", i, percIndex);
          // console.log("####", JSON.stringify(props.values));
          // console.log(props.info.nom_commune_complet, this.percentileValue, props.values[this.percentileValue]);

          if (percIndex >= 0) {
            minMax[percIndex].min = Math.min(minMax[percIndex].min, props.values[value]).toFixed(2);
            minMax[percIndex].max = Math.max(minMax[percIndex].max, props.values[value]).toFixed(2);
          }

          const color = props.values[this.percentileValue] < 0 ? this.worstColor : this[this.theColors][props.values[this.percentileValue]]; // || this.worstColor;
          // console.log(color);

          const v = this.$refs[this.allGeoCommunes[i].data.id];
          try {
            Vue.set(v[0]._props.layer.paint, 'fill-color', color);
          } catch(err) {
            console.log("");
          }
        }

        this.scaleLabels = minMax;
        // console.log("SCALELABELS", this.scaleLabels, this.scaleReverse, this.theColors);
      },

      mouseclickDept(e) {
        const props = e.component._props.source.data.properties;
        console.log("mouseclickDept", props);
      },

      async selectCommune(props) {
        console.log("selectCommune PROPS", props);
        this.loading2 = true;

        this.currentProps = props;

        this.stats = props.insee;
        console.log("STATS", JSON.stringify(this.stats));

        await this.fetchAllMandataires({communeCode: props.insee.CODGEO, period: this.period});
        console.log("MANDS", this.currentMands);

        await this.fetchCommuneStats(props.insee.CODGEO);
        console.log("COMMUNE STATS", this.currentStats);

        const self = this;

        // set sparkline tooltips
        setTimeout(() => {
          for (let what of ['Mands', 'Reseaux', 'Annonces', 'Sold', 'PricesMaison', 'PricesAppartement', 'PricesTerrain']) {
            const chart = this.$refs['stats'+what].$children[0].chart;
            chart.updateOptions({
              tooltip: {
                x: {
                  show: true,
                  formatter: function (v) {
                    return self['labelStats'+what][v-1];
                  },
                }
              }
            });
          }
        }, 200);

        this.sortMandataires();

        this.values = props.values;
        this.values.stats = props.stats;

        // console.log("VALUES STATS", this.values.stats);

        // tous les réseaux
        this.reseauSelectItems = [];
        let allReseaux = this.mandataires.map(m => ({
          text: m.rname,
          value: m.rid,
        }));

        // comptage mands per reseau
        const counts = {};
        for (const ar of allReseaux) {
          counts[ar.value] = counts[ar.value] ? counts[ar.value] + 1 : 1;
        }

        // unique sur rid
        allReseaux = [...new Map(allReseaux.map(item => [item.value, item])).values()];
        
        // add count after rname
        this.reseauSelectItems = [...allReseaux.map(ar => ({
          text: ar.text + ' (' + counts[ar.value] + ')',
          value: ar.value,
          count: counts[ar.value]
        }))];

        // sort (name asc or count desc ) → count desc
        // this.reseauSelectItems = this.reseauSelectItems.sort((a,b) => a.text.localeCompare(b.text));
        this.reseauSelectItems = this.reseauSelectItems.sort((a,b) => b.count - a.count);
        
        // add header
        if (allReseaux.length > 0) {
          this.reseauSelectItems = [{
            text: 'Tous les réseaux (' + allReseaux.length + ')',
            value: 'all'
          }, {
            divider: true,
          },
          ...this.reseauSelectItems];
        }

        this.ville = {
          nom: this.nomDansProps(props),
          id_commune: props.info.id,
          cp: props.info ? props.info.code_postal : props.code,
          dept: props.info ? props.info.nom_departement : '',
          code_commune_INSEE: props.info.code_commune_INSEE,
        }
        this.setMapVille(this.ville);

        this.hasStats = this.CDRButton == 0 ? true : false;

        console.log("ville", this.ville, this.hasStats);
        // TEST
        // const all = this.map.getStyle().layers.filter(l => !isNaN(parseInt(l.source)));
        // console.log("LEN", all, all.length);

        console.log("mandSort", this.mandSort);
        this.loading2 = false;
      },

      overCommune(props) {
        const code = props.code;
        // console.log("overCommune code", code, "current", this.currentAreaCode, "props", props);
        if (this.currentAreaCode != code) {
          if (this.currentAreaCode) this.displayPolygon(this.currentAreaCode, 0);
          this.displayPolygon(code, 1);
        }
        this.currentAreaCode = code;
        this.areaOver = this.nomDansProps(props);

        this.reseauxInCommune = props.reseaux.sort((a,b) => b.n-a.n);
        const height = this.$refs.overlayReseaux ? this.$refs.overlayReseaux.clientHeight : 0;
        const max = Math.round(height / (this.reseauxInCommune.length + 16));
        // console.log("height", height, "reseauxInCommune", this.reseauxInCommune.length, "max", max);
        if (this.reseauxInCommune.length >= max) this.imgReseauSize = 30; else this.imgReseauSize = 50;
      },

      getProps(e) {
        return e.component._props.source.data.properties;
      },

      nomDansProps(props) {
        switch (this.CDRButton) {
          case 0 : return props.info ? props.info.nom_commune_complet : '';
          case 1 : return props.nom;
          case 2 : return props.nom;
          default: return null;
        }
      },

      mouseclick(e) {
        if (!this.drawer) this.drawer = true;
        this.setMapDrawer(this.drawer);

        // const withShift = false; //e.mapboxEvent.originalEvent.shiftKey;
        const props = this.getProps(e);
        this.selectCommune(props);        
      },

      mouseover(e) {
        const props = this.getProps(e); 
        this.overCommune(props);
      },

      mouseout() {
        this.areaOver = null;
        // this.reseauxInCommune = [];
      },

      _buildGeojson(code, polygon, data, fillColor, tag) {
        return {
          type: 'geojson',
          data: {
            id: code,
            line: 'l' + code,
            color: {
              type: "fill",
              paint: {
                "fill-color": fillColor < 0 ? this.worstColor : this[this.theColors][fillColor], //'#21303e',
                "fill-opacity": 0.3,
                "fill-outline-color": '#000',
              }
            },
            layer: {
              type: 'line',
              paint: {
                'line-color': '#21303e',
                'line-width': 1.5,
                'line-opacity': 0,
              }
            },
            type: 'Feature',
            geometry:{
              type: 'Polygon',
              coordinates: polygon
            },
            properties: {...data, tag: tag}
          }
        }
      },

      _buildArrayOfGeojson(code, nom, polygons, data) {
        let result = {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: polygons.map(p => this._buildGeojson(code, p, data))
          }
        }
        return result;
      },

      async fetch() {
        try {
          console.log("fetch center", JSON.stringify(this.center), "zoom", this.zoom);
          
          this.loading = true;
          
          // this.$nextTick(async () => {
          setTimeout(async () => { 
            this.mapBounds = this.$refs.map.map.getBounds();

            // console.log("bounds", JSON.stringify(this.mapBounds));

            const nearest = await this.fetchNearestCommunes({
              lat: this.center.lat,
              lng: this.center.lng,
              zoom: this.zoom,
              nelat: this.mapBounds._ne.lat,
              nelng: this.mapBounds._ne.lng,
              swlat: this.mapBounds._sw.lat,
              swlng: this.mapBounds._sw.lng,
              mode: this.CDRButton, 
              communeINSEE: this.searchCommuneINSEE || ''
            });

            // remove undefined (in case)
            this.allGeoCommunes = [];
            this.communes = nearest[0].filter(c => c);
            const geos = nearest[1];

            // console.log("this.communes", this.communes.length, this.communes.map(c => c.geometry.properties.nom));
            // console.log("this.communes", this.communes);

            // this.stats = response.data.result[3];

            let agenceCommune = null;
            if (this.user.agenceCodeCommune) {
              console.log("agenceCodeCommune", this.user.agenceCodeCommune);
              agenceCommune = this.communes.find(c => c && c.geometry.properties.code === this.user.agenceCodeCommune);
              console.log('FETCH COMMUNE', this.user.agenceCodeCommune, agenceCommune);
              if (agenceCommune) this.selectCommune(agenceCommune.geometry.properties);
              // setTimeout(() => {
              //   this.overCommune(agenceCommune.geometry.properties);
              // }, 300);
            }

            if (this.CDRButton == 0) {
              // console.log("CDRButton = 0");

              for (let commune of this.communes) {
                const geojsonSource = this._buildGeojson(commune.geometry.properties.code, commune.geometry.coordinates, commune.geometry.properties, commune.geometry.properties.values[this.percentileValue], 'commune');

                this.allGeoCommunes.push(geojsonSource);
              }
              // console.log("@@@@@ allGeoCommunes", this.allGeoCommunes.length);
            } // end CDRButton 0

            /*
            if (this.CDRButton == 1) {
              console.log("CDRButton = 1");
              for (let commune of geos) {
                // pas de doublon
                const f = this.communes.find(c => c.geometry.properties.code === commune.geometry.properties.code);
                if (f) continue;

                console.log(commune.geometry.properties.nom);

                this.communes.push(commune);

                const geojsonSource = this._buildGeojson(commune.geometry.properties.code, commune.geometry.coordinates, commune.geometry.properties, commune.geometry.properties.values[this.percentileValue], 'dept');

                this.allGeoCommunes.push(geojsonSource);
              }
            } // end CDRButton 1
            */

            /*
            const departements = response.data.result[1];
            console.log("DEPT LENGTH", departements.length, 'THIS.DEPTS', this.departements.length);
            // if (!departements.length && this.departements.length) {
            //   console.log("REMOVE ALL DEPTS");
            //   this.removeAllPolygons('dept');
            //   this.departements = [];
            //   this.allGeoDepts = [];
            // } else if (this.departements.length == 0) {
              for (let dept of departements) {
                const f = this.departements.find(d => d.properties.code === dept.properties.code);
                console.log(dept.properties.code, dept.properties.nom, f);
                if (f) continue;
                this.departements.push(dept);
                if (dept.geometry.type == 'MultiPolygon') {
                  // console.log("DEPT MULTI", dept.properties.nom);
                  for (let i=0; i<dept.geometry.coordinates.length; i++) {
                    const geo = this._buildGeojson(d'+dept.id+''+i, dept.geometry.coordinates[i], { code: dept.properties.code, nom: dept.properties.nom, tag: 'dept'});
                    this.allGeoDepts.push(geo);  
                  }
                } else {
                  const geo = this._buildGeojson(d'+dept.id, dept.geometry.coordinates, { code: dept.properties.code, nom: dept.properties.nom, tag: 'dept'});
                  // const geo = this._buildGeojson(dept.id, dept.polygon.coordinates, { code: dept.code_departement_INSEE, nom: dept.nom, tag: 'dept'});
                  this.allGeoDepts.push(geo);
                }
              }
            // }

            const regions = response.data.result[2];
            if (!regions.length && this.regions.length) {
              this.removeAllPolygons('region');
              this.regions = [];
              this.allGeoDepts = [];
            } else if (this.regions.length == 0) {
              this.regions = regions;
              console.log("REGIONS", this.regions); // this.regions.map(d => d.nom));
              // console.log("BRETAGNE", this.regions.find(d => d.properties.nom=='Bretagne'));

              // [{ code_departement_INSEE, id, nom, polygon }]
              for (let reg of this.regions) {
                if (reg.geometry.type == 'MultiPolygon') {
                  // console.log("MULTI", reg.properties.code, reg);
                  // const geo = this._buildArrayOfGeojson(r'+reg.id, reg.nom, reg.geometry.coordinates, { code: reg.properties.code, nom: reg.properties.nom, tag: 'region'});
                  for (let i=0; i<reg.geometry.coordinates.length; i++) {
                    const geo = this._buildGeojson('r'+reg.id+''+i, reg.geometry.coordinates[i], { code: reg.properties.code, nom: reg.properties.nom, tag: 'region'});
                    this.allGeoDepts.push(geo);
                  }
                } else {
                  // console.log("SIMPLE", reg.properties.code, reg);
                  const geo = this._buildGeojson('r'+reg.id, reg.geometry.coordinates, { code: reg.properties.code, nom: reg.properties.nom, tag: 'region'});
                  // const geo = this._buildGeojson(reg.id, reg.polygon.coordinates, { code: reg.code_region_INSEE, nom: reg.nom, tag: 'region'});
                  this.allGeoDepts.push(geo);
                }
              }
            }

            // 1 seul geojson collection (pas utilisé)
            // this.geoJson = {
            //   type: "geojson",
            //   data: {
            //     id: 'hello',
            //     type: "FeatureCollection",
            //     features: geoCommunes
            //   }
            // }
            */

            this.loading = false;
            
            // console.log("allGeoCommunes", this.allGeoCommunes.length);
            // if (this.allGeoCommunes.length > 0 && this.allGeoCommunes.length < 4) {
            //   console.log("ZOOM--");
            //   this.zoom--; this.setMapZoom(this.zoom);
            // }
            // console.log("allGeoDepts", this.allGeoDepts);
            // console.log("allGeoDepts", this.allGeoDepts.length);

            this.mapVariable2 = this.mapVariable;

            this.changeValue(this.mapVariable);

            if (this.$route.params.id) {
              const c = this.communes.find(c => c.geometry.properties.info.id == this.$route.params.id);
              if (c) {
                setTimeout(() => {
                  this.overCommune(c.geometry.properties)
                }, 150);
              }
            }

          }, 150); // setTimeout / nextTick
        } catch(err) {
          console.log("fetch", err.message);
        }
      }, // fetch

      async properties(id) { 
        const response = await axios.get(process.env.VUE_APP_API_BASE_URL+'/agent/' + id + '/properties');
        console.log(response.data);
      },

      async changePeriod() {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog('pro');
          return;
        }
        this.sortMandataires();
      },

      changeMandSort() {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog('pro');
          return;
        }

        this.setMapLocalVariable(this.mandSort);

        // z.tosale
        const a = this.mandSort.split('.');
        // console.log(this.mandSort, JSON.stringify(a));
        this.sortZone = a[0];
        this.sortWhat = a[1];

        this.sortMandataires();
        // this.mandataires = this.mandataires.sort(this.sortMandataires);
      },

      changeReseauSelect() {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog('pro');
          return;
        }
        this.sortMandataires();
      },

      sortMandataires() {
        // tri
        if (this.isPro || this.isEnterprise) {
          this.mandataires = this.currentMands ? this.currentMands.sort(this._sortMandataires) : [];
        } else {
          this.mandataires = this.currentMands ? this.currentMands : [];
        }
      },

      _sortMandataires(a, b) {
        return b.zg_stats[this.currentPeriod][this.sortZone][this.sortWhat] - a.zg_stats[this.currentPeriod][this.sortZone][this.sortWhat];
      },
      
      async favoriteZone() {
        if (this.isBasic) {
          this.showPremiumDialog();
          return;
        }
        this.showProgressFav = true;
        if (!this.isFavorite) {
          await this.addFavori({
            userUuid: this.userUUID,
            id: this.ville.id_commune,
            type: 'zone'
          });
          this.zonesUpdated = true;
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('favorite-zone-add');
          }
        } else {
          await this.removeFavori({
            userUuid: this.userUUID,
            id: this.ville.id_commune,
            type: 'zone'
          });
          this.zonesUpdated = true;
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('favorite-zone-remove');
          }
        }
        await this.fetchUserInfo(this.userUUID);
        setTimeout(() => {
          this.showProgressFav = false;
        }, 300);
      },

      displayBoxAvgPrices(stats) {
        return stats && stats.tosale && (stats.tosale.maisons_pricem2_avg.avg || stats.tosale.appartements_pricem2_avg.avg || stats.tosale.terrains_pricem2_avg.avg); 
      },

      destroyEvents() {
        EventBus.$off('searchCommune');
        EventBus.$off('searchMandataire');
        // EventBus.$off('changeValue');
      },

      toggleInfo(what) {
        this[what] = !this[what];
      },

      onMapDragStart(e) {
        this.drawer = false;
      },

      onMapDragEnd(e) {
        if (this.zoom != this.maxZoom && this.zoom != this.minZoom) this.removeAllLayers();
      },

      onMapError(e) {
        if (this.saveObj) EventBus.$emit('searchCommune', this.saveObj);
      },

      async onMapLoaded(component) {
        console.log("onMapLoaded", JSON.stringify(this.mapCenter));
        
        this.$refs.map.map.scrollZoom.disable();
        this.$refs.map.map.doubleClickZoom.disable();

        if (this.$route.params.id) {
          // from search
          const commune = await this.fetchCommune(this.$route.params.id);
          if (commune) {
            this.center = {lng: commune.longitude, lat: commune.latitude};
          }
        } else if (this.$route.query.lat && this.$route.query.lng) {
          // from launch email
          const zoom = this.$route.query.zoom ? parseInt(this.$route.query.zoom) : 11;
          this.center = {lng: this.$route.query.lng, lat: this.$route.query.lat};
          // this.setMapZoom(zoom);
          this.displayScale = true;
          this.displayControls = true;
        } else {
          if (this.user.id_m_rm_ra) {
            console.log("FETCH MAND", this.user.id_m_rm_ra);
            await this.fetchMandataire(this.user.id_m_rm_ra);
            console.log("MOUNTED CURRENT MAND", this.currentMand.lat, this.currentMand.lng);
            this.location = { lng: this.currentMand.lng, lat: this.currentMand.lat }
          } else {
            this.loading = true;
            this.$getLocation().then(location => {
                this.location = location;
                console.log("CREATED GEOLOC", JSON.stringify(this.location));
                this.loading = false;
              }, error => {
                console.log("Geolocation error", error);
                this.loading = false;
              });
          }
        }

        this.zoom = this.mapZoom || 12;
        this.drawer = this.mapDrawer || false;
        if (this.drawer && !this.currentAreaCode) this.drawer = false;
        this.ville = this.mapVille || '';

        if (!this.mapLocalVariable) this.setMapLocalVariable(this.mandSort);
        else this.mandSort = this.mapLocalVariable;
      },

      clickToOpenReseaux() {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog('pro');
          return;
        }
        this.overlayReseauxOpen = !this.overlayReseauxOpen;
      },

      gotoCommune() {
        const route = this.$router.resolve({ name: 'commune' , params: {id: this.ville.id_commune} });
        window.open(route.href, '_blank');
      }
    },

    created() {
      this.drawer = false;

      // this.destroyEvents();

      EventBus.$on('searchCommune', obj => {        
        console.log("EMIT.ON searchCommune", obj.nom_commune_complet, obj.longitude, obj.latitude);
        this.saveObj = obj;
        this.moveFromSearch = true;
        this.scaleLabels = null;
        this.zoom = this.defaultZoom;
        this.center = { lng: obj.longitude, lat: obj.latitude };
        this.searchCommuneINSEE = obj.code_commune_INSEE;
        this.drawer = false;
      });

      EventBus.$on('searchMandataire', obj => {
        console.log("EMIT.ON searchMandataire", obj);
        this.$router.push({ name: 'mandataire', params: { id: obj.id } });
      });
      
      console.log("CREATED MAP CENTER", JSON.stringify(this.mapCenter));
      console.log("CURRENT MAND", this.currentMand);

      // if (!this.mapCenter) {
      //   if (this.isNetworkAgences && this.user && this.user.agenceLat && this.user.agenceLng) {
      //     this.location = { lat: this.user.agenceLat, lng: this.user.agenceLng }
      //   } else if (this.currentMand && this.currentMand.lat && this.currentMand.lng) {
      //     console.log("CREATED CURRENT MAND", this.currentMand);
      //     this.location = { lat: this.currentMand.lat, lng: this.currentMand.lng }          
      //   }
      // } else {
      //   this.location = this.mapCenter;
      //   console.log("CREATED STORE", JSON.stringify(this.location));
      // }
    },

    async mounted() {
      /*
      // to scrap for launch email image generation 
      if (this.$route.query.lat && this.$route.query.lng) {
        const zoom = this.$route.query.zoom ? parseInt(this.$route.query.zoom) : 11;
        this.center = {lng: this.$route.query.lng, lat: this.$route.query.lat};
        this.setMapZoom(zoom);
        this.displayScale = true;
        this.displayControls = false;
      } else {
        if (this.user.id_m_rm_ra) {
          console.log("FETCH MAND", this.user.id_m_rm_ra);
          await this.fetchMandataire(this.user.id_m_rm_ra);
          console.log("MOUNTED CURRENT MAND", this.currentMand.lat, this.currentMand.lng);
          this.location = { lat: this.currentMand.lat, lng: this.currentMand.lng }
        } else {
          this.loading = true;
          this.$getLocation().then(location => {
              this.location = location;
              console.log("CREATED GEOLOC", JSON.stringify(this.location));
              this.loading = false;
            }, error => {
              console.log("Geolocation error", error);
              this.loading = false;
            });
        }
      }
      */
      this.center = this.lastCenter;

      // TEST center
      // this.center = { lng: 2.1791122380758425, lat: 48.88614255261046 };

      /*
      this.zoom = this.mapZoom || 12;
      this.drawer = this.mapDrawer || false;
      this.ville = this.mapVille || '';

      if (!this.mapLocalVariable) this.setMapLocalVariable(this.mandSort);
      else this.mandSort = this.mapLocalVariable;

      if (this.$refs.map.map) {
        this.mapBounds = this.$refs.map.map.getBounds();
        // console.log("MAP BOUNDS", JSON.stringify(this.mapBounds));
      }
      */

      const self = this;
      setTimeout(() => {
        self.displayTour('Mapbox');
      }, 1000);
    },

    destroyed() {
      this.destroyEvents();
    },
  }
</script>

<style scoped>
.v-toolbar__title {
  margin-top: 2px;
  font-size: 16px;
  font-weight: bold;
  color: #FF9E00;
  background-color: #666;
  padding-left: 4px;
  padding-right: 4px;
}
.right_side {
  font-size: 12px;
  color: white;
}
.right_side_title {
  font-size: 14px;
  font-weight: bold;
}
.side-container {
  position: relative;
}
.plus-side-container {
  position: absolute;
  right: -20px;
  top: 0px;
}
.box-mand {
  position: relative;
}
.num-mand {
  position: absolute;
  top: 5px;
  right: 5px;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  border: 1px solid #D3D3D3;
  color: #D3D3D3;
  text-align: center;
}
.photo {
}
.for_photo {
  vertical-align: top;
}
.map {
  width: 100%;
  height: 100%;
}
.map-pixel {
  position: absolute;
  width: 1px;
  height: 1px;
  top: 0;
  left: 0;
  z-index: 1;
}
.map-pixel-1 {
  margin-top: 400px;
  margin-left: 400px;
}
.map-pixel-2 {
  margin-top: 0px;
  margin-left: 500px;
}
.map-pixel-3 {
  margin-top: 0px;
  margin-left: 650px;
}
.map-scale {
  position: absolute;
  top: 0;
  left: 0;
  margin-top: 35px;
  margin-left: 10px;
  z-index: 1;
}
.center-text {
  text-align: center;
  padding-left: 5px;
  padding-right: 5px;
}
.map-city-overlay {
  position: absolute;
  width: 200;
  height: 40;
  margin-left: 10px;
  background-color: transparent;
  z-index: 1;
  font-size: 14px;
  font-weight: bold;
}
.map-cdr-overlay {
  position: absolute; pointer-events: none; z-index: 2;
}
.map-cdr-overlay { left: 0; bottom: 0; }
.map-cdr-overlay .mapboxgl-ctrl { margin: -225px 0px 0 10px; float: left; }

.map-overlay-reseaux {
  position: absolute;
  cursor: pointer;
  z-index: 2;
  top: 0;
  left: 0;
  margin-top: 130px;
  margin-left: 10px;
  background-color: #fff;
  width: 145px;
  border: 1px solid #D3D3D3;
  border-radius: 4px;
}
.map-overlay-reseaux-open {
  padding: 7px 5px 5px 5px;
  height: 60%;
}
.map-overlay-reseaux-closed {
  padding: 5px 5px 5px 5px;
  height: 30px;
  font-size: 13px;
}
.map-cdr-button {
  background-color: transparent !important;
}
.map-cdr-button-sel {
  background-color: #B0B0B0 !important;
}
.selectFilter {
  font-size: 12px;
}
.city {
  color: #FF9E00;
}
.link-dark {
  color: #4AEBF8;
}
.title-zone {
  color: #AE75D8;
}
.sheet {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  color: #005F73;
  height: 60px;
  width: 60px;
}
.font-smaller {
  font-size: smaller;
}
.font-bigger {
 font-size: 18px;
 font-weight: bold;
}
.toggle-info {
  cursor: pointer;
}
.grid-reseaux {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.item-reseau {
  flex-basis: 40%;
  flex: 1;
}
.item-reseau-small {
  flex-basis: 20%;
  flex: 1;
}
.img-reseau {
  border-radius: 50%;
  border: 1px solid #D3D3D3;
  margin-right: 10px;
  margin-bottom: 10px;
}
.badge-reseau {
  font-size: 10px;
}
.evol-stats {
  text-align: center
}
.inner-stats {
  display: inline-block;
}
</style>
