<template>
  <div>
    <v-alert
      v-model="bottomInfo"
      dense
      type="warning"
      color="#EF7C00"
      icon="mdi-alert"
      dismissible
    >
      {{currentMand.rmessage}}
    </v-alert>

    <v-alert v-if="currentMand.status == 0"
      dense
      type="error"
      color="red"
      icon="mdi-alert"
      dismissible
    >
      Ce mandataire n'existe plus - veuillez <a class="link-mailto" href="mailto:support@agentbis.com">nous joindre</a> si vous pensez que c'est une erreur 
    </v-alert>

    <!-- Basic {{isBasic}} Essentiel {{isEssentiel}} Pro {{isPro}} Enterprise {{isEnterprise}} -->
    <base-box id="step-mandataire-1">
      <div class="d-flex">
        <a @click="gotoMand" :class="{ zoneoff: pointerMandataire(currentMand.url) }" target="_blank">
          <photo-mand
            :obj="currentMand"
            :size="110"
            :isItMe="isMe"
          ></photo-mand>
        </a>
        <div class="ml-4">
          <b>{{currentMand.firstname}} {{mandataireName(currentMand.lastname)}}</b> du réseau <b>{{currentMand.rname}}</b><span class="smaller">{{labelTypeReseau}}</span>
          <br/>
          <div :class="blurIfNotMe">
            <span v-if="currentMand.city">Installé(e) à {{currentMand.city}} ({{currentMand.zip}})</span>
            <span v-if="showDateFrom()"> depuis le {{formatDate(mandStart, 'D MMM YYYY')}} ({{timeAgo(mandStart)}})</span>
            <span v-if="currentMand.siret">
              <br/>
              SIRET : {{currentMand.siret}}
            </span>
          </div>

          <!-- <div v-if="currentMand.stats && currentMand.stats.avis_count && currentMand.stats.avis_avg">
            <a :href="currentMand.avis_url" target="_blank">{{currentMand.stats.avis_count}} avis</a> - Moyenne : {{currentMand.stats.avis_avg}}
          </div> -->

          <div v-if="!isMe" class="mt-2">
            <v-btn
              :disabled="showProgressFav"
              outlined
              x-small
              color="primary"
              @click="favoriteMandataire"
            >
              {{isFavorite ? 'Supprimer' : 'Ajouter'}}
              <v-icon v-if="!showProgressFav" right small :color="isFavorite ? 'error' : 'primary'">
                {{isFavorite ? 'mdi-heart-remove' : 'mdi-heart'}}
              </v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="14" width="1" class="ml-2"
              ></v-progress-circular>
            </v-btn>

            <v-btn v-if="isNetwork"
              :disabled="isRecruit"
              outlined
              x-small
              color="primary"
              class="ml-2"
              @click="recruitMandataire"
            >
              Recruter
              <v-icon v-if="!showProgressRecruit" right small color="primary">
                mdi-shield-account
              </v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="14" width="1" class="ml-2"
              ></v-progress-circular>
            </v-btn>

            <!-- NO INVITE -->
            <v-btn v-if="0 && amIMandataire"
              :disabled="showProgressInvite || isInvited"
              outlined
              x-small
              color="primary"
              class="ml-2"
              @click="inviteMandataire"
            >
              {{labelInvited}}
              <v-icon v-if="!showProgressInvite" right :color="isInvitationAccepted ? 'green' : ''">
                mdi-link
              </v-icon>
              <v-progress-circular v-else indeterminate color="primary" size="14" width="1" class="ml-2"
              ></v-progress-circular>
            </v-btn>

            <v-btn
              outlined
              x-small
              color="primary"
              class="ml-2"
              @click="gotoMap"
            >
              Voir sa ville <v-icon small class="ml-1">mdi-map-marker-outline</v-icon>
            </v-btn>
            <v-btn
              :disabled="!currentMand.url"
              outlined
              x-small
              color="primary"
              class="ml-2"
              @click="gotoMand"
            >
              Fiche {{currentMand.rname}}<v-icon small class="ml-1">mdi-open-in-new</v-icon>
            </v-btn>
          </div>
        </div>
        <v-spacer></v-spacer>
          <v-img :class="{zone: amIMandataire || isNetworkAgences || isNetwork}" max-width="160" max-height="120" contain :src="franchiseImageUrl(currentMand.rid)" @click="gotoReseau"></v-img>
      </div>
    </base-box>

    <!-- <base-box class="mt-4" title="Evolution professionnelle" v-if="1 && movesDisplayed && movesDisplayed.length">
      <ul>
        <li v-for="move in movesDisplayed" :key="move.id">
          {{formatDate(move.move_at, 'D MMM YYYY')}} : 
          <span :class="blurEssentielPlus">[{{labelMove(move.move)}}] {{valueMove(move, 'old_value')}} → {{valueMove(move, 'new_value')}}</span>
        </li>
      </ul>
    </base-box> -->

    <base-box class="mt-4" title="Parcours" v-if="currentMand.turnover && currentMand.turnover.length">
      <ul>
        <li v-for="turn in currentMand.turnover" :key="turn.id">
          {{formatDate(turn.start, 'D MMM YYYY')}} - {{formatDate(turn.end, 'D MMM YYYY')}} : {{turn.name}} ({{timeAgo(turn.start, turn.end)}})
        </li>
      </ul>
    </base-box>

    <base-box id="step-mandataire-2" class="mt-4" title="Indicateurs" v-if="currentMand" v-show="hasIndicators">
      <v-row justify="space-around" class="mt-3">
        <v-col align="center" >
          <sheet
            v-if="currentMand && propsToSale.length"
            :value="currentMand.indics.meanAnnonces"
            title="Moyenne nombre<br/>mandats"
            :width="80"
            :blurry="blurPlus"
          ></sheet>
        </v-col>
        <v-col align="center" >
          <sheet
            v-if="currentMand && propsToSale.length"
            :value="euros(currentMand.indics.meanValuePort)"
            title="Moyenne valeur<br/>portefeuille"
            :width="120"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col>
        <v-col align="center" >
          <sheet
            v-if="currentMand && propsToSale.length"
            :value="decimal(currentMand.indics.percExclusivity, 1)+'%'"
            title="Taux exclusivité moyen"
            :width="80"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col>
        <v-col align="center" v-if="currentMand.indics && currentMand.indics.transfoRatio > 0">
          <sheet
            v-if="currentMand && propsToSale.length"
            :value="decimal(currentMand.indics.transfoRatio, 1)+'%'"
            title="Taux de transformation"
            :width="80"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col>
        <!-- <v-col align="center" v-if="currentMand.indics.estimCA">
          <sheet
            :value="euros(currentMand.indics.estimCA)"
            title="CA estimé<br/>(4% comm.)"
            :width="100"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col> -->
        <v-col align="center" v-if="currentMand.indics && currentMand.indics.meanSoldPerMonth">
          <sheet
            :value="decimal(currentMand.indics.meanSoldPerMonth,1)"
            title="Ventes moyenne<br/>par mois"
            :width="80"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col>
        <v-col align="center" v-if="currentMand.indics && currentMand.indics.meanMonthToSale">
          <sheet
            :value="decimal(currentMand.indics.meanMonthToSale,1)"
            title="Délai moyen vente<br/>(mois)"
            :width="80"
            :blurry="blurEssentielPlus"
          ></sheet>
        </v-col>
        <v-col align="center">
          <sheet v-if="currentMand.indics && currentMand.indics.rayonDistanceCityZones"
            :value="currentMand.indics.rayonDistanceCityZones"
            title="Rayon zone chalandise<br/>(km)"
            :width="80"
            :blurry="blurPlus"
          ></sheet>
        </v-col>
      </v-row>
    </base-box>

    <div class="d-flex mt-4" v-if="!isBasic">
      <v-spacer></v-spacer>
      <!-- <v-btn-toggle
        v-if="propsToSale.length > 0"
        v-model="period"
      >
        <v-btn active-class="period-active" class="period">Semaine</v-btn>
        <v-btn active-class="period-active" class="period">Mois</v-btn>
      </v-btn-toggle> -->
      <div class="select-year ml-2">
        <v-select ml-2
          dense
          outlined
          :items="years"
          label="Année"
          v-model="currentYear"
          @change="fetch"
        >
        </v-select>
      </div>
    </div>
    <div v-else>
      &nbsp;
    </div>

    <base-box id="step-mandataire-3" v-if="currentMand" title="Activité">
      <div v-if="propsToSale.length">
        <div class="d-flex flex-around">
          <div>
            <BaseProgress :vif="!chartSeries.length"></BaseProgress>

            <vue-apex-charts v-if="!isBasic"
              id="step-mandataire-4"
              ref="refAnnonces"
              width="600"
              height="350"
              type="bar"
              :options="chartOptions"
              :series="chartSeries"
            ></vue-apex-charts>
            <v-img v-else
              width="570"
              src="@/assets/images/specimen-mandats.png">
            </v-img>

            <div v-if="newAnnonces.length">
              <span class="nouvelles">Nouveaux</span>
              <ul class="font-smaller mt-1" :class="blurEssentiel">
                <li v-for="ann in newAnnonces" :key="keyPropertyList('new', ann)">
                  <v-icon color="tertiary">{{ iconType(ann.type) }}</v-icon>
                  {{ann.city}}
                  <span v-if="ann.type == 'terrain'">{{ann.land}}</span><span v-else>{{ann.m2}}</span> m² <span v-if="ann.rooms">{{ann.rooms}} pièces</span>
                  <v-chip dark color="tertiary" x-small class="ml-1" v-if="ann.exclusivity">Exclusivité</v-chip>
                  - <b>{{euros(ann.price)}}</b>
                  <a v-if="(isPro || isEnterprise) && ann.url" class="ml-2 no-underline" :href="ann.url" target="_blank"><v-icon small>mdi-open-in-new</v-icon></a>
                </li>
              </ul>
            </div>
            <div v-if="removedAnnonces.length" class="mt-4">
              <span class="supprimees">Supprimés</span>
              <ul class="font-smaller mt-1" :class="blurEssentiel">
                <li v-for="ann in removedAnnonces" :key="keyPropertyList('removed', ann)">
                  <v-icon color="tertiary">{{ iconType(ann.type) }}</v-icon>
                  {{ann.city}}
                  <span v-if="ann.type == 'terrain'">{{ann.land}}</span><span v-else>{{ann.m2}}</span> m² <span v-if="ann.rooms">{{ann.rooms}} pièces</span>
                  <v-chip dark color="tertiary" x-small class="ml-1" v-if="ann.exclusivity">Exclusivité</v-chip>
                  - <b>{{euros(ann.price)}}</b>
                  <a v-if="(isPro || isEnterprise) && ann.url" class="ml-2 no-underline" :href="ann.url" target="_blank"><v-icon small>mdi-open-in-new</v-icon></a>
                </li>
              </ul>
            </div>
            <div v-if="soldAnnonces.length" class="mt-4">
              <span class="vendues">Vendus</span>
              <ul class="font-smaller mt-1" :class="blurEssentiel">
                <li v-for="ann in soldAnnonces" :key="ann.id">
                  <v-icon color="tertiary">{{ iconType(ann.type) }}</v-icon>
                  {{ann.city}}
                  <span v-if="ann.type == 'terrain'">{{ann.land}}</span><span v-else>{{ann.m2}}</span> m² <span v-if="ann.rooms">{{ann.rooms}} pièces</span>
                  <v-chip dark color="tertiary" x-small class="ml-1" v-if="ann.exclusivity">Exclusivité</v-chip>
                  - <b>{{euros(ann.price)}}</b>
                </li>
              </ul>
            </div>
          </div>

          <BaseProgress :vif="currentMand.rhas_sold && !Object.keys(evolSold).length"></BaseProgress>

          <div v-if="!isBasic">
            <graph-line
              id="step-mandataire-5"
              name="evolSold" 
              :title="cumulSoldTitle" 
              :data="evolSold" 
              :width="580" :height="350"
              :colors="['#6A626D']"
              :noData="noSold"
              :rotateLabels="true"
              @click="clickSold" @move="moveSold" @leave="leaveSold">
            </graph-line>
            <div v-if="ventes.length" class="mt-4">
              <span class="vendues">Vendus</span>
              <ul class="font-smaller mt-1" :class="blurEssentiel">
                <li v-for="vente in ventes" :key="vente.id">
                  <v-icon color="tertiary">{{ iconType(vente.type) }}</v-icon>
                  {{vente.city}}
                  <span v-if="vente.type == 'terrain'">{{vente.land}}</span><span v-else>{{vente.m2}}</span> m² <span v-if="vente.rooms">{{vente.rooms}} pièces</span>
                  <v-chip dark color="tertiary" x-small class="ml-1" v-if="vente.exclusivity">Exclusivité</v-chip>
                  - <b>{{euros(vente.price)}}</b>
                  <a v-if="(isPro || isEnterprise) && vente.url" class="ml-2 no-underline" :href="vente.url" target="_blank"><v-icon small>mdi-open-in-new</v-icon></a>
                  <div v-if="timeToSale(vente) > 0">
                    <span class="time-sale">(en {{timeToSale(vente)}} mois)</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div v-else>
            <v-img
              width="580"
              src="@/assets/images/specimen-cumul-sales.png"
            ></v-img>
          </div>

        </div>

        <BaseProgress :vif="!evolValuePort.series"></BaseProgress>

        <div class="d-flex mt-4 justify-space-around">
          <graph-line v-if="!loading && !isBasic"
            name="evolValuePort"
            title="Valeur portefeuille" 
            :data="evolValuePort" 
            :width="600" :height="380"
            :funcVal="valeurEuro"
            >
          </graph-line>
          <div v-else>
            <v-img
              width="570"
              src="@/assets/images/specimen-portefeuille.png"
            ></v-img>
          </div>

          <BaseProgress :vif="currentMand.rhas_sold && !evolValueVentes.series"></BaseProgress>
          
          <graph-line v-if="!loading && !isBasic"
            name="evolValueVentes"
            :title="cumulValuesSoldTitle" 
            :data="evolValueVentes" 
            :width="600" :height="380"
            :noData="noSold"
            :colors="['#6A626D']"
            :funcVal="valeurEuro"
            >
          </graph-line>
          <div v-else>
            <v-img
              width="600"
              src="@/assets/images/specimen-cumul-money.png"
            ></v-img>
          </div>

        </div>

        <div class="d-flex mt-4 justify-space-around">
          <graph-bar v-if="!loading && !isBasic"
            name="evolAnnoncesBar"
            title="Types de biens" 
            :data="evolAnnoncesBar" 
            :width="500"
            :height="350" 
            :stacked="true"
            :showDataLabel="false"
            :tooltip="tooltipAnnoncesBar"
            legendPosition="bottom"
            @click="clickTypes">
          </graph-bar>
          <div v-else>
            <v-img
              width="570"
              src="@/assets/images/specimen-types.png"
            ></v-img>
          </div>

          <vue-apex-charts v-if="!loading"
            ref="donutTypes"
            height="350"
            type="donut"
            :options="donutOptions"
            :series="donutSeries"
          ></vue-apex-charts>

          <graph-bar v-if="!loading"
            name="barPrices"
            title="Profil prix" 
            :data="barPrices" 
            :stacked="false"
            :colors="['#77B6EA', '#6A626D']"
            :width="500" 
            :height="350"
            :showDataLabel="false"
            :separatorWidth="2"
            valueSuffix="%"
            labelY="%"
            legendPosition="bottom">
          </graph-bar>

        </div>

        <div>
          <div class="d-flex justify-center my-4 font-smaller">
            <b>Prix par type de bien</b>
          </div>
          <div class="d-flex justify-center flex-wrap font-smaller">
            <div v-if="allStats.tosale && allStats.tosale.maison.avg_m2" style="text-align: center">
              <v-icon large :color="colorType('maison')">{{ iconType('maison') }}</v-icon>
              <ul class="ul-nodeco">
                <li>Surface moyenne : <span :class="blurEssentiel"><b>{{allStats.tosale.maison.avg_m2}}m²</b></span></li>
                <li>Prix m² moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.maison.pricem2_avg)}}</b></span>
                  <br/><span class='font-smaller' :class="blurEssentiel">({{euros(allStats.tosale.maison.pricem2_min)}} - {{euros(allStats.tosale.maison.pricem2_max)}})</span>
                </li>
                <li>Prix moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.maison.price_avg)}}</b></span>
                  <br/><span class='font-smaller' :class="blurEssentiel">({{euros(allStats.tosale.maison.price_min)}} - {{euros(allStats.tosale.maison.price_max)}})</span>
                </li>
                <li>&nbsp;</li>
              </ul>
            </div>
            <div v-if="allStats.tosale && allStats.tosale.appartement.avg_m2" style="text-align: center" class="ml-8">
              <v-icon large :color="colorType('appartement')">{{ iconType('appartement') }}</v-icon>
              <ul class="ul-nodeco">
                <li>Surface moyenne : <span :class="blurEssentiel"><b>{{allStats.tosale.appartement.avg_m2}}m²</b></span></li>
                <li>Prix m² moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.appartement.pricem2_avg)}}</b></span>
                  <br/><span class='font-smaller' :class="blurEssentiel">({{euros(allStats.tosale.appartement.pricem2_min)}} - {{euros(allStats.tosale.appartement.pricem2_max)}})</span>
                </li>
                <li>Prix moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.appartement.price_avg)}}</b></span>
                  <br/><span class='font-smaller' :class="blurEssentiel">({{euros(allStats.tosale.appartement.price_min)}} - {{euros(allStats.tosale.appartement.price_max)}})</span>
                </li>
                <li>&nbsp;</li>
              </ul>
            </div>
            <div v-if="allStats.tosale && allStats.tosale.terrain.price_avg" style="text-align: center" class="ml-8">
              <v-icon large :color="colorType('terrain')">{{ iconType('terrain') }}</v-icon>
              <ul class="ul-nodeco">
                <li v-if="allStats.tosale.terrain.avg_m2">Surface moyenne : <span :class="blurEssentiel"><b>{{bigNumber(allStats.tosale.terrain.avg_m2)}}m²</b></span></li>
                <li>Prix moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.terrain.price_avg)}}</b></span></li>
                <li>&nbsp;</li>
              </ul>
            </div>
            <div v-if="allStats.tosale && allStats.tosale.autres.price_avg" style="text-align: center" class="ml-8">
              <v-icon large :color="colorType('autres')">{{ iconType('autres') }}</v-icon>
              <ul class="ul-nodeco">
                <li>Prix moyen : <span :class="blurEssentiel"><b>{{euros(allStats.tosale.autres.price_avg)}}</b></span></li>
                <li>&nbsp;</li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div v-else>
        Aucune activité en ce moment
      </div>
    </base-box>

    <base-box id="step-mandataire-6" v-if="currentMand" class="mt-4" :title="zonesTitle">
      <div class="my-2">
        <v-chip-group active-class="primary--text" column mandatory v-model="currentFilter">
          <v-chip v-for="zone in zones" 
            :key="zone.id_commune"
            :value="zone.id_commune"
            :class="{ 'sa-ville': zone.sa_ville }"
          >
            {{zone.code_postal}} {{zone.nom_commune_complet}} <span class="zone-count" v-if="zone.count">({{zone.count}})</span>
          </v-chip>
          <v-chip v-if="1"
            :value="0">
            toutes
          </v-chip>
        </v-chip-group>
      </div>
    </base-box>

    <base-box id="step-mandataire-7" v-if="currentMand && properties.length > 0" class="mt-4" :title="propsTitle">
      <div class="d-flex">
        <v-spacer></v-spacer>
        <v-switch
          v-model="onlyExclusivity"
          label="Exclusivité seulement"
        ></v-switch>
      </div>
      <v-row class="mt-1">
        <v-col>
          <span v-if="currentMand.rhas_sold">Mandats ({{propsToSale.length}})</span>
          <v-card min-height="160" class="mt-2" dark v-for="property in propsToSale" :key="keyPropertyList('sale', property)" color="#023047" @click="gotoProp(property.url)">
            <v-system-bar
              color="#0A9396"
              dark
            >
              <span>{{propSubtitle(property)}}</span>
              <v-spacer></v-spacer>
              <span class="badge-offer" v-if="property.offer">sous offre</span>
              <span class="badge-compromis" v-if="!property.offer && property.compromis">sous compromis</span>
            </v-system-bar>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card-subtitle>
                <v-icon :color="colorType(property.type)" class="mr-2">{{ iconType(property.type) }}</v-icon>
                {{property.type}} <span :class="blurEssentiel">{{propSubtitle2(property)}}</span>
                <br/>
                <br/>
                <span class="price" :class="blurEssentiel">{{euros(property.price)}}</span>
                <br/>
                <span class="font-smaller" :class="blurEssentiel" v-if="property.m2 || property.land">{{m2('tosale', property)}}</span>
                <div v-if="property.price">
                  <br/>
                  <span class="font-smaller date" :class="blurEssentiel">{{formatDate(property.created_at, 'D MMM YYYY')}} (depuis {{diffDays(property.created_at)}})</span>
                </div>
              </v-card-subtitle>

              <v-avatar
                size="150"
              >
                <div class="photo photo-bien">
                  <div class="d-block" :class="{ 'zone': property.url }">
                    <!-- <div class="badge-compromis" v-if="property.compromis"><span>Compromis</span></div> -->
                    <!-- <div class="badge-offer" v-if="property.offer"><span>Offre</span></div> -->
                    <div class="badge-exclu" v-if="property.exclusivity"><span>Exclusivité</span></div>
                    
                    <img 
                      class="photo photo-bien zone"
                      :src="property.photo"
                      :class="{ 'zone': property.url, 'blurPlus': blurEssentielPlus }"
                    >
                  </div>
                </div>
              </v-avatar>
            </div>
          </v-card>
        </v-col>

        <v-col v-if="currentMand.rhas_sold">
          Ventes ({{propsSold.length}})
          <v-card min-height="160" class="mt-2" dark v-for="property in propsSold" :key="keyPropertyList('sold', property)" color="#023047" @click="gotoProp(property.url)">
            <v-system-bar
              color="#0A9396"
              dark
            >
              <span>{{propSubtitle(property)}}</span>
            </v-system-bar>
            <div class="d-flex flex-no-wrap justify-space-between">
              <v-card-subtitle>
                <v-icon :color="colorType(property.type)" class="mr-2">{{ iconType(property.type) }}</v-icon>
                {{property.type}} <span :class="blurEssentiel">{{propSubtitle2(property)}}</span>
                <br/>
                <br/>
                <span class="price" :class="blurEssentiel">{{euros(property.price)}}</span>
                <br/>
                <span class="font-smaller" :class="blurEssentiel" v-if="property.m2 || property.land">{{m2('sold', property)}}</span>
                <div v-if="property.price">
                  <br/>
                  <span class="font-smaller date" :class="blurEssentiel">{{dateSold(property)}}</span>
                </div>
              </v-card-subtitle>

              <v-avatar
                class="ma-3"
                size="125"
                tile
              >
                 <div class="photo photo-bien">
                  <div class="d-block" :class="{ 'zone': property.url }">
                    <div class="badge-exclu" v-if="property.exclusivity"><span>Exclusivité</span></div>
                    
                    <img
                      class="photo photo-bien"
                      :src="property.photo"
                      :class="{ 'zone': property.url, 'blurPlus': blurEssentielPlus }">
                    >
                  </div>
                </div>
              </v-avatar>
            </div>
          </v-card>
        </v-col>
      </v-row>
    </base-box>

    <!-- <v-bottom-sheet
      v-model="bottomInfo"
      inset
    >
      <v-sheet height="150px" class="pt-6">
        <div class="d-flex justify-center align-center">
          <v-icon x-large color="warning" class="mr-3">mdi-alert</v-icon>
          <span>
            {{currentMand.rmessage}}
          </span>
        </div>
        <div class="d-flex justify-center mt-2">
          <v-btn
              text
              color="primary"
              @click="bottomInfo = !bottomInfo"
          >
            Fermer
          </v-btn>
        </div>
      </v-sheet>
    </v-bottom-sheet> -->

    <v-tour name="Mandataire" :steps="mandataireSteps"></v-tour>

  </div>
</template>

<script>
  import { EventBus } from '@/event-bus.js';
  import BaseBox from "@/components/commonComponents/BaseBox";
  import VueApexCharts from 'vue-apexcharts';
  import GraphLine from "@/components/GraphLine";
  import GraphBar from "@/components/GraphBar";
  import Sheet from "@/components/Sheet";
  import PhotoMand from "@/components/PhotoMand";
  import mixinTour from "@/mixins/mixin-tour-steps";

  import { mapActions, mapGetters, mapState } from "vuex";

  const moment = require('moment');
  const ss = require('simple-statistics');

  export default {
    name: 'Mandataire',

    mixins: [mixinTour],

    components: {
      BaseBox,
      VueApexCharts,
      GraphLine,
      GraphBar,
      Sheet,
      PhotoMand,
    },

    data: () => ({
      bottomInfo: false,
      COMMISSION_RATE: 4,
      id: null,
      period: 1,
      properties: [],
      histoProperties: [],
      zones: [],
      currentFilter: 0,
      allStats: {},
      evol: [],
      evolSold: {},
      evolAnnoncesBar: {
        series: [],
        labels: [],
      },
      tooltipAnnoncesBar: {
        shared: true,
        intersect: false,
      },
      barPrices: {
        series: [],
        labels: [],
      },
      onlyExclusivity: false,
      chartSeries: [],
      chartOptions: {
        noData: {
          text: 'Chargement ...' 
        },
        chart: {
          type: 'bar',
          height: 440,
          stacked: true,
          toolbar: {
            show: false
          },
          animations: {
            enabled: false,
          }
        },
        colors: ['#77B6EA', '#FF4560', '#6A626D', '#F7A732'],
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '30%',
            barHeight: '100%',
          },

        },
        dataLabels: {
          enabledOnSeries: [3],
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
        stroke: {
          width: [1, 1, 1, 3]
        },
        grid: {
          xaxis: {
            lines: {
              show: false
            },
          },
          yaxis: {
            lines: {
              show: false
            }
          }
        },
        yaxis: [],
        tooltip: {
          shared: true,
          intersect: false,
          fixed: {
            enabled: true,
            position: 'topLeft',
          },
          x: {
            show: false,
            formatter: function (val) {
              return val
            }
          },
          y: {
            show: false,
            formatter: function (val) {
              return Math.abs(val)
            }
          },
          marker: {
            show: true,
          }
        },
        title: {
          text: "Mandats",
          align: 'center'
        },
        xaxis: {
          tooltip: {
            enabled: true
          },
          categories: [],
          tickPlacement: 'between',
          title: {
            text: ''
          },
          labels: {
            rotate: -45,
            rotateAlways: true,
          }
        },
        markers: {
          size: 5
        }
      },
      ventes: [], // click on sold
      newAnnonces: [], // click on annonces
      removedAnnonces: [], // click on annonces
      soldAnnonces: [], // click on annonces
      evolValuePort: {},
      evolValueVentes: {},
      donutSeries: [],
      donutOptions: {
        chart: {
          type: 'donut',
        },
        labels: [],
        title: {
          text: "Profil général biens",
          align: 'center' 
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: '12px',
            fontWeight: 'bold',
            colors: [ "#000" ],
          },
          
          background: {
            enabled: true,
            foreColor: '#fff',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 0,
            borderColor: '#000',
          },
          dropShadow: {
            enabled: false,
          }
        },
        legend: {
          show: true,
          position: 'bottom',
        }
      },
      showProgressFav: false,
      showProgressRecruit: false,
      showProgressInvite: false,
      hasIndicators: false,
      years: [
        {text: 'toutes', value: 0},
        // {text: '2024', value: 2024}
        {text: '2023', value: 2023},
        {text: '2022', value: 2022},
      ],
      currentYear: 0,
      cumulSold: 0,
      cumulValuesSold: 0,
    }),

    watch: {
      period(val, old) {
        if (old != val) {
          this.loading = true;
          this.computeEvolutions(val);
          this.loading = false;
        }
      }
    },

    computed: {
      ...mapGetters('Mandataire', [ 'currentMand' ]),
      ...mapGetters('User', [ 'user', 'userUUID' ]),
      // ...mapState('User', [ 'user' ]),

      propsToSale() { 
        let filt = this.properties.filter(p => !p.sold);
        if (this.currentFilter != 0) filt = filt.filter(p => p.id_commune == this.currentFilter);
        if (this.onlyExclusivity) filt = filt.filter(p => p.exclusivity);
        // console.log("propsToSale currentFilter", this.currentFilter, "onlyExclusivity", this.onlyExclusivity);
        filt = filt.sort((a,b) => moment(b.created_at) - moment(a.created_at));
        // console.log("propsToSale", filt);
        return filt;
      },

      cumulSoldTitle() {
        return `Cumul nombre de ventes (${this.cumulSold})`;
      },

      cumulValuesSoldTitle() {
        return `Cumul valeurs ventes (${this.euros(this.cumulValuesSold)})`;
      },

      propsSold() { 
        let filt = this.properties.filter(p => p.sold); // && p.created_at != p.sold_at);
        if (this.currentFilter != 0) filt = filt.filter(p => p.id_commune == this.currentFilter);
        if (this.onlyExclusivity) filt = filt.filter(p => p.exclusivity);
        // console.log("propsSold currentFilter", this.currentFilter, "onlyExclusivity", this.onlyExclusivity);
        filt = filt.sort((a,b) => moment(b.sold_at) - moment(a.sold_at));
        return filt;
      },

      zonesTitle() {
        return "Zones (" + this.zones.length + ")"; 
      },

      propsTitle() {
        let label = "Mandats";
        if (this.currentMand.rhas_sold) label += " et Ventes";
        label += " en cours (" + this.properties.length + ")";
        return label;
      },

      mandStart() {
        return this.currentMand.date_start_MR || this.currentMand.created_at;
      },

      noSold() {
        return !this.currentMand.rhas_sold ? 'Aucune donnée de vente du réseau' : 'Aucune vente détectée';
      },

      isFavorite() {
        return this.user.favoris.find(f => f.id_favori == this.currentMand.id && f.type == 'mand');
      },

      isRecruit() {
        return this.user.recruit.find(f => f.id == this.currentMand.id);
      },

      isInvited() {
        return this.user.linksSent.find(l => l.id_mandataire2 == this.currentMand.id && l.type == 'link' && l.status == 'sent') ? true : false;
      },

      isInvitationDeclined() {
        return this.user.linksSent.find(l => l.id_mandataire2 == this.currentMand.id && l.type == 'link' && l.status == 'declined') ? true : (
          this.user.linksSent.find(l => l.id_mandataire2 == this.currentMand.id && l.type == 'link' && l.status == 'declined') ? true : false);
      },

      isInvitationAccepted() {
        return this.user.linksSent.find(l => l.id_mandataire2 == this.currentMand.id && l.type == 'link' && l.status == 'accepted') ? true : (
          this.user.linksReceived.find(l => l.id_mandataire2 == this.currentMand.id && l.type == 'link' && l.status == 'accepted') ? true : false); 
      },

      blurIfNotMe() {
        return this.isMe ? '' : this.blur;
      },

      borderMember() {
        return 'borderMember';
        // return this.currentMand.isMember ? 'borderMember' : ''; 
      },

      planPremium() {
        return true;
        // return !this.currentMand.plan_type || this.currentMand.plan_type == 'free' ? false : true;
      },

      labelTypeReseau() {
        return this.currentMand.rtype == 'reseau-agences' ? " (réseau d'agences de mandataires)" : "";
      },

      movesDisplayed() {
        return this.currentMand.moves ? this.currentMand.moves.filter(m => m.move != 'dept' && m.move != 'name') : [];
      },

      labelInvited() {
        if (this.isInvitationAccepted) return 'Supprimer';
        if (this.isInvitationDeclined) return 'Déclinée';
        if (!this.invited) return 'Inviter';
        return 'Envoyée';
      },

      firstYear() {
        return this.years.findIndex(y => y.value == this.currentYear) == this.years.length-1;
      }
    },

    methods: {
      ...mapActions('Mandataire', [ 'fetchMandataire', 'fetchHistoProperties', 'fetchProperties', 'fetchZones', 'fetchStats', 'addLink', 'removeLink' ]),
      ...mapActions('API', [ 'fetchSimulRevenus' ]),
      ...mapActions('User', [ 'fetchUserInfo', 'addFavori', 'removeFavori', 'addRecruit' ]),
      ...mapActions('Map', [ 'setMapCenter' ]),

      // IADPhoto(slug) {
      //     const path = `@/assets/photos/iad/${slug}.jpg`;
      //     try {
      //       return require(path)
      //     } catch (e) {
      //       return null;
      //     }
      // },

      async fetch() {
        this.loading = true;

        await this.fetchMandataire(this.id);

        this.currentMand.indics = {}

        await this.fetchProperties(this.id);
        this.properties = this.currentMand.properties;

        await this.fetchHistoProperties(this.id);

        await this.fetchZones({mandId: this.id, year: this.currentYear});
        this.zones = this.currentMand.zones;

        await this.fetchStats(this.id);
        this.computeAllStats();

        this.computeDistancesZones();

        this.computeZonesAndProperties();

        this.computeEvolutions(this.period);

        this.distributionPrice();

        // console.log("PROPS", this.properties);
        // console.log("propsToSale", this.propsToSale);
        // console.log("propsSold", this.propsSold);
        console.log("ZONES", this.zones);

        this.loading = false;
      },

      computeZonesAndProperties() {
        // console.log("computeZonesAndProperties", this.zones.length);
        for (let i=0; i<this.zones.length; i++) {
          this.zones[i].count = this.properties.filter(p => p.id_commune == this.zones[i].id_commune).length;
        }
        this.zones = this.zones.sort((a,b) => b.count - a.count); //.filter(z => z.count);

        for (let i=0; i<this.properties.length; i++) {
          const idx = this.zones.findIndex(z => z.id_commune == this.properties[i].id_commune);
          this.properties[i].idxZone = idx;
        }
      },

      _distance(lat1, lng1, lat2, lng2) {
        const R = 6371e3; // metres
        const φ1 = lat1 * Math.PI/180; // φ, λ in radians
        const φ2 = lat2 * Math.PI/180;
        const Δφ = (lat2-lat1) * Math.PI/180;
        const Δλ = (lng2-lng1) * Math.PI/180;

        const a = Math.sin(Δφ/2) * Math.sin(Δφ/2) +
                  Math.cos(φ1) * Math.cos(φ2) *
                  Math.sin(Δλ/2) * Math.sin(Δλ/2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));

        return R * c; // in metres
      },

      computeAllStats() {
        // console.log("ALL STATS", this.currentMand.allStats.length);

        // filter with year
        let allStats = this.currentYear ? this.currentMand.allStats.filter(as => moment(as.created_at).year() == this.currentYear) : this.currentMand.allStats;

        // BUG Efficity : certaines stats sont des string (maisons et apartements) :(
        if (this.currentMand.rid == 7) {
          allStats = allStats.filter(stats => {
            return (typeof stats.stats.tosale.maisons_price_min == 'string') ? false : true; 
          });
        }

        const maisons = allStats.filter(as => as.stats.tosale.maisons);
        const appartements = allStats.filter(as => as.stats.tosale.appartements);
        const terrains = allStats.filter(as => as.stats.tosale.terrains);
        const autres = allStats.filter(as => as.stats.tosale.autres);
        this.allStats = {
          tosale: {
            'maison': {
              avg_m2: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_m2_avg))) : 0,
              price_avg: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_price_avg))) : 0,
              price_min: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_price_min))) : 0,
              price_max: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_price_max))) : 0,
              pricem2_avg: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_pricem2_avg))) : 0,
              pricem2_min: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_pricem2_min))) : 0,
              pricem2_max: maisons.length ? Math.round(ss.mean(maisons.map(as => as.stats.tosale.maisons_pricem2_max))) : 0,
            },
            'appartement': {
              avg_m2: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_m2_avg))) : 0,
              price_avg: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_price_avg))) : 0,
              price_min: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_price_min))) : 0,
              price_max: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_price_max))) : 0,
              pricem2_avg: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_pricem2_avg))) : 0,
              pricem2_min: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_pricem2_min))) : 0,
              pricem2_max: appartements.length ? Math.round(ss.mean(appartements.map(as => as.stats.tosale.appartements_pricem2_max))) : 0,
            },
            'terrain': {
              avg_m2: terrains.length ? Math.round(ss.mean(terrains.map(as => as.stats.tosale.terrains_m2_avg))) : 0,
              price_avg: terrains.length ? Math.round(ss.mean(allStats.filter(as => as.stats.tosale.terrains).map(as => as.stats.tosale.terrains_price_avg))) : 0,
            },
            'autres': {
              price_avg: autres.length ? Math.round(ss.mean(autres.map(as => as.stats.tosale.autres_price_avg))) : 0,
            }
          }
        }

        // console.log("ALL STATS", this.allStats);
      },

      computeDistancesZones() {
        const city = this.zones.find(z => z.sa_ville);
        if (city) {
          // console.log("computeDistancesZones sa_ville", city.nom_commune_complet);

          const distances = this.zones.filter(z => z.id_commune != city.id_commune)
                                  .map(z => this._distance(z.lat, z.lng, city.lat, city.lng));
          
          this.currentMand.indics.sumDistanceCityZones = distances.length == 0 ? 0 : Math.round(ss.sum(distances)/1000);
          this.currentMand.indics.rayonDistanceCityZones = distances.length == 0 ? 0 : Math.round(ss.max(distances)/1000);
        } else {
          this.currentMand.indics.sumDistanceCityZones = 0;
          this.currentMand.indics.rayonDistanceCityZones = 0;
        }
      },

      propSubtitle(prop) {
        return prop.city + ' (' + prop.zip + ')';
      },

      propSubtitle2(prop) {
        let arr = [];
        // console.log(prop.price, prop.photo);
        if (prop.m2 || prop.land) arr.push((prop.m2 || prop.land) + 'm²');
        if (prop.rooms) arr.push(prop.rooms + ' pièce' + (prop.rooms > 1 ? 's' : ''));
        if (prop.bedrooms) arr.push(prop.bedrooms + ' chambre' + (prop.bedrooms > 1 ? 's' : ''));
        return arr.join(' - ');
      },

      gotoProp(url) {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog({plan: 'pro', message:"Affichage de l'annonce"});
          return;
        }
        if (url) window.open(url, '_blank');
      },

      gotoMap() {
        if (this.isBasic) {
          this.showPremiumDialog();
          return;
        }
        const city = this.zones.find(z => z.sa_ville);
        // console.log("gotoMap city", JSON.stringify(city));
        this.setMapCenter({lng: city.lng, lat: city.lat});
        this.$router.push({ name: 'mapbox', params: { id: this.currentMand.id_commune }});
      },

      gotoMand() {
        if (this.isBasic || this.isEssentiel) {
          this.showPremiumDialog('pro');
          return;
        }
        window.open(this.currentMand.url, '_blank');
      },

      // what = tosale || sold
      m2(what, prop) {
        if (prop.m2 || prop.land) {
          const _m2 = prop.m2 || prop.land;
          let result = (prop.price ? this.euros(Math.round(prop.price / _m2)) : "?") + " le m²";
          if (prop.idxZone && prop.idxZone != -1) {
            const zstats = this.zones[prop.idxZone].zstats;
            const attr = prop.type + 's_pricem2_avg';
            if (zstats[what][attr]) result += " (" + this.euros(zstats[what][attr]) + " moyen)";
          }
          return result;
        }
        return "";
      },

      diffDays(d1, d2=undefined) {
        const day2 = d2 ? moment(d2) : moment();
        const days = day2.diff(moment(d1), 'days');
        return days == 0 ? "aujourd'hui" : days + " jours";
      },

      leaveSold() {
        // this.ventes = [];
      },

      moveSold(idx) {
        this.clickSold(idx);
      },

      clickSold(idx) {
        if (idx >= 0) {
          const value = this.evolValueVentes.series[0].data[idx];
          // console.log("ENREG", this.evol[idx].soldList.length, value);
          
          // try to find the same value in evolValueVentes
          let idxFound = -1;
          for (let i=idx; i >= 0; i--) {
            if (this.evolValueVentes.series[0].data[i] == value) idxFound = i;
          }
          if (idxFound > 0) {
            this.ventes = this.evol[idxFound].soldList.map(a => ({
                id: a.id, type: a.type, city: a.city, zip: a.zip, exclusivity: a.exclusivity, m2: a.m2, land: a.land, url: a.url, rooms: a.rooms, price: a.sold_price, created_at: a.created_at, sold_at: a.sold_at
              }));
          } else {
            this.ventes = [];
          }
        }
      },

      clickTypes(idx) {
        // console.log("BAR", idx);
      },

      _hoverAnnonce(config, evol, annonces) {
        if (config.dataPointIndex <= 0) return;

        const ev = evol[config.dataPointIndex];
        // console.log("HOVER", config.dataPointIndex, annonces[config.dataPointIndex].date);
        // console.log("HOVER", config.dataPointIndex, ev.newAnnonces, annonces[config.dataPointIndex].data);
        // console.log("HOVER", JSON.stringify(ev));
        // console.log("HOVER", annonces[config.dataPointIndex].data);
        this.newAnnonces = annonces[config.dataPointIndex].data.filter(a => ev.newAnnonces.includes(a.url));
        // console.log("HOVER newAnnonces", this.newAnnonces);
        this.removedAnnonces = annonces[config.dataPointIndex-1].data.filter(a => !a.sold && ev.removedAnnonces.includes(a.url));
        this.soldAnnonces = ev.soldList;
        // console.log("new", this.newAnnonces, "removed", this.removedAnnonces);
      },

      // https://stackoverflow.com/questions/46794232/group-objects-by-multiple-properties-in-array-then-sum-up-their-values
      _groupBy(array, what, monthOrYear, f) {
        let groups = {};
        const prefix = what == 'sold' ? 'sold_' : '';
        // console.log("_groupBy", what, monthOrYear, "→", prefix);
        array.forEach(o => {
          // YYYY or YYYY-MM
          let group = monthOrYear == 'Y' ? o[prefix+'year'] : o[prefix+'year'] + '-' + o[prefix+'month'].toString().padStart(2, '0');
          groups[group] = groups[group] || [];
          groups[group].push(o);
        });
        return groups;
      },

      // by month - sold and annonces
      groupByMonth(annonces, what) {
        const ann = annonces.map(a => a.data).flat();

        const res = this._groupBy(ann, what, 'M', (item) => [item.year, item.month]);
        
        let result = [];
        const keys = Object.keys(res);
        for (let i=0; i<keys.length; i++) {
          const key = keys[i];
          if (what == 'sold' && i > 0) {
            // concat with prec
            const keyPrec = keys[i-1];
            res[key] = res[key].concat(res[keyPrec]);
          }
          // recreate structure
          result.push({
            date: key,
            data: [...new Map(res[key].map(item => [item.ref, item])).values()]
          });
        }

        result = result.sort((a,b) => moment(a.date) - moment(b.date));

        // console.log("groupByMonth", what, result);
        return result;
      },

      // by year - sold and annonces
      groupByYear(annonces, what) {
        const ann = annonces.map(a => a.data).flat();

        const res = this._groupBy(ann, what, 'Y', (item) => [item.year]);

        const result = [];
        
        const keys = Object.keys(res);
        for (let i=0; i<keys.length; i++) {
          const key = keys[i];
          if (what == 'sold' && i > 0) {
            // concat with prec
            const keyPrec = keys[i-1];
            res[key] = res[key].concat(res[keyPrec]);
          }
          let data = [...new Map(res[key].map(item => [item.ref, item])).values()];
          // recreate structure
          result.push({
            date: key,
            data: data,
          });
        }

        // console.log("groupByYear", what, result);
        return result;
      },

      computeEvolutions(period) {
        let annonces = [...this.currentMand.histoProperties[0][0]];
        // let sold = [...this.currentMand.histoProperties[1][0]];

        console.log("HISTO ANN", annonces);
        // console.log("HISTO SOLD", sold);

        if (period == 1) {
          annonces = this.groupByMonth(annonces, 'annonces');
          console.log("annonce grouped", annonces.length);
          // sold = this.groupByMonth(sold, 'sold');
        }

        // recrée years en fonction
        let agentYears = annonces.map(a => a.data.map(d => moment(d.created_at).year())).flat();
        agentYears = [...new Set(agentYears)]; 
        this.years = this.years.filter(y => {
          if (y.value == 0) return true;
          return agentYears.includes(y.value) || y.value == 2021;
        });
        // une seule valeur (avec le 0 = toutes)
        if (this.years.length == 2) {
          this.years = this.years.filter(y => y.value > 0);
          this.currentYear = this.years[0].value;
        }
        // console.log("AGENT YEARS", agentYears, "YEARS", this.years);

        console.log("HISTO ANN grouped", annonces.map(a => ({
          date: a.date,
          data: a.data.map(d => ({date: d.date, sold_date: d.sold_date, city: d.city, m2: d.m2, price: d.price}))
        })));
        // console.log("HISTO SOLD grouped", sold);

        // toutes les sold
        let sold = annonces.map(a => a.data.filter(a => a.sold)).flat();
        // console.log("sold", sold.length);
        sold = [...new Map(sold.map(item => [item.ref, item])).values()];
        console.log("ALL SOLD", sold);

        this.evol = [];

        if (annonces.length) {
          this.evol.push({
            month: moment(annonces[0].date).month()+1,
            year: moment(annonces[0].date).year(),
            len: annonces[0].data.length,
            new: 0,
            removed: 0,
            soldList: [],
            sold: 0,
            totalSold: 0,
            totalSoldPrice: 0,
          });
        }

        let totalSold = 0;
        let totalSoldPrice = 0;

        for (let i=1; i<annonces.length; i++) {
          const aPrev = annonces[i-1].data.map(a => a.url);
          // const aPrevRef = annonces[i-1].data.map(a => a.ref);
          const aNext = annonces[i].data.map(a => a.url);

          const difference = aNext.filter(a => !aPrev.includes(a));
          const removed = aPrev.filter(a => !aNext.includes(a));

          const soldList = sold.filter(s => s.sold_year == moment(annonces[i].date).year() && s.sold_month == moment(annonces[i].date).month()+1);

          totalSold += soldList.length;
          totalSoldPrice += soldList.reduce((acc, a) => acc + a.price, 0);

          this.evol.push({
            month: moment(annonces[i].date).month()+1,
            year: moment(annonces[i].date).year(),
            len: aNext.length,
            new: difference.length,
            newAnnonces: difference,
            removed: -removed.length,
            removedAnnonces: removed,
            soldList: soldList,
            sold: soldList.length,
            totalSold: totalSold,
            totalSoldPrice: totalSoldPrice, 
          });
        }

        if (this.currentYear) {
          this.evol = this.evol.filter(e => e.year == this.currentYear);
          annonces = annonces.filter(a => moment(a.date).year() == this.currentYear);

          if (!this.firstYear && this.evol.length) {
            const firstSold = this.evol[0].totalSold;
            const firstSoldPrice = this.evol[0].totalSoldPrice;
            // on soustrait le 1er totalSold à toutes les valeurs pour partir de 0
            this.evol = this.evol.map(e => {
              e.totalSold -= firstSold;
              e.totalSoldPrice -= firstSoldPrice;
              return e;
            });
          }
        }

        console.log("EVOL", this.evol.filter(e => e.sold > 0), "totalSold", totalSold);

        // sold
        if (this.evol.length && totalSold > 0) {
          this.evolSold = {
            series: [
              {
                name: 'ventes',
                data: this.evol.map(e => e.totalSold),
              },
            ],
            labels: annonces.map(a => a.date),
          };

          this.evolValueVentes = {
            series: [
              {
                name: 'cumul valeurs ventes',
                data: this.evol.map(e => e.totalSoldPrice),
              },
            ],
            labels: annonces.map(a => a.date),
          };

          // console.log("evolValueVentes", this.evolValueVentes);

          // meanMonthToSale
          const delaisVente = this.evol.map(e => e.soldList.filter(sl => sl.created_at != sl.sold_at).map(sl => moment(sl.sold_at).diff(moment(sl.created_at), 'months'))).flat();
          // console.log("FLAT", delais);
          this.currentMand.indics.meanMonthToSale = delaisVente.length ? ss.mean(delaisVente) : 0;

          // meanSoldPerMonth
          if (totalSold) {
            this.cumulSold = this.evol[this.evol.length-1].totalSold - this.evol[0].totalSold;
            this.cumulValuesSold = this.evol[this.evol.length-1].totalSoldPrice - this.evol[0].totalSoldPrice;
            this.currentMand.indics.meanSoldPerMonth = this.cumulSold / annonces.map(a => a.date).length;
          }

          // transfo ratio
          const countNew = this.evol.reduce((acc, a) => acc + a.new, 0);
          const countSold = this.evol.reduce((acc, a) => acc + a.sold, 0);
          this.currentMand.indics.transfoRatio = countNew ? (countSold / countNew)*100.0 : 0;

        } else {
          this.evolSold = {
            series: [
              {
                name: 'ventes',
                data: []
              },
            ],
            labels: []
          };

          this.evolValueVentes = {
            series: [
              {
                name: 'cumul valeurs ventes',
                data: []
              },
            ],
            labels: []
          };
        }

        // type de biens
        this.evolAnnoncesBar = {
          series: [
            {
              name: 'Maison',
              data: annonces.map(hp => hp.data.filter(hp => hp.type == 'maison').length),
            }, {
              name: 'Appartement',
              data: annonces.map(hp => hp.data.filter(hp => hp.type == 'appartement').length),
            }, {
              name: 'Terrain',
              data: annonces.map(hp => hp.data.filter(hp => hp.type == 'terrain').length),
            }, {
              name: 'Autres',
              data: annonces.map(hp => hp.data.filter(hp => hp.type == 'autre').length),
            }
          ],
          labels: annonces.map(hp => hp.date),
        }

        const donutTypes = [
          {name: 'Maison', value: this.evolAnnoncesBar.series[0].data.reduce((acc, a) => acc + a, 0)},
          {name: 'Appartement', value: this.evolAnnoncesBar.series[1].data.reduce((acc, a) => acc + a, 0)},
          {name: 'Terrain', value: this.evolAnnoncesBar.series[2].data.reduce((acc, a) => acc + a, 0)},
          {name: 'Autres', value: this.evolAnnoncesBar.series[3].data.reduce((acc, a) => acc + a, 0)},
        ]
        // console.log("DONUT", donutTypes);
      
        const maxBar = Math.max(...this.evol.map(e => e.new));
        const minBar = Math.min(...this.evol.map(e => e.removed));
        const maxLine = Math.max(...this.evol.map(e => e.len));
        const minLine = Math.min(...this.evol.map(e => e.len));

        // console.log(maxBar, minBar, maxLine, minLine);

        this.chartSeries = [
          {
            name: 'Nouveaux',
            data: this.evol.map(e => e.new),
            type: 'bar'
          },
          {
            name: 'Supprimés',
            data: this.evol.map(e => e.removed),
            type: 'bar'
          },
          {
            name: 'Vendus',
            data: this.evol.map(e => -e.sold),
            type: 'bar'
          },
          {
            name: 'Total',
            type: 'line',
            data: this.evol.map(e => e.len)
          }
        ];

        // console.log("CHART SERIES", this.chartSeries);

        this.currentMand.indics.meanAnnonces = this.evol.length ? Math.round(ss.mean(this.evol.map(e => e.len))) : '-';
        this.currentMand.indics.sdAnnonces = this.evol.length ? ss.standardDeviation(this.evol.map(e => e.len)) : 0;
        const allExcluLength = annonces.reduce((acc,a) => acc + a.data.filter(af => af.exclusivity).length, 0);
        this.currentMand.indics.percExclusivity = allExcluLength ? allExcluLength / annonces.reduce((acc,a) => acc + a.data.length, 0) * 100 : 0;

        // valeur portefeuille
        this.evolValuePort = {
          series: [
            {
              name: 'valeur portefeuille',
              data: annonces.map(hp => {
                const all = [...new Map(hp.data.map(item => [item.ref, item])).values()];
                return all.reduce((acc, a) => acc + a.price, 0);
              })
            },
          ],
          labels: annonces.map(hp => hp.date),
        };

        this.currentMand.indics.meanValuePort = Math.round(this.evolValuePort.series[0].data.reduce((acc, a) => acc + a, 0) / this.evolValuePort.series[0].data.length);

        const self = this;
        // this.$nextTick(() => {

        this.hasIndicators = this.indicsEmpty();

        setTimeout(() => {
          if (annonces.length) {
            // console.log("TIMEOUT", self.$refs);

            const chart = this.$refs.refAnnonces.chart;
            this.updateNoData(chart);
            const options = {
              chart: {
                events: {
                  dataPointMouseEnter: (event, chartContext, config) => {
                    this._hoverAnnonce(config, this.evol, annonces);
                  }, 
                  mouseMove: (event, chartContext, config) => {
                    this._hoverAnnonce(config, this.evol, annonces);
                  },
                  mouseLeave: () => {
                    // this.newAnnonces = [];
                    // this.removedAnnonces = [];
                  }
                },
              },
              xaxis: {
                categories: annonces.map(hp => hp.date),
                tooltip: {
                  enabled: true,
                },
              },
              yaxis: [
                {
                  max: maxBar+1,
                  min: minBar-1,
                  axisTicks: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                    color: '#008FFB'
                  },
                  labels: {
                    show: false,
                    style: {
                      colors: '#008FFB',
                    }
                  },
                  title: {
                    text: "",
                    style: {
                    }
                  },
                },
                {
                  max: maxBar+1,
                  min: minBar-1,
                  opposite: true,
                  axisTicks: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                    color: '#00E396'
                  },
                  labels: {
                    show: false,
                    style: {
                      colors: '#00E396',
                    }
                  },
                  title: {
                    text: "",
                  }
                },
                {
                  max: maxLine + 20,
                  min: minLine - 20,
                  opposite: true,
                  axisTicks: {
                    show: false,
                  },
                  axisBorder: {
                    show: false,
                    color: '#F7A732'
                  },
                  labels: {
                    show: false,
                  },
                  title: {
                    text: "",
                    style: {
                      color: '#F7A732',
                    }
                  }
                }
              ]
            };
            // console.log("CHART OPTIONS", options, "MAX BAR", maxBar, "MIN/MAX LINE", minLine,maxLine);
            chart.updateOptions(options);
          
            const chartD = this.$refs.donutTypes.chart;
            this.donutSeries = donutTypes.map(dt => dt.value);
            // console.log("DonutSeries", this.donutSeries, donutTypes.map(dt => dt.name));
            chartD.updateOptions({
              labels: donutTypes.map(dt => dt.name),
            });
          }

        }, 300);

      },

      _computeDistribution(props) {
        const th = [0, 150, 250, 500, 750, 1000, 1250, 1500, 1750, 2000];
        const MAX = 999999999999;
        let result = [];
        
        for (let i=0; i<th.length; i++) {
          if (i != th.length-1) {
            result.push({
              low: th[i] * 1000,
              high: th[i+1] * 1000,
              count: 0
            });
          } else {
            result.push({
              low: th[i] * 1000,
              high: MAX,
              count: 0
            });
          }
        }

        let total = 0;
        for (let prop of props) {
          for (let i=0; i<result.length; i++) {
            const p = prop.price;
            if (p >= result[i].low && p <= result[i].high) {
              result[i].count++;
              total++;
              break;
            }
          }
        }

        return {data: result, total: total};
      },

      distributionPrice() {
        let annonces = [...this.currentMand.histoProperties[0][0]];
        let allSold = [...this.currentMand.histoProperties[1][0]];

        console.log("distributionPrice", annonces, allSold);
        if (this.currentYear) {
          annonces = annonces.filter(a => moment(a.date).year() == this.currentYear);
          allSold = allSold.filter(s => moment(s.date).year() == this.currentYear);
        }
        console.log("distributionPrice AFTER", annonces, allSold);

        // all annonces
        let annoncesFiltered = annonces.map(a => a.data.map(a => ({ref: a.ref, price: a.price})))
                                .flat();
        // unique ref
        annoncesFiltered = [...new Map(annoncesFiltered.map(item => [item.ref, item])).values()];

        // all sold
        let soldFiltered = allSold.map(a => a.data.map(a => ({ref: a.ref, price: a.price})))
                                .flat();
        // unique ref
        soldFiltered = [...new Map(soldFiltered.map(item => [item.ref, item])).values()];
        
        // console.log("DISTRIB", annoncesFiltered, soldFiltered.sort((a,b) => a.price - b.price));

        const tosale = this._computeDistribution(annoncesFiltered);
        const sold = this._computeDistribution(soldFiltered);

        let series = [];
        let labels = [];

        if (tosale.data.length) {
          series.push({
            name: 'Annonces',
            data : tosale.data.map(r => Math.round(r.count/tosale.total*100))
          });
          labels = tosale.data.map((r, idx) => {
                        const l = r.low / 1000;
                        const h = r.high / 1000;
                        return idx != tosale.data.length-1 ? `${l}k€ - ${h}k€` : `${l}k€ -`;
                      });
        }
        if (this.currentMand.rhas_sold && sold.data.length) {
          series.push({
            name: 'Ventes',
            data : sold.data.map(r => Math.round(r.count/sold.total*100))
          });
          labels = sold.data.map((r, idx) => {
                        const l = r.low / 1000;
                        const h = r.high / 1000;
                        return idx != sold.data.length-1 ? `${l}k€ - ${h}k€` : `${l}k€ -`;
                      });
        }

        if (series.length) {
          this.barPrices = {
            series: series,
            labels: labels
          }
        }

        // console.log("** distributionPrice", this.barPrices);
      },

      valeurEuro(val) {
        return this.euros(val);
      },

      showDateFrom() {
        // axo&actifs(26)
        return ![26].includes(this.currentMand.rid);
      },

      gotoReseau() {
        // NO ! only the proper network
        // if (this.isMandataire && !this.isMyNetwork) return;
        if (!this.isEnterprise) {
          this.showPremiumDialog('enterprise');
          return;
        }
        this.$router.push({ name: 'reseau', params: {id: this.currentMand.rid } });
      },

      async favoriteMandataire() {
        if (this.isBasic) {
          this.showPremiumDialog();
          return;
        }
        this.showProgressFav = true;
        if (!this.isFavorite) {
          await this.addFavori({
            userUuid: this.userUUID,
            id: this.currentMand.id,
            type: 'mand'
          });
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('favorite-mand-add');
          }
        } else {
          await this.removeFavori({
            userUuid: this.userUUID,
            id: this.currentMand.id,
            type: 'mand'
          });
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('favorite-mand-remove');
          }
        }
        await this.fetchUserInfo(this.userUUID);
        setTimeout(() => {
          this.showProgressFav = false;
        }, 300);
      },

      async recruitMandataire() {
        if (!this.isEnterprise) {
          this.showPremiumDialog();
          return;
        }
        this.showProgressRecruit = true;

        await this.addRecruit({
          userUuid: this.userUUID,
          mandId: this.currentMand.id
        });

        await this.fetchUserInfo(this.userUUID);
        
        setTimeout(() => {
          this.showProgressRecruit = false;
        }, 300);
      },

      async inviteMandataire() {
        if (this.isBasic) {
          this.showPremiumDialog();
          return;
        }
        // console.log("inviteMandataire", this.isInvited);
        this.showProgressInvite = true;
        if (!this.isInvited) {
          // console.log("addLink", this.user.id_m_rm_ra, this.currentMand.id, 'link');
          await this.addLink({mandId1: this.user.id_m_rm_ra, mandId2: this.currentMand.id, type: 'link'});
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('invite');
          }
        } else {
          await this.removeLink({mandId1: this.user.id_m_rm_ra, mandId2: this.currentMand.id, type: 'link'});
          if (!this.isLocalhost) {
            // Sendinblue tracking
            window.sendinblue.track('invite-remove');
          }
        }
        await this.fetchUserInfo(this.userUUID);
        setTimeout(() => {
          this.showProgressInvite = false;
        }, 300);
      },

      indicsEmpty() {
        if (!this.currentMand.indics) return false;
        const values = Object.values(this.currentMand.indics);
        return values.filter(v => v != 0 && v != null && v != '-' && !isNaN(v)).length > 0;  
      },
    
      labelMove(move) {
        switch(move) {
          case 'city': return 'ville';
          case 'dept': return 'département';
          case 'name': return 'nom';
          case 'network': return 'réseau';
          default: return '';
        }
      },

      valueMove(move, field) {
        switch(move.move) {
          case 'network': return field == 'old_value' ? move.rname_old : move.rname_new; 
          case 'dept':
            return field == 'old_value' ? `${move.dname_old} (${move.old_value})` : `${move.dname_new} (${move.new_value})`;
          default: return move[field];
        }
      },

      dateSold(property) {
        if (property.created_at == property.sold_at) return '(date inconnue)';
        return this.formatDate(property.sold_at, 'D MMM YYYY') + " (en " + this.diffDays(property.created_at, property.sold_at) + ")";
      },

      urlMandataire(url) {
        return this.isPro || this.isEnterprise ? url : '';
      },

      pointerMandataire(url) {
        if (!url) return true;
        return this.isPro || this.isEnterprise  ? false : true;
      },

      keyPropertyList(prop, where) {
        return where+'-'+prop.id+'-'+prop.code_commune_INSEE;
      },

      timeToSale(data) {
        return moment(data.sold_at).diff(moment(data.created_at), 'months');
      },

    },

    async mounted() {
      this.id = this.$route.params.id;
      await this.fetch();

      const self = this;

      if (this.currentMand.rmessage) {
        const diff = moment().diff(this.currentMand.rcreated_at, 'days');
        if (this.currentMand.rmessage.match(/récent/)) {
          this.bottomInfo = diff < 60;
        } else this.bottomInfo = true;
      }

      setTimeout(() => {
        self.displayTour('Mandataire');
      }, 200);
    },

    beforeCreate() {
      EventBus.$on('searchMandataire', obj => {
        console.log("EMIT.ON searchMandataire", obj);
        this.$router.push({ name: 'mandataire', params: { id: obj.id } });
      });
    }
  }
</script>

<style lang="scss" scoped>
.zone {
  cursor: pointer;
}
.zoneoff {
  cursor: default;
}
.zone:hover {
  color: $linkcolor;
}
.zone-count {
  font-size: smaller;
  margin-left: 3px;
}
.sa-ville {
  font-weight: bold;
}
.font-smaller {
  font-size: smaller;
}
.photo {
  position: relative;
  width: 150px;
  height: 150px;
  display: block;
}
.d-block {
  display: block!important;
}

.badge-exclu {
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 150px;
    overflow: hidden;
    height: 150px;
    z-index: 1;
    span {
        width: 150px;
        position: absolute;
        top: 18px;
        right: -45px;
        transform: rotate(45deg);
        color: #fff;
        text-align: center;
        padding: 2px 40px 2px;
        font-size: 11px;
    }
}

.badge-exclu span {
    background: red;
}
.badge-compromis {
    background: #EE9B0E;
    margin-left: 3px;
    padding-left: 5px;
    padding-right: 5px;
}
.badge-offer {
    background: #EE9B0E;
    margin-left: 3px;
    padding-left: 5px;
    padding-right: 5px;
}
.photo-bien {
  border-radius: 10%;
  width: 110px;
  height: 110px;
}
.price {
  font-weight: bold;
  font-size: 16px;
}
.date {
  color: #94D2BD;
}
.no-underline {
  text-decoration: none; 
}
.nouvelles {
  background-color: #77B6EA;
  color: #fff;
  padding: 0px 4px 0px 4px;
}
.supprimees {
  background-color: #FF6178;
  color: #fff;
  padding: 0px 4px 0px 4px;
}
.vendues {
  background-color: #6A626D;
  color: #fff;
  padding: 0px 4px 0px 4px;
}
.flex-around {
  justify-content: space-around;
}
.period {
  font-size: 12px;
  max-height: 40px;
}
.period-active {
  background-color: #089F8F !important;
  color: white !important;
}
.ul-nodeco {
  list-style-type: none;
  padding: 0;
  margin: 0;
  line-height: 180%;
}
.link-mailto {
  color: white;
}
.select-year {
  height: 40px;
  width: 110px;
}
.time-sale {
  margin-left: 30px;
  font-style: italic;
}
</style>