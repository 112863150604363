<template>
  <v-col cols="12" sm="6" lg="3" class="py-0">
    <v-card class="mb-7" color="grey lighten-4">
      <v-card-text>
        <v-row class="d-flex align-center">
          <v-col cols="6">
            <h2 class="font-weight-light headline">{{number}}</h2>
            <h5
              class="subtitle-2 text-no-wrap blue-grey--text text--lighten-2 font-weight-regular"
            >
              {{title}}
            </h5>
          </v-col>
          <v-col cols="" class="pt-0">
            <vue-apex-charts
              type="donut"
              width="100"
              :options="chartOptions"
              :series="series"
            ></vue-apex-charts>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-col>
</template>

<script>
  import VueApexCharts from "vue-apexcharts";

  export default {
    name: 'BaseNumPercCard',

    props: {
      title: {
        type: String,
        required: true
      },
      number: {
        type: Number,
        required: true
      },
      percentage: {
        type: Number,
        required: true
      },
      color: {
        type: String,
        required: true
      },
    },

    components: {
      VueApexCharts
    },

    computed: {
      series() {
        return [ 100, parseFloat(this.percentage) ];
      },
      colors() {
        return [ this.color, "rgb(250, 250, 250)" ];
      }
    },

    data: () => ({
      chartOptions: {
        dataLabels: {
          enabled: false
        },
        grid: {
          padding: {
            left: 0,
            right: 0
          }
        },
        chart: {},
        plotOptions: {
          pie: {
            donut: {
              size: "85px"
            }
          }
        },
        stroke: {
          width: 0
        },
        legend: {
          show: false
        },
        colors: ["rgb(30, 136, 229)", "rgb(250, 250, 250)"],
        tooltip: {
          enabled: false,
          theme: "dark"
        }
      }
    })
  }
</script>

<style lang="scss">
</style>