// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { getSubscription, getPlans, createLink, checkout } from "@/api/stripe"

const LOCAL = {
  PLANS: 'user-plans',
}

const state = {
    plans: []
}

const getters = {
    plans: (state) => state.plans,
}

const actions = {
  async stripeFetchSubscription({ commit }, subscriptionId) {
    if (!subscriptionId) return null;
    const response = await getSubscription(subscriptionId);
    commit('NONE');
    return response.data.result;
  },

  async stripeFetchPlans({ commit }, type) {
    const plans = localStorage.getItem(LOCAL.PLANS);
    if (plans) return JSON.parse(plans);

    const response = await getPlans(type);
    console.log("stripeFetchPlans", type, response.data.result);
    commit('SET_PLANS', response.data.result);
    return response.data.result;
  },

  async stripeCreateLink({ commit }, {plan: plan, interval: interval, trial: trial}) {
    const response = await createLink(plan, interval, trial);
    commit('NONE');
    return response.data.result;
  },

  async stripeCheckout({ commit }, {customerId:  customerId, plan: plan, interval: interval, trial: trial}) {
    const response = await checkout(customerId, plan, interval, trial);
    commit('NONE');
    return response.data.result;
  },

}

const mutations = {
    NONE() {   
    },
    SET_PLANS(state, data) {
        state.plans = data;
        localStorage.setItem(LOCAL.PLANS, JSON.stringify(data));
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}