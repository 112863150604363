<template>
  <div>
    <div class="ml-1 mt-2 mb-6 smaller italic">Satistiques publiées par l'INSEE en 2019</div>

    <base-box :title="communeTitle" v-if="!loading">
      <div class="d-flex justify-space-around">
        <div>
          <span class="bold">Superficie</span> : {{bigNumber(insee.SUPERF)||'-'}} km&sup2;
          <br/>
          <span class="bold">Population</span> : {{bigNumber(insee.P19_POP)}}
        </div>
        <div>     
          <span class="bold">Niveau de vie</span> : {{bigNumber(insee.MED20, true)}}€
          <br/>
          <span class="bold">Taux de pauvreté</span> : {{decimal(insee.TP6020)}}%
        </div>
      </div>
    </base-box>

    <base-box title="Logements" v-if="!loading" class="mt-4">
      <div class="d-flex justify-space-around">
        <graph-line
          id="logements"
          name="logements" 
          title="Logements" 
          :data="dataLogements" 
          :width="330"
          :height="250"
        ></graph-line>
        <graph-line
          id="residprinc"
          name="residprinc" 
          title="Résidences principales" 
          :data="dataResidPrinc" 
          :width="330"
          :height="250"
        ></graph-line>
        <graph-line
          id="prop"
          name="prop" 
          title="Propriétaires" 
          :data="dataProp" 
          :width="330"
          :height="250"
        ></graph-line>
        <graph-line
          id="loc"
          name="loc" 
          title="Locataires" 
          :data="dataLoc" 
          :width="330"
          :height="250"
        ></graph-line>
      </div>

      <!-- <div>
        <b>Logements</b> {{number(insee2.P19_LOG)}} - 2013: {{number(insee2.P13_LOG)}} (<span><v-icon small :color="color(diffperc(insee2.P19_LOG, insee2.P13_LOG))">{{arrow(diffperc(insee2.P19_LOG, insee2.P13_LOG))}}</v-icon>{{diffperc(insee2.P19_LOG, insee2.P13_LOG)}}%</span>)
        <br/>
        <b>Résidences Principales</b> {{number(insee2.P19_RP)}} ({{perc(insee2.P19_RP, insee2.P19_LOG)}}) - 2013: {{number(insee2.P13_RP)}} (<span><v-icon small :color="color(diffperc(insee2.P19_RP, insee2.P13_RP))">{{arrow(diffperc(insee2.P19_RP, insee2.P13_RP))}}</v-icon>{{diffperc(insee2.P19_RP, insee2.P13_RP)}}%</span>)
        <br/>
        <b>Propriétaires</b> {{number(insee2.P19_RP_PROP)}} ({{perc(insee2.P19_RP_PROP, insee2.P19_RP)}}) - 2013: {{number(insee2.P13_RP_PROP)}} (<span><v-icon small :color="color(diffperc(insee2.P19_RP_PROP, insee2.P13_RP_PROP))">{{arrow(diffperc(insee2.P19_RP_PROP, insee2.P13_RP_PROP))}}</v-icon>{{diffperc(insee2.P19_RP_PROP, insee2.P13_RP_PROP)}}%</span>)
        <br/>
        <b>Locataires</b> {{number(insee2.P19_RP_LOC)}} ({{perc(insee2.P19_RP_LOC, insee2.P19_RP)}}) - 2013: {{number(insee2.P13_RP_LOC)}} (<span><v-icon small :color="color(diffperc(insee2.P19_RP_LOC, insee2.P13_RP_LOC))">{{arrow(diffperc(insee2.P19_RP_LOC, insee2.P13_RP_LOC))}}</v-icon>{{diffperc(insee2.P19_RP_LOC, insee2.P13_RP_LOC)}}%</span>)
      </div> -->

      <div class="d-flex justify-space-around mt-4">
        <base-box title="Maisons" v-if="!loading">
          <div class="d-flex">
            <v-icon :color="colorType('maison')" class="mr-4" large>{{ iconType('maison') }}</v-icon>
            <b>{{number(insee2.P19_RPMAISON)}} ({{perc(insee2.P19_RPMAISON, insee2.P19_RP)}})</b>
          </div>
          <graph-bar
            name="dym"
            title="Distribution années de construction" 
            :data="dataAnneesMaison" 
            :width="500"
            :height="350"
            :stacked="false"
            :showDataLabel="false"
            legendPosition="bottom"
          >
          </graph-bar>
        </base-box>

        <base-box title="Appartements" v-if="!loading">
          <div class="d-flex">
            <v-icon :color="colorType('appartement')" class="mr-4" large>{{ iconType('appartement') }}</v-icon>
            <b>{{number(insee2.P19_RPAPPART)}} ({{perc(insee2.P19_RPAPPART, insee2.P19_RP)}})</b>
          </div>
          <graph-bar
            name="dya"
            title="Distribution années de construction" 
            :data="dataAnneesAppart" 
            :width="500"
            :height="350"
            :stacked="false"
            :showDataLabel="false"
            legendPosition="bottom"
          >
          </graph-bar>
        </base-box>
      </div>

      <div class="d-flex justify-center mt-4">
        <graph-bar
          name="dr"
          title="Distribution nombre de pièces" 
          :data="dataPieces" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
    </base-box>

    <base-box title="Ménages" v-if="!loading" class="mt-4">
      <div class="d-flex">
        <span class="bold">Ménages</span> : {{number(insee2.P19_MEN)}}
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Distribution nombre années emménagements" 
          :data="dataMenageEmm" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
    </base-box>

    <base-box title="Population" v-if="!loading" class="mt-4">
      <div class="d-flex">
        <span class="bold">Population 15-64 ans</span> : {{number(insee3.P19_POP1564)}} ({{perc(insee3.P19_POP1564, insee.P19_POP)}}) - <span class="bold">Actifs</span> ({{perc(insee3.P19_ACT1564, insee3.P19_POP1564)}}) - <span class="bold">Chômeurs</span> ({{perc(insee3.P19_CHOMEUR1564, insee3.P19_POP1564)}})
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Qualités" 
          :data="dataQualite" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Distribution age population" 
          :data="dataPopAge" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
        <graph-bar
          name="dr"
          title="Distribution age population active" 
          :data="dataPopAgeActif" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Distribution age hommes" 
          :data="dataPopHomme" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
        <graph-bar
          name="dr"
          title="Distribution age femmes" 
          :data="dataPopFemme" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Distribution age chômeurs hommes" 
          :data="dataPopHChomeur" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
        <graph-bar
          name="dr"
          title="Distribution age chômeurs femmes" 
          :data="dataPopFChomeur" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
      <div class="d-flex justify-center">
        <graph-bar
          name="dr"
          title="Distribution profils actifs" 
          :data="dataActif" 
          :width="500"
          :height="350"
          :stacked="false"
          :showDataLabel="false"
          legendPosition="bottom"
        >
        </graph-bar>
      </div>
    </base-box>

  </div>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import mixinTour from "@/mixins/mixin-tour-steps";
  import BaseBox from "@/components/commonComponents/BaseBox";
  import GraphBar from "@/components/GraphBar";
  import GraphLine from "@/components/GraphLine";

  export default {
    name: 'Commune',

    mixins: [mixinTour],

    components: {
      BaseBox,
      GraphBar,
      GraphLine,
    },

    data: () => ({
      loading: false,
      insee: null,
      insee2: null,
      insee3: null,

      commune: null,
      dataLogements: {},
      dataResidPrinc: {},
      dataProp: {},
      dataLoc: {},
      dataPieces: {},
      dataAnneesMaison: {},
      dataAnneesAppart: {},
      dataMenageEmm: {},
      dataPopAge: {},
      dataPopAgeActif: {},
      dataPopHomme: {},
      dataPopFemme: {},
      dataPopHChomeur: {},
      dataPopFChomeur: {},
      dataActif: {},
      dataQualite: {},
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),
      ...mapGetters('Commune', [ 'currentMands', 'currentStats' ]),

      communeTitle() {
        return `${this.commune.nom_commune_complet} (${this.commune.code_postal}) - ${this.commune.nom_departement} (${this.commune.code_departement}) - ${this.commune.nom_region}`;
      }
    },

    methods: {
      ...mapActions('Commune', [ 'fetchCommune', 'fetchCommuneStats', 'fetchCommuneINSEE', 'fetchCommuneINSEE2', 'fetchCommuneINSEE3' ]),

      async fetch() {
        this.loading = true;

        this.commune = await this.fetchCommune(this.$route.params.id);
        console.log("commune", this.commune);

        await this.fetchCommuneStats(this.commune.code_commune_INSEE);
        console.log("commune stats", this.currentStats);

        this.insee = await this.fetchCommuneINSEE(this.commune.code_commune_INSEE);
        console.log("commune insee", this.insee);

        this.insee3 = await this.fetchCommuneINSEE3(this.commune.code_commune_INSEE);
        console.log("commune insee3", this.insee3);

        this.insee2 = await this.fetchCommuneINSEE2(this.commune.code_commune_INSEE);
        console.log("commune insee2", this.insee2);

        const annees = [2008, 2013, 2019];
        const annees2 = ['<1919', '1919-1945', '1945-1970', '1970-1990', '1990-2005', '2005-2015'];

        this.dataLogements = {
          series: [
            {
              name: '# logements',
              data: [Math.round(this.insee2.P08_LOG), Math.round(this.insee2.P13_LOG), Math.round(this.insee2.P19_LOG)]
            },
          ],
          labels: annees
        };

        this.dataResidPrinc = {
          series: [
            {
              name: '# logements',
              data: [Math.round(this.insee2.P08_RP), Math.round(this.insee2.P13_RP), Math.round(this.insee2.P19_RP)]
            },
          ],
          labels: annees
        };

        this.dataProp = {
          series: [
            {
              name: '# logements',
              data: [Math.round(this.insee2.P08_RP_PROP), Math.round(this.insee2.P13_RP_PROP), Math.round(this.insee2.P19_RP_PROP)]
            },
          ],
          labels: annees
        };

        this.dataLoc = {
          series: [
            {
              name: '# logements',
              data: [Math.round(this.insee2.P08_RP_LOC), Math.round(this.insee2.P13_RP_LOC), Math.round(this.insee2.P19_RP_LOC)]
            },
          ],
          labels: annees
        };

        this.dataPieces = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee2.P08_RP_1P), Math.round(this.insee2.P08_RP_2P), Math.round(this.insee2.P08_RP_3P), Math.round(this.insee2.P08_RP_4P), Math.round(this.insee2.P08_RP_5PP)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee2.P13_RP_1P), Math.round(this.insee2.P13_RP_2P), Math.round(this.insee2.P13_RP_3P), Math.round(this.insee2.P13_RP_4P), Math.round(this.insee2.P13_RP_5PP)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee2.P19_RP_1P), Math.round(this.insee2.P19_RP_2P), Math.round(this.insee2.P19_RP_3P), Math.round(this.insee2.P19_RP_4P), Math.round(this.insee2.P19_RP_5PP)]
            },
          ],
          labels: [1,2,3,4,'5+']
        }

        this.dataAnneesMaison = {
          series: [
            {
              name: '2008',
              data: [0, Math.round(this.insee2.P08_RPMAISON_ACHT1), Math.round(this.insee2.P08_RPMAISON_ACHT2), Math.round(this.insee2.P08_RPMAISON_ACHT3), Math.round(this.insee2.P08_RPMAISON_ACHT4), 0]
            },
            {
              name: '2013',
              data: [Math.round(this.insee2.P13_RPMAISON_ACH19), Math.round(this.insee2.P13_RPMAISON_ACH45), Math.round(this.insee2.P13_RPMAISON_ACH70), Math.round(this.insee2.P13_RPMAISON_ACH90), Math.round(this.insee2.P13_RPMAISON_ACH05), Math.round(this.insee2.P13_RPMAISON_ACH10)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee2.P19_RPMAISON_ACH19), Math.round(this.insee2.P19_RPMAISON_ACH45), Math.round(this.insee2.P19_RPMAISON_ACH70), Math.round(this.insee2.P19_RPMAISON_ACH90), Math.round(this.insee2.P19_RPMAISON_ACH05), Math.round(this.insee2.P19_RPMAISON_ACH15)]
            },
          ],
          labels: annees2
        }

        this.dataAnneesAppart = {
          series: [
            {
              name: '2008',
              data: [0, Math.round(this.insee2.P08_RPAPPART_ACHT1), Math.round(this.insee2.P08_RPAPPART_ACHT2), Math.round(this.insee2.P08_RPAPPART_ACHT3), Math.round(this.insee2.P08_RPAPPART_ACHT4), 0]
            },
            {
              name: '2013',
              data: [Math.round(this.insee2.P13_RPAPPART_ACH19), Math.round(this.insee2.P13_RPAPPART_ACH45), Math.round(this.insee2.P13_RPAPPART_ACH70), Math.round(this.insee2.P13_RPAPPART_ACH90), Math.round(this.insee2.P13_RPAPPART_ACH05), Math.round(this.insee2.P13_RPAPPART_ACH10)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee2.P19_RPAPPART_ACH19), Math.round(this.insee2.P19_RPAPPART_ACH45), Math.round(this.insee2.P19_RPAPPART_ACH70), Math.round(this.insee2.P19_RPAPPART_ACH90), Math.round(this.insee2.P19_RPAPPART_ACH05), Math.round(this.insee2.P19_RPAPPART_ACH15)]
            },
          ],
          labels: annees2
        }

        this.dataMenageEmm = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee2.P08_MEN_ANEM0002), Math.round(this.insee2.P08_MEN_ANEM0204), Math.round(this.insee2.P08_MEN_ANEM0509), Math.round(this.insee2.P08_MEN_ANEM1019), Math.round(this.insee2.P08_MEN_ANEM2029), Math.round(this.insee2.P08_MEN_ANEM30P)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee2.P13_MEN_ANEM0002), Math.round(this.insee2.P13_MEN_ANEM0204), Math.round(this.insee2.P13_MEN_ANEM0509), Math.round(this.insee2.P13_MEN_ANEM1019), Math.round(this.insee2.P13_MEN_ANEM2029), Math.round(this.insee2.P13_MEN_ANEM30P)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee2.P19_MEN_ANEM0002), Math.round(this.insee2.P19_MEN_ANEM0204), Math.round(this.insee2.P19_MEN_ANEM0509), Math.round(this.insee2.P19_MEN_ANEM1019), Math.round(this.insee2.P19_MEN_ANEM2029), Math.round(this.insee2.P19_MEN_ANEM30P)]
            },
          ],
          labels: ['<2', '2-4', '5-9', '10-19', '20-29', '30+']
        }

        this.dataPopAge = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_POP1524), Math.round(this.insee3.P08_POP2554), Math.round(this.insee3.P08_POP5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_POP1524), Math.round(this.insee3.P13_POP2554), Math.round(this.insee3.P13_POP5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_POP1524), Math.round(this.insee3.P19_POP2554), Math.round(this.insee3.P19_POP5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataPopAgeActif = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_ACT1524), Math.round(this.insee3.P08_ACT2554), Math.round(this.insee3.P08_ACT5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_ACT1524), Math.round(this.insee3.P13_ACT2554), Math.round(this.insee3.P13_ACT5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_ACT1524), Math.round(this.insee3.P19_ACT2554), Math.round(this.insee3.P19_ACT5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataPopHomme = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_H1524), Math.round(this.insee3.P08_H2554), Math.round(this.insee3.P08_H5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_H1524), Math.round(this.insee3.P13_H2554), Math.round(this.insee3.P13_H5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_H1524), Math.round(this.insee3.P19_H2554), Math.round(this.insee3.P19_H5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataPopFemme = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_F1524), Math.round(this.insee3.P08_F2554), Math.round(this.insee3.P08_F5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_F1524), Math.round(this.insee3.P13_F2554), Math.round(this.insee3.P13_F5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_F1524), Math.round(this.insee3.P19_F2554), Math.round(this.insee3.P19_F5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataPopHChomeur = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_HCHOM1524), Math.round(this.insee3.P08_HCHOM2554), Math.round(this.insee3.P08_HCHOM5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_HCHOM1524), Math.round(this.insee3.P13_HCHOM2554), Math.round(this.insee3.P13_HCHOM5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_HCHOM1524), Math.round(this.insee3.P19_HCHOM2554), Math.round(this.insee3.P19_HCHOM5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataPopFChomeur = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_FCHOM1524), Math.round(this.insee3.P08_FCHOM2554), Math.round(this.insee3.P08_FCHOM5564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_FCHOM1524), Math.round(this.insee3.P13_FCHOM2554), Math.round(this.insee3.P13_FCHOM5564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_FCHOM1524), Math.round(this.insee3.P19_FCHOM2554), Math.round(this.insee3.P19_FCHOM5564)]
            },
          ],
          labels: ['15-24', '25-54', '55-64']
        }

        this.dataActif = {
          series: [
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_ACT_DIPLMIN), Math.round(this.insee3.P19_ACT_BEPC), Math.round(this.insee3.P19_ACT_CAPBEP), Math.round(this.insee3.P19_ACT_BAC), Math.round(this.insee3.P19_ACT_SUP2), Math.round(this.insee3.P19_ACT_SUP34), Math.round(this.insee3.P19_ACT_SUP5)]
            },
          ],
          labels: ['sans diplôme', 'BEPC-Brevet', 'CAP', 'Bac', 'Bac+2', 'Bac+3-4', 'Bac+5']
        }

        this.dataQualite = {
          series: [
            {
              name: '2008',
              data: [Math.round(this.insee3.P08_POP1564), Math.round(this.insee3.P08_ACT1564), Math.round(this.insee3.P08_CHOM1564), Math.round(this.insee3.P08_RETR1564), Math.round(this.insee3.P08_ETUD1564)]
            },
            {
              name: '2013',
              data: [Math.round(this.insee3.P13_POP1564), Math.round(this.insee3.P13_ACT1564), Math.round(this.insee3.P13_CHOM1564), Math.round(this.insee3.P13_RETR1564), Math.round(this.insee3.P13_ETUD1564)]
            },
            {
              name: '2019',
              data: [Math.round(this.insee3.P19_POP1564), Math.round(this.insee3.P19_ACT1564), Math.round(this.insee3.P19_CHOMEUR1564), Math.round(this.insee3.P19_RETR1564), Math.round(this.insee3.P19_ETUD1564)]
            },
          ],
          labels: ['Population 15-64', 'Actifs 15-64', 'Chômeurs 15-64', 'Retraités 15-64', 'Etudiants 15-64']
        }

        console.log("dataPopChomeur", this.dataPopChomeur);

        this.loading = false;
      },

      number(n) {
        return this.bigNumber(Math.round(n));
      },

      perc(n1, n2) {
        return n2 == 0 ? '0%' : (n1/n2*100).toFixed(1)+'%';
      },

      diffperc(n1, n2) {
        return n2 == 0 ? '0%' : ((1-(n1/n2))*100).toFixed(2);
      },

      arrow(v) {
        console.log("arrow", v, typeof v);
        if (typeof v == 'string') v = parseFloat(v);
        return v == 0 ? 'mdi-arrow-right-thin' : (v > 0 ? 'mdi-arrow-top-right-thin' : 'mdi-arrow-bottom-right-thin');
      },

      color(v) {
        return v == 0 ? 'blue' : (v > 0 ? 'green' : 'red');
      }
    },

    async mounted() {
      if (this.isBasic || this.isEssentiel) {
        return this.showPremiumDialog('pro');
      }

      await this.fetch();

      // this.displayTour('Commune');
    }
  }
</script>

<style scoped>
.bold {
  font-weight: 520;
}
.smaller {
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
.italic {
  font-style: oblique 50deg;
}
</style>