<template>
  <v-dialog v-model="showDialog" max-width="950" persistent>
    <!-- {{currentMand}} -->
    <div class="d-flex" style="height:100%">
      <div style="background-color:#005466">
        <h2 class="mt-8 mb-8 white--text text-center">
          Bienvenue sur
          <br/>
          Meilleurs Mandataires
        </h2>
        <v-img src="@/assets/images/welcome-image.png" height="300" max-width="450" class="mt-4"/>
      </div>

      <v-card >
        <div class="mt-8 mx-6" style="height:80%">
          <p>
            Votre compte est activé avec le plan <b>ESSENTIEL</b>.
          </p>

          <p v-if="1">
            Vous bénéficiez d'<b>une période d'essai gratuite de 15 jours</b>. 
          </p>

          <center><v-divider width="150" class="my-8" /></center>

          <div v-if="currentMand">
            <p>
              Bonne nouvelle, nous avons trouvé votre profil dans notre base de mandataires 🎉🙂
            </p>

            <div class="d-flex">
              <p class="my-auto">
                <b>{{currentMand.name}}</b> du réseau
              </p>
              <p>
                <v-img class="ml-4 my-auto" :src="franchiseImageUrl(currentMand.rid)" max-width="120"></v-img>
              </p>
            </div>
          </div>

          <div v-else>
            Nous n'avons malheureusement pas trouvé votre profil dans notre base de mandataires 🙁
            <br/><br/>
            <div v-if="!reseauIsManaged">
              <v-icon color="red">mdi-alert</v-icon>
              Le réseau <b>{{user.rname}}</b> n'est pas encore géré, nous vous tiendrons au courant dès qu'il le sera. Vous pouvez tout de même profiter du service, certaines fonctionnalités seront absentes ou réduites.
            </div>
            <div v-else>
              <v-icon color="red">mdi-alert</v-icon>
              Le réseau <b>{{user.rname}}</b> est bien géré mais votre email <b>{{user.email}}</b> n'est pas connu 🤔
              <p>
                Veuillez <a :href="mailto('support')">contacter le support</a> afin de nous aider à retouver votre profil et ainsi profiter pleinement du service.
              </p>
            </div>
          </div>

          <!-- <div class="ml-8 mt-4">
            <a href="">
              <img width="220" height="100"/>
              <br/>
              Voir la vidéo de démonstration
            </a>
          </div> -->

        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="gotoDashboard"
          >
            Commencer
            <v-img class="ml-2" src="@/assets/images/arrow-ab.png" max-width="12"></v-img>
          </v-btn>  
        </v-card-actions>
      </v-card>
    </div>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: 'Welcome',

    data: () => ({
      showDialog: true,
      reseauIsManaged: false,
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),
      ...mapGetters('Mandataire', [ 'currentMand' ]),

      isError() {
        return this.reseauIsManaged && !this.currandMand;
      }
    },

    methods: {
      ...mapActions('Reseau', [ 'fetchAllReseaux' ]),
      ...mapActions('Mandataire', [ 'fetchMandataire' ]),
      ...mapActions('User', [ 'fetchUserInfo' ]),

      gotoDashboard() {
        this.showDialog = false;
        this.$router.replace({ name: 'dashboard' })
      }
    },

    async mounted() {      
      const allReseaux = await this.fetchAllReseaux();
      const reseaux = allReseaux.filter(r => r.color);

      // force reload user after signup
      await this.fetchUserInfo(this.userUUID);

      if (this.user.id_m_rm_ra) {
        await this.fetchMandataire(this.user.id_m_rm_ra);
      }

      console.log("MOUNTED WELCOME", "user", this.user, "mand", this.currentMand);
      
      this.reseauIsManaged = reseaux.find(r => r.id == this.user.id_franchise);
    }
  }
</script> 

<style scope>

</style>