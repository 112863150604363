<template>
  <v-dialog v-model="showDialog" max-width="800" scrollable>
    <v-card>
      <v-card-title>{{title}}</v-card-title>
      <v-card-text class="px-8 py-6">
        <iframe
          ref="doc"
          width="100%"
          height="400"
          frameborder="0"
        ></iframe>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="secondary" class="mr-2" @click.stop="showDialog=!showDialog">OK</v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
</template>

<script>
  import { EventBus } from '@/event-bus.js';
  import { mapActions } from "vuex";

  export default {
    name: 'CGUDialog',

    data: function () {
      return {
        showDialog: false,
        type: 'cgu',
      }
    },

    computed: {
      title() {
        return this.type == 'cgu' ? "Conditions Générales d'Utilisation" : "Politique de confidentialité";
      },
    },

    methods: {
      ...mapActions('API', [ 'fetchDocument' ]),
    
      escape() {
        this.showDialog = false;
      }
    },
    
    created() {
      EventBus.$on('showCGUDialog', async (type) => {
        this.showDialog = true;
        this.type = type;
        const htmlSrc = await this.fetchDocument(type);
        this.$refs.doc.srcdoc = htmlSrc;
      })
    },
  }
</script>