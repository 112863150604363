<template>
  <div class="scale-container" :style="containerStyle">
    <div v-for="(label,i) in labels" :key="i+label.min">      
      <div class="d-flex flex-row">
        <div class="scale" :style="color(i)"></div>
        <div class="label" :style="labelStyle">{{decim(label.min)}}-{{decim(label.max)}}</div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Scale',

    props: {
      gcolors: {
        type: Array
      },
      labels: {
        type: Array
      },
      labels2: {
        type: Array
      },
      size: {
        type: Number,
        default: 60
      },
      rounded: {
        type: Boolean,
        default: true,
      }
    },

    data: () => ({
    }),

    computed: {
      labelStyle() { return "width:" + this.size + "px" },
      containerStyle() { return "min-width:" + (this.size + 20) + "px" }
    },

    methods: {
      color(i) {
        return "backgroundColor:" + this.gcolors[i] + "";
      },
      decim(v) {
        return this.rounded ? Math.round(parseFloat(v)) : v;
      },
    },

    mounted() {
    }
  }
</script>

<style scoped>
  .scale-container {
    min-height: 80px;
  }
  .scale {
    width: 20px;
    height: 20px;
  }
  .label {
    margin-left: 5px;
    padding-left: 5px;
    padding-top: 2px;
    font-size: 10px;
    background-color: white;
  }
</style>