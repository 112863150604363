<template>
  <v-app id="agentbis" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
    <router-view/>
  </v-app>
</template>

<script>

import { loadScript } from "vue-plugin-load-script";

export default {
  name: 'App',

  data: () => ({
  }),

  // dynamic title in mixin
  metaInfo() {
    return {
      title: this.windowTitle,
      // link: [
      //   { rel: 'favicon', href: this.windowFavicon }
      // ]
    };
  },

};
</script>

<style>
.blur {
  -webkit-filter: blur(4px);
  -moz-filter: blur(4px);
  -o-filter: blur(4px);
  -ms-filter: blur(4px);
  filter: blur(4px);
  background-color: transparent;
}

.blurPlus {
  -webkit-filter: blur(6px);
  -moz-filter: blur(6px);
  -o-filter: blur(6px);
  -ms-filter: blur(6px);
  filter: blur(6px);
  background-color: transparent;
}

.borderMember {
  border: 2px solid #EE9B0E;
}

</style>
