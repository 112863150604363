<template>
  <v-alert v-if="alert.display" prominent dismissible :type="alert.type">
    <v-row align="center">
      <v-col class="grow">
        {{alert.text}}
      </v-col>
      <v-col class="shrink">
        <v-btn color="red lighten-3" @click="reload()">Reload</v-btn>
      </v-col>
    </v-row>
  </v-alert>
</template>

<script>
  import { EventBus } from '@/event-bus.js';

  export default {
    name: 'BaseAlert',

    props: {
    },
    data:  () => ({
      alert: {
        display: false,
        text: "",
        type: "error"
      },
    }),
    
    created() {
      EventBus.$on('showAlert', text => {
        this.display(text);
      })
    },

    methods:{
      display(text, type='error') {
        this.alert.text = text;
        this.alert.type = type;
        this.alert.display = true;
      },
      reload() {
        window.location.reload();
      }
    }
  }
</script>