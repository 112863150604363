	<!--
génére une image
https://quickchart.io/apex-charts/render?config={chart:{type:line,sparkline:{enabled:true}},series:[{name:sales,data:[31,40,35,50,49,60,70,91,125]}]}
-->
<template>
  <div>
  	<v-overlay v-if="loading" :opacity="0.3">
  	  <v-progress-circular size="50">...
  	  </v-progress-circular>
  	</v-overlay>
		<base-box :title="`Réseaux de mandataires ${count}`">
			<v-text-field
				id="step-reseaux-1"
        dense
        class="searchtextinput"
        placeholder="rechercher"
        v-model="search"
      ></v-text-field>

			<v-data-table
			  :headers="headersShow"
			  :items="reseaux"
			  item-key="id"
			  disable-pagination
			  hide-default-footer
			  class="table-top-mand"
			  :search="search"
			  :custom-filter="customFilter"
			  :custom-sort="customSort"
			>
			  <template v-slot:body="{ items }">
			    <tbody>
			      <tr v-for="(reseau, idx) in items" 
			      	:key="reseau.id" class="pointer" @click="reseauClick(reseau)"
			      >
			        <td class="text-center">
			          {{idx+1}}
			        </td>
			        <td class="text-center">
			        	<v-img max-width="80" max-height="60" contain :src="franchiseImageUrl(reseau.id_franchise)" class="pointer my-2"></v-img>
			        </td>
			        <td class="text-center">
			          {{reseau.name}}
			        </td>
			        <td>
		          	<div class="d-flex justify-center">
		          		<span class="num mt-2">{{bigNumber(reseau.agents)}}</span>
		          		<spark-line class="ml-4" :data="reseau.evol_agents"></spark-line>
		          	</div>
		          </td>
		          <td>
		          	<div class="d-flex justify-center">
		          		<span class="num mt-2">{{bigNumber(reseau.tosale)}}</span>
		          		<spark-line class="ml-4" :data="reseau.evol_tosale"></spark-line>
								</div>	          		
		          </td>
		          <td>
		          	<div class="d-flex justify-center">
		          		<span class="num mt-2">{{reseau.sold ? bigNumber(reseau.sold) : '-'}}</span>
		          		<spark-line class="ml-4" :data="reseau.evol_sold"></spark-line>
		          	</div>
		          </td>
			      </tr>
			    </tbody>
			  </template>
			</v-data-table>
		</base-box>

		<v-tour name="Reseaux" :steps="reseauxSteps"></v-tour>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import SparkLine from "@/components/SparkLine";
  import mixinTour from "@/mixins/mixin-tour-steps";

  export default {
    name: 'Reseaux',

    mixins: [mixinTour],

    components: {
    	SparkLine
    },

    data: () => ({
      loading: true,
      reseaux: [],
      headers: [
      	{ text: "#", value: "rank", align: 'center', show: true, sortable: false, filterable: false },
      	{ text: "", value: "", align: 'center', show: true, sortable: false, filterable: false },
      	{ text: "Réseau", value: "name", align: 'center', show: true },
      	{ text: "Nombre de mandataires", value: "agents", align: 'center', show: true, filterable: false },
      	{ text: "Nombre d'annonces", value: "tosale", align: 'center', show: true, filterable: false },
      	{ text: "Nombre de ventes", value: "sold", align: 'center', show: true, filterable: false },
      ],
      search: '',
    }),

    computed: {
      count() {
      	return this.reseaux && this.reseaux.length ? `(${this.reseaux.length})` : "";
      },

      headersShow() {
        return this.headers.filter(h => h.show);
      },
    },

    methods: {
      ...mapActions('Reseau', [ 'fetchAllReseauxWithMA' ]),

      reseauClick(reseau) {
        this.$router.push({ name: 'reseau', params: { id: reseau.id_franchise } });
      },

      customFilter(value, search, item) {
        return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()));
      },

      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          switch (index[0]) {
            case 'agents':
            case 'tosale':
            case 'sold':
              return !isDesc[0] ? (a[index[0]] - b[index[0]]) : (b[index[0]] - a[index[0]]);
            case 'name':
              return !isDesc[0] ? a.name.localeCompare(b.name) : b.city.localeCompare(a.name);
          }
        });
        return items;
      },
  	},

    async mounted() {
      this.reseaux = await this.fetchAllReseauxWithMA();
    },

    updated() {
      this.loading = false;
    }
  }
</script>

<style scoped>
.num {
	display: inline-block;
  width: 50px;
}
.searchtextinput {
	max-width: 250px;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
	font-weight: 600;
}
</style>