// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { deptCenter  } from "@/api/map"

const state = {
    center: 0,
    zoom: 0,
    drawer: false,
    ville: '',
    variable: null,
    localVariable: null,
    commune: null,
}

const getters = {
    mapCenter: (state) => state.center,
    mapZoom: (state) => state.zoom,
    mapDrawer: (state) => state.drawer,
    mapVille: (state) => state.ville,
    mapVariable: (state) => state.variable,
    mapLocalVariable: (state) => state.localVariable,
    mapCommune: (state) => state.commune,
}

const actions = {
    setMapCenter({commit}, center) {
        commit('SET_MAP_CENTER', center);        
    },
    setMapZoom({commit}, zoom) {
        commit('SET_MAP_ZOOM', zoom);        
    },
    setMapDrawer({commit}, drawer) {
        commit('SET_MAP_DRAWER', drawer);        
    },
    setMapVille({commit}, ville) {
        commit('SET_MAP_VILLE', ville);        
    },
    setMapVariable({commit}, variable) {
        commit('SET_MAP_VARIABLE', variable);        
    },
    setMapLocalVariable({commit}, localVariable) {
        commit('SET_MAP_LOCALVARIABLE', localVariable);        
    },
    setMapCommune({commit}, commune) {
        commit('SET_MAP_COMMUNE', commune);        
    },

    async fetchDeptCenter({ commit }, code) {
        try {
            const response = await deptCenter(code);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },
}

const mutations = {
    SET_MAP_CENTER(state, data) {
        state.center = data;
    },
    SET_MAP_ZOOM(state, data) {
        state.zoom = data;
    },
    SET_MAP_DRAWER(state, data) {
        state.drawer = data;
    },
    SET_MAP_VILLE(state, data) {
        state.ville = data;
    },
    SET_MAP_VARIABLE(state, data) {
        state.variable = data;
    },
    SET_MAP_LOCALVARIABLE(state, data) {
        state.localVariable = data;
    },
    SET_MAP_COMMUNE(state, data) {
        state.commune = data;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}