<template>
  <v-container>
    <div class="mt-4">
      Cet page vous permet de déterminer votre revenu potentiel selon votre chiffre d'affaires annuel de vente et votre réseau de filleuls selon les conditions des réseaux que nous avons à date et que nous nous efforçons de mettre à jour régulièrement.
      <br/>
      Si vous obervez des erreurs, merci de <a :href="mailto('support')">nous contacter</a>.
    </div>
    <div id="step-simul-1" class="ml-4">
      <div class="d-flex">
        <div style="width:600px">
          <v-slider
            class="mt-10"
            v-model="ca"
            label="Chiffre d'affaires (HT)"
            max="500000"
            step="10000"
            @change="change"
            @end="changeEnd"
          ></v-slider>
        </div>
        <div class="ml-5 mt-11">
          {{euros(ca)}}
        </div>
      </div>

      <div class="d-flex">
        <div style="width:600px">
          <v-slider
            v-model="children"
            label="Filleuls"
            max="50"
            step="1"
            @change="change"
          ></v-slider>
        </div>
        <div class="ml-5 mt-1">
          {{children}}
        </div>
      </div>

      <div class="d-flex" v-if="children > 0">
        <div style="width:600px">
          <v-slider
            v-model="levels"
            label="Niveaux"
            max="7"
            step="1"
            @change="change"
          ></v-slider>
        </div>
        <div class="ml-5 mt-1">
          {{levels}}
        </div>
      </div>

      <div class="d-flex" v-if="children > 0">
        <div style="width:600px">
          <v-slider
            v-model="caPerChild"
            label="CA par filleul"
            max="500000"
            step="10000"
            @change="change"
          ></v-slider>
        </div>
        <div class="ml-5 mt-1">
          {{euros(caPerChild)}}
        </div>
      </div>
    </div>

    <div class="d-flex justify-end">
      <v-switch
        v-model="centPourCent"
        inset
        dense
        label="Afficher les réseaux 100%"
      ></v-switch>
    </div>

    <v-simple-table dense v-if="ca > 0">
      <template v-slot:default>
        <thead>
          <tr>
            <th class="text-center">Rang</th>
            <th class="text-center"></th>
            <th class="text-center">Réseau</th>
            <th class="text-center">Pack</th>
            <th class="text-center">Commissions</th>
            <th v-if="children" class="text-center">Filleuls</th>
            <th class="text-center">Total</th>
            <th class="text-center">Prix année 1</th>
            <th class="text-center">Prix année 2</th>
            <th class="text-center">Annonces incluses</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(res, i) in responseFiltered" :key="i">
            <td class="text-center">{{i+1}}</td>
            <td class="text-center">
              <v-img max-width="70" contain :src="franchiseImageUrl(res.reseau_id)" @click="gotoReseau(res.reseau_id)" class="cursor-pointer">
              </v-img>
            </td>
            <td class="text-center" :class="blurEssentiel">{{res.reseau_name}}</td>
            <td class="text-center" :class="blurEssentiel">{{res.pack}}</td>
            <td class="text-center" :class="blurEssentiel"><span class="bold">{{euros(res.commissions)}}</span><br/><span class="smaller">{{euros(Math.round(res.commissions/12))}} /mois</span></td>
            <td v-if="children" class="text-center" :class="blurEssentiel"><span class="bold">{{godComm1(res)}}</span><br/><span class="smaller">{{godComm2(res)}}</span></td>
            <td class="text-center" :class="blurEssentiel"><span class="bold">{{euros(res.commissions+res.god_commissions)}}</span><br/><span class="smaller">{{euros(Math.round((res.commissions+res.god_commissions)/12))}} / mois</span></td>
            <td class="text-center" :class="blurEssentiel"><span class="bold">{{price1(res.price_y1)}}</span><br/><span class="smaller">{{price2(res.price_y1)}}</span></td>
            <td class="text-center" :class="blurEssentiel"><span class="bold">{{price1(res.price_y2)}}</span><br/><span class="smaller">{{price2(res.price_y2)}}</span></td>
            <td class="text-center" :class="blurEssentiel">{{res.count_annonces == null ? 'illimitées' : res.count_annonces}}</td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-tour name="Simul" :steps="simulSteps"></v-tour>

  </v-container>
</template>

<script>
  import { mapGetters, mapActions } from "vuex";
  import mixinTour from "@/mixins/mixin-tour-steps";

  export default {
    name: 'Simul',

    mixins: [mixinTour],

    data: () => ({
      ca: 30000,
      children: 0,
      levels: 1,
      caPerChild: 20000,
      response: '',
      centPourCent: true,
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),

      responseFiltered() {
        if (this.centPourCent) return this.response;
        return this.response.filter(r => {
          if (r.comms.length != 1) return true;
          else if (r.comms[0].commission == 100) return false;
          return true;
        });
      }
    },

    methods: {
      ...mapActions('API', [ 'fetchSimulRevenus' ]),

      godComm1(res) {
        return this.children > 0 && res.god_commissions ? this.euros(res.god_commissions) : "";
      },

      godComm2(res) {
        return this.children > 0 && res.god_commissions ? this.euros(Math.round(res.god_commissions/12)) + " / mois" : "";
      },

      price1(price) {
        return (price ? this.euros(price) : "0€");
      },

      price2(price) {
        return (price ? this.euros(Math.round(price/12)) : "0€") + " / mois";
      },

      async change() {
        this.response = !this.isBasic ? await this.fetch() : [];
      },

      changeEnd() {
        if (!this.isLocalhost) {
          // Sendinblue tracking
          window.sendinblue.track('simul-ca-value', { value: this.ca });
        }
      },

      async fetch() {
        return await this.fetchSimulRevenus({
          ca: this.ca/1000,
          children: this.children,
          caPerChild: this.caPerChild/1000,
          levels: this.levels
        });
      },

      gotoReseau(id) {
        if (!this.isEnterprise) {
          this.showPremiumDialog('enterprise');
          return;
        }
        this.$router.push({ name: 'reseau', params: {id: id } });
      },
    },

    async mounted() {
      if (this.isBasic || this.isEssentiel) {
        return this.showPremiumDialog('pro');
      }

      await this.change();

      this.displayTour('Simul');
    }
  }
</script>

<style scoped>
.bold {
  font-weight: 400;
}
.smaller {
  font-size: 12px;
}
.cursor-pointer {
  cursor: pointer;
}
</style>