// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { simulRevenus, getNotifications, getDocument, getSettings } from "@/api/api"

const state = {
    searchItems: [],
    settings: null,
}

const getters = {
    searchResult: (state) => state.searchItems,
    settings: (state) => state.settings,
}

const actions = {
    async fetchSimulRevenus({ commit }, query) {
        try {
            const response = await simulRevenus(query);
            // console.log("vuex simulRevenus", response.data.result);
            commit('NONE');
            return response.data.result;
            // commit('SET_SEARCH', response.data);
        } catch (error) {
            // handle the error here
        }
    },

    async fetchNotifications({ commit }) {
        try {
            const response = await getNotifications();
            commit('NONE');
            return response.data.result;
        } catch (error) {
            // handle the error here
        }
    },

    async fetchDocument({ commit }, doc) {
        try {
            const response = await getDocument(doc);
            commit('NONE');
            return response.data;
        } catch (error) {
            // handle the error here
        }
    },

    async fetchSettings({ commit }) {
        try {
            const response = await getSettings();
            commit('SET_SETTINGS', response.data);
            return response.data.result;
        } catch (error) {
            // handle the error here
        }
    },
}

const mutations = {
    SET_SEARCH(state, data) {
        state.searchItems = data.result;
    },
    SET_LOCATION(state, data) {
        state.locationItems = data.result;
    },
    SET_SETTINGS(state, data) {
        state.settings = data.result;
    },
    SET_TAG(state, data) {
        state.tags = data.result;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}