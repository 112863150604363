import axios from 'axios';
import store from '../store/store';
import router from '../router';

const httpClient = axios.create({
    baseURL: 'https://api.meilleursmandataires.fr',
    headers: {
        'Content-Type': 'application/json'
    },
    timeout: 5000,
});

// to set jwt token for each call
httpClient.interceptors.request.use(
	config => {
		const token = localStorage.getItem('user-token');
		if (token) {
	    config.headers['Authorization'] = `Bearer ${token}`;
	  }

	  config['timeout'] = 40000;

	  return config;
  },
  error => {
    Promise.reject(error);
  }
);

// intercept response and managed
httpClient.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (error.response.status === 401) {
			store.dispatch('User/signout');
			router.push({name: 'signin'});
		}
	return Promise.reject(error)
});

httpClient.path = (arr) => {
	return arr.join('/');
}

httpClient.args = (args) => {
	let result = "";
	for (let i in args) {
		result += (i==0) ? "?" : "&";
		result += args[i].arg+"="+args[i].value;
	}
	return result;
}

httpClient.args2 = (args) => {
	let result = "";
	const keys = Object.keys(args);
	const values = Object.values(args);
	for (let i in keys) {
		result += (i==0) ? "?" : "&";
		result += keys[i]+"="+values[i];
	}
	return result;
}

export default httpClient
