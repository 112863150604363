<template>
  <div>
    <v-sheet
      rounded="xl"
      class="sheet mx-auto"
      :class="blurry"
      :color="bgcolor || 'quinary'"
      :style="fontColor"
      :width="width"
      :height="height"
    >{{value}}{{suffixe}}</v-sheet>
    <p class="font-smaller lh" v-html="title"></p>
  </div>
</template>

<script>

  export default {
    name: 'Sheet',

    components: {
    },

    props: {
      value: {
      },
      title: {
        type: String
      },
      color: {
        type: String,
        default: '#005F73'
      },
      bgcolor: {
        type: String
      },
      width: {
        type: Number,
        default: 60
      },
      height: {
        type: Number,
        default: 60
      },
      blurry: {
        type: String
      },
      negative: {
        type: Boolean,
        default: false,
      },
      suffixe: {
        type: String,
        default: '',
      }
    },

    data: () => ({  
    }),

    computed: {
      fontColor() {
        return "color:" + (this.negative && this.value < 0 ? "#C4280D" : this.color);
      }
    },

    methods: {
    },

    mounted() {
    }
  }
</script>

<style scoped>
.sheet {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
}
.font-smaller {
  font-size: smaller;
  text-align: center;
}
.lh {
  margin-top: 10px;
  line-height: 16px;
}
</style>