// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { getInfo, getAgences } from "@/api/reseauA"

const state = {
    reseauA: {},
}

const getters = {
    currentReseauA: (state) => state.reseauA,
}

const actions = {
    async fetchReseauAInfo({ commit }, id) {
        try {
            const response = await getInfo(id);
            commit('SET_RESEAU', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchReseauAAgences({ commit }, id) {
        try {
            const response = await getAgences(id);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

}

const mutations = {
    SET_RESEAU(state, data) {
        state.reseauA = data;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}