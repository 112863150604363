// https://haxzie.com/architecting-vuex-store-large-scale-vue-application
// https://dev.to/localeai/architecting-http-clients-in-vue-js-applications-for-effective-network-communication-1eec

import { getAllMandataires, nearestCommunes, getCommune, fetchCommunes, 
         getCommuneStats, getCommuneINSEE, getCommuneINSEE2, getCommuneINSEE3 } from "@/api/commune"

const state = {
    mands: [],
    stats: null,
}

const getters = {
    currentMands: (state) => state.mands,
    currentStats: (state) => state.stats,
}

const actions = {
    setMandataires({commit}, mands) {
        commit('SET_MANDS', mands);        
    },

    async fetchAllMandataires({ commit }, {communeCode, period}) {
        try {
            const response = await getAllMandataires(communeCode, period);
            commit('SET_MANDS', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchCommuneStats({ commit }, communeCode) {
        try {
            const response = await getCommuneStats(communeCode);
            commit('SET_STATS', response.data.result);
        } catch(error) {
            // handle the error here
        }
    },

    async fetchCommuneINSEE({ commit }, communeCode) {
        try {
            const response = await getCommuneINSEE(communeCode);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchCommuneINSEE2({ commit }, communeCode) {
        try {
            const response = await getCommuneINSEE2(communeCode);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchCommuneINSEE3({ commit }, communeCode) {
        try {
            const response = await getCommuneINSEE3(communeCode);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchNearestCommunes({commit}, data) {
        try {
            const response = await nearestCommunes(data);
            // console.log("VUEX fetchNearestCommunes", response);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }
    },

    async fetchCommune({commit}, id) {
        try {
            const response = await getCommune(id);
            console.log("VUEX fetchCommune", id, "response", response);
            commit('NONE');
            return response.data.result;
        } catch(error) {
            // handle the error here
        }  
    },

    async fetchCommunes({ commit }, search) {
        try {
            // console.log("vuex fetchCommune", JSON.stringify(search));
            const response = await fetchCommunes(search);
            // console.log("vuex fetchCommune", response.data.result);
            commit('NONE');
            return response.data.result;
            // commit('SET_SEARCH', response.data);
        } catch (error) {
            // handle the error here
        }
    },
}

const mutations = {
    SET_MANDS(state, data) {
        state.mands = data;
    },
    SET_STATS(state, data) {
        state.stats = data;
    },
    NONE() {   
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}