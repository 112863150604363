<template>
  <div>
    <!--
    {{user.plan}} {{user.current_plan}}
    <br/>
    {{$route.query}}
    <br/>
    Basic {{isBasic}} Essentiel {{isEssentiel}} Pro {{isPro}} Enterprise {{isEnterprise}}
    -->
    <!-- NO INVITE -->
    <base-box id="step-dashboard-1" title="Mon réseau" class="mb-4 pb-0" v-if="0 && amIMandataire">
      <div>
        <div class="grid">
          <div v-for="link in userLinks" :key="link.id" class="ma-2">
            <photo-mand
              :obj="link"
              @onClick="clickMandataire"
              :clickData="link.id_mandataire2 == user.id_m_rm_ra ? link.id_mandataire1 : link.id_mandataire2"
            ></photo-mand>
            <div class="ml-2 d-flex flex-column smaller align-start">
              {{link.firstname}} {{mandataireName(link.lastname)}}
              <span class="more-smaller">{{link.rname}}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot:default="{ hover }">
                    <v-icon v-on="on" v-bind="attrs" small
                      @click="removeInviteMandataire(link.id, link.token)"
                      :color="hover ? 'error' : ''"
                    >
                      {{hover ? 'mdi-link-variant-remove' : 'mdi-link-variant'}}
                    </v-icon>
                  </v-hover>
                </template>
                <span class="smaller">Supprimer</span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <div class="mt-4" v-if="userLinksSent.length == 1">Invitation envoyée</div>
        <div class="mt-4" v-if="userLinksSent.length > 1">Invitation(s) envoyée(s)</div>
        <div class="grid">
          <div v-for="link in userLinksSent" :key="link.id" class="d-flex ma-2">
            <photo-mand
              :obj="link"
              @onClick="clickMandataire"
              :clickData="link.id_mandataire2"
            ></photo-mand>
            <div class="ml-2 d-flex flex-column smaller align-start">
              {{link.firstname}} {{mandataireName(link.lastname)}}
              <span class="more-smaller">{{link.rname}}</span>
              <v-tooltip bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-hover v-slot:default="{ hover }">
                    <v-icon v-on="on" v-bind="attrs" small
                      @click="removeInviteMandataire(link.id, link.token)"
                      :color="hover ? 'error' : ''"
                    >
                      {{hover ? 'mdi-email-fast' : 'mdi-email-fast-outline'}}
                    </v-icon>
                  </v-hover>
                </template>
                <span class="smaller">Supprimer</span>
              </v-tooltip>
            </div>
          </div>
        </div>

        <div class="mt-4" v-if="userLinksReceived.length == 1">Invitation reçue</div>
        <div class="mt-4" v-if="userLinksReceived.length > 1">Invitation(s) reçue(s)</div>
        <div class="grid">
          <div v-for="link in userLinksReceived" :key="link.id" class="d-flex ma-2">
            <photo-mand
              :obj="link"
              :size="60"
              @onClick="clickMandataire"
              :clickData="link.id_mandataire1"
            ></photo-mand>
            <div class="d-flex flex-column ml-2 smaller">
              {{link.firstname}} {{mandataireName(link.lastname)}}
              <span class="more-smaller">{{link.rname}}</span>
              <div class="d-flex mt-2">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot:default="{ hover }">
                      <v-icon v-on="on" v-bind="attrs" small
                        @click="acceptInviteMandataire(link.id, link.token, true)"
                        :color="hover ? 'green' : ''"
                      >
                        mdi-link-variant-plus
                      </v-icon>
                    </v-hover>
                  </template>
                  <span class="smaller">Accepter</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-hover v-slot:default="{ hover }">
                      <v-icon v-on="on" v-bind="attrs" small class="ml-2"
                        @click="acceptInviteMandataire(link.id, link.token, false)"
                        :color="hover ? 'error' : ''"
                      >
                        mdi-link-variant-minus
                      </v-icon>
                    </v-hover>
                  </template>
                  <span class="smaller">Refuser</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>

        <!-- <v-icon small @click="openMonReseau" :v-ripple="false">
          {{monReseauOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}
        </v-icon>

        <div class="mt-4" v-if="monReseauOpen">
          Exemple
        </div> -->
      </div>
    </base-box>

    <base-box id="step-dashboard-2" title="Mandataires Favoris" class="mb-4" v-if="amIMandataire || isNetworkAgences || isNetwork">
      <draggable v-model="userFavorisMandataires" @end="onDragEnd">
        <transition-group tag="div" class="grid" name="grid">
          <div v-for="link in userFavorisMandataires" :key="link.id_favori" class="d-flex ma-2 move">
            <photo-mand
              :obj="link"
              @onClick="clickMandataire"
              :clickData="link.id_favori"
            ></photo-mand>
            <div class="ml-2 d-flex flex-column align-start smaller">
              {{link.firstname}} {{mandataireName(link.lastname)}}
              <span class="more-smaller">{{link.rname}}</span>

              <!-- <v-tooltip right>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-on="on" v-bind="attrs" class="pointer" small color="error" @click="removeFavorite(link.id_favori)">
                    mdi-heart
                  </v-icon>
                </template>
                supprimer
              </v-tooltip> -->

              <v-hover v-slot:default="{ hover }">
                <v-icon class="pointer" small
                  @click="removeFavorite(link.id_favori)"
                  :color="hover ? 'error' : ''"
                >{{hover ? 'mdi-heart-remove' : 'mdi-heart-outline'}}</v-icon>
              </v-hover>
            </div>
          </div>
        </transition-group>
      </draggable>
    </base-box>

    <base-box id="step-dashboard-3" title="Zones Favorites" class="mb-4" v-if="amIMandataire || isNetworkAgences || isNetwork">
      <div>
        <v-chip small class="mr-2 mt-2" v-for="zone in userFavorisZones" 
          :key="'fav_'+zone.code_postal"
          :value="zone.commune_id"
          outlined
          :ripple="false"
          @click="clickZoneFavorite(zone)"
        >
          {{zone.nom_commune_complet}}
          <v-icon right small>mdi-map-marker-outline</v-icon>
        </v-chip>
      </div>
    </base-box>

    <base-box id="step-dashboard-4" title="Nouveaux Mandataires sur vos zones" class="mb-4" v-if="amIMandataire || isNetworkAgences">
      <div class="my-2">
        <v-chip-group active-class="primary--text" column mandatory v-model="currentZoneFilter">
          <v-chip small v-for="zone in zones" 
            :key="zone.code_postal"
            :value="zone.id_commune"
            :class="{ 'chip-saville': zone.sa_ville }"
            active-class="chip-active"
            outlined
          >
            {{zone.nom_commune_complet}}
          </v-chip>
          <v-chip small v-if="zones.length > 1"
            :value="0"
            active-class="chip-active"
            outlined
          >
            toutes
          </v-chip>
        </v-chip-group>
      </div>

      <v-progress-circular
        v-if="!newMandataires || !newMandataires.length" 
        color="grey lighten-2" indeterminate size="30">
      </v-progress-circular>

      <div class="grid">
        <div v-for="mand in newMandatairesListed" :key="mand.id" class="d-flex ma-2">
          <photo-mand
            :obj="mand"
            @onClick="clickMandataire"
            :clickData="mand.id"
          ></photo-mand>
          <div class="ml-2 d-flex flex-column smaller">
            {{mand.firstname}} {{mandataireName(mand.lastname)}}
            <span class="more-smaller">{{mand.rname}}</span>
            <span class="smaller bold">{{timeAgo(mand.created_at)}}</span>
          </div>
        </div>
      </div>
      <div v-if="newMandatairesCount < newMandataires.length-10">
        <a class="smaller" @click="displayMoreNew">afficher plus</a>
      </div>
    </base-box>

    <base-box id="step-dashboard-5" :title="titleAnnonces" class="mb-4" v-if="amIMandataire || isNetworkAgences">
      <div class="d-flex align-center justify-start" v-if="!loading && newAnnonces && newAnnonces.length">
        <div class="search-div">
          <v-text-field
            class="mr-8"
            placeholder="rechercher"
            v-model="searchAnnonces"
            @input="inputSearchAnnonces"
          ></v-text-field>
        </div>
        <v-switch
          class="mt-6 mr-8"
          v-model="onlySales"
          label="Ventes seulement"
          @change="changeOnlySales"
        ></v-switch>
        <div class="search-reseau-div">
          <v-select
            class="mt-4"
            dense
            placeholder="réseau"
            :items="newAnnoncesReseaux"
            item-value="id"
            item-text="name"
            multiple
            v-model="selectedAnnoncesReseaux"
            @change="changeSelectedAnnoncesReseaux"
          ></v-select>
        </div>
        <!-- <v-spacer></v-spacer>
        <span class="smaller mt-2 mr-2">jours</span>
        <v-btn-toggle
          class="mt-2"
          v-model="period"
        >
          <v-btn active-class="period-active" class="period">30</v-btn>
          <v-btn active-class="period-active" class="period">60</v-btn>
        </v-btn-toggle> -->
      </div>


      <!-- <div class="d-flex">
        <v-spacer></v-spacer>
        <v-switch
          v-model="onlySales"
          label="Ventes seulement"
        ></v-switch>
      </div> -->
      <v-progress-circular
        v-if="loading" 
        color="grey lighten-2" indeterminate size="30">
      </v-progress-circular>

      <v-data-table
        v-if="newAnnonces && newAnnonces.length"
        style="overflow: auto"
        height="600"
        dense
        :headers="headers"
        fixed-header
        :items="newAnnoncesComputed"
        item-key="id"
        :disable-sort="!isPro && !isEnterprise"
        :search="searchAnnonces"
        :custom-filter="customFilter"
        :custom-sort="customSort"
        disable-pagination
        hide-default-footer
        no-results="Aucun résultat pour ce filtre"
        np-data="Aucune annonce ou vente"
        class="mt-2 table-annonce"
      >
        <template v-slot:body="{ items }">
          <tbody>
            <tr v-for="(annonce, idx) in items" :key="annonce.key"
               :class="annonce.url ? 'pointer' : ''">
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.type}}</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.rooms}}</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.type == 'terrain' ? bigNumber(annonce.land) : bigNumber(annonce.m2)}}</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.city}} ({{annonce.zip}})</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(annonce)">{{annonce.sold ? formatDate(annonce.sold_at) : formatDate(annonce.created_at)}}</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.price == 0 ? '-' : euros(annonce.price)}}</td>
              <td class="text-center" :class="firstNoBlur(idx)" @click="gotoAnnonce(idx, annonce)">{{annonce.price == 0 ? '-' : (annonce.type == 'terrain' ? euros(annonce.price_land_m2) : euros(annonce.price_m2))}}</td>
              <td @click="clickMandataire(annonce.mid)">
                <div class="d-flex">
                  <photo-mand
                    :obj="annonce"
                    :size="40"
                  ></photo-mand>
                  <div class="ml-2 mt-2 smaller" :class="firstNoBlur(idx)">
                    {{annonce.firstname}} {{mandataireName(annonce.lastname)}}
                  </div>
                </div>
              </td>
              <td class="text-center" @click="gotoAnnonce(idx, annonce)">{{annonce.rname}}</td>              
              <td class="text-center" @click="gotoAnnonce(idx, annonce)"><v-icon small color="error">{{annonce.sold ? 'mdi-check' : ''}}</v-icon></td>
            </tr>
          </tbody>
        </template>
      </v-data-table>

      <p class="mt-4" v-else>
        <span v-if="!loading">Aucune annonce/vente détectée sur vos zones et sur la période.</span>
      </p>

    </base-box>

    <base-box v-if="isMandataire && !amIMandataire">
      <p>
        <v-icon :color="reseauIsManaged ? 'orange' : 'red'">mdi-alert</v-icon>
        Nous n'avons malheureusement pas trouvé votre profil dans notre base de mandataires 🙁
      </p>

      <div v-if="!reseauIsManaged">
        Le réseau <b>{{user.rname}}</b> n'est pas encore géré, nous vous tiendrons au courant dès qu'il le sera. Vous pouvez tout de même profiter du service.
      </div>
      <div v-else>
        Le réseau <b>{{user.rname}}</b> est bien géré mais votre email <b>{{user.email}}</b> n'est pas connu.
        <p>
          Veuillez svp <a :href="mailto('support')">contacter le support</a> afin de nous aider à retouver votre profil et ainsi profiter pleinement du service.
        </p>
      </div>
    </base-box>

    <v-tour name="Dashboard" :steps="dashboardSteps"></v-tour>

  </div>
</template>

<script>
  import { mapActions, mapGetters, mapState } from "vuex";
  import draggable from "vuedraggable";
  import PhotoMand from "@/components/PhotoMand";
  import mixinTour from "@/mixins/mixin-tour-steps";
  import moment from 'moment';

  export default {
    name: 'Dashboard',

    components: {
      draggable,
      PhotoMand,
    },

    mixins: [mixinTour],

    data() {
      return {
        loading: false,
        showProgressInvite: false,
        zones: [],
        currentZoneFilter: 0,
        newMandataires: [],
        newMandatairesCount: 10,
        newAnnonces: [],
        newAnnoncesReseaux: [],
        selectedAnnoncesReseaux: [0],
        oldSelectedAnnoncesReseaux: [0],
        headers: [
          { text: "Type", value: "type", align: 'center' },
          { text: "Pièces", value: "rooms", width: 90, align: 'center' },
          { text: "m2", value: "m2", width: 80, align: 'center' },
          { text: "Ville", value: "city", align: 'center' },
          { text: "Date", value: "created_at", width: 130, align: 'center' },
          { text: "Prix", value: "price", width: 150, align: 'center' },
          { text: "Prix/m2", value: "price_m2", width: 100, align: 'center' },
          { text: "Agent", value: "agent", align: 'center' },
          { text: "Réseau", value: "rname", align: 'center' },
          { text: "Vendu", value: "sold", width: 60, align: 'center' },
        ],
        reseauIsManaged: false,
        monReseauOpen: false,
        period: 0, // 30 jours,
        onlySales: false,
        searchAnnonces: null,
        userFavorisMandataires: []
      }
    },

    watch: {
      currentZoneFilter(val) {
        console.log(val);
      },

      // period(val, old) {
      //   if (!this.isPro && !this.isEnterprise) {
      //     this.showPremiumDialog('pro');
      //     return;
      //   }
      // }
    },

    computed: {
      ...mapGetters([ 'firstDashboard' ]),
      ...mapGetters('User', [ 'user', 'userUUID' ]),
      // ...mapState('User', [ 'user' ]),
      ...mapGetters('Mandataire', [ 'currentMand' ]),

      userFavorisZones() {
        return this.user.favoris.filter(f => f.type == 'zone');
      },

      userLinks() {
        let sentAccepted = this.user.linksSent && this.user.linksSent.filter(l => l.type == 'link' && l.status == 'accepted');
        let receivedAccepted = this.user.linksReceived && this.user.linksReceived.filter(l => l.type == 'link' && l.status == 'accepted');

        return [ ...sentAccepted, ...receivedAccepted ];
      },

      userLinksSent() {
        return this.user.linksSent && this.user.linksSent.filter(l => l.type == 'link' && l.status == 'sent');
      },

      userLinksReceived() {
        return this.user.linksReceived && this.user.linksReceived.filter(l => l.type == 'link' && l.status == 'sent');
      },

      newMandatairesListed() {
        let list = this.newMandataires.slice(0, this.newMandatairesCount);
        if (this.currentZoneFilter != 0) list = list.filter(p => p.communes && p.communes.includes(this.currentZoneFilter));
        return list;
      },

      borderMember() {
        return 'borderMember';
        // return this.currentMand.isMember ? 'borderMember' : ''; 
      },

      planPremium() {
        return true;
        // return !this.currentMand.plan_type || this.currentMand.plan_type == 'free' ? false : true;
      },

      newAnnoncesComputed() {
        let result = this.onlySales ? this.newAnnonces.filter(a => a.sold) : this.newAnnonces;
        return this.selectedAnnoncesReseaux.includes(0) ? result : result.filter(a => this.selectedAnnoncesReseaux.includes(a.rid));
      },

      periodValue() {
        return this.period == 0 ? 30 : 60;
      },

      titleAnnonces() {
        // return `Dernières Annonces et Ventes sur vos zones (${this.periodValue} jours)`;
        return "Dernières Annonces et Ventes sur vos zones";
      }

    },

    methods: {
      ...mapActions([ 'setFirst' ]),
      ...mapActions('Mandataire', [ 'fetchMandataire', 'newInZones', 'removeLink', 'setLinkStatus' ]),
      ...mapActions('User', [ 'fetchUserInfo', 'addFavori', 'removeFavori', 'replaceFavoris' ]),
      ...mapActions('Reseau', [ 'fetchAllReseaux' ]),
      ...mapActions('Map', [ 'setMapCenter', 'setMapCommune' ]),

      displayMoreNew() {
        this.newMandatairesCount += 10;
      },

      isInvitationDeclined(mandId) {
        return this.user && this.user.linksSent && this.user.linksSent.find(l => l.id_mandataire2 == mandId && l.type == 'link' && l.status == 'declined');
      },

      isInvitationAccepted(mandId) {
        return this.user && this.user.linksSent && this.user.linksSent.find(l => l.id_mandataire2 == mandId && l.type == 'link' && l.status == 'accepted');
      },

      isInvitationReceived(mandId) {
        return this.user && this.user.linksSent && this.user.linksSent.find(l => l.id_mandataire1 == mandId && l.type == 'link' && l.status == 'sent');
      },

      async clickMandataire(id) {
        await this.fetchMandataire(id);
        this.$router.push({ name: 'mandataire', params: { id: id } });
      },

      clickZoneFavorite(zone) {
        this.$router.push({ name: 'mapbox', params: { id: zone.commune_id } });
      },

      async removeFavorite(mandId) {
        console.log("removeFavorite", mandId);
        await this.removeFavori({
          userUuid: this.userUUID,
          id: mandId,
          type:'mand'
        });
        await this.fetchUserInfo(this.userUUID);
        this.userFavorisMandataires = this.user.favoris.filter(f => f.type == 'mand');
      },

      async acceptInviteMandataire(linkId, token, ok) {
        console.log("acceptInviteMandataire", linkId, ok);
     
        const status = ok ? 'accepted' : 'declined';
        await this.setLinkStatus({linkId: linkId, token: token, status: status});
        await this.fetchUserInfo(this.userUUID);
      },

      async removeInviteMandataire(linkId, token) {
        console.log("removeInviteMandataire", linkId, token);

        await this.setLinkStatus({linkId: linkId, token: token, status: 'removed'});
        await this.fetchUserInfo(this.userUUID);
      },

      gotoAnnonce(idx, annonce) {
        if (!this.isPro && !this.isEnterprise) {
          this.showPremiumDialog('pro');
          return;
        }
        if (annonce.url) window.open(annonce.url, '_blank');
      },

      openMonReseau() {
        this.monReseauOpen = !this.monReseauOpen;
      },

      customFilter(value, search, item) {
        return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()));
      },

      customSort(items, index, isDesc) {
        console.log("CUSTOMSORT", index[0]);        
        items.sort((a, b) => {
          if (index[0] === 'created_at') {
            if (a.lastname == 'BISMUTH') console.log(a[index], b[index], "***", moment(b[index]).diff(moment(a[index])));
            return !isDesc[0] ? moment(b[index]).diff(moment(a[index])) : moment(a[index]).diff(moment(b[index]));
          } else if (['rooms','m2','price','price_m2'].includes(index[0])) {
            return !isDesc[0] ? a[index] - b[index]: b[index] - a[index];
          } else if (index[0] == 'agent') {
            const aa = (a.firstname + ' ' + this.mandataireName(a.lastname)).toLowerCase();
            const bb = (b.firstname + ' ' + this.mandataireName(b.lastname)).toLowerCase();
            return !isDesc[0] ? aa.localeCompare(bb) : bb.localeCompare(aa);
          } else if (typeof a[index] !== 'undefined') {
            console.log("OTHER", a[index], b[index]);
            return !isDesc[0] ? a[index].toLowerCase().localeCompare(b[index].toLowerCase()) : b[index].toLowerCase().localeCompare(a[index].toLowerCase());
          }
        });
        return items;
      },

      inputSearchAnnonces() {
        // if (!this.isPro && !this.isEnterprise) {
        //   this.searchAnnonces = '';
        //   this.showPremiumDialog('pro');
        //   return false;
        // }
        return true;
      },

      firstNoBlur(idx) {
        return '';
        // only first 3 are viewable
        //if (idx < 3) return '';
        // return this.blurEssentiel;
      },

      changeOnlySales(value) {
        if (!this.isPro && !this.isEnterprise) {
          this.$nextTick(() => {
            this.onlySales = 0;
          });
          this.showPremiumDialog('pro');
        }
      },

      changeSelectedAnnoncesReseaux(values) {
        console.log(values, this.oldSelectedAnnoncesReseaux);
        if (!this.isPro && !this.isEnterprise) {
          this.$nextTick(() => {
            this.selectedAnnoncesReseaux = this.oldSelectedAnnoncesReseaux;
          });
          this.showPremiumDialog('pro');
        } else {
          if (this.oldSelectedAnnoncesReseaux.includes(0) && this.oldSelectedAnnoncesReseaux.length == 1) {
            // first time
            if (values.length > 1) this.selectedAnnoncesReseaux = values.filter(v => v);
          } else if (values.findIndex(v => !v) > 0) {
            // come back to 0
            this.selectedAnnoncesReseaux = [0];
          } else if (values.length == 0) {
            // nothing
            this.selectedAnnoncesReseaux = [0];
          }
          this.oldSelectedAnnoncesReseaux = this.selectedAnnoncesReseaux;
        }
      },

      async loadNewInZones(forcePeriod) {
        // zones du mandataire ET des favoris du user
        let {zones, mandataires, annonces} = await this.newInZones({
          userId: this.user.id,
          mandId: this.user.id_m_rm_ra,
          period: forcePeriod || this.periodValue,
          cache: forcePeriod ? false : !this.zonesUpdated,
        });

        this.zonesUpdated = false; // to force cache after

        if (!this.isPro && !this.isEnterprise) {
          // shuffle annonces
          annonces = annonces.sort((a, b) => 0.5 - Math.random());
        }

        this.zones = zones;
        this.newMandataires = mandataires;
        this.newAnnonces = annonces;

        // extract reseaux
        this.newAnnoncesReseaux = annonces.map(a => ({id: a.rid, name: a.rname}))
        this.newAnnoncesReseaux = [...new Map(this.newAnnoncesReseaux.map(item => [item.id, item])).values()].sort((a,b) => a.name.localeCompare(b.name));
        this.newAnnoncesReseaux = [{ id:0, name: '- tous les réseaux -'}, ...this.newAnnoncesReseaux];

        return this.newAnnonces.length;
      },

      async onDragEnd() {
        console.log("onDragEnd");
        await this.replaceFavoris({
          userUuid: this.userUUID,
          favoris: this.userFavorisMandataires.map(f => f.id_favori)
        });
      }

    },
    
    created() {
      if (!this.userUUID) this.$router.push({ name: 'signin' });
    },

    async mounted() {
      // debugger; // eslint-disable-line no-debugger

      this.userFavorisMandataires = this.user.favoris.filter(f => f.type == 'mand');

      const allReseaux = await this.fetchAllReseaux();
      const reseaux = allReseaux.filter(r => r.color);
      this.reseauIsManaged = reseaux.find(r => r.id == this.user.id_franchise) ? true : false;

      if (this.amIMandataire || this.isNetworkAgences) {
        // attente sinon l'activité ne s'affiche pas :(
        const self = this;
        setTimeout(async () => {
          this.loading = true;
          const n = await self.loadNewInZones();
          console.log("n=", n);
          if (!n) {
            await self.loadNewInZones(200);
            self.period = 1;
          }
          this.loading = false;
        }, 100);
      }

      this.displayTour('Dashboard');
    }
  }
</script>

<style lang="scss" scoped>
.photo {
  border-radius: 50%;
  width: 60px;
  height: 60px;
  vertical-align: middle;
}

.photo2 {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  vertical-align: middle;
}

.smaller {
  font-size: smaller;
}

.more-smaller {
  font-size: 11px;
  padding-top: 0px;
}

.bold {
  font-weight: 600;
}

.v-data-table.table-annonce td
{
  height: 60px !important;
}

.zone {
  cursor: pointer;
}
.zone:hover {
  color: $linkcolor;
}

.chip-saville {
  font-weight: 700;
}

.chip-active {
  color: #005F73;
}

.mon-reseau {
  position: relative;
}
#mon-reseau-chevron {
  position: absolute;
  bottom: -20px;
  left: -10px;
}
.period {
  font-size: 12px;
  max-height: 25px;
}
.period-active {
  background-color: #089F8F !important;
  color: white !important;
}

.search-div {
  width: 250px;
}
.search-reseau-div {
  width: 300px;
}

.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(12rem, 1fr));
  grid-gap: 0.5rem;
}
.grid-move {
  transition: all 0.3s;
}
.move {
  cursor: move;
  background-color: #fff;
}
</style>
