import { EventBus } from '@/event-bus.js';
import moment from 'moment'
import localization from 'moment/locale/fr';

import httpClient from '@/api/httpClient';

moment.locale('fr', localization);

export default {
	data() {
		return {
			ab: {
				title: 'AGENT BIS - Intelligence immobilière',
				favicon: 'favicon-ab.ico' // NOT USED → nginx
			},
			mm: {
				title: 'Meilleurs Mandataires - Toutes les données du marché des mandataires immobiliers',
				favicon: 'favicon-ab.ico' // NOT USED → nginx
			}
		}
	},

	computed: {
		amIMandataire() {
			return this.user.type == 'm' && this.user.id_m_rm_ra;
		},
		isMandataire() {
			return this.user.type == 'm';
		},
		isNetwork() {
			return this.user.type == 'rm';
		},
		isNetworkAgences() {
			return this.user.type == 'ra';
		},
		isAgence() {
			return this.user.type == 'a';
		},
		isMyNetwork() {
			return this.currentMand.rid == this.user.id_franchise; 
		},
		isMe() {
			return this.currentMand.id == this.user.id_m_rm_ra; 	
		},
		defaultAvatar() {
			return "https://www.gravatar.com/avatar/-?d=mp&f=y";
		},
		isLocalhost() {
			return window.location.href.match(/localhost/);
		},
		isAgentBis() {
			return window.location.href.match(/agentbis/);
		},
		logoABMM() {
			// if (this.isLocalhost) return "logo-header-ab.png"; 
			return this.isAgentBis ? "logo-header-ab.png" : "logo-header-mm.png";
		},
		logoABMM_trans() {
			// if (this.isLocalhost) return "logo-mm-transparent.png";
			if (!this.isMandataire) return "logo-ab-transparent.png";
		  return this.isAgentBis ? "logo-ab-transparent.png" : "logo-mm-transparent.png";
		},
		windowTitle() {
			if (this.isLocalhost) return this.mm.title; 
			return this.isAgentBis ? this.ab.title : this.mm.title;
		},
		windowFavicon() {
			if (this.isLocalhost) return this.mm.favicon; 
			return this.isAgentBis ? this.ab.favicon : this.mm.favicon;
		},
		isInPeriod() {
			return (moment(this.user.plan.end).diff(moment()) < 0) ? false : true;
		},
		isTrialEnded() {
			return this.user && this.user.trial_ended && this.user.plan.status != 'paid';
		},
		isBasic() {
			return this.user ? this.user.current_plan == 'basic' : false;
			// return (this.user && !this.user.plan && this.isTrialEnded) || (this.user && (this.user.stripe_payment != 'paid' && this.user.plan && this.user.plan.status == 'canceled' && !this.isInPeriod));
		},
		isEssentiel() {
			return this.user ? this.user.current_plan == 'essentiel' : false;
			// if (!this.user || !this.user.plan) return false;
			// return this.user.plan.type == 'essentiel' ? (this.user.plan.status != 'unpaid' && (this.user.plan.status != 'canceled' || (this.user.plan.status == 'canceled' && this.isInPeriod))) : false; 
		},
		isPro() {
			return this.user ? this.user.current_plan == 'pro' : false;
			// if (!this.user || !this.user.plan) return false;
			// return this.user.plan.type == 'pro' ? (this.user.plan.status != 'unpaid' && (this.user.plan.status != 'canceled' || (this.user.plan.status == 'canceled' && this.isInPeriod))) : false;
		},
		isEnterprise() {
			return this.user ? this.user.current_plan == 'enterprise' : false;
			// if (!this.user || !this.user.plan) return false;
			// return this.user.plan.type == 'enterprise' ? (this.user.plan.status != 'unpaid' && (this.user.plan.status != 'canceled' || (this.user.plan.status == 'canceled' && this.isInPeriod))) : false; 
		},
		blur() {
			return !this.isBasic ? '' : 'blur';
		},
		blurEssentiel() {
			return !this.isEssentiel && !this.isBasic ? '' : 'blur';
		},
		blurPlus() {
			return !this.isBasic ? '' : 'blurPlus';
		},
		blurEssentielPlus() {
			return !this.isEssentiel && !this.isBasic ? '' : 'blurPlus';
		},

		zonesUpdated: {
		  get() {
		    return this.$store.state.zonesUpdated;
		  },
		  set(val) {
		    this.$store.commit("SET_ZONES_UPDATED", val);
		  },
		},
	},

	methods: {
		defaultAvatarEvent(e) {
			console.log("defaultAvatarEvent", e);
        	e.target.src = "https://www.gravatar.com/avatar/-?d=mp";
      	},

		delay(ms) {
			new Promise(res => setTimeout(res, ms));	
		},

		// HELPERS
		euros(num) {
			return num ? num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')+"€" : '';
		},
		bigNumber(num, round=false) {
			if (!num) return 0;
			if (round) num = Math.round(num);
			return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
		},
		decimal(f, n=2) {
			return f ? parseFloat(f.toFixed(n)) : 0;
		},
		perc(val1, val2, decimals=0) {
			if (!val2) return '-';
			const res = val1/val2*100;
			if (!res) return '-'; 
			return (!decimals ? Math.round(res) : res.toFixed(decimals)) + '%';
		},
		formatDateSQL(date) {
			return moment(date).isValid() ? moment(date).format('YYYY-MM-DD') : '';
		},
		formatDateTimeSQL(date) {
			return moment(date).isValid() ? moment(date).format('YYYY-MM-DD HH:mm:ss') : '';
		},
		formatDate(date, format='DD-MM-YYYY') {
			return moment(date).isValid() ? moment(date).format(format) : '';
		},
		formatTime(date, format='HH:mm') {
			return moment(date).isValid() ? moment(date).format(format) : '';
		},
		formatDateTime(date, format='DD-MM-YYYY HH:mm') {
			return moment(date).isValid() ? moment(date).format(format) : '';
		},
		timeAgo(d, dnow=undefined) {
			let result = [];

			const now = moment(dnow);
			const dd = moment(d);

			const diffY = now.diff(dd, 'years');

			if (diffY) {
				const years = diffY > 1 ? "ans" : "an";
				result.push(diffY + ' ' + years);
				const diffM = Math.floor(now.diff(dd, 'month', true) % 12);
				if (diffM) {
					result.push('et ' + diffM + ' mois');
				}
			} else {
				const diffM = now.diff(dd, 'months', true);
				if (diffM > 1) {
					result.push(Math.floor(diffM) + ' mois');
					const nweeks = Math.round((diffM - Math.floor(diffM)) * 31 / 7);
					const weeks = nweeks > 1 ? "semaines" : "semaine";
					if (nweeks) result.push(' et ' + nweeks + ' ' + weeks);
				} else {
					const diffW = Math.round(now.diff(dd, 'weeks', true));
					const diffD = Math.round(now.diff(dd, 'days', true));
					if (diffD == 0) {
						result.push("aujourd'hui");
					} else if (diffD < 15) {
						result.push(diffD + ' jour' + ((diffD>1) ? 's' : ''));
					} else {
						result.push(diffW + ' semaine' + ((diffW>1) ? 's' : ''));
					}
				}
			}

			return result.join(' ')

			// return diffY;
		},
/* 		mandatairePhoto(slug, url) {
			if (slug == 'iad') {
				return "require(`photos/iad/${url}`)";
			}
			return url;
		}, */
		franchiseImageUrl(id) {
			return httpClient.defaults.baseURL + "/images/franchises/" + id + ".png"; //?r=" + Math.random();
		},
		agenceImageUrl(id) {
			return httpClient.defaults.baseURL + "/images/agences/" + id + ".png?r=" + Math.random();
		},
		partnerImageUrl(id) {
			return httpClient.defaults.baseURL + "/images/market/" + id + ".png?r=" + Math.random();
		},
		gotoRoute(routeName, id, hash="") {
			// id could be id or uuid → managed by CRUD;
			if (id || hash) this.$router.push({ name: routeName, hash: hash, params: {id:id} });
			else this.$router.push({name: routeName});
		},
		goContactUs() {
			window.open('https://www.meilleursmandataires.fr/contact', '_blank');
		},
		iconType(type) {
			switch (type) {
				case 'maison': return 'mdi-home-outline';
				case 'appartement': return 'mdi-floor-plan';
				case 'terrain': return 'mdi-perspective-less';
				default: return 'mdi-crop-landscape';
			}
		},
		colorType(type) {
			switch (type) {
				case 'maison': return '#008FFB';
				case 'appartement': return '#00E396';
				case 'terrain': return '#FFB01A';
				default: return '#FF4560';
			}
		},

		showSnackbar(text, color="primary", duration=1500) {
			EventBus.$emit('showSnackbar', {text: text, color: color, duration: duration});
		},

		showSnackbarServerError() {
			EventBus.$emit('showSnackbar', {text: "Erreur serveur, veuillez réessayer.<br/>Si le problème persiste, merci de contacter le support.", color: 'error', duration: 1500});
		},

		showPremiumDialog(plan) {
			EventBus.$emit('showPremiumDialog', plan);
		},

		showYesNo(data) {
			EventBus.$emit('showYesNo', data);
		},

		showCGUDialog(type) {
			EventBus.$emit('showCGUDialog', type);
		},

		photoReseau(slugReseau) {
			return slugReseau == 'iad' || slugReseau == 'lmdimmo' || slugReseau == 'mciimmo';
		},
		photoMandReseau(slugReseau, slug, uid) {
			if (slugReseau == 'iad') return `${httpClient.defaults.baseURL}/images/photos/iad/${slug}.jpg`;
			return `${httpClient.defaults.baseURL}/images/photos/${slugReseau}/${uid}.jpeg`;
		},

		visibilitySIBWidget(bool) {
			const el = document.getElementById('sib-conversations');
			if (el) el.style.visibility = !bool ? 'hidden' : 'visible';
		},

		mandataireName(name) {
			if (!name) return '';
			if (!this.isBasic) return name;
			// return name[0] + '...';
			return '...';
		},

		updateNoData(chart) {
			chart.updateOptions({
			  noData: {
			    text: !this.isBasic ? 'Chargement...' : 'Graphique Premium',
			  },
			});
		},

		firstUpper(s) {
			return !s ? '' : (s[0].toUpperCase() + s.slice(1).toLowerCase());
		},

		mailto(s) {
			return "mailto:" + s + "@agentbis.com";
		},

		mailtoUser() {
			return `%0D%0A %0D%0A
				Client : ${this.user.firstname} ${this.user.lastname}
				%0D%0A
				Réseau : ${this.user.rname}
				%0D%0A
				Email : ${this.user.email}`;
		}
	},
}