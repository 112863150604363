<template>
  <div>
    <v-overlay v-if="loading" :opacity="0.3">
      <v-progress-circular indeterminate size="50">
      </v-progress-circular>
    </v-overlay>

    <div class="center">
      <div class="wrap">
        <v-img
          max-width="450"
          src="@/assets/images/marketplace.png"
        ></v-img>
        <div class="mx-6 my-auto box">
          <p>
            Meilleurs Mandataires vous propose une sélection d'offres de partenaires pour
            vous permettre de générer des revenus complémentaires.
          </p>
          <p>
            Cliquez, inscrivez-vous aux offres qui vous intéresse et c'est parti !
          </p>
        </div>
      </div>
    </div>

    <base-box title="Offres partenaires" v-if="!loading">
      <div class="d-flex wrap ml-4 my-4">
        <v-card
          v-for="partner in partners" :key="partner.id"
          class="d-flex flex-column mr-4"
          outlined
          shaped
          tile
          max-width="300"
          min-width="250"
          min-height="110"
        >
          <v-img
            :href="partner.url"
            class="mx-auto mt-4"
            max-height="50"
            max-width="200"
            :src="partnerImageUrl(partner.id)"
          ></v-img>
          <v-spacer></v-spacer>
          <v-card-actions class="justify-center">
            <a class="link" :src="partnerImageUrl(partner.id)" :href="partner.url" target="_blank">{{partner.descr}}</a>
          </v-card-actions>
        </v-card>
      </div>
    </base-box>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";

  export default {
    name: 'Marketplace',

    data: () => ({
      loading: false,
      partners: [
        {
          id: 1,
          name: 'Crédit Agricole',
          descr: 'Souscripteur prêt immobilier',
          url: 'https://www.credit-agricole.fr',
        },
        {
          id: 2,
          name: 'Hemea',
          descr: 'Souscripteur rénovation énergétique',
          url: 'https://www.hemea.com/fr/environnement-travaux/renovation-ecologique',
        },
        {
          id: 3,
          name: 'Diagamter',
          descr: 'Souscripteur Diagnostic',
          url: 'https://www.diagamter.com',
        }
      ]
    }),

    computed: {
      ...mapGetters('User', [ 'user', 'userUUID' ]),
    },

    methods: {
    },

    async mounted() {
    }
  }
</script>

<style lang="scss" scoped>
.center {
  display: flex;
  justify-content: center;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
}
.box {
  width: 600px;
  font-size: 18px;
}
.link {
  text-decoration: none;
  font-size: 14px;
}
</style>
