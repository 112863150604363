<template>
  <v-dialog v-model="showDialog" max-width="450">
    <v-card>
      <v-card-text class="px-8 py-6 text-center">
        <v-icon x-large :color="color">{{icon}}</v-icon>
        <p class="mt-6 msg" v-html="message"></p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn text color="primary" class="mr-2" @click.stop="click('no', dataClick)">{{noLabel}}</v-btn>
        <v-btn text color="secondary" @click.stop="click('yes', dataClick)">{{yesLabel}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { EventBus } from '@/event-bus.js';

  export default {
    name: 'BaseYesNo',

    data: function () {
      return {
        icon: '',
        color: '',
        message: '',
        yesLabel: '',
        noLabel: '',
        onClick: null,
        dataClick: null,
        showDialog: false
      }
    },

    created() {
      EventBus.$on('showYesNo', data => {
        this.icon = data.icon ? 'mdi-' + data.icon : 'mdi-chat-question-outline',
        this.color = data.color || '#EE9B0E', 
        this.message = data.message;
        this.yesLabel = data.yes || "Oui";
        this.noLabel = data.no || "Non";
        this.onClick = data.onClick;
        this.dataClick = data.dataClick;
        this.showDialog = true;
      })
    },

    methods:{
      click(which, data) {
        this.showDialog = false;
        this.onClick(which, data);
      }
    }
  }
</script>

<style lang="scss" scoped>
.msg {
  font-size: 18px;
  font-weight: 400;
}
</style>