<template>
  <v-overlay opacity="0.3">
    <div class="page-loader">
      <div class="container">
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
        <div class="cube"></div>
      </div>
    </div> 
  </v-overlay>
</template>

<script>
  export default {
    data: () => {
      return {
        // isloaded: false
      }
    },
    mounted() {
      // document.onreadystatechange = () => {
      //   if (document.readyState == "complete") { 
      //     this.isloaded = true;
      //   } 
      // }
    },
  }
</script>

<style lang="scss" scoped>
   $colors: #005466, #089F8F, #8ACBB6, #EE9B0E, #EF7C00, #E52F09;

  // -----------------------------------------------------

  .page-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
  }

  .container {
    postion: relative;
    width: 200px;
    height: 200px;
  }

  .cube{
    width: 20px;
    height: 20px;
    position: relative;

    @for $i from 1 through length($colors) {
      &:nth-child(#{$i}) {
        background-color: nth($colors, $i);
      }
    }

    &:nth-child(1) {
      right: -60;
      bottom: -60;
    }

    &:nth-child(2) {
      right: -40;
      left: -40;
    }

    &:nth-child(4) {
    }

    &:nth-child(5) {
    }

    /*
    &:first-child {
      animation: left 1s infinite;
    }

    &:last-child {
      animation: right 1s infinite .5s;
    }
    */
  }

  // -----------------------------------------------------

  @keyframes left {
    40% {
      transform: translateX(-60px);
    }
    50% {
      transform: translateX(0);      
    }
  }

  @keyframes right {
    40% {
      transform: translateX(60px);
    }
    50% {
      transform: translateX(0);
    }
  }
</style>