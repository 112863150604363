import http from './httpClient';

const END_POINT = '/stripe';

const getSubscription = (subscriptionId) => http.get(http.path([END_POINT,'subscription',subscriptionId]));
const getPlans = (type) => http.get(http.path([END_POINT,'plans',type]));
const createLink = (plan, interval, trial) => http.get(http.path([END_POINT,'payment_link',plan,interval]) + http.args2({trial:trial}));
const checkout = (customerId, plan, interval, trial) => http.post(http.path([END_POINT,'checkout',customerId, plan,interval]) + http.args2({trial:trial}));
// const config = () => http.get('/config');
// const createSession = (data) => http.post('/create-checkout-session', data); // email, priceId
// const cancelSubscription = (subscriptionId) => http.post('/cancel-subscription', {subscription_id: subscriptionId});
// const createCustomer = (email, fullname) => http.post('/create-customer', {email: email, fullname: fullname});
// const createSubscription = (email, methodId, priceId) => http.post('/create-subscription', {email: email, methodId: methodId, priceId: priceId});

export {
    getSubscription,
    getPlans,
    createLink,
    checkout,
    // config,
    // createSession,
    // cancelSubscription,
    // createCustomer,
    // createSubscription,
}