import http from './httpClient';

const END_POINT = '/user';

const signup = (data) => http.post(http.path([END_POINT,'signup']), data);
const signin = (data) => http.post(http.path([END_POINT,'signin']), data);
const signout = (uuuid) => http.post(http.path([END_POINT,uuuid,'signout']));
const lostPassword = (email) => http.post(http.path([END_POINT,'lostpassword']), {email:email});
const setPassword = (data) => http.post(http.path([END_POINT,'setpassword']), data);
const getInfo = (uuuid) => http.get(http.path([END_POINT,uuuid]));
const getPlans = (uuuid) => http.get(http.path([END_POINT,uuuid,'plans']));
const update = (uuuid, data) => http.put(http.path([END_POINT,uuuid]), data);
const addFavori = (uuuid, id, type) => http.post(http.path([END_POINT,uuuid,'addFavori']), {id:id, type:type});
const removeFavori = (uuuid, id, type) => http.post(http.path([END_POINT,uuuid,'removeFavori']), {id:id, type:type});
const replaceFavoris = (uuuid, favoris) => http.post(http.path([END_POINT,uuuid,'replaceFavoris']), {favoris:favoris});
const googleAuth = (uuuid, authCode) => http.post(http.path([END_POINT,uuuid,'googleauth']), {authCode:authCode});
const createStripePortal = (uuuid, url) => http.post(http.path([END_POINT,uuuid,'createStripePortal']), {url:url});
const addSearch = (uuuid, search, id, type) => http.post(http.path([END_POINT,uuuid,'addSearch',id, type]), {search:search});
const updateCoupon = (uuuid, coupon) => http.post(http.path([END_POINT,uuuid,'updateCoupon',coupon]));
const recruitAdd = (uuuid, mandId) => http.post(http.path([END_POINT,uuuid,'addRecruit',mandId]));
const recruitUpdate = (uuuid, mandId, body) => http.post(http.path([END_POINT,uuuid,'updateRecruit',mandId]), body);
const recruitRemove = (uuuid, mandId) => http.post(http.path([END_POINT,uuuid,'removeRecruit',mandId]));

export {
	signup,
	signin,
	signout,
	lostPassword,
	setPassword,
	getInfo,
	update,
	getPlans,
	addFavori,
	removeFavori,
	replaceFavoris,
	googleAuth,
	createStripePortal,
	addSearch,
	updateCoupon,
	recruitAdd,
	recruitUpdate,
	recruitRemove,
}