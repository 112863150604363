<template>
  <v-badge
    :class="smallM"
    bordered
    :color="badgeColorClass"
    overlap
    :content="badgeContent"
    :offset-x="offset"
    :offset-y="offset"
    transition=""
    :value="planPremium"
    style="font-size:2px"
  >
    <img v-if="photoReseau(obj.rslug)" class="photo b-w" :class="[pointerClass, blurPlus]" :src="photoMandReseau(obj.rslug, obj.slug, obj.muid)" :style="theStyle" @error="defaultAvatarEvent" @click="click" />
    <img v-else-if="isTempo" class="photo b-w" :class="[pointerClass, myblurPlus]"
      src="https://www.gravatar.com/avatar/-?d=mp&f=y" :width="size"
    />
    <v-img v-else class="photo b-w" :class="[pointerClass, myblurPlus]" :src="thePhoto" @error="defaultAvatarEvent" :style="theStyle" @click="click">
      <template #placeholder>
        <img src="https://www.gravatar.com/avatar/-?d=mp&f=y" :width="size" /> 
      </template>
    </v-img>
  </v-badge>
</template>

<script>

  export default {
    name: 'PhotoMand',

    props: {
      obj: {
        type: Object
      },
      size: {
        type: Number,
        default: 50,
      },
      onClick: {
        type: Function
      },
      clickData: {
      },
      isItMe: {
        type: Boolean
      }
    },

    data: () => ({  
    }),

    computed: {
      smallM() {
        return this.size < 60 ? 'verysmallM' : (this.size < 100 ? 'smallM' : '');
      },

      offset() {
        return this.size < 60 ? 12 : (this.size < 100 ? 17 : 25);
      },

      theStyle() {
        return "width:"+this.size+"px;height:"+this.size+"px";
      },

      thePhoto() {
        return this.obj.photo || this.obj.mphoto;
      },

      isTempo() {
        return (this.obj.photo && this.obj.photo.match(/lazy/)) || (this.obj.mphoto && this.obj.mphoto.match(/lazy/))
      },

      // borderMemberClass() {
      //   return '';
        // return this.obj.isMember || this.obj.uid ? 'borderMember' : ''; 
      // },

      badgeContent() {
        return this.obj.plan_type == 'basic' ? 'B' : 'P';
      },

      badgeColorClass() {
        switch (this.obj.plan_type) {
          case 'basic': return '#089F8F';
          case 'trial':
          case 'essentiel':
          case 'pro':
          case 'enterprise': {
            return '#EF7C00';
          }
          default: return '';
        }
      },

      pointerClass() {
        return 'pointer';
      },

      planPremium() {
        return !this.obj.plan_type || this.obj.plan_type == 'basic' ? false : true;
      },

      myblurPlus() {
        return this.isItMe ? '' : this.blurPlus;
      }
    },

    methods: {
      click: function(e) {
        this.$emit('onClick', this.clickData || this.obj.id);
      },
    },

    mounted() {
    }
  }
</script>

<style>
.photo {
  border-radius: 50%;
  vertical-align: middle;
}
.smallM .v-badge__badge {
 font-size: 9px;
 height: 16px;
 min-width: 16px;
 padding: 4px 2px; 
}
.verysmallM .v-badge__badge {
 font-size: 7px;
 height: 14px;
 min-width: 14px;
 padding: 4px 2px; 
}
.b-w {
  -webkit-filter: grayscale(100%);
  filter: grayscale(100%);
}
</style>