<template>
  <center>
    <v-img width="500" src="@/assets/images/not-found.png"></v-img>
    <h3>Page non trouvée ou inaccessible</h3>
    <p v-if="isBasic" class="mt-6">
      Veuillez vérifier l'adresse ou contacter le <a href="https://www.meilleursmandataires.fr/contact">support</a> si le problème persiste.
    </p>
    <p v-else class="mt-6">
      Veuillez vérifier l'adresse ou contacter le support via le widget en bas à droite de l'écran si le problème persiste.
    </p>
    <p>
      <a href="/">Retour à l'accueil</a>
    </p>
  </center>
</template>

<script>
  import { mapGetters } from "vuex";

  export default {
    name: 'NotFound',

    computed: {
      ...mapGetters('User', [ 'user' ]),
    }
  }
</script>

<style scoped>
center {
  margin: 15vw;
}
</style>