export default {
	methods: {

		apexSetCategories(ref, data) {
			try {
				const chart = this.$refs[ref].chart;
				chart.updateOptions({
					xaxis: {
					categories: data,
					},
				});
			} catch(e) {
				//
			}
    	},

		apexSetOptions(ref, options) {
			try {
				const chart = this.$refs[ref].chart;
				chart.updateOptions(options);
			} catch(e) {
				//
			}
		},

  }
}