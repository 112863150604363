<template>
  <div>
    <v-navigation-drawer
      :dark="true"
      mobile-breakpoint="960"
      width="400"
      clipped
      :right="true"
      mini-variant-width="70"
      :expand-on-hover="expandOnHover"
      id="main-sidebar"
      app
    >
      <v-checkbox
        class="mx-2"
        v-model="enableTooltip"
        label="tooltip"
      ></v-checkbox>
      
      <div class="mx-2" style="color:white" v-if="clicked.length > 0">
        <div class="title">STATISTIQUES</div>
        <div class="stats">
          {{buffer.commune}} ({{buffer.cp}})
          <br/>
          {{buffer.region}}
          <br/>
          Superficie: {{bigNumber(buffer.superficie)}} km&sup2;
          <br/>
          Population: {{bigNumber(buffer.population)}}
          <br/>
          Ménages: {{bigNumber(buffer.menages)}}
          <br/>
          <!-- Ménages fiscaux: {{bigNumber(buffer.menages_fiscaux)}}
          <br/> -->
          Logements: {{bigNumber(buffer.logements)}}
          <br/>
          Résidences : {{bigNumber(buffer.residences_principales)}}
          <br/>
          Propriétaire: {{bigNumber(buffer.residences_principales_prop)}} ({{decimal(buffer.residences_principales_prop/buffer.residences_principales*100)}}%)
          <br/>
          Niveau de vie: {{bigNumber(buffer.niveau_vie_median, true)}}€
          <br/>
          Taux de pauvreté: {{decimal(buffer.taux_pauvrete)}}%
        </div>
      </div>

      <div class="mt-2 mx-2" style="color:white" v-if="clicked.length > 0">
        <div class="title">MANDATAIRES</div>
        <div class="ml-1 stats">
          <!-- {{mandataires}} -->
        </div>
      </div>
    </v-navigation-drawer>
  
    <div class="">
      <l-map
        :center="center"
        :zoom="zoom"
        class="map"
        ref="map"
        :options="{doubleClickZoom: false, scrollWheelZoom: false}"
        @update:zoom="zoomUpdated"
        @update:center="centerUpdated"
      >
        <l-tile-layer :url="url">
        </l-tile-layer>
        <l-marker
          v-for="marker in markers"
          :key="marker.id"
          :lat-lng="marker.coordinates"
        >
        </l-marker>
        <l-polyline
          v-for='departement in departements'
          :key="departement.id"
          :lat-lngs="departement.coordinates"
          color="red"
        >
        </l-polyline>
        <l-geo-json
          v-for='commune in communes'
          :key="commune.id"
          :geojson="commune.coordinates"
          :options="options"
          :options-style="styleFunction"
        >
          
        </l-geo-json>

      </l-map>
    </div>
  </div>
</template>

<script>
import { LMap, LTileLayer, LMarker, LPolyline, LGeoJson } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';
import { Icon }  from 'leaflet';
import 'leaflet/dist/leaflet.css';
import axios from 'axios';
const ss = require('simple-statistics');
// voir aussi stats-lite : https://www.npmjs.com/package/stats-lite

// for markers icons
delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

export default {
  components: {
    LMap,
    LTileLayer,
    LMarker,
    LPolyline,
    LGeoJson,
  },

  data () {
    return {
      url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
      // center: [ 49.1193089, 6.1757156 ],
      center: [ 48.871330588905884, 2.1840463277640767 ], // rueil
      zoom: 12,
      distance: 9,
      oldDistance: 0,
      oldZoom: 0,
      markers: [
        {id: 1, coordinates: [ 49.114910, 6.178810 ]},
        {id: 2, coordinates: [ 49.133290, 6.154370 ]},
        {id: 3, coordinates: [ 49.102160, 6.158850 ]},
        {id: 4, coordinates: [ 49.136010, 6.199630 ]},
        {id: 5, coordinates: [ 49.105563, 6.182234 ]},
      ],
      communes: [],
      stats: {},
      departements: [],
      fillColor: "#aed4dd",
      fillOpacity: 0.4,
      enableTooltip: true,
      centerChanged: false,
      lastCallTime: 0,
      buffer: {},
      clicked: [],
      mandataires: null,
    }
  },

  watch: {
    // communes: {
    //   deep: true,
    //   handler() {
    //     console.log('The list of colours has changed!');
    //   }
    // },

    enableTooltip() {
      console.log("cocou");
    }
  },

  methods: {
    
    distanceFromZoom(zoom) {
      switch (zoom) {
        case 12: return 9;
        case 11: return 12;
        case 10: return 20;
        default: return 30;
      }
    },
    pastelColor() {
      const r = (Math.round(Math.random()* 127) + 127).toString(16);
      const g = (Math.round(Math.random()* 127) + 127).toString(16);
      const b = (Math.round(Math.random()* 127) + 127).toString(16);
      return '#' + r + g + b;
    },
    zoomUpdated (zoom) {
      this.oldDistance = this.distance;
      this.oldZoom = this.zoom;
      this.distance = this.distanceFromZoom(zoom);
      this.zoom = zoom;
    },
    async centerUpdated (center) {
      this.center = [ center.lat, center.lng ];
      console.log("CENTER CHANGE", this.center);
      this.centerChanged = true;
      await this.fetch();
    },
    async fetch() {
      // selon commune
      // const response = await axios.get(process.env.VUE_APP_API_BASE_URL+'/nearest/commune/57463');
      
      // selon centre de la map
      if (this.centerChanged || this.oldDistance != this.distance || this.oldZoom != this.zoom) {
        const now = new Date().getTime();
        const ellapse = this.lastCallTime > 0 ? now-this.lastCallTime : 0;
        
        if (ellapse == 0 || ellapse > 500) {
          console.log("FETCH", this.center, this.zoom, this.distance);
          const response = await axios.get(process.env.VUE_APP_API_BASE_URL+'/nearest'
                          +'?lat='+this.center[0]
                          +'&lng='+this.center[1]
                          +'&dist='+this.distance);

          this.communes = response.data.result[0];
          this.departements = response.data.result[1]
          this.stats = response.data.result[2];

        }
        this.centerChanged = false;
        this.lastCallTime = now;
      }
    },

    computeBuffer() {
      // console.log(this.communes);
      this.clicked = this.communes.filter(commune => commune.coordinates.properties.clicked).map(commune => commune.coordinates.properties);

      // console.log("CLICKED", clicked);

      this.buffer = {
        population : ss.sum(this.clicked.map(prop => prop.insee.P18_POP)),
        menages: ss.sum(this.clicked.map(prop => prop.insee.P18_MEN)),
        menages_fiscaux: ss.sum(this.clicked.map(prop => prop.insee.NBMENFISC18)),
        superficie : ss.sum(this.clicked.map(prop => prop.insee.SUPERF)),
        logements : ss.sum(this.clicked.map(prop => prop.insee.P18_LOG)),
        residences_principales: ss.sum(this.clicked.map(prop => prop.insee.P18_RP)),
        residences_principales_prop: ss.sum(this.clicked.map(prop => prop.insee.P18_RP_PROP)),
        niveau_vie_median: ss.mean(this.clicked.map(prop => prop.insee.MED18)),
        taux_pauvrete: ss.mean(this.clicked.map(prop => prop.insee.TP6018)),
      }
    }
  },

  computed: {
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction
      };
    },
    styleFunction() {
      const self = this;
      return () => {
        return {
          weight: 1,
          color: "black",
          opacity: 0.8,
          fillColor: self.fillColor, //this.pastelColor(),
          fillOpacity: self.fillOpacity
        };
      };
    },
    onEachFeatureFunction() {
      var self = this;

      return (feature, layer) => {
        
        if (self.enableTooltip) {
          const div = [];

          div.push('<div>');
          div.push(feature.properties.nom_commune_complet);
          div.push(' (' + feature.properties.code_postal + ')');
          div.push('<br/>');
          div.push(feature.properties.nom_departement + ' / ' + feature.properties.nom_region);

          div.push('<hr>');
          
          div.push(this.bigNumber(feature.properties.insee.P18_POP) + " habitants");
          div.push('<br/>');
          div.push(this.bigNumber(feature.properties.insee.P18_MEN) + " ménages");
          div.push('<br/>');
          div.push(this.bigNumber(feature.properties.insee.P18_LOG) + " logements");
          div.push('<br/>');
          const proprio = (feature.properties.insee.P18_RP_PROP / feature.properties.insee.P18_RP * 100).toFixed(1);
          div.push(this.bigNumber(feature.properties.insee.P18_RP) + " résidences principales");
          div.push('<br/>');
          div.push(proprio + "% propriétaires");
          div.push('<br/>');
          div.push(this.bigNumber(feature.properties.insee.TP6018) + "% pauvreté");
          if (feature.properties.insee.percentile) {
            div.push('<br/>');
            div.push("percentile " + feature.properties.insee.percentile);
          }
          
          if (feature.properties.mandataires) {
            div.push('<hr>');
            div.push("Mandataires " + feature.properties.mandataires.length);
            let mands = [];
            for (let mand of feature.properties.mandataires) {
              let str = mand.sa_ville ? "* " : "";
              str += mand.rname+" → "+mand.firstname+" "+mand.lastname;
              str += " avis:"+mand.avis_count+"/"+mand.avis_average;
              str += JSON.stringify(mand.stats);
              str += "<br/>" + JSON.stringify(mand.zstats);

              // str += " prop:"+mand.prop_count+" sold:"+mand.sold_count+" prix moyen:"+this.euros(mand.price_average);
              mands.push(str);
            }
            div.push('<br/>');
            div.push(mands.join('<br/>')); 
          }
          div.push('</div>');

          layer.bindTooltip(
            div.join(''),
            { permanent: false, sticky: true }
          );
        }

        // click
        layer.on('click', function (e) {
          // console.log(e);
          let properties = e.sourceTarget.feature.geometry.properties;
          
          if (properties.clicked) {
            properties.clicked = false;
            e.target.setStyle({
                fillColor: properties.fillColor,
            });

          } else {
            properties.clicked = true;
            properties.fillColor = e.target.options.fillColor;

            e.target.setStyle({
                fillColor: 'green',
            });
          }

          console.log(JSON.stringify(properties));
          e.sourceTarget.feature.geometry.properties = properties;

          self.computeBuffer();
        });
      };
    }
  },

  async mounted() {
    await this.fetch();
    console.log("Communes", this.communes.length);
  }
}
</script>

<style>
  .title {
    font-weight: bold; 
    background: #555;
  }
  .stats {
    margin-left: 3px;
    margin-top: 5px;
  }
  .map {
    top:0;
    left: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    overflow :hidden
 }
</style>