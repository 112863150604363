var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.loadInfo)?_c('v-overlay',{attrs:{"opacity":0.3}},[_c('v-progress-circular',{attrs:{"indeterminate":"","size":"50"}})],1):_vm._e(),_c('base-box',{attrs:{"title":("Recrutement de mandataires " + _vm.count)}},[_c('v-text-field',{staticClass:"searchtextinput",attrs:{"id":"step-recruit-1","dense":"","placeholder":"rechercher"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('v-data-table',{ref:"myTable",staticClass:"table-top-mand",attrs:{"headers":_vm.headersShow,"items":_vm.user.recruit,"item-key":"id","disable-pagination":"","hide-default-footer":"","search":_vm.search,"custom-filter":_vm.customFilter,"custom-sort":_vm.customSort,"expanded":_vm.expanded,"show-expand":""},on:{"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center"},[_c('photo-mand',{staticClass:"my-2 mr-2 pointer",attrs:{"obj":item,"size":45,"clickData":item.id},on:{"onClick":_vm.gotoMand}}),_c('div',{staticClass:"my-2 align-center",staticStyle:{"width":"100%"}},[_vm._v(" "+_vm._s(item.name)+" "),(!_vm.hasNotDateInfo(item))?_c('span',[_c('br'),_c('span',{staticClass:"copy",on:{"click":function($event){return _vm.copy(item, 'phone')}}},[_vm._v(_vm._s(item.phone))]),_c('br'),_c('span',{staticClass:"copy",on:{"click":function($event){return _vm.copy(item, 'email')}}},[_vm._v(_vm._s(item.email))])]):_vm._e()])],1)]}},{key:"item.reseau",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"pointer my-2",attrs:{"max-width":"60","max-height":"60","contain":"","src":_vm.franchiseImageUrl(item.id_franchise)},on:{"click":function($event){return _vm.gotoReseau(item.id_franchise)}}})]}},{key:"item.commune",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"pointer",on:{"click":function($event){return _vm.gotoCommune(item.commune_id)}}},[_vm._v(" "+_vm._s(item.nom_commune_complet)+" "),_c('br'),_vm._v(" ("+_vm._s(item.code_postal)+") ")])]}},{key:"item.nom_departement",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nom_departement)+" ")]}},{key:"item.nom_region",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.nom_region)+" ")]}},{key:"item.open",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","save-text":"Sauver","cancel-text":"Annuler","return-value":props.item},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.saveData('date_open', props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","locale":"fr-FR"},model:{value:(props.item.date_open),callback:function ($$v) {_vm.$set(props.item, "date_open", $$v)},expression:"props.item.date_open"}})]},proxy:true}],null,true)},[_c('span',{attrs:{"id":"step-recruit-2"}},[_vm._v(_vm._s(_vm.formatDate(props.item.date_open)))]),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.date_open)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveData('date_open', props.item, '')}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")]):_vm._e()]}}],null,true)},[_vm._v(" Supprimer ")])],1)]}},{key:"item.call",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","save-text":"Sauver","cancel-text":"Annuler","return-value":props.item},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.saveData('date_call', props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","locale":"fr-FR"},model:{value:(props.item.date_call),callback:function ($$v) {_vm.$set(props.item, "date_call", $$v)},expression:"props.item.date_call"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.date_call))+" "),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.date_call)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveData('date_call', props.item, '')}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")]):_vm._e()]}}],null,true)},[_vm._v(" Supprimer ")])],1)]}},{key:"item.meet",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","save-text":"Sauver","cancel-text":"Annuler","return-value":props.item},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.saveData('date_meet', props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","locale":"fr-FR"},model:{value:(props.item.date_meet),callback:function ($$v) {_vm.$set(props.item, "date_meet", $$v)},expression:"props.item.date_meet"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.date_meet))+" "),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.date_meet)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveData('date_meet', props.item, '')}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")]):_vm._e()]}}],null,true)},[_vm._v(" Supprimer ")])],1)]}},{key:"item.close",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","save-text":"Sauver","cancel-text":"Annuler","return-value":props.item},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.saveData('date_close', props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","locale":"fr-FR"},model:{value:(props.item.date_close),callback:function ($$v) {_vm.$set(props.item, "date_close", $$v)},expression:"props.item.date_close"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.date_close))+" "),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.date_close)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveData('date_close', props.item, '')}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")]):_vm._e()]}}],null,true)},[_vm._v(" Supprimer ")])],1)]}},{key:"item.ok",fn:function(props){return [_c('v-edit-dialog',{attrs:{"large":"","save-text":"Sauver","cancel-text":"Annuler","return-value":props.item},on:{"update:returnValue":function($event){return _vm.$set(props, "item", $event)},"update:return-value":function($event){return _vm.$set(props, "item", $event)},"save":function($event){return _vm.saveData('date_ok', props.item)}},scopedSlots:_vm._u([{key:"input",fn:function(){return [_c('v-date-picker',{attrs:{"no-title":"","locale":"fr-FR"},model:{value:(props.item.date_ok),callback:function ($$v) {_vm.$set(props.item, "date_ok", $$v)},expression:"props.item.date_ok"}})]},proxy:true}],null,true)},[_vm._v(" "+_vm._s(_vm.formatDate(props.item.date_ok))+" "),_c('br'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(props.item.date_ok)?_c('v-icon',_vm._g(_vm._b({staticClass:"ml-1",attrs:{"small":""},on:{"click":function($event){$event.stopPropagation();return _vm.saveData('date_ok', props.item, '')}}},'v-icon',attrs,false),on),[_vm._v("mdi-close")]):_vm._e()]}}],null,true)},[_vm._v(" Supprimer ")])],1)]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"red"},on:{"click":function($event){$event.stopPropagation();return _vm.removeMand(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-close-circle-outline")])]}}],null,true)},[_c('span',{staticClass:"red--text"},[_vm._v("Supprimer")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.hasNotDateInfo(item))?_c('v-icon',_vm._g(_vm._b({attrs:{"color":"blue"},on:{"click":function($event){$event.stopPropagation();return _vm.getInfoMand(item)}}},'v-icon',attrs,false),on),[_vm._v("mdi-download-circle-outline")]):_vm._e()]}}],null,true)},[_c('span',{staticClass:"blue--text"},[_vm._v("Récupérer informations")])]),_c('span',{attrs:{"id":"step-recruit-3"}})]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"py-2",attrs:{"colspan":headers.length}},[_c('v-textarea',{attrs:{"label":"Note"},model:{value:(item.notes),callback:function ($$v) {_vm.$set(item, "notes", $$v)},expression:"item.notes"}}),_c('v-icon',{staticClass:"pointer",on:{"click":function($event){return _vm.saveData('notes', item)}}},[_vm._v("mdi-content-save-edit")])],1)]}}],null,true)})],1),_c('BaseSnackbar'),_c('v-tour',{attrs:{"name":"Recruit","steps":_vm.recruitSteps}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }