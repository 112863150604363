<template>
  <div class="d-flex" style="height:100%">
    <v-row justify="center" class="my-auto mx-auto">
      <v-col lg="11" sm="8" xl="7">
        <v-card class="elevation-4">
          <v-row>
            <v-col lg="6" class="primary d-none d-md-flex align-center justify-center">
              <div class="d-none d-sm-block">
                <div class="d-flex align-center pa-10">
                  <div>
                    <div class="d-flex">
                      <img :src="require(`@/assets/images/${logoABMM_trans}`)" class="mx-auto" />
                    </div>
                    <v-img src="@/assets/images/login-image.png" max-width="550" class="mt-8" />
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="6">
              <div class="pa-7 pa-sm-12">
                <h2 class="font-weight-bold mt-4 blue-grey--text text--darken-2">Connexion</h2>
                <h6 class="subtitle-1" v-if="!isAgentBis">
                  Pas de compte ?
                  <router-link to="Signup">S'inscrire</router-link>
                </h6>

                <v-form ref="form" v-model="formValid" @keyup.enter.native="submit">
                  <v-text-field
                    v-model="email"
                    :rules="rules.email"
                    label="E-mail"
                    placeholder=" "
                    persistent-placeholder
                    class="mt-4"
                    required
                    outlined
                  ></v-text-field>
                  <v-text-field
                    v-model="password"
                    counter
                    :rules="rules.password"
                    label="Mot de passe"
                    placeholder=" "
                    persistent-placeholder
                    required
                    outlined
                    :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="showPassword ? 'text' : 'password'"
                    @click:append="showPassword=!showPassword"
                  ></v-text-field>

                  <div class="d-block d-sm-flex align-right">
                    <div class="ml-auto">
                      <a href="javascript:void(0)" class="small-font" @click="doLostPassword">Mot de passe oublié</a>
                    </div>
                  </div>

                  <div class="d-block d-sm-flex align-center mb-4 mb-sm-0">
                    <v-checkbox
                      v-model="remember"
                      label="Rester connecter"
                      required
                    ></v-checkbox>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon v-on="on" v-bind="attrs" small class="ml-2">
                          mdi-information-outline
                        </v-icon>
                      </template>
                      La connexion sera conservée 7 jours
                    </v-tooltip>
                  </div>
                  <v-btn
                    :disabled="!formValid"
                    color="primary"
                    block
                    class="mr-4"
                    submit
                    @click="submit"
                  >S'identifier</v-btn>
                </v-form>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>

    <v-dialog v-model="showDialog" max-width="500">
      <v-card class="confirm-box">
        <v-card-title>
          <span>Mot de passe oublié</span>
        </v-card-title>
        <v-card-text class="px-8 py-6 text-center">
          <v-img
            class="ma-auto"
            src="@/assets/images/check-your-email.png"
            width="120"
          >
          </v-img>
          <p class="mt-6">
            Nous avons envoyé un e-mail pour permettre de changer votre mot de passe à
          </p>
          <p class="mt-4 bold">{{email}}</p>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="showDialog=!showDialog">OK</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <BaseSnackbar />

  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "BoxedLogin",

  data: () => ({
    showDialog: false,
    formValid: false,
    password: "",
    email: "",
    remember: false,
    showPassword: false,
    rules: {
      password: [
        v => !!v || "Mot de passe requis",
        v => (v && v.length >= 6) || "Le mot de passe doit comporter au moins 6 caractères"
      ],
      email: [
        v => !!v || "E-mail requis",
        v => /.+@.+\..+/.test(v) || "E-mail non valide"
      ],
    }
  }),
  
  computed: {
    ...mapGetters('User', [ 'user', 'userUUID' ]),
    // ...mapState('User', [ 'user' ]),
  },

  methods: {
    ...mapActions('User', [ 'signin', 'lostPassword', 'fetchUserInfo' ]),

    async submit() {
      const result = await this.signin({
        email: this.email,
        password: this.password,
        remember: this.remember,
      });

      if (!this.user.id) {
        this.showSnackbar("E-mail ou mot de passe inconnu", 'error');
      }  else {
        await this.fetchUserInfo(this.userUUID);

        console.log("USER", this.user);

        const whereToGo = (this.user.count_signin <= 1 && this.isMandataire)? 'welcome' : 'dashboard'; 
        this.$router.push({ name: whereToGo });
      }
    },

    async doLostPassword() {
      if (this.email == "") {
        this.showSnackbar("Veuillez saisir une addresse email", 'error');        
      } else {
        const ok = await this.lostPassword(this.email);

        if (!ok) return this.showSnackbar(this.email + " non trouvé, veuillez vérifier l'adresse email", 'error');

        this.showSnackbar("Un e-mail de réinitialisation de votre mot de passe a été envoyé");
      }
    }
  },

  mounted() {
    this.visibilitySIBWidget(false);
  }
};
</script>

<style scoped>
  .small-font {
      font-size: 12px;
  }
  .v-chip:hover {
      border-color: #1e88e5;
  }
</style>
