<template>
  <div class="d-flex justify-center align-center" :style="myStyle" v-if="vif">
    <v-progress-circular
      color="grey lighten-2" indeterminate size="30">
    </v-progress-circular>
  </div>
</template>

<script>

  export default {
    name: 'BaseProgress',

    props: {
      vif: {
        type: Boolean,
      },

      height: {
        type: Number,
        default: 350,
      },

      size: {
        type: Number,
        default: 30,
      },
    },

    computed: {
      myStyle() {
        return "width:100%; height:" + this.height + "px";
      }
    }
  }
</script>