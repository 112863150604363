import Vue from 'vue';
import App from './App.vue';
import VueRouter from 'vue-router';
import VueEsc from 'vue-esc'; // https://www.npmjs.com/package/vue-esc
import VueGeolocation from 'vue-browser-geolocation'; // https://www.npmjs.com/package/vue-browser-geolocation
import ImageFallback from 'vue-image-fallback'; // https://www.npmjs.com/package/vue-image-fallback?activeTab=readme
import VueMeta from 'vue-meta'; // https://vue-meta.nuxtjs.org/guide/#download-cdn
import VueTour from 'vue-tour'; // https://github.com/pulsardev/vue-tour

// require('vue-tour/dist/vue-tour.css'); // default
require('@/assets/vue-tour.css');

Vue.use(ImageFallback);
import "./plugins/base"; // all Base components
import mixins from './mixins/mixins';
import vuetify from './plugins/vuetify';
import store from './store/store';
import router from './router';

Vue.config.productionTip = false;

Vue.use(VueRouter);
Vue.use(VueEsc);
Vue.use(VueGeolocation);
Vue.use(ImageFallback);
Vue.use(VueMeta);
Vue.use(VueTour);

Vue.mixin(mixins);

store.dispatch('User/local');

new Vue({
  vuetify,
  store,
  router,
  render: h => h(App)
}).$mount('#app');