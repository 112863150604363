import http from './httpClient';

const END_POINT = '/reseauA';

const getInfo = (id) => http.get(http.path([END_POINT,id]));
const getAgences = (id) => http.get(http.path([END_POINT,id,'agences']));

export {
    getInfo,
    getAgences,
}