<template>
  <vue-apex-charts
    :ref="name"
    :height="height"
    :width="width"
    type="bar"
    :options="chartOptions"
    :series="series"
    @click="onClick"
  ></vue-apex-charts>
</template>

<script>
  import VueApexCharts from 'vue-apexcharts';

  export default {
    name: 'GraphBar',

    components: {
      VueApexCharts,
    },

    props: {
      name: {
        type: String,
      },
      title: {
        type: String
      },
      labelX: {
        type: String
      },
      labelY: {
        type: String
      },
      data: {
        type: Object,
      },
      width: {
        type: Number,
      },
      height: {
        type: Number,
      },
      colors: {
        type: Array,
      },
      legendPosition: {
        type: String,
        default: 'right'
      },
      showDataLabel: {
        type: Boolean,
        default: true,
      },
      separatorWidth: {
        type: Number,
        default: 0,
      },
      stacked: {
        type: Boolean,
        default: true,
      },
      stackType: {
        type: String,
        default: '', // '100%'
      },
      onClick: {
        type: Function,
        default: () => {}
      },
      valueSuffix: {
        type: String,
        default: ''
      },
      delayToDisplay: {
        type: Number,
        default: 0
      },
      tooltip: {
        type: Object,
      },
    },

    data: () => ({
      chartOptions: {
        chart: {
          type: 'bar',
          height: 300,
        },
        title: {
          text: "",
          align: 'center'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            borderRadius: 5,
            dataLabels: {
              position: 'top',
            },
          },
        },
        xaxis: {
          categories: [],
        },
        legend: {
          position: 'right',
          offsetY: 40
        },
        fill: {
          opacity: 1
        },
        dataLabels: {
          offsetY: -25,
          style: {
            fontSize: '14px',
            fontWeight: 'bold',
          },
          background: {
            enabled: true,
            foreColor: '#000',
            borderRadius: 2,
            padding: 4,
            opacity: 0.9,
            borderWidth: 1,
            borderColor: '#F7A732'
          },
        },
      },
    }),

    computed: {
      series() { return this.data.series },
      categories() { return this.data.labels },
    },

    methods: {
    },

    mounted() {
      // console.log("showDataLabel", this.showDataLabel, this.categories);

      setTimeout(() => {
        const chart = this.$refs[this.name].chart;
        const self = this;
        chart.updateOptions({
          chart: {
            stacked: this.stacked,
            stackType: this.stackType,
            dropShadow: {
              enabled: false,
              color: '#000',
              top: 18,
              left: 7,
              blur: 10,
              opacity: 0.2
            },
            toolbar: {
              show: false,
            },
            events: {
              click: (event, chartContext, config) => {
                self.$emit('click', {seriesIndex: config.seriesIndex, index: config.dataPointIndex});
              }
            },
          },
          title: {
            text: this.title,
          },
          yaxis: {
            title: {
              text: this.labelY,
            },
          },
          colors: this.colors,
          stroke: {
              colors: ['transparent'],
              width: this.separatorWidth,    
          },
          xaxis: {
            categories: this.categories,
            title: {
              text: this.labelX,
            },
          },
          legend: {
            show: this.legendPosition == 'none' ? false : true,
            position: this.legendPosition,
            floating: false,
            offsetX: 0,
            offsetY: 0,
          },
          dataLabels: {
            enabled: this.showDataLabel,
            formatter: function(value) {
              return value + self.valueSuffix;
            }
          },
          tooltip: {
            enabled: true,
            x: {
              show: false,
            },
            y: {
              formatter: function(value) {
                return value + self.valueSuffix;
              }
            }
          },
        });
        if (this.tooltip) chart.updateOptions({ tooltip: this.tooltip });
        
      }, this.delayToDisplay);
    }
  }
</script>

<style scoped>
</style>