<template>
  <v-snackbar
    v-model="snackbar.display"
    :color="snackbar.color"
    :timeout="snackbar.timeout"
  >
    <div class="d-flex justify-center mt-1" v-html="snackbar.text"></div>

    <template v-slot:action="{ attrs }" v-if="snackbar.color=='error'">
      <v-btn
        icon
        v-bind="attrs"
        @click="snackbar.display=false"
      >
        <v-icon>mdi-close-circle-outline</v-icon>
      </v-btn>
    </template>
  </v-snackbar>
</template>

<script>
  import { EventBus } from '@/event-bus.js';

  export default {
    name: 'BaseSnackbar',

    props: {
      color: {
        type: String,
        default: "info"
      },
      duration: {
        type: Number,
        default: 2000
      }
    },

    data: function () {
      return {
        snackbar: {
          display: false,
          timeout: this.duration,
          text: "",
          color: this.color
        }
      }
    },
    
    created() {
      EventBus.$on('showSnackbar', obj => {
        this.display(obj.text, obj.color, obj.duration);
      })
    },

    methods:{
      display(text, color, duration) {
        this.snackbar.text = text;
        this.snackbar.color = color;
        this.snackbar.timeout = (color != 'error') ? duration : 10000;
        this.snackbar.display = true;
      }
    }
  }
</script>