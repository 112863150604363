<template>
  <center>
    <v-img width="500" src="@/assets/images/desktop-only.jpg"></v-img>
    <h3>Le service Meilleurs Mandataires<br/>est uniquement disponible pour les écrans d'ordinateurs et tablettes</h3>
  </center>
</template>

<script>
  export default {
    name: 'DesktopOnly',
  
    mounted() {
      this.visibilitySIBWidget(false);
    }
  }
</script>

<style scoped>
center {
  margin: 5vw;
}
</style>