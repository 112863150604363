<template>
  <div>
    <base-box>
      <div class="d-flex">
        <div>
          <b>{{currentReseauA.name}}</b>
          <br/>
          Siège social : {{currentReseauA.address}}
          <br/>
          <b>{{agences.length}}</b> agences
        </div>
        <v-spacer></v-spacer>
        <v-img max-width="160" max-height="120" contain :src="agenceImageUrl(currentReseauA.id)"></v-img>
      </div>
    </base-box>

    <v-row justify="center" align-content="center" class="mt-6">
      <v-col cols="12" sm="12" md="6" lg="6">
        <div class="d-flex"> 
          <v-spacer></v-spacer>
          <div class="map-parent mx-auto">
            <scale
              class="map-scale"
              :gcolors="scaleColors"
              :labels="scaleLabels"
              :size="60"
            ></scale>
            <vector-map v-if="!loading"
              name="ragences"
              type="departements"
              :gradients="gradients"
              :gcolors="gcolors"
              @click="deptClick"
              @tip="deptTip"
              @markertip="agenceTip"
              @markerclick="agenceClick"
            >
            </vector-map>
          </div>
          <v-spacer></v-spacer>
        </div>
      </v-col>
      <v-col cols="12" sm="12" md="6" lg="6">
        <base-box title="Réseaux de mandataires">
          <v-simple-table dense>
            <template v-slot:default>
              <thead>
                <tr>
                  <th class="text-left">Nom</th>
                  <th class="text-left">Nombre de mandataires</th>
                  <th class="text-left">Nombre d'annonces</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="reseau in reseaux" :key="reseau.id" class="pointer" @click="reseauClick(reseau)">
                  <td>{{reseau.name}}</td>
                  <td>{{bigNumber(reseau.nb_mandataires)}}</td>
                  <td>{{bigNumber(reseau.nb_annonces)}}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </base-box>
      </v-col>
    </v-row>

    <!-- <div>
      <v-simple-table>
        <template v-slot:default>
          <tbody>
            <tr v-for="agence in filteredAgences" :key="agence.id">
              <td>{{agence.name}}</td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div> -->
  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import VectorMap from '@/components/VectorMap';
  import Scale from '@/components/Scale';

  const ss = require('simple-statistics');

  var $ = window.jQuery;

  export default {
    name: 'RAgences',

    mixins: [],

    components: {
      VectorMap,
      Scale,
    },

    data: () => ({
      loading: true,
      departements: [],
      agences: [],
      filteredAgences: [],
      reseaux: [],
      gradients: {},
      gcolors: ['#23CAC0', '#2362CA'],
      scaleLabels: {},
      scaleColors: [ '#2362ca', '#2385c7', '#23a7c3', '#23cac0' ],
    }),

    computed: {
      ...mapGetters('ReseauA', [ 'currentReseauA' ]),

      map() {
        return $('#ragences').vectorMap('get', 'mapObject');
      },
    },

    methods: {
      ...mapActions('ReseauA', [ 'fetchReseauAInfo', 'fetchReseauAAgences' ]),
      ...mapActions('Reseau', [ 'fetchAllReseauxWithMA' ]),

      async deptClick(code) {
        console.log("deptClick", code);

        this.map.tip.hide();

        // this.filteredAgences = this.agences.filter(a => a.dept == code).sort((a,b) => a.name.localeCompare(b.name));
      },

      deptTip({el, code}) {
        const count = this.agences.filter(a => a.dept == code).length;
        if (count) {
          el.html(el.html()+' : '+count+' agences');
        }
      },

      agenceTip({el, code}) {
        // code = idx
        const agence = this.agences[code];
        if (agence) {
          el.html(el.html()+'<br/>'+agence.address);
        }
      },

      agenceClick(code) {
        // code = idx
        const agence = this.agences[code];
        window.open(agence.url, '_blank');
      },

      reseauClick(reseau) {
        const map = $('#ragences').vectorMap('get', 'mapObject');
        map.tip.hide();
        this.$router.push({ name: 'reseau', params: { id: reseau.id } });
      },

      _computePercentile(result) {
        const clusters = 4;

        const values = result.map(r => r.nb);
        
        // clustering
        let ckmeans = null;
        try {
          ckmeans = ss.ckmeans(values, clusters);
        } catch(err) {
          // console.log("CKMEANS NULL");
        }

        console.log("ckmeans", ckmeans);
        
        if (ckmeans == null) {
          return null;
        }

        // percentiles = 0, 1 ...
        for (let i=0; i<result.length; i++)  {
          for (let j=0; j<ckmeans.length; j++) {
            const val = result[i].nb;
            if (ckmeans[j].includes(val)) {
              result[i].percentile_nb = j;
            }
          }
        }

        // scaleLabels : min/max
        this.scaleLabels = [];
        for (let mean of ckmeans) {
          this.scaleLabels.push({min:mean[0], max:mean[mean.length-1]});
        }
        this.scaleLabels = this.scaleLabels.reverse();

        return result;
      },

      _computeGradients() {
        this.gradients = {};
        for (let dept of this.departements) {
          this.gradients[dept.code] = dept.percentile_nb;
        }
      },
    },

    async mounted() {
      this.reseaux = await this.fetchAllReseauxWithMA();

      await this.fetchReseauAInfo(this.$route.params.id);
      
      this.agences = await this.fetchReseauAAgences(this.$route.params.id);
      
      // compute departements from agences for percentiles
      this.departements = this.agences.map(a => ({
        code: a.dept,
        nb: this.agences.filter(ag => ag.dept === a.dept).length
      }));
      this.departements = this._computePercentile(this.departements);
      this._computeGradients();
      console.log("departements", this.departements);
      console.log("scales", this.scaleLabels);
      console.log("gradients", this.gradients);

      this.loading = false;

      setTimeout(() => {
        const data = this.agences.map(a => {
          return {
            latLng: [a.lat, a.lng],
            name: a.name,
            style: { r: 3, fill: this.currentReseauA.color }
          }
        });

        this.map.removeAllMarkers();
        this.map.addMarkers(data, []);
      }, 200);
    }
  }
</script>

<style scoped>
.map-parent {
  position: relative;
}

.map-scale {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: white;
}
</style>