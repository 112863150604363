<template>
  <div class="d-flex">
    <v-icon class="mr-2" v-if="icon">{{icon}}</v-icon>
    <vuetify-money
      :options="moneyOptions"
      :properties="properties"
      valueWhenIsEmpty=""
      v-bind="$attrs"
      v-model="content"
      @input="handleInput"
      style="width:100%"
    ></vuetify-money>
  </div>
</template>

<script>
  // https://www.npmjs.com/package/vuetify-money

  export default {
    name: 'BaseMoneyTextField',

    props: [ 'value', 'icon' ],

    data() {
      return {
        content: this.value,
        moneyOptions: {
          locale: "fr-FR",
          prefix: "",
          suffix: "",
          precision: 0,
          min: 0,
        },
        properties: {
          // type: "number",
          // step: "1000"
        }
      }
    },

    // to set value from parent 
    watch: {
      value: function (val) {
        this.content = val;
      }
    },

    methods: {
      handleInput() {
        this.$emit('input', this.content);
      }
    }
  }
</script>