<template>
  <v-list-item
    :href="href"
    :rel="href && href !== '#' ? 'noopener' : undefined"
    :to="to"
    :active-class="``"
    @click="goclick"
  >
   
    <v-list-item-icon v-if="item.icon">
      <v-icon v-text="item.icon" />
    </v-list-item-icon>
    

    <v-list-item-content v-if="item.title">
      <v-list-item-title v-text="item.title" />
    </v-list-item-content>
  </v-list-item>
</template>

<script>
  import Themeable from 'vuetify/lib/mixins/themeable'

  export default {
    name: 'BaseItem',

    mixins: [Themeable],

    props: {
      item: {
        type: Object,
        default: () => ({
          href: undefined,
          icon: undefined,
          title: undefined,
          to: undefined,
        }),
      },
      text: {
        type: Boolean,
        default: false,
      },
      click: {
        type: Function
      },
    },

    computed: {
        
      href () {
        return this.item.href || (!this.item.to ? '#' : undefined)
      },

      to() {
        if (!this.item.to) return undefined;
        return this.item.params ? { name: this.item.to, params: this.item.params } : { name: this.item.to }
      }
    },

    methods: {
      goclick() {
        this.$emit('click', this.$route.name);
      }
    }
  }
</script>
