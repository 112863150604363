<template>
  <div class="map-container">
    <div :id="name" class="jvmap-smart"></div>
  </div>
</template>

<script>
  window.jQuery = require('jquery'); // npm -i jquery
  var $ = window.jQuery;

  require('jvectormap');  // https://jvectormap.com/

  export default {
    name: 'VectorMap',

    props: {
      name: {
        type: String,
        required: true
      },
      type: {
        type: String,
        required: true
      },
      fillcolor: {
        type: String,
        default: '#ededed'
      },
      hovercolor: {
        type: String,
        default: '#FFC300'
      },
      selcolor: {
        type: String,
        default: 'orange'
      },
      selected: {
        type: Array,
        default: () => []
      },
      zoomable: {
        type: Boolean,
        default: true
      },
      area: {
        type: String,
      },
      click: {
        type: Function
      },
      hover: {
        type: Function
      },
      out: {
        type: Function
      },
      tip: {
        type: Function
      },
      markerclick: {
        type: Function
      },
      markerhover: {
        type: Function
      },
      markerout: {
        type: Function
      },
      markertip: {
        type: Function
      },
      colors: {
        type: Object
      },
      gradients: {
        type: Object
      },
      gcolors: {
        type: Array
      },
      markers: {
        type: Array,
        default: () => []
      },
      zoomAnimate: {
        type: Boolean,
        default: false
      }
    },

    data: () => ({
    }),

    computed: {
    },

    methods: {
    },

    mounted() {
      require('./' + this.type + '.js');

      const self = this;

      const mapName = '#'+this.name;

      $(mapName).vectorMap({
        map: 'fr_'+this.type,
        backgroundColor: 'transparent',
        zoomButtons : this.zoomable,
        zoomOnScroll: this.zoomable,
        zoomAnimate: this.zoomAnimate,
        regionStyle: {
          initial: {
            fill: this.fillcolor,
            "fill-opacity": 1,
            stroke: '#505050',
            "stroke-width": 1,
            "stroke-opacity": 1
          },
          hover: {
            "fill-opacity": 0.8,
            cursor: 'pointer',
            fill: this.hovercolor
          },
          selected: {
            fill: this.selcolor || '',
            "stroke-width": this.selcolor ? 1 : 4,
          },
          selectedHover: {
          },
        },
        series: {
            regions: [{
              values: self.gradients ? self.gradients : self.colors,
              attribute: 'fill',
              scale: self.gcolors || null
            }],
            markers: [{
              values: [],
            }]
        },
        onRegionOver: function(e, code) {
          self.$emit('hover', code);
        },
        onRegionOut:  function(e, code) {
          self.$emit('out', code);
        },
        onRegionClick: function(e, code) {
          self.$emit('click', code);
        },
        onRegionTipShow: function(e, el, code) {
          self.$emit('tip', {el: el, code: code});
        },
        onMarkerOver: function(e, code) {
          self.$emit('markerhover', code);
        },
        onMarkerOut: function(e, code) {
          self.$emit('markerout', code);
        },
        onMarkerClick: function(e, code) {
          self.$emit('markerclick', code);
        },
        onMarkerTipShow: function(e, el, code) {
          self.$emit('markertip', {el: el, code: code});
        },
        onViewportChange: function(e, scaleFactor) {
          var map = $(mapName).vectorMap("get", "mapObject");
          if(map) {
            var markers = map.markers,
            min = 3, 
            max = 15,
            r = ~~Math.min(Math.max(min * scaleFactor, min), max);
            for(var m in markers) {
              var el = markers[m].element,
                  style = el.config.style;
              el.shape.node.setAttribute('r', r);
              style.initial.r = r;
              style.hover.r = r;
            }
          }
        },
        selectedRegions: this.selected,
        markerStyle: {
          initial: {
            fill: '#F8E23B',
            stroke: '#383f47'
          }
        },
        markers: this.markers
      });

      // change colors
      // const map = $('#'+this.name).vectorMap('get', 'mapObject');
      // map.series.regions[0].setValues(this.colors);
    }
  }
</script>

<style>
  @import './jquery-jvectormap-2.0.5.css';

  .map-container {
      width: 500px;
      height: 500px;
  }
  .jvmap-smart {
      width: 100%; 
      height: 100%;  
  }
  .text-center {
      text-align:center;
      font-weight: 300;
      font-size: 2rem;
  }
  .map-container:after, .clearfix {
      display: block;
      content: '';
      clear: both;
  }

  @media only screen and (min-width: 576px) {
      .map-container{
        width: 300px;
        height: 300px;
      }
  }
  @media only screen and (min-width: 768px) {
      .map-container{
        width: 400px;
        height: 400px;
      }
  }
  @media only screen and (min-width: 992px) {
      .map-container{
        width: 500px;
        height: 500px;
      }
  }
  @media only screen and (min-width: 1200px) {
      .map-container{
        width: 600px;
        height: 600px;
      }
  }
</style>