<template>
  <div>
  	<v-overlay v-if="loadInfo" :opacity="0.3">
  	  <v-progress-circular indeterminate size="50">
  	  </v-progress-circular>
  	</v-overlay>

		<base-box :title="`Recrutement de mandataires ${count}`">
			<v-text-field
				id="step-recruit-1"
        dense
        class="searchtextinput"
        placeholder="rechercher"
        v-model="search"
      ></v-text-field>

			<v-data-table
				ref="myTable"
			  :headers="headersShow"
			  :items="user.recruit"
			  item-key="id"
			  disable-pagination
			  hide-default-footer
			  class="table-top-mand"
			  :search="search"
			  :custom-filter="customFilter"
			  :custom-sort="customSort"
			  :expanded.sync="expanded"
			  show-expand
			>
				<template v-slot:[`item.name`]="{item}"
	      >
	        <div class="d-flex align-center" >
	        	<photo-mand
	        	  :obj="item"
	        	  :size="45"
	        	  class="my-2 mr-2 pointer"
	        	  @onClick="gotoMand"
	        	  :clickData="item.id"
	        	></photo-mand>
	        	<div class="my-2 align-center" style="width:100%">
	        		{{item.name}}
		        	<span v-if="!hasNotDateInfo(item)">
		        		<br/>
		        		<span class="copy" @click="copy(item, 'phone')">{{item.phone}}</span>
		        		<br/>
		        		<span class="copy" @click="copy(item, 'email')">{{item.email}}</span>
		        	</span>
		        </div>
	        </div>
	      </template>

				<template v-slot:[`item.reseau`]="{item}">
					<v-img max-width="60" max-height="60" contain :src="franchiseImageUrl(item.id_franchise)" class="pointer my-2" @click="gotoReseau(item.id_franchise)"></v-img>
				</template>

				<template v-slot:[`item.commune`]="{item}">
					<div class="pointer" @click="gotoCommune(item.commune_id)">
						{{item.nom_commune_complet}}
						<br/>
						({{item.code_postal}})
					</div>
				</template>

				<template v-slot:[`item.nom_departement`]="{item}">
					{{item.nom_departement}}
				</template>

				<template v-slot:[`item.nom_region`]="{item}">
					{{item.nom_region}}
				</template>

				<template v-slot:[`item.open`]="props">
					<v-edit-dialog
						large
						save-text="Sauver"
						cancel-text="Annuler"
	          :return-value.sync="props.item"
	          @save="saveData('date_open', props.item)"
	        >
	          <span id="step-recruit-2">{{ formatDate(props.item.date_open) }}</span>
	          <br/>
	          <v-tooltip bottom>
	            <template v-slot:activator="{ on, attrs }">
	          		<v-icon v-if="props.item.date_open" v-on="on" v-bind="attrs" small class="ml-1" @click.stop="saveData('date_open', props.item, '')">mdi-close</v-icon>
	          	</template>
	          	Supprimer
	          </v-tooltip>
	          <template v-slot:input>
	          	<v-date-picker
	          		no-title
	          		locale="fr-FR"
      	        v-model="props.item.date_open"
      	      ></v-date-picker>
	          </template>
	        </v-edit-dialog>
				</template>

				<template v-slot:[`item.call`]="props">
					<v-edit-dialog
						large
						save-text="Sauver"
						cancel-text="Annuler"
	          :return-value.sync="props.item"
	          @save="saveData('date_call', props.item)"
	        >
	          {{ formatDate(props.item.date_call) }}
	          <br/>
	          <v-tooltip bottom>
	            <template v-slot:activator="{ on, attrs }">
	            	<v-icon v-if="props.item.date_call" v-on="on" v-bind="attrs" small class="ml-1" @click.stop="saveData('date_call', props.item, '')">mdi-close</v-icon>
	          	</template>
	          	Supprimer
	          </v-tooltip>
	          <template v-slot:input>
	          	<v-date-picker
	          		no-title
	          		locale="fr-FR"
      	        v-model="props.item.date_call"
      	      ></v-date-picker>
	          </template>
	        </v-edit-dialog>
				</template>

				<template v-slot:[`item.meet`]="props">
					<v-edit-dialog
						large
						save-text="Sauver"
						cancel-text="Annuler"
	          :return-value.sync="props.item"
	          @save="saveData('date_meet', props.item)"
	        >
	          {{ formatDate(props.item.date_meet) }}
	          <br/>
	          <v-tooltip bottom>
	            <template v-slot:activator="{ on, attrs }">
	            	<v-icon v-if="props.item.date_meet" v-on="on" v-bind="attrs" small class="ml-1" @click.stop="saveData('date_meet', props.item, '')">mdi-close</v-icon>
	          	</template>
	          	Supprimer
	          </v-tooltip>
	          <template v-slot:input>
	          	<v-date-picker
	          		no-title
	          		locale="fr-FR"
      	        v-model="props.item.date_meet"
      	      ></v-date-picker>
	          </template>
	        </v-edit-dialog>
				</template>

				<template v-slot:[`item.close`]="props">
					<v-edit-dialog
						large
						save-text="Sauver"
						cancel-text="Annuler"
	          :return-value.sync="props.item"
	          @save="saveData('date_close', props.item)"
	        >
	          {{ formatDate(props.item.date_close) }}
	          <v-tooltip bottom>
	            <template v-slot:activator="{ on, attrs }">
	          		<v-icon v-if="props.item.date_close" v-on="on" v-bind="attrs" small class="ml-1" @click.stop="saveData('date_close', props.item, '')">mdi-close</v-icon>
	          	</template>
	          	Supprimer
	          </v-tooltip>
	          <template v-slot:input>
	          	<v-date-picker
	          		no-title
	          		locale="fr-FR"
      	        v-model="props.item.date_close"
      	      ></v-date-picker>
	          </template>
	        </v-edit-dialog>
				</template>

				<template v-slot:[`item.ok`]="props">
					<v-edit-dialog
						large
						save-text="Sauver"
						cancel-text="Annuler"
	          :return-value.sync="props.item"
	          @save="saveData('date_ok', props.item)"
	        >
	          {{ formatDate(props.item.date_ok) }}
	          <br/>
	          <v-tooltip bottom>
	            <template v-slot:activator="{ on, attrs }">
	          		<v-icon v-if="props.item.date_ok" v-on="on" v-bind="attrs" small class="ml-1" @click.stop="saveData('date_ok', props.item, '')">mdi-close</v-icon>
	          	</template>
	          	Supprimer
	          </v-tooltip>
	          <template v-slot:input>
	          	<v-date-picker
	          		no-title
	          		locale="fr-FR"
      	        v-model="props.item.date_ok"
      	      ></v-date-picker>
	          </template>
	        </v-edit-dialog>
				</template>

				<template v-slot:[`item.actions`]="{item}">
					<v-tooltip bottom>
					  <template v-slot:activator="{ on, attrs }">
							<v-icon v-on="on" v-bind="attrs" color="red" @click.stop="removeMand(item)">mdi-close-circle-outline</v-icon>
						</template>
						<span class="red--text">Supprimer</span>
					</v-tooltip>
					<v-tooltip bottom>
					  <template v-slot:activator="{ on, attrs }">
							<v-icon v-on="on" v-bind="attrs" v-if="hasNotDateInfo(item)" color="blue" @click.stop="getInfoMand(item)">mdi-download-circle-outline</v-icon>
						</template>
						<span class="blue--text">Récupérer informations</span>
					</v-tooltip>
					<span id="step-recruit-3"></span>
				</template>

			  <template v-slot:expanded-item="{ headers, item }">
	        <td :colspan="headers.length" class="py-2">
	        	<v-textarea
      	      label="Note"
      	      v-model="item.notes"
      	    ></v-textarea>
	          <v-icon class="pointer" @click="saveData('notes', item)">mdi-content-save-edit</v-icon>
	        </td>
	      </template>
			</v-data-table>
		</base-box>

		<BaseSnackbar />

		<v-tour name="Recruit" :steps="recruitSteps"></v-tour>

  </div>
</template>

<script>
  import { mapActions, mapGetters } from "vuex";
  import { EventBus } from '@/event-bus.js';
  import PhotoMand from "@/components/PhotoMand";
  import mixinTour from "@/mixins/mixin-tour-steps";
  import moment from 'moment';

  export default {
    name: 'Recruit',

    mixins: [mixinTour],

    components: {
    	PhotoMand,
    },

    data: () => ({
      loading: true,
      headers: [
      	{ text: "", value: 'data-table-expand', show: true },
      	{ text: "Mandataire", value: 'name', align: 'center', show: true },
      	{ text: "Réseau", value: 'reseau', align: 'center', show: true, width: 100, sortable: false, filterable: false },
      	{ text: "Commune", value: 'commune', align: 'center', show: true },
      	{ text: "Département", value: 'nom_departement', align: 'center', show: true },
      	{ text: "Région", value: 'nom_region', align: 'center', show: true },
      	{ text: "Début", value: 'open', align: 'center', show: true },
      	{ text: "Appel", value: 'call', align: 'center', show: true },
      	{ text: "Renc.", value: 'meet', align: 'center', show: true },
      	{ text: "Refus", value: 'close', align: 'center', show: true },
      	{ text: "Ok", value: 'ok', align: 'center', show: true },
      	{ text: "Actions", value: 'actions', align: 'center', show: true, sortable: false, filterable: false },
      ],
      search: '',
      expanded: [],
      loadInfo: false,
      limitDays: 10,
    }),

    computed: {
    	...mapGetters('User', [ 'user', 'userUUID' ]),

      count() {
      	return this.user.recruit && this.user.recruit.length ? `(${this.user.recruit.length})` : "";
      },

      headersShow() {
        return this.headers.filter(h => h.show);
      },
    },

    methods: {
    	...mapActions('User', [ 'fetchUserInfo', 'updateRecruit', 'removeRecruit' ]),
      ...mapActions('Reseau', [ 'fetchAllReseauxWithMA' ]),

      gotoMand(mandId) {
        this.$router.push({ name: 'mandataire', params: { id: mandId } });
      },

      gotoReseau(franchiseId) {
        this.$router.push({ name: 'reseau', params: { id: franchiseId } });      	
      },

      gotoCommune(communeId) {
        this.$router.push({ name: 'mapbox', params: { id: communeId } });      	
      },

      async saveData(what, item, forcedValue) {
      	// console.log("SAVE OPEN", item, item.date_open, forcedValue);
      	this.showSnackbar("Profil mis à jour");

      	await this.updateRecruit({
      	  userUuid: this.userUUID,
      	  mandId: item.id,
      	  body: {
      	  	attr: what,
      	  	value: forcedValue == '' ? null : item[what]
      	  }
      	});
      	if (forcedValue == '') await this.fetchUserInfo(this.userUUID);
      },

      toto() {
      	setTimeout(() => {
      		console.log(this.$refs.myTable);
	      	if (this.$refs.myTable.$el) {
	      		console.log("click on table");
	      		this.$refs.myTable.$el.click();
	      		this.$refs.myTable.$el.click();
	      		this.$refs.myTable.$el.click();
	      	}
	      });
      },

      removeMand(mand) {
      	this.showYesNo({
      	  icon: 'alert',
      	  yes : "Supprimer",
      	  no: "Annuler",
      	  message: `Voulez-vous supprimer<br/><b>${mand.name}</b><br/>de votre liste de recrutement ?`,
      	  onClick: this.removeMandConfirm,
      	  dataClick: mand
      	});
      },

      async removeMandConfirm(what, mand) {
      	console.log("removeMandConfirm", what, JSON.stringify(mand));
      	if (what == 'no') return;

      	await this.removeRecruit({
      	  userUuid: this.userUUID,
      	  mandId: mand.id
      	});
      	await this.fetchUserInfo(this.userUUID);
      },

      getInfoMand(mand) {
      	this.showYesNo({
      	  icon: 'info',
      	  yes : "Récupérer",
      	  no: "Annuler",
      	  message: `Il est peut-être possible de récupérer les informations téléphone et adresse email du mandataire<br/>
      	  <b>${mand.name}</b><br>
      	  sur le site du réseau ${mand.rname}.<br/><br/>Souhaitez-vous que nous tentions ?<br/></br>
      	  <i>Attention : ces informations seront disponibles pendant ${this.limitDays} jours, après quoi il faudra renouveler cette action.</i>`,
      	  onClick: this.getInfoMandConfirm,
      	  dataClick: mand
      	});
      },

      async getInfoMandConfirm(what, mand) {
      	console.log("getInfoMandConfirm", what, JSON.stringify(mand));
      	if (what == 'no') return;
      	
      	this.loadInfo = true;

      	setTimeout(async () => {
	      	await this.updateRecruit({
	      	  userUuid: this.userUUID,
	      	  mandId: mand.id,
	      	  body: {
	      	  	attr: 'date_info',
	      	  	value: moment().format('YYYY-MM-DD')
	      	  }
	      	});
	      	await this.fetchUserInfo(this.userUUID);

	      	this.loadInfo = false;

	      	let message = `Informations du mandataire ${mand.name} récupérées.`
	      	let color = 'primary';
	      	let duration = 1500;
	      	let errors = [];
	      	if (!mand.email) {
	      		errors.push("Adresse email vide.");
	      	}
	      	if (!mand.phone) {
	      		errors.push("Téléphone vide.");
	      	}
	      	if (mand.email && mand.generated_email) {
	      		errors.push("Attention : l'email a été généré, il n'est donc pas 100% sûr.")
	      	}
	      	if (errors.length) {
	      		message += "<br/><br/>" + errors.join("<br/>");
	      		color = 'error';
	      		duration = 2500;
	      	}
	      	this.showSnackbar(message, color, duration);
	      }, 3000);
      },

      customFilter(value, search, item) {
        return Object.values(item).some(v=>v&&v.toString().toLowerCase().includes(search.toLowerCase()));
      },

      customSort(items, index, isDesc) {
        items.sort((a, b) => {
          switch (index[0]) {
            case 'name':
            case 'nom_departement':
            case 'nom_region':
              return !isDesc[0] ? a[index[0]].localeCompare(b[index[0]]) : b.city.localeCompare(a[index[0]]);
            case 'commune':
              return !isDesc[0] ? a.code_postal - b.code_postal : b.code_postal-a.code_postal;
            case 'open':
            case 'call':
            case 'meet':
            case 'close':
            case 'ok':
              return !isDesc[0] ? (moment(a[index[0]]) - moment(b[index[0]])) : (moment(b[index[0]]) - moment(a[index[0]]));
          }
        });
        return items;
      },

      hasNotDateInfo(item) {
      	if (!item.date_info) return true;

      	const diff = moment().diff(moment(item.date_info), 'days');
      	// console.log("diff", diff);
      	return diff > this.limitDays;
      },

      copy(mand, what) {
      	const text = mand[what];
      	navigator.clipboard.writeText(text);
      	const msg = what == 'phone' ? "Téléphone copié" : "Adresse email copiée";
      	this.showSnackbar(msg)
      }
  	},

    async mounted() {
    	await this.fetchUserInfo(this.userUUID);
    },

    updated() {
      this.loading = false;
    }
  }
</script>

<style scoped>
.num {
	display: inline-block;
  width: 50px;
}
.searchtextinput {
	max-width: 250px;
}
.copy {
	cursor: context-menu;
	border: 0;
	outline: 0;
	text-align: center;
}
.copy::selection {
  color: none;
}
::v-deep .v-data-table__wrapper > table > thead > tr > th {
	font-weight: 600;
}
</style>