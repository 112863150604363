import http from './httpClient';

const END_POINT = '/mandataire';

const find = (franchiseId, email) => {
    const args = [
        { arg: 'franchiseId', value: franchiseId },
        { arg: 'email', value: email },
    ];
    return http.get(http.path([END_POINT,"find"])+http.args(args));
}
const getOne = (mandId) => http.get(http.path([END_POINT,mandId]));
const getProperties = (mandId) => http.get(http.path([END_POINT,mandId,'properties']));
const getHistoProperties = (mandId) => http.get(http.path([END_POINT,mandId,'histo-properties']));
const getZones = (mandId, year) => http.get(http.path([END_POINT,mandId,'zones',year]));
const getStats = (mandId) => http.get(http.path([END_POINT,mandId,'stats']));
const getManyFromName = (str) => http.get(http.path([END_POINT,"names",str]));
const addLink = (mandId1, mandId2, type) => http.get(http.path([END_POINT,mandId1,'add'+type,mandId2]));
const removeLink = (mandId1, mandId2, type) => http.get(http.path([END_POINT,mandId1,'remove'+type,mandId2]));
const linkStatus = (linkId, token, status) => http.post(http.path([END_POINT,'linkstatus',linkId,token,status]));

const newInZones = (userId, mandId, period, cache) => http.get(http.path([END_POINT,mandId,'newInZones',userId])+'?period='+period+'&cache='+cache);

export {
    find,
    getOne,
    getManyFromName,
    getHistoProperties,
    getProperties,
    getZones,
    getStats,
    addLink,
    removeLink,
    linkStatus,
    newInZones,
}